import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Page } from "vetrf-ui";
import CommonInformationECertView from "./components/CommonInformationECertView";
import PvkpView from "./components/PvkpView";
import OrganizationView from "./components/OrganizationView";
import OperationLogTimeLine from "./components/OperationLogTimeLine";
import { requestOperationLogsForECert } from "../../../actions/listLogsAction";
import { requestECertImport } from "../../../actions/viewECertActions";
import { requestUnCefactXml } from "../../../actions/loadXmlAction";
import XmlView from "./components/XmlView";
import TransportView from "./components/TransportView";
import { useParams } from "react-router-dom";

const title = "Просмотр импортного сертификата";
const breadcrumbs = [
    {
        home: true,
        link: "/",
        text: "Главная",
    },
    {
        link: "/import/cert/",
        text: "Импортные сертификаты",
    },
    {
        active: true,
        text: title,
    },
];

const ECertImportViewPage = ({ eCert, log, xmlData, list, requestECertImport, requestOperationLogsForECert, requestUnCefactXml }) => {
    const { id } = useParams();

    useEffect(() => {
        if (eCert && id !== eCert.id) {
            requestECertImport(id);
        }
        if (!log || (eCert && id !== eCert.id)) {
            requestOperationLogsForECert(id);
        }
        if (!xmlData || (eCert && id !== eCert.id)) {
            requestUnCefactXml(id);
        }
    }, []);

    return (
        <Page title={title} header={title} breadcrumbs={breadcrumbs}>
            {eCert && <CommonInformationECertView eCert={eCert} />}

            {eCert && eCert.pvkp && <PvkpView pvkp={eCert ? eCert.pvkp : {}} />}
            {eCert && eCert.transport && <TransportView transport={eCert ? eCert.transport : {}} />}
            <div className={"row"}>
                {eCert && eCert.consignor && (
                    <div className={"col-lg-6"}>
                        <OrganizationView org={eCert ? eCert.consignor : {}} headerName={"Юридический грузоотправитель"} />
                    </div>
                )}
                {eCert && eCert.despatch && (
                    <div className={"col-lg-6"}>
                        <OrganizationView org={eCert ? eCert.despatch : {}} headerName={"Фактический грузоотправитель"} />
                    </div>
                )}
            </div>
            <div className={"row"}>
                {eCert && eCert.consignee && (
                    <div className={"col-lg-6"}>
                        <OrganizationView org={eCert ? eCert.consignee : {}} headerName={"Юридический грузополучатель"} />
                    </div>
                )}

                {eCert && eCert.delivery && (
                    <div className={"col-lg-6"}>
                        <OrganizationView org={eCert ? eCert.delivery : {}} headerName={"Фактический грузополучатель"} />
                    </div>
                )}
            </div>
            <div className={"row"}>
                <div className={"col-lg-4"}>
                    <OperationLogTimeLine logs={list} />
                </div>
                {xmlData && xmlData.xmlExtData && (
                    <div className={"col-lg-8"}>
                        <XmlView xml={xmlData.xmlExtData} />
                    </div>
                )}
            </div>
        </Page>
    );
};

function mapStateToProps(state) {
    return {
        eCert: state.processing.eCertStore.eCert,
        xmlData: state.processing.eCertStore.xmlData,
        error: state.processing.eCertStore.error,
        list: state.processing.logStore.log.logs,
    };
}

export default connect(mapStateToProps, {
    requestECertImport,
    requestOperationLogsForECert,
    requestUnCefactXml,
})(ECertImportViewPage);
