import { useCustomQuery } from "../../utils/commonQueryBase";

const baseUrl = "/rest-api/info/version";

export function useIntervalAppInfo(intervalInSeconds, enabled) {
    return useCustomQuery(
        baseUrl,
        { headers: { "Cache-Control": "no-cache, no-store, must-revalidate", Pragma: "no-cache", Expires: "0" } },
        {
            refetchInterval: intervalInSeconds * 1000,
            enabled,
        }
    );
}
