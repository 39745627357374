import { producerRegistry } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке производителей";

export const requestProducers = (nameOrNumber) =>
    createLoadAsyncAction(
        producerRegistry,
        request,
        {
            type: "GET",
            url: `producer?nameOrNumber=${nameOrNumber}`,
        },
        errorMessage
    );

export const resetProducers = () => (dispatch) => dispatch(producerRegistry.reset());
