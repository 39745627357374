import { researchResultStatus } from "./research-result-statuses";

export const RUSSIA = {
    code: "RU",
    guid: "74a3cbb1-56fa-94f3-ab3f-e8db4940d96b",
    view: "Российская Федерация (Russian Federation)",
    name: "Российская Федерация",
    englishName: "Russian Federation",
};

export const VIET_NAM = {
    guid: "4ca20feb-6a9e-126e-b9cf-8ab92a042fab",
    view: "Вьетнам (Viet Nam)",
    name: "Вьетнам",
    englishName: "Viet Nam",
};

export const UAE = {
    guid: "0cec5623-8755-d3a8-0a12-8f9dc2f4e44d",
    view: "Объединенные Арабские Эмираты (United Arab Emirates)",
    name: "Объединенные Арабские Эмираты",
    englishName: "United Arab Emirates",
};

export const CHINA = {
    guid: "14abc6f9-0e71-7b4b-8a2e-c7a040857c7b",
    view: "Китай (China)",
    name: "Китай",
    englishName: "China",
    fullEnglishName: "The People's Republic of China",
    fullName: "Китайская Народная Республика",
};

export const GEORGIA = {
    guid: "62768b23-14c1-bde6-341e-d237d1388e44",
    view: "Грузия (Georgia)",
    name: "Грузия",
    englishName: "Georgia",
};

export const ISSUED = {
    code: "ISSUED",
    name: "Оформлен",
};

export const MAX_TEXT_LENGTH = 255;

export const documentStatusList = [
    {
        code: "NEW",
        name: "Создан",
    },
    {
        code: "IN_PROCESS",
        name: "Начата процедура оформления",
    },
    {
        code: "ISSUED",
        name: "Оформлен",
    },
    {
        code: "WITHDRAWN",
        name: "Аннулирован",
    },
];

export const operationTypes = [
    {
        name: "ACKNOWLEDGE",
        type: "ACKNOWLEDGE",
    },
    {
        name: "ACCEPT",
        type: "ACCEPT",
    },
    {
        name: "LOAD",
        type: "LOAD",
    },
    {
        name: "REJECT",
        type: "REJECT",
    },
    {
        name: "REPRODUCE",
        type: "REPRODUCE",
    },
    {
        name: "REPAIR",
        type: "REPAIR",
    },
    {
        name: "SAVE_ACCEPT",
        type: "SAVE_ACCEPT",
    },
    {
        name: "SAVE_REJECT",
        type: "SAVE_REJECT",
    },
    {
        name: "COMPLETE",
        type: "COMPLETE",
    },
    {
        name: "NOTIFY_STATUS",
        type: "NOTIFY_STATUS",
    },
];

export const statusList = [
    {
        name: "ISSUED",
        type: "ISSUED",
    },
    {
        name: "APPROVED",
        type: "APPROVED",
    },
    {
        name: "REPLACED",
        type: "REPLACED",
    },
    {
        name: "ACKNOWLEDGED",
        type: "ACKNOWLEDGED",
    },
    {
        name: "CONFIRMED",
        type: "CONFIRMED",
    },
    {
        name: "WITHDRAWN",
        type: "WITHDRAWN",
    },
];

export const unknownNormalText = "Не указано";

export const getUnknownNormalText = (t) => {
    return t(unknownNormalText);
};
export const getUnknownCursiveText = (t) => {
    return <i>{t(unknownNormalText)}</i>;
};
export const getUnknownRequiredText = (t) => {
    return <i className="unknown">{t(unknownNormalText)}</i>;
}

export const emptyErrorMessage = "Данное поле должно быть заполнено";

export const CHECK_FORM_ERRORS_TIME = 300;

export const KGM = {
    id: "KGM",
    code: "KGM",
    shortName: "кг",
    name: "килограмм",
    englishName: "kg",
    englishShortName: "kg",
    mercuryGuid: "21ed96c9-337b-4a27-8761-c6e6ad3c9f5b",
};

export const cartonsBox = {
    id: "f3ec0e66-e21d-11ea-8c54-4e15304ebc29",
    unCode: "CT",
    mercuryGuid: "fedf4328-053c-11e1-99b4-d8d385fbc9e8",
    name: "Коробка, картонная",
    enName: "Carton",
    view: "Коробка, картонная (Carton)",
};

export const Slaughter = {
    id: "e06226fb-459f-4266-8867-f9a9c234ca94",
    name: "Убой",
    englishName: "Slaughter",
    unCode: "12",
    view: "Убой (Slaughter)",
};

export const Cutting = {
    id: "36743998-b282-4df8-9aec-ff4e908dfc6e",
    name: "Нарезка/разделка",
    englishName: "Cutting",
    unCode: "34",
    view: "Нарезка/разделка (Cutting)",
};

export const Freezing = {
    id: "7f3ed6ca-87c0-4ece-8785-76d483528bae",
    name: "Замораживание",
    englishName: "Freezing",
    unCode: "7",
    view: "Замораживание (Freezing)",
};
export const Manufacturing = {
    id: "75fe1405-1bea-4377-b830-a3c5c594f5fc",
    name: "Производство (из сырья вручную или с помощью машин)",
    englishName: "Manufacturing",
    unCode: "37",
    view: "Производство (Manufacturing)",
};

export const DEFAULT_LABORATORY_RESEARCH_RESULT = researchResultStatus.STATUS_NONE;
export const NONE_IDENTIFICATION_SYSTEM_ID = "6a85d663-eb27-4be3-8190-8e6bbf46d015";
export const CHIP_IDENTIFICATION_SYSTEM_ID = "b26ccde9-14ba-47cf-9302-c41c1890e077";
export const TATTOO_IDENTIFICATION_SYSTEM_ID = "f5fd5236-ff5b-4ccc-98a6-3e26df9f2bd6";

export const CAT_SPECIES_ID = "11ebf430-37f7-498d-af7e-eb4c27aec6fc";
export const DOG_SPECIES_ID = "91a75797-e28e-45bd-b090-c12d01a9938d";

//Переменная для перевода миллисекунд в сутки и наоборот
//1 * 24 * 60 * 60 * 1000 = 86400000
export const MILLISECONDS_IN_DAY = 86400000;

export const ORGANIZATION_TYPES = {
    HEAD_DEPARTMENT: "HD", // Центральный аппарат
    TERRITORIAL_DEPARTMENT: "TD", //Территориальное управление
    LABORATORY: "LB", //Ветеринарная лаборатория
    BORDER_INSPECTION_POINT: "BP", //Пункт ветеринарного контроля
    VETERINARY_DEPARTMENT: "VD", //Ветеринарное управление Россельхознадзора
    RESEARCH_INSTITUTE: "RI", //Научно-исследовательский институт
    REFERENCE_CENTER: "RC", // Референтный центр
    FEDERAL_ENTERPRISE: "FE", // ФГУП
    ANIMAL_HEALTH_CENTER: "AH", // СББЖ
    DEPARTMENT: "DE", // Ведомство
    PRIVATE_LABORATORY: "PL", //Частная ветеринарная лаборатория
};

export const ORGANIZATION_ACTIVITIES = {
    WBR_ISSUE: "ca05b3a1-f11f-4167-97ed-816e9039d5f2",
};

export const INTENDED_USE_TYPES = {
    CANNING_INDUSTRY: "CANNING_INDUSTRY",
    CULTURE_BREEDING: "CULTURE_BREEDING",
    FUTURE_PROCESSING: "FUTURE_PROCESSING",
    HUMAN_CONSUMPTION: "HUMAN_CONSUMPTION",
    LIVE_AQUATIC_ANIMALS: "LIVE_AQUATIC_ANIMALS",
    ORNAMENTAL_PURPOSES: "ORNAMENTAL_PURPOSES",
    RESEARCH_AND_INVESTIGATION: "RESEARCH_AND_INVESTIGATION",
    OTHER: "OTHER",
};

export const EXPORT_DESTINATION_TYPES = {
    FOR_INTERNAL_MARKET: "FOR_INTERNAL_MARKET",
    FOR_TRANSIT: "FOR_TRANSIT",
    TEMPORARY_STORAGE: "TEMPORARY_STORAGE",
};

export const AQUATIC_BIOLOGICAL_RESOURCE_TYPE = {
    AQUACULTURED: "AQUACULTURED",
    MIXED: "MIXED",
    WILD_CAUGHT: "WILD_CAUGHT",
};

export const PROCESS_TYPE_ID = {
    SLAUGHTER: "e06226fb-459f-4266-8867-f9a9c234ca94", // Убой
    CUTTING: "36743998-b282-4df8-9aec-ff4e908dfc6e", // Нарезка
    FREEZING: "7f3ed6ca-87c0-4ece-8785-76d483528bae", // Замораживание
    MANUFACTURING: "75fe1405-1bea-4377-b830-a3c5c594f5fc", // Производство
    HARVESTING: "079ef922-cea9-45b3-a879-636a855353b5", // Добыча
    CATCHING: "98897b83-1ea5-4583-a624-bb357dce3846", // Вылов
    TRANSFERRING: "961d92be-5680-47cc-a5c0-c84172d3e680", // Перемещение
    PROCESSING: "a65e1fde-4213-4218-86cd-a51fd663a2ba", // Обработка/Переработка
    CHILLING: "fa8d7505-e1cd-4c57-acb1-fab71176a09d", // Охлаждение
};

export const TRANSPORT_TYPE_GUID = {
    MARITIME: "32c2e908-7bcc-470e-b219-bb14c1b842ca",
    INLAND_WATER: "d7102953-dc71-439e-b442-8b7c6681282e",
    ROAD_TRANSPORT: "f76a39ae-adad-4a46-afb2-81a2de7435b7",
    AIR_TRANSPORT: "405896b5-1f77-4d21-95fa-e0c623f698e6",
    RAIL_TRANSPORT: "e9c94066-c86f-4cd7-92bb-f617ddef7ab0",
    TRANSPORT_NOT_APPLICABLE: "fd7dbc0a-8aa6-4664-b044-1779da1b95e6",
};

export const WATER_AREA_TYPE = {
    FRESH: "FRESH", // Пресная (Fresh water)
    SEA: "SEA", // Морская (Sea water)
};
