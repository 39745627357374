import { Route, Routes } from "react-router-dom";
import React from "react";

import { business_participant_modify, business_participant_view } from "../common/constants/permissions";
import BusinessParticipantListPage from "./containers/businessParticipants/BusinessParticipantListPage";
import CreateFormPage from "./containers/businessParticipants/CreateFormPage";
import EditFormPage from "./containers/businessParticipants/EditFormPage";
import PageNotFound from "../errors/PageNotFound";
import RequireAuth from "../components/private-route/RequireAuth";
import ViewFormPage from "./containers/businessParticipants/ViewFormPage";

const BusinessParticipantRoutes = () => {
    return (
        <Routes>
            <Route
                exact
                path=""
                element={
                    <RequireAuth someAuthority={[business_participant_view, business_participant_modify]}>
                        <BusinessParticipantListPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="/create"
                element={
                    <RequireAuth authority={business_participant_modify}>
                        <CreateFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="/:id"
                element={
                    <RequireAuth authority={business_participant_view}>
                        <ViewFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="/:id/edit"
                element={
                    <RequireAuth authority={business_participant_modify}>
                        <EditFormPage />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default BusinessParticipantRoutes;
