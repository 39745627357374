import { Page } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import FetchingPageIndicator from "../fetching/FetchingPageIndicator";
import AccessDenied from "../../errors/AccessDenied";

const PageContent = ({ title, subHeader, chapter, toolbar, secondLineInfo, middleBreadcrumbs = [], isLoading, canAccess = true, children }) => {
    const { t } = useTranslation();

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            link: "/",
            text: t("Главная"),
        },
        ...middleBreadcrumbs.map((crumb) => ({
            ...crumb,
            id: crumb.id ?? crumb.link ? `breadcrumb__${idifyString(crumb.link)}` : "",
        })),
        {
            text: title,
        },
    ];

    const pageToolbar = toolbar && <div className="pull-right inline actions">{toolbar}</div>;
    return isLoading || canAccess ? (
        <Page
            title={title}
            header={chapter ?? title}
            subHeader={chapter ? title : subHeader}
            breadcrumbs={breadcrumbs}
            toolbar={pageToolbar}
            secondLineInfo={secondLineInfo}
            headerClassName="col-lg-6"
            toolbarClassName="col-6"
        >
            {!isLoading ? <Fragment>{children}</Fragment> : <FetchingPageIndicator />}
        </Page>
    ) : (
        <AccessDenied />
    );
};

const idifyString = (str) =>
    str
        .replaceAll("/", "_")
        .replace(/[^_a-zA-Z0-9]/g, "")
        .toLowerCase();

export default PageContent;
