import { handleActions } from "redux-actions";
import { catalog } from "./actions";

const initialState = {
    catalog: {},
    catalogItem: {},
    error: null,
    loading: false,
    saving: false,
    deleting: false,
};

export default handleActions(
    {
        [catalog.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [catalog.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            catalog: payload,
            loading: false,
        }),
        [catalog.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [catalog.saveItem.request]: (state) => ({
            ...state,
            error: null,
            saving: true,
        }),
        [catalog.saveItem.success]: (state) => ({
            ...state,
            error: null,
            saving: false,
        }),
        [catalog.saveItem.error]: (state, action) => ({
            ...state,
            error: action.payload,
            saving: false,
        }),
        [catalog.deleteItem.request]: (state) => ({
            ...state,
            error: null,
            deleting: true,
        }),
        [catalog.deleteItem.success]: (state) => ({
            ...state,
            error: null,
            deleting: false,
        }),
        [catalog.deleteItem.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [catalog.item.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [catalog.item.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            catalogItem: payload,
            loading: false,
        }),
        [catalog.item.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
    },
    initialState
);
