import { RUSSIAN_REGION_LIST_LOAD, RUSSIAN_REGION_LIST_RECEIVE, RUSSIAN_REGION_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    russianRegions: [],
    error: null,
    loading: false,
};

const russianRegionsLoad = (state, action) => ({ ...state, loading: true });

const getRussianRegions = (action) => (action.russianRegions && action.russianRegions.length > 0 ? action.russianRegions : []);

const russianRegionsReceive = (state, action) => ({
    ...state,
    error: null,
    russianRegions: getRussianRegions(action),
    loading: false,
});

const russianRegionsError = (state, action) => ({
    ...state,
    error: action.error,
    russianRegions: [],
    loading: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case RUSSIAN_REGION_LIST_LOAD:
            return russianRegionsLoad(state, action);
        case RUSSIAN_REGION_LIST_RECEIVE:
            return russianRegionsReceive(state, action);
        case RUSSIAN_REGION_LIST_ERROR:
            return russianRegionsError(state, action);

        default:
            return state;
    }
};
