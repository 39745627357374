import { ProfileTable } from "vetrf-ui";

/**
 * @deprecated - use DescriptionTable
 */
const BlockTable = ({ data, rows }) => {
    return (
        <div className="wraptablebig">
            <ProfileTable
                itemList={data ? [{ item: data }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={rows}
            />
        </div>
    );
};

export default BlockTable;
