import { useDictionaryListQuery, useDictionaryOneQuery, useDictionaryPageQuery } from "./dictionaryQueries";

const baseUrl = "/regions";
const russianBaseUrl = "/russian-regions";
const russianItemName = "Российского региона";
const russianDictionaryName = "Российских регионов";
const dictionaryName = "регионов";

export function useRussianRegionQuery(id) {
    return useDictionaryOneQuery(russianBaseUrl, id, { dictionaryItemName: russianItemName });
}

export function useRussianRegionsListQuery() {
    return useDictionaryListQuery(russianBaseUrl, { params: { size: 1000 }, dictionaryName: russianDictionaryName, select: (page) => page.content });
}

export function useRussianRegionsPageQuery(searchParams) {
    return useDictionaryPageQuery(russianBaseUrl, searchParams, { dictionaryName: russianDictionaryName });
}

export function useRegionsList(countryGuid) {
    return useDictionaryListQuery(baseUrl, {
        method: "POST",
        params: { countryGuid },
        enabled: !!countryGuid,
        dictionaryName,
        select: (regions) =>
            regions.map((region) => ({ ...region, selectView: `${region.view}${region.englishName ? ` (${region.englishName})` : ""}` })),
    });
}
