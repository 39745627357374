import { Icon, ProfileTable, SubHeader } from "vetrf-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { isWithdrawn } from "../../../../utils/eCert-status-utils";
import { transformECertStatusColor } from "../../../../utils/transformers/transformECertStatusColor";
import { transformL10nECertStatus } from "../../../../utils/transformers/transformECertStatus";
import { WBR_APP_ROUTE } from "../../../application/WbrApplicationRoutes";
import { wbrComplianceDecision } from "../../../../utils/constants/wbr/wbr-constants";
import CountryFlag from "../../../../components/countryFlag/CountryFlag";

const getWbrComplianceDecisionView = (decision, t) => {
    if (!decision) return null;
    switch (decision) {
        case wbrComplianceDecision.MEET_REQUIREMENTS:
            return (
                <Fragment>
                    <Icon icon="check-circle" color="green" /> {t("соответствует требованиям страны-импортера")}
                </Fragment>
            );
        case wbrComplianceDecision.NOT_MEET_REQUIREMENTS:
            return (
                <Fragment>
                    <Icon icon="times-circle" color="red" /> {t("не соответствует требованиям страны-импортера")}
                </Fragment>
            );
        default:
            return null;
    }
};

const WbrConclusionDocumentView = ({ conclusion, file }) => {
    const { t } = useTranslation("translation");
    const unknown = t("Не указано");
    const unknownView = <i className="unknown">{unknown}</i>;
    let commonRows = [
        {
            header: {
                key: "referenceNumber",
                title: t("Номер"),
            },
            dataFormatter: (item) => (item?.referenceNumber ? item.referenceNumber : unknownView),
        },
        {
            header: {
                key: "issueDateTime",
                title: t("Дата оформления"),
            },
            dataFormatter: (item) => (item?.issueDateTime ? item.issueDateTime : unknownView),
        },
        {
            header: {
                key: "status",
                title: t("Статус"),
            },
            getCellClass: (item) => (item?.referenceNumber ? transformECertStatusColor(item.documentStatus) : ""),
            dataFormatter: (item) => (item?.referenceNumber ? transformL10nECertStatus(item.documentStatus, t) : unknownView),
        },
        {
            header: {
                key: "decision",
                title: t("Решение"),
            },
            dataFormatter: (item) => getWbrComplianceDecisionView(item?.complianceConclusion?.decision, t) ?? unknownView,
        },
    ];
    if (conclusion?.complianceConclusion?.decision === wbrComplianceDecision.NOT_MEET_REQUIREMENTS) {
        commonRows.push({
            header: {
                key: "conclusion",
                title: t("Причина решения о несоответствии"),
            },
            dataFormatter: (item) => item?.complianceConclusion?.conclusion ?? unknownView,
        });
    }
    if (conclusion?.createDateTime) {
        commonRows.push({
            header: {
                key: "create_date_time",
                title: t("Дата и время регистрации"),
            },
            dataFormatter: (item) => item?.createDateTime || unknown,
        });
    }
    commonRows.push(
        {
            header: {
                key: "user",
                title: t("Специалист, оформивший заключение"),
            },
            dataFormatter: (item) => (item?.createdByUser ? getUserView(item.createdByUser) : unknownView),
        },
        {
            header: {
                key: "exportCountry.name",
                title: t("Страна отправления"),
            },
            dataFormatter: (item) =>
                item?.consignment?.exportCountry ? <CountryFlag country={item.consignment.exportCountry} nameField={"view"} /> : unknownView,
        },
        {
            header: {
                key: "importCountry.name",
                title: t("Страна назначения"),
            },
            dataFormatter: (item) =>
                item?.consignment?.importCountry ? <CountryFlag country={item?.consignment.importCountry} nameField={"view"} /> : unknownView,
        }
    );
    if (isWithdrawn(conclusion)) {
        commonRows.push(
            {
                header: {
                    key: "withdraw_date",
                    title: t("Дата аннулирования"),
                },
                dataFormatter: (item) => item?.withdrawDateTime || unknown,
            },
            {
                header: {
                    key: "withdraw_reason",
                    title: t("Причина аннулирования"),
                },
                dataFormatter: (item) => item?.withdrawInfo?.reason || unknown,
            }
        );
    }
    if (conclusion?.application) {
        commonRows.push({
            header: {
                key: "file",
                title: t("Создано на основе заявки"),
            },
            dataFormatter: (item) => (
                <Fragment>
                    {item.application.referenceNumber || unknown} {t("от")} {item.application.receiveDateTime}{" "}
                    <Link to={WBR_APP_ROUTE.VIEW.getFullPath(item.application.id)}>{t("Просмотр")}</Link>
                </Fragment>
            ),
        });
    }
    if (file) {
        commonRows.push({
            header: {
                key: "file",
                title: t("Прикрепленный файл"),
            },
            dataFormatter: () => getFileMetaView(),
        });
    }
    const getFileMetaView = () => {
        if (!file) return;
        const fileName = file.nativeName ? file.nativeName : file.name;
        const size = file ? ", " + Math.floor(file.size / 1000) + " KB" : "";
        const textLink = `${fileName}${size}`;
        return conclusion.id ? <a href={`/rest-api/wbr/conclusion/record/${conclusion.id}/conclusion.pdf`}>{textLink}</a> : textLink;
    };

    const getUserView = (createdByUser) => {
        if (createdByUser) {
            return (
                <Fragment>
                    <span>
                        {createdByUser.fio || unknown} ({createdByUser.fioEnglish || unknown})
                    </span>
                    <br />
                    <span>
                        {createdByUser.position || unknown} ({createdByUser.positionEnglish || unknown})
                    </span>
                    <br />
                    <span>{createdByUser.institution?.shortName || unknown}</span>
                </Fragment>
            );
        } else return `${unknown}`;
    };

    return (
        <div className={"row"}>
            <SubHeader header={t("Реквизиты документа")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__document"
                    itemList={conclusion ? [{ item: conclusion }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={commonRows}
                />
            </div>
        </div>
    );
};

export default WbrConclusionDocumentView;
