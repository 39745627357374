import React, { useEffect, useMemo } from "react";
import { Button, SubHeader } from "vetrf-ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ShortFormProvider from "../form/ShortFormProvider";
import { getFieldForm } from "../form/schema/SearchFormBuilder";

const AdvancedSearch = ({ schema, searchParams, setSearchParams }) => {
    const methods = useForm();
    const { reset } = methods;
    const { t } = useTranslation();

    useEffect(() => {
        reset(searchParams, { keepDefaultValues: true });
    }, [searchParams, reset]);

    const resetSearch = () => {
        reset(fieldsArrayToObject(schema.advancedSearch));
    };

    const slicedFields = useMemo(() => sliceHalf(schema.advancedSearch), [schema.advancedSearch]);
    return (
        <div className="search-panel col-xs-12 no-padding">
            <SubHeader header={t("Поиск")} icon={""} />
            <ShortFormProvider methods={methods} onSubmit={(data) => setSearchParams(data)}>
                <div className={"col-xs-12 col-md-12 col-lg-6"}>
                    {slicedFields[0]?.map((field, index) => (
                        <div key={index} className="form-horizontal css-form">
                            {getFieldForm(field, schema.fields?.[field], field in searchParams)}
                        </div>
                    ))}
                </div>
                <div className={"col-xs-12 col-md-12 col-lg-6"}>
                    {slicedFields[1]?.map((field, index) => (
                        <div key={index} className="form-horizontal css-form">
                            {getFieldForm(field, schema.fields?.[field], field in searchParams)}
                        </div>
                    ))}
                </div>
                <div className="clearfix form-actions no-margin-bottom col-xs-12">
                    <div className="col-md-offset-5 col-md-7">
                        <Button type="submit" size="sm" color="info" icon="search" iconSize={110} text={t("Поиск")} />
                        &nbsp;&nbsp;&nbsp;
                        <Button size="sm" icon="eraser" iconSize={110} text={t("Очистить")} onClick={resetSearch} />
                    </div>
                </div>
            </ShortFormProvider>
        </div>
    );
};

const sliceHalf = (array) => {
    if (!array || !array.length) return [];
    const half = Math.ceil(array.length / 2);
    return [array.slice(0, half), array.slice(half, array.length)];
};

const fieldsArrayToObject = (fieldsArray = []) => {
    return fieldsArray.reduce((obj, item) => {
        obj[item] = null;
        return obj;
    }, {});
};

export default AdvancedSearch;
