import React, { useEffect, useRef, useState } from "react";
import { Alert, AutocompleteFormGroup, Button, deleteIn, InputFormGroup, setIn } from "vetrf-ui";
import { ERROR, VALID } from "../../../../components/sidePanel/constants/FormStatus";
import { isEmptyValidationError } from "../../../../utils/isEmptyAfterOmitEmpty";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import AddBusinessParticipantModal from "../../../../dictionary/containers/businessParticipants/AddBusinessParticipantModal";
import AddressFormComponent from "../../../../components/form/AddressFormComponent";

const WbrConclusionConsignorForm = ({
    conclusion,
    onChangeHandler,
    submitHandler,
    statusHandler,
    previousButtonHandler,
    countries,
    regions,
    requestRegionsByCountry,
    businessParticipantsService,
    businessParticipants,
    businessParticipantsLoading,
}) => {
    const { t } = useTranslation("translation");
    const invalidFields = useRef({});
    const [showError, setShowError] = useState(false);
    const [autocomplete, setAutocomplete] = useState(false);
    const [emptyResult, setEmptyResult] = useState(false);
    const [showParticipantModal, setShowParticipantModal] = useState(false);

    useEffect(() => {
        if (autocomplete) setEmptyResult(isEmpty(businessParticipants));
    }, [autocomplete, businessParticipants]);

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keydownHandler = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            nextOnClickHandler();
        }
    };

    const onChange = (value, path) => {
        statusHandler();
        onChangeHandler && onChangeHandler(value, path);
    };

    const onAutocompleteChangeHandler = (value) => {
        const location = conclusion?.consignment?.consignor?.address;
        const item = {
            view: value,
            address: location,
        };
        setAutocomplete(true);
        onChange(item, "conclusion.consignment.consignor");
    };

    const onAutocompleteResetHandler = () => {
        const { requestAutocompleteReset } = businessParticipantsService;
        requestAutocompleteReset();
    };

    const selectAutocompleteHandler = (value) => {
        const { requestParticipantsForAutocomplete } = businessParticipantsService;

        const item = {
            guid: value.businessEntityGuid,
            view: value.name,
            enView: value.englishName,
            address: {
                ...value.location.address,
                enAddressView: value.englishLocation.address.addressView,
            },
        };
        onChange(item, "conclusion.consignment.consignor");
        // Ищем по имени в зависимости от заполнения поля наименования на русском языке, чтобы при выборе контрагента учитывать,
        // что при добавлении зарубежных ХС может быть не введено наименование на русском языке
        const searchName = !isEmpty(value.name) ? value.name : value.englishName;
        requestParticipantsForAutocomplete(searchName);
    };

    const onAutocompleteHandler = (value) => {
        setAutocomplete(true);
        const { requestParticipantsForAutocomplete } = businessParticipantsService;
        requestParticipantsForAutocomplete(value.name);
    };

    const toggleParticipantModal = () => {
        setShowParticipantModal(!showParticipantModal);
    };

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const nextOnClickHandler = () => {
        setShowError(true);
        setTimeout(() => {
            const status = isEmptyValidationError(invalidFields) ? VALID : ERROR;
            submitHandler(status);
        }, 300);
    };
    const consignor = conclusion.consignment.consignor;

    const prefixIdForAlert = "alert";
    const businessParticipantId = prefixIdForAlert + "__business_participant_add_btn";
    return (
        <form className="form-horizontal css-form">
            <h3 className="header smaller lighter blue">{t("Сведения о грузоотправителе")}</h3>
            <AutocompleteFormGroup
                labelText="Наименование"
                name="name"
                searchField="name"
                selectField="name"
                id="consignor_name"
                viewKey="autocompleteView"
                value={consignor?.view}
                items={businessParticipants}
                onSelect={selectAutocompleteHandler}
                onAutocomplete={onAutocompleteHandler}
                resetAutocompleteList={onAutocompleteResetHandler}
                onChange={onAutocompleteChangeHandler}
                require
                showError={showError}
                errorHandler={errorHandler}
                help={t("Введите часть названия для поиска в реестре контрагентов.")}
                additionalBlock={
                    emptyResult && (
                        <Alert closable={true} buttonId={prefixIdForAlert + "__close_btn"} messageType="warning">
                            Поиск не дал результатов.
                            <Button
                                id={businessParticipantId}
                                className="btn-link btn-xs"
                                text={t("Добавьте сведения о хозяйствующем субъекте в справочник")}
                                onClick={() => {
                                    toggleParticipantModal();
                                }}
                            />
                        </Alert>
                    )
                }
            />
            {showParticipantModal && (
                <AddBusinessParticipantModal id={businessParticipantId + "__modal"} selectedTab={"russian"} toggleModal={toggleParticipantModal} />
            )}
            <InputFormGroup
                labelText={t("Наименование на английском")}
                require
                onChange={onChange}
                field={`conclusion.consignment.consignor.enView`}
                value={consignor?.enView}
                showError={showError}
                maxLength={255}
                errorHandler={errorHandler}
                help=""
            />
            <AddressFormComponent
                address={consignor?.address}
                addressPath="conclusion.consignment.consignor.address"
                showError={showError}
                errorHandler={errorHandler}
                countries={countries}
                regions={regions}
                onChangeHandler={onChange}
                requestRegionsByCountry={requestRegionsByCountry}
            />
            <h4 className="header smaller lighter blue">{t("Контактная информация")}</h4>
            <InputFormGroup
                labelText={t("Контактный телефон")}
                onChange={onChange}
                field="conclusion.consignment.consignor.phone"
                value={consignor?.phone}
                showError={showError}
                maxLength={50}
                errorHandler={errorHandler}
                help=""
            />
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <div className="pull-left">
                    <Button id="back_btn" text={t("Назад")} size="sm" icon="arrow-left" onClick={previousButtonHandler} />
                </div>
                <div className="pull-right">
                    <Button id="next_btn" text={t("Применить")} color="info" size="sm" icon="check" onClick={nextOnClickHandler} />
                </div>
            </div>
        </form>
    );
};
export default WbrConclusionConsignorForm;
