import { Fragment, useEffect, useState } from "react";
import { Icon } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import { getWbrApplicationContractBasisFileUrl, useWbrApplicationContract } from "../../../../api/WbrApplicationDocumentsQueries";
import BlinkingFetchingContainer from "../../../../../../components/fetching/BlinkingFetchingContainer";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import BlockTable from "../../../../../../components/table/BlockTable";
import IconedLink from "../../../../../../components/page/IconedLink";
import TextButton from "../../../../../../components/button/TextButton";

const WbrApplicationContractInfo = ({ application }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { data: contract, isLoading, refetch: refetchContract, isError } = useWbrApplicationContract(application?.id, false);

    useEffect(() => {
        if (!isLoading && isError) {
            setIsOpen(false);
        }
    }, [isError, isLoading]);

    const unknown = getUnknownNormalText(t);
    const tableRows = [
        {
            header: {
                key: "applicantPerson",
                title: t("Наименование заказчика"),
            },
            dataFormatter: () => application.applicant?.view ?? unknown,
        },
        {
            header: {
                key: "authorizedPerson",
                title: t("Уполномоченное лицо заказчика"),
            },
            dataFormatter: (contract) => contract?.authorizedPerson?.fio ?? unknown,
        },
        {
            header: {
                key: "basis",
                title: t("Основание права подписи уполномоченного лица"),
            },
            dataFormatter: (contract) =>
                contract.basisFile ? (
                    <IconedLink.Download href={getWbrApplicationContractBasisFileUrl(application.id)} title={t("Скачать")} />
                ) : (
                    unknown
                ),
        },
        {
            header: {
                key: "addressView",
                title: t("Юридический адрес"),
            },
            dataFormatter: () => application.applicant?.address?.addressView ?? unknown,
        },
        {
            header: {
                key: "postAddressViewMail",
                title: t("Почтовый адрес"),
            },
            dataFormatter: (contract) => contract.postAddress?.addressView ?? unknown,
        },
        {
            header: {
                key: "inn",
                title: t("ИНН"),
            },
            dataFormatter: (contract) => contract.businessEntity?.inn ?? application.applicant?.inn ?? unknown,
        },
        {
            header: {
                key: "businessEntityOgrn",
                title: t("ОГРН/ОГРНИП"),
            },
            dataFormatter: (contract) => contract.businessEntity?.ogrn ?? unknown,
        },
        {
            header: {
                key: "businessEntityKpp",
                title: t("КПП"),
            },
            dataFormatter: (contract) => contract.businessEntity?.kpp ?? unknown,
        },
        {
            header: {
                key: "paymentAccountNumber",
                title: t("Расчетный счет"),
            },
            dataFormatter: (contract) => contract.bankAccount?.paymentAccountNumber ?? unknown,
        },
        {
            header: {
                key: "correspondentAccountNumber",
                title: t("Корреспондентский счет"),
            },
            dataFormatter: (contract) => contract.bankAccount?.correspondentAccountNumber ?? unknown,
        },
        {
            header: {
                key: "BIC",
                title: t("БИК"),
            },
            dataFormatter: (contract) => contract.bankAccount?.bic ?? unknown,
        },
        {
            header: {
                key: "bankName",
                title: t("Наименование банка"),
            },
            dataFormatter: (contract) => contract.bankAccount?.bankName ?? unknown,
        },
        {
            header: {
                key: "definedContact",
                title: t("Контактные данные"),
            },
            dataFormatter: (contract) =>
                contract.definedContact ? (
                    <Fragment>
                        {contract.definedContact.phone && (
                            <Fragment>
                                {t("Телефон")}: {contract.definedContact.phone}
                            </Fragment>
                        )}
                        {contract.definedContact.email && (
                            <Fragment>
                                <br />
                                {t("E-mail")}: <IconedLink.Email href={contract.definedContact.email} />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    unknown
                ),
        },
    ];

    const onToggle = () => {
        if (!contract && !isOpen) {
            refetchContract();
        }
        setIsOpen(!isOpen);
    };

    const toolbar = (
        <div className="header-toolbar pull-right">
            <TextButton text={isOpen ? t("Скрыть") : t("Развернуть")} />
        </div>
    );
    return application?.contractInfo?.contract?.id ? (
        <Fragment>
            <BlockHeader onClick={onToggle} toolbar={toolbar}>
                <Icon icon={isOpen ? "chevron-down" : "chevron-right"} /> {t("Сведения для заключения договора")}
            </BlockHeader>
            {isOpen && (
                <BlinkingFetchingContainer isFetching={isLoading}>
                    <BlockTable data={contract} rows={tableRows} />
                </BlinkingFetchingContainer>
            )}
        </Fragment>
    ) : null;
};

export default WbrApplicationContractInfo;
