import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";
import { appResearchDocuments } from "./actions";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке типов документов для лабораторных исследований";

export const requestAppResearchDocuments = () =>
    createLoadAsyncAction(
        appResearchDocuments,
        request,
        {
            type: "GET",
            url: "app-research-documents",
        },
        errorMessage
    );
