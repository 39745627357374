import { Button, PaginationRow, Table } from "vetrf-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { business_participant_modify } from "../../../../common/constants/permissions";
import { RUSSIA } from "../../../../utils/constants/constants";
import BusinessParticipantDeleteButton from "../inner/BusinessParticipantDeleteButton";
import BusinessParticipantSynchronizationConfirmButton from "../inner/BusinessParticipantSynchronizationConfirmButton";
import CountryFlag from "../../../../components/countryFlag/CountryFlag";
import PrivateContent from "../../../../components/private-route/PrivateContent";

const tableId = "business_participant";

const BusinessParticipantPageableTable = ({ page, onChangePage, onChangeSizePerPage, sortedId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns = [
        {
            key: "country",
            title: t("Страна"),
            dataFormatter: (item) => (item.country ? <CountryFlag country={item.country} nameField={"name"} /> : t("Не указано")),
        },
        {
            key: "englishName",
            title: t("Наименование на английском"),
            dataFormatter: (item) => item.englishName || t("Не указано"),
            sortable: true,
        },
        {
            key: "englishAddress",
            title: t("Адрес на английском"),
            dataFormatter: (item) => item.englishAddress || t("Не указано"),
            sortable: true,
        },
        {
            key: "name",
            title: t("Наименование"),
            dataFormatter: (item) => item.name,
            sortable: true,
        },
        {
            key: "address",
            title: t("Адрес"),
            dataFormatter: (item) => item.address || t("Не указано"),
            sortable: true,
        },
        {
            key: "cerberusSync",
            title: "",
            dataFormatter: (value) => {
                return <span className={value.businessEntityGuid ? "fa fa-link green" : "fa fa-link grey"} />;
            },
            width: "10px",
        },
        {
            key: "actions",
            title: "",
            className: "actions",
            buttonFormatter: (item, tableId, index) => {
                const viewButtonId = `${tableId}__view_btn${index}`;
                const editButtonId = `${tableId}__edit_btn${index}`;
                const deleteButtonId = `${tableId}__edit_btn${index}`;
                return (
                    <Fragment>
                        <Button
                            id={viewButtonId}
                            onlyIcon={true}
                            iconColor="blue"
                            icon="bars"
                            iconSize={150}
                            tooltip={t("Просмотр")}
                            onClick={() => navigate(`${item.id}`)}
                        />
                        <PrivateContent authority={business_participant_modify}>
                            <Button
                                id={editButtonId}
                                onlyIcon={true}
                                iconColor="blue"
                                icon="pencil"
                                iconSize={150}
                                tooltip={t("Редактировать")}
                                onClick={() => navigate(`${item.id}/edit`)}
                            />
                            <BusinessParticipantDeleteButton id={deleteButtonId} onlyIcon participant={item} />
                            {item?.country?.guid === RUSSIA.guid && (
                                <BusinessParticipantSynchronizationConfirmButton id={`${tableId}__sync_btn${index}`} onlyIcon item={item} />
                            )}
                        </PrivateContent>
                    </Fragment>
                );
            },
        },
    ];

    const pagination = (
        <PaginationRow
            id={tableId}
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            id={tableId}
            className="table table-striped table-bordered table-hover"
            dataList={page?.content}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

export default BusinessParticipantPageableTable;
