import { Button } from "vetrf-ui";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { hasAuthority } from "../../../../utils/authority-utils";
import { useUserContext } from "../../../../session/UserContextProvider";
import { useWbrApplicationRevision } from "../../api/WbrApplicationQueries";
import { WBR_APP_BREADCRUMBS, WBR_APP_ROUTE } from "../../WbrApplicationRoutes";
import { wbr_application_view_all } from "../../../../common/constants/permissions";
import AlertWithIcon from "../../../../components/page/AlertWithIcon";
import Container from "../../../../components/page/Container";
import FormActionsPanel from "../../../../components/page/FormActionsPanel";
import PageContent from "../../../../components/page/PageContent";
import WbrApplicationNotFound from "./components/WbrApplicationNotFound";
import WbrApplicationRevisionReasonsTable from "./components/WbrApplicationRevisionReasonsTable";
import WbrApplicationViewTable from "./components/WbrApplicationViewTable";

const WbrApplicationRevisionViewPage = () => {
    const { t } = useTranslation();
    const { id, statusChangeId, anotherStatusChangeId } = useParams();
    const { user } = useUserContext();
    const applicationQuery = useWbrApplicationRevision(id, statusChangeId);
    const anotherApplicationQuery = useWbrApplicationRevision(id, anotherStatusChangeId);
    const application = applicationQuery.data;
    const anotherApplication = anotherApplicationQuery.data;

    const title = WBR_APP_ROUTE.HISTORY_VIEW.getTitle(!!anotherStatusChangeId, t);
    const canAccess = hasAuthority(wbr_application_view_all, user?.authorities) || application?.laboratory.guid === user?.institution.guid;
    const isLoading = applicationQuery.isLoading || anotherApplicationQuery.isLoading;
    const isError = applicationQuery.isError || anotherApplicationQuery.isError;
    const isApplicationsReady = application && (!anotherStatusChangeId || anotherApplication);
    return (
        <PageContent title={title} middleBreadcrumbs={WBR_APP_BREADCRUMBS.getViewBreadcrumbs(id)} isLoading={isLoading} canAccess={canAccess}>
            {isError && (
                <WbrApplicationNotFound
                    title={t("Версия заявки не найдена")}
                    message={t(
                        "При поиске версии заявки произошла ошибка. Проверьте корректность указанных сведений и попробуйте еще раз, если ошибка повториться обратитесь в поддержку."
                    )}
                    returnText={t("К просмотру")}
                    returnLink={WBR_APP_ROUTE.VIEW.getFullPath(id)}
                />
            )}
            {isApplicationsReady && (
                <div className="sticky-block" style={{ top: "-55px" }}>
                    <div className="col-md-12">
                        <div className="row">
                            <AlertWithIcon icon="history">
                                {t("Внимание! Вы в режиме просмотра истории состояний заявки")}{" "}
                                <Link to={WBR_APP_ROUTE.VIEW.getFullPath(id)}>{t("Перейти к обычному просмотру")}</Link>
                            </AlertWithIcon>
                        </div>
                    </div>
                </div>
            )}
            <Container>
                {isApplicationsReady && (
                    <Fragment>
                        {!anotherStatusChangeId && (
                            <WbrApplicationRevisionReasonsTable reasons={application.statusHistory[application.statusHistory.length - 1]?.reportConclusions} />
                        )}
                        <WbrApplicationViewTable application={application} applicationForCompare={anotherApplication} />
                    </Fragment>
                )}
            </Container>
            <FormActionsPanel>
                <Button id="back_btn" text={t("К просмотру")} size="sm" icon="list" href={WBR_APP_ROUTE.VIEW.getFullPath(id)} />
            </FormActionsPanel>
        </PageContent>
    );
};

export default WbrApplicationRevisionViewPage;
