import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useCreateMutation, useCustomQuery } from "../../utils/commonQueryBase";
import ajax from "../../utils/ajax";
import { useQuery } from "react-query";

const BASE_URL = "/rest-api/user-settings/";
const NOTIFICATION_SETTINGS_URL_PART = "notifications";
const ENGLISH_INFO_URL_PART = "english-info";

export function useUserRegionCode() {
    const request = ajax(BASE_URL);
    return useQuery({
        staleTime: 5 * 60 * 1000,
        keepPreviousData: true,
        queryKey: [BASE_URL],
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке кода региона."),
        queryFn: () => request({ type: "GET", url: "region-code" }),
    });
}

export function useUserNotificationSettings() {
    return useCustomQuery(
        BASE_URL + NOTIFICATION_SETTINGS_URL_PART,
        {},
        {
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке настроек уведомлений."),
        }
    );
}

export function useUserNotificationSettingsMutation() {
    return useCreateMutation(BASE_URL + NOTIFICATION_SETTINGS_URL_PART, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при сохранении настроек уведомлений."),
    });
}

export function useSaveEnglishInfoMutation() {
    return useCreateMutation(BASE_URL + ENGLISH_INFO_URL_PART, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при сохранении информации о пользователе."),
    });
}
