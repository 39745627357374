import { API_URL, useDictionaryListQuery, useDictionaryOneQuery, useDictionaryPageQuery } from "./dictionaryQueries";
import { queryClient } from "../..";

const BASE_URL = "/authority-departments";
const BY_ORG_LIST_URL_PART = "/by-org-list";
const itemName = "места оформления экспортных сертификатов";
const dictionaryName = "мест оформления экспортных сертификатов";

export function useAuthorityDepartmentQuery(id) {
    return useDictionaryOneQuery(BASE_URL, id, { dictionaryItemName: itemName });
}

export function useAuthorityDepartmentsPageQuery(searchParams) {
    return useDictionaryPageQuery(BASE_URL, searchParams, { dictionaryName: dictionaryName });
}

export function useAuthorityDepartmentsListByOrgQuery(searchParams) {
    return useDictionaryListQuery(BASE_URL + BY_ORG_LIST_URL_PART, searchParams, { dictionaryName: dictionaryName });
}

export function resetDepartmentListByOrgQuery() {
    queryClient.resetQueries([API_URL + BASE_URL + BY_ORG_LIST_URL_PART]);
}
