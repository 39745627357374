import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useListQuery } from "../../utils/commonQueryBase";

const baseUrl = "/rest-api/dictionary/products";
const wbrUrlPart = "/wbr";
const allBaseUrl = "/rest-api/dictionary/all-products";

const onError = (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка продукции.");

export function useGetProductList(searchParams) {
    return useListQuery(baseUrl, searchParams, {
        staleTime: 5 * 60 * 1000, //5 минут таймаут для кеша загрузки
        onError,
    });
}

export function useWbrProducts() {
    return useListQuery(baseUrl + wbrUrlPart, {}, {
        onError,
    });
}

export function useAllProductsListQuery(searchParams) {
    return useListQuery(allBaseUrl, searchParams, {
        staleTime: 5 * 60 * 1000, //5 минут таймаут для кеша загрузки
        onError,
    });
}
