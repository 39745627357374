import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";
import { appResearchResults } from "./actions";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке типов результатов лабораторных исследований";

export const requestAppResearchResults = () =>
    createLoadAsyncAction(
        appResearchResults,
        request,
        {
            type: "GET",
            url: "app-research-results",
        },
        errorMessage
    );
