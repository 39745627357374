import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { ProfileTable, SubHeader } from "vetrf-ui";
import { unknownNormalText } from "../../../utils/constants/constants";

const UserInfo = ({ user }) => {
    const rows = [
        {
            header: {
                key: "fio",
                title: "Ф.И.О.",
            },
            dataFormatter: (item) => item.fio || unknownNormalText,
        },
        {
            header: {
                key: "institution",
                title: "Учреждение",
            },
            dataFormatter: (item) => (item.institution && item.institution.shortName) || unknownNormalText,
        },
    ];

    return (
        <Fragment>
            <SubHeader header="Пользователь" />
            <ProfileTable itemList={user ? [{ item: user }] : [{}]} tableClassName="table color-table-border font-weight-normal" rows={rows} />
        </Fragment>
    );
};

UserInfo.propTypes = {
    user: PropTypes.object,
};

export default UserInfo;
