import { handleActions } from "redux-actions";
import { pharmaceuticals } from "./actions";

const initialState = {
    pharmaceuticals: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [pharmaceuticals.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [pharmaceuticals.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            pharmaceuticals: payload.reduce((result, curr) => {
                if (!result[curr.type.name]) result[curr.type.name] = [];
                result[curr.type.name].push(curr);
                return result;
            }, {}),
        }),
        [pharmaceuticals.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
    },
    initialState
);
