import { Alert, Button } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React from "react";

import { WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";

const WbrApplicationNotFound = ({title, message, returnText, returnLink}) => {
    const { t } = useTranslation();

    return (
        <div className="col-xs-6 col-xs-offset-3">
            <Alert messageType="danger">
                <h3 className="no-margin-top">
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp; {title ?? t("Заявка не найдена!")}
                </h3>
                <p>{message ?? t("Мы не смогли найти заявку, которую вы искали. Проверьте корректность указанных сведений и попробуйте еще раз.")}</p>
                <br />
                <Button text={returnText ?? t("К списку")} icon="arrow-left" color="danger" href={returnLink ?? WBR_APP_ROUTE.LIST.getFullPath()} />
            </Alert>
        </div>
    );
};

export default WbrApplicationNotFound;
