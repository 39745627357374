import React from "react";
import { Button, Page } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import WbrConclusionView from "./components/WbrConclusionView";
import { wbr_conclusion_record_modify } from "../../../common/constants/permissions";
import { editRoute, path } from "../../WbrConclusionRoutes";
import { hasAuthority } from "../../../utils/authority-utils";
import { useGetWbrConclusion } from "../../wbrConclusionQuery";
import { useParams } from "react-router-dom";
import DropdownOtherButton from "./components/DropdownOtherButton";
import { checkInstitution } from "../../../utils/check-rights";
import { useUserContext } from "../../../session/UserContextProvider";

const canEditWbr = (conclusion) => conclusion && (conclusion.documentStatus === "NEW" || conclusion.documentStatus === "ISSUED");

const WbrConclusionViewPage = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useUserContext();
    const title = t("Просмотр заключения");

    const { data: conclusion } = useGetWbrConclusion(id);

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            link: "/",
            text: t("Главная"),
        },
        {
            id: "breadcrumb__wbr_list_page",
            link: path,
            text: t("Заключения ВБР"),
        },
        {
            text: title,
        },
    ];

    const toolbar = (
        <div className="pull-right actions">
            {hasAuthority(wbr_conclusion_record_modify, user.authorities) && checkInstitution(conclusion, user) && canEditWbr(conclusion) && (
                <Button id="edit_btn" text={t("Редактировать")} href={editRoute.replace(":id", id)} color="purple" size="sm" icon="edit" />
            )}
            {hasAuthority(wbr_conclusion_record_modify, user.authorities) && checkInstitution(conclusion, user) && (
                <DropdownOtherButton conclusion={conclusion} />
            )}
        </div>
    );

    return (
        <Page title={title} breadcrumbs={breadcrumbs} header={title} toolbar={toolbar}>
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0">
                <WbrConclusionView conclusion={conclusion} />
            </div>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="back_btn" size="sm" icon="list" href={path} text={t(" К списку")} />
                {toolbar}
            </div>
        </Page>
    );
};

export default WbrConclusionViewPage;
