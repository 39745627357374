import { genders } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка полов";

export const requestGenders = () =>
    createLoadAsyncAction(
        genders,
        request,
        {
            type: "GET",
            url: "genders/all",
        },
        errorMessage
    );
