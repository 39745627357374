import ajax from "../../utils/ajax";

import { UNCEFACT_XML_RECEIVE, UNCEFACT_XML_LOAD_ERROR, UNCEFACT_XML_LOAD } from "../constants/ActionTypes";

let request = ajax("/rest-api/import/ecert/extdata/xml");

export const unCefactXMLLoad = (id) => ({
    type: UNCEFACT_XML_LOAD,
    id,
});

export const unCefactXMLReceive = (xmlData) => ({
    type: UNCEFACT_XML_RECEIVE,
    xmlData,
});

export const unCefactXMLLoadError = (error) => ({
    type: UNCEFACT_XML_LOAD_ERROR,
    error,
});

export const requestUnCefactXml = (id) => (dispatch) => {
    dispatch(unCefactXMLLoad(id));
    let url = "/" + id;
    request({
        url: url,
        type: "GET",
    })
        .then((response) => {
            dispatch(unCefactXMLReceive(response));
        })
        .catch((error) => {
            dispatch(unCefactXMLLoadError(error));
        });
};
