import { handleActions } from "redux-actions";
import { officialVeterinarians } from "./actions";

const initialState = {
    officialVeterinarians: [],
    page: {},
    item: {},
    error: null,
    loading: false,
    deleting: false,
};

export default handleActions(
    {
        [officialVeterinarians.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [officialVeterinarians.load.success]: (state, { payload = {} }) => ({
            ...state,
            officialVeterinarians: payload,
        }),
        [officialVeterinarians.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [officialVeterinarians.page.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [officialVeterinarians.page.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            page: payload,
            loading: false,
        }),
        [officialVeterinarians.page.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [officialVeterinarians.save.request]: (state) => ({
            ...state,
            error: null,
            saving: true,
        }),
        [officialVeterinarians.save.success]: (state) => ({
            ...state,
            officialVeterinarians: [],
            page: {},
            error: null,
            saving: false,
        }),
        [officialVeterinarians.save.error]: (state, action) => ({
            ...state,
            error: action.payload,
            saving: false,
        }),
        [officialVeterinarians.delete.request]: (state) => ({
            ...state,
            error: null,
            deleting: true,
        }),
        [officialVeterinarians.delete.success]: (state) => ({
            ...state,
            officialVeterinarians: [],
            page: {},
            error: null,
            deleting: false,
        }),
        [officialVeterinarians.delete.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [officialVeterinarians.item.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [officialVeterinarians.item.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            item: payload,
            loading: false,
        }),
        [officialVeterinarians.item.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
    },
    initialState
);
