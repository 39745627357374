import { BooleanSelectField } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import withOnChange from "./hoc/withOnChange";

/**
 * @param {callback} onChange - обратный вызов при изменении значения
 */
const EBooleanSelectField = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <BooleanSelectField
            options={[
                {
                    label: t("Да"),
                    value: true,
                },
                {
                    label: t("Нет"),
                    value: false,
                },
            ]}
            {...props}
        />
    );
};

export default withOnChange(EBooleanSelectField);
