import {
    COUNTRY_LIST_LOAD,
    COUNTRY_LIST_RECEIVE,
    COUNTRY_LIST_ERROR,
    SUPPORTED_COUNTRY_LIST_LOAD,
    SUPPORTED_COUNTRY_LIST_RECEIVE,
    SUPPORTED_COUNTRY_LIST_ERROR,
} from "../constants/ActionTypes";

const initialState = {
    countries: [],
    supportedCountries: [],
    error: null,
    loading: false,
};

const countriesLoad = (state, action) => ({ ...state, loading: true });

const getCountries = (action) => (action.countries && action.countries.length > 0 ? action.countries : []);

const countriesReceive = (state, action) => ({
    ...state,
    error: null,
    countries: getCountries(action),
    loading: false,
});

const countriesError = (state, action) => ({
    ...state,
    error: action.error,
    countries: [],
    loading: false,
});
const countriesSupportedReceive = (state, action) => ({
    ...state,
    error: null,
    supportedCountries: getCountries(action),
    loading: false,
});

const countriesSupportedError = (state, action) => ({
    ...state,
    error: action.error,
    supportedCountries: [],
    loading: false,
});

export default function countries(state = initialState, action) {
    switch (action.type) {
        case COUNTRY_LIST_LOAD:
        case SUPPORTED_COUNTRY_LIST_LOAD:
            return countriesLoad(state, action);
        case COUNTRY_LIST_RECEIVE:
            return countriesReceive(state, action);
        case COUNTRY_LIST_ERROR:
            return countriesError(state, action);
        case SUPPORTED_COUNTRY_LIST_RECEIVE:
            return countriesSupportedReceive(state, action);
        case SUPPORTED_COUNTRY_LIST_ERROR:
            return countriesSupportedError(state, action);

        default:
            return state;
    }
}
