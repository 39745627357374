import { useNavigate, useSearchParams } from "react-router-dom";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import React, { useContext, useEffect, useRef, useState } from "react";

import { isExtendEmpty } from "./utils";

const PageParamsContext = React.createContext();

const defaultParams = {
    pageParams: {
        page: 1,
        size: 10,
    },
    searchParams: {},
};

/**
 * Хук для хранения и отображения параметров страницы
 * @param {string} key
 * @param {object} options
 * @param {boolean} [options.enabled=true]
 * @param {object} [options.initialParams]
 * @returns {object}
 */
export default function usePageParams(key, options = {}) {
    const [isParamsInitialized, setIsParamsInitialized] = useState(false);
    const [pageParams, setPageParams] = useState();
    const [searchParams, setSearchParams] = useState({});

    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const { getPageParams: getContextParams, setPageParams: setContextParams } = useContext(PageParamsContext);
    const pageParamsRef = useRef();

    useEffect(() => {
        if (options.enabled == null) {
            if (!isParamsInitialized) {
                initializeFromQueryParams(queryParams);
            }
        } else if (options.enabled === true) {
            initializeFromQueryParams(queryParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.enabled]);

    useEffect(() => {
        if (isParamsInitialized) {
            const queryPageParams = filterPageParams({ ...pageParams }, { ...defaultParams, ...options.initialParams }.pageParams);
            const querySearchParams = mapToQueryParams(searchParams);
            const mergedParams = { ...queryPageParams, ...querySearchParams };
            navigate({ search: new URLSearchParams(mergedParams).toString() }, { replace: true });
            pageParamsRef.current = { pageParams, searchParams };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageParams, searchParams]);

    useEffect(() => {
        return () => {
            setContextParams(key, pageParamsRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeFromQueryParams = (queryParams) => {
        const query = mapQueryParamsToPageParams(queryParams);
        const stored = getContextParams(key) ?? {};
        const mergedParams = mergeParams(defaultParams, options.initialParams, stored, query);
        setPageParams(mergedParams.pageParams);
        setSearchParams(mergedParams.searchParams);
        setIsParamsInitialized(true);
    };

    const setPageHandler = (page) => {
        setPageParams((pageParams) => ({ ...pageParams, page }));
    };

    const setSizeHandler = (size) => {
        setPageParams({ page: 1, size });
    };

    const setSearchParamsHandler = (searchParams) => {
        if (isParamsInitialized) {
            setPageParams((pageParams) => ({ ...pageParams, page: 1 }));
            setSearchParams(removeEmpty(searchParams));
        }
    };

    return {
        pageParams: pageParams,
        setPage: setPageHandler,
        setSize: setSizeHandler,
        searchParams: searchParams,
        setSearchParams: setSearchParamsHandler,
        isParamsInitialized,
    };
}

export function PageParamsProvider({ children }) {
    const pageParamsMap = useRef(new Map());
    const getPageParams = (key) => pageParamsMap.current.get(key);
    const setPageParams = (key, params) => pageParamsMap.current.set(key, params);
    return <PageParamsContext.Provider value={{ getPageParams, setPageParams }}>{children}</PageParamsContext.Provider>;
}

function queryParamsEntriesToObject(params) {
    let paramsObject = {};
    params?.forEach((value, key) => {
        paramsObject[key] = value;
    });
    return paramsObject;
}

function mapQueryParamsToPageParams(queryParams) {
    let paramsObject = queryParamsEntriesToObject(queryParams);
    const page = paramsObject.page && Number(paramsObject.page);
    const size = paramsObject.size && Number(paramsObject.size);
    delete paramsObject.page;
    delete paramsObject.size;

    const parseParam = (value) => {
        try {
            return JSON.parse(value);
        } catch (error) {
            return undefined;
        }
    };
    const searchParams = Object.fromEntries(Object.keys(paramsObject).map((k) => [k, parseParam(paramsObject[k])]));
    return removeEmpty({ pageParams: { page, size }, searchParams });
}

function mapToQueryParams(object) {
    const serializedObject = {};
    for (const key in object) {
        const value = object[key];
        if (!isExtendEmpty(value)) {
            serializedObject[key] = JSON.stringify(value);
        }
    }
    return serializedObject;
}

function filterPageParams(queryPageParams, defaultPageParams) {
    if (!defaultPageParams) return queryPageParams;
    let filteredParams = { ...queryPageParams };
    if (filteredParams.page && filteredParams.page === defaultPageParams.page) {
        delete filteredParams.page;
    }
    if (filteredParams.size && filteredParams.size === defaultPageParams.size) {
        delete filteredParams.size;
    }
    return filteredParams;
}

export function removeEmpty(obj) {
    let finalObj = {};
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value && isObject(value) && !isArray(value)) {
            const nestedObj = removeEmpty(value);
            if (Object.keys(nestedObj).length) {
                finalObj[key] = nestedObj;
            }
        } else if (!isExtendEmpty(value)) {
            finalObj[key] = value;
        }
    });
    return finalObj;
}

function mergeParams(...params) {
    let mergedParams = { pageParams: {}, searchParams: {} };
    for (const param of params) {
        if (param) {
            mergedParams.pageParams = { ...mergedParams.pageParams, ...param.pageParams };
            mergedParams.searchParams = { ...mergedParams.searchParams, ...param.searchParams };
        }
    }
    return mergedParams;
}

// Spring Data REST стиль сортировки
// https://docs.spring.io/spring-data/rest/docs/current/reference/html/#paging-and-sorting.sorting
// Две строки сортировки: "field,field" - список полей отсортированных по возрастанию и "field,filed,desc" - по убыванию
export function implementSortToSearchParams(searchParams, field, direction) {
    let params = { ...searchParams };
    let sortArray = (params.sort ?? []).map((sortString) => sortString.split(","));
    let index = sortArray.findIndex((x) => x[0] === field);

    if (direction === "undef") {
        sortArray.splice(index);
    } else {
        const sort = direction === "asc" ? [[field]] : [[field], ["desc"]];
        if (index > -1) {
            sortArray[index] = sort;
        } else {
            sortArray.push(sort);
        }
    }
    params.sort = sortArray.map((x) => x.join(","));
    return params;
}
