export const ECERT_IMPORT_LIST_LOAD = "eCert/import/list/load";
export const ECERT_IMPORT_LIST_RECEIVE = "eCert/import/list/receive";
export const ECERT_IMPORT_LIST_ERROR = "eCert/import/list/load/error";

export const ECERT_IMPORT_LOAD = "eCert/import/load";
export const ECERT_IMPORT_RECEIVE = "eCert/import/receive";
export const ECERT_IMPORT_ERROR = "eCert/import/load/error";

export const UNCEFACT_XML_LOAD = "eCert/uncefact/load";
export const UNCEFACT_XML_RECEIVE = "eCert/uncefact/receive";
export const UNCEFACT_XML_LOAD_ERROR = "eCert/uncefact/load/error";

export const LOG_LOAD = "logs/load";
export const LOG_RECEIVE = "logs/receive";
export const LOG_ERROR = "logs/load/error";

export const LOGS_LIST_LOAD = "logs/list/load";
export const LOGS_LIST_RECEIVE = "logs/list/receive";
export const LOGS_LIST_ERROR = "logs/list/load/error";

export const LOGS_IMPORT_LIST_FILTER_SET = "logs/list/filter/set";
export const LOGS_IMPORT_LIST_FILTER_CLEAR = "logs/list/filter/clear";
export const ECERT_IMPORT_LIST_FILTER_SET = "eCert/import/list/filter/set";
export const ECERT_IMPORT_LIST_FILTER_CLEAR = "eCert/import/list/filter/clear";
