import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../errors/PageNotFound";
import RequireAuth from "../components/private-route/RequireAuth";
import WbrConclusionFormPage from "./containers/form/WbrConclusionFormPage";
import WbrConclusionListPage from "./containers/list/WbrConclusionListPage";
import WbrConclusionViewPage from "./containers/view/WbrConclusionViewPage";
import { wbr_conclusion_record_modify, wbr_conclusion_record_view } from "../common/constants/permissions";

export const path = "/wbr-conclusion/record";
export const listRoute = `${path}/`;
export const createRoute = `${path}/create`;
export const viewRoute = `${path}/:id`;
export const editRoute = `${path}/:id/edit`;

export const WBR_CONCLUSION_ROUTE = {
    CREATE_FROM_APPLICATION: {
        getFullPath: (id) => `${createRoute}/from/application/${id}`,
        getTitle: (t) => t("Регистрация заключения"),
    },
};

const WbrConclusionRoutes = () => {
    return (
        <Routes>
            <Route
                exact
                path=""
                element={
                    <RequireAuth authority={wbr_conclusion_record_view}>
                        <WbrConclusionListPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="create/from/application/:applicationId/*"
                element={
                    <RequireAuth authority={wbr_conclusion_record_modify}>
                        <WbrConclusionFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="create/*"
                element={
                    <RequireAuth authority={wbr_conclusion_record_modify}>
                        <WbrConclusionFormPage />
                    </RequireAuth>
                }
            />

            <Route
                exact
                path=":id/:action/*"
                element={
                    <RequireAuth authority={wbr_conclusion_record_modify}>
                        <WbrConclusionFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path=":id"
                element={
                    <RequireAuth authority={wbr_conclusion_record_view}>
                        <WbrConclusionViewPage />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default WbrConclusionRoutes;
