import { Translation } from "react-i18next";

import { filterError, filterRetry, useCustomMutation, useOneItemQuery, usePageQuery } from "../../../utils/commonQueryBase";
import { useCustomQuery } from "../../../utils/commonQueryBase";
import NotificationManager from "../../../common/utils/notification/NotificationManager";

const BASE_URL = "/rest-api/wbr/application";
const CREATE_CONCLUSION_URL_PART = "/create-conclusion";
const HISTORY_URL_PART = "/history";
const CLOSE_EXPIRED = "/close-expired";
const REVISION_PLACES_URL_PART = "/revision-places";

export const getWbrApplicationQueryKey = (id) => [BASE_URL, id];

export const getWbrApplicationFileDownloadUrl = (applicationId, fileId) => `/rest-api/wbr/application/${applicationId}/files/${fileId}`;

export function useWbrApplication(id) {
    const ignoreCodes = ["ECERT60403", "ECERT60404"];
    return useOneItemQuery(BASE_URL, id, {
        retry: filterRetry(3, { notRetryCode: ignoreCodes }),
        refetchOnWindowFocus: false,
        onError: filterError(
            (error) => NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при загрузке заявки на ВБР")}</Translation>),
            { ignoreCodes }
        ),
    });
}

export function useWbrApplicationRevision(id, statusChangeId) {
    const ignoreCodes = ["ECERT60403", "ECERT60404", "ECERT60018"];
    return useOneItemQuery(`${BASE_URL}/${id}${HISTORY_URL_PART}`, statusChangeId, {
        retry: filterRetry(3, { notRetryCode: ignoreCodes }),
        refetchOnWindowFocus: false,
        onError: filterError(
            (error) => NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при загрузке версии заявки на ВБР")}</Translation>),
            { ignoreCodes: ignoreCodes }
        ),
    });
}

export function useWbrApplicationPage(pageParams, searchParams) {
    return usePageQuery(BASE_URL, pageParams, searchParams, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при загрузке списка заявок на ВБР")}</Translation>),
    });
}

export function useWbrApplicationToConclusion(id) {
    return useCustomQuery(
        `${BASE_URL}/${id}${CREATE_CONCLUSION_URL_PART}`,
        {},
        {
            enabled: !!id,
            retry: filterRetry(3, { notRetryCode: ["ECERT60403", "ECERT60404"] }),
            refetchOnWindowFocus: false,
            onError: (error) =>
                NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при загрузке заявки на ВБР")}</Translation>),
        }
    );
}

export function useCloseExpiredWbrApplications() {
    const createRequest = () => ({
        type: "POST",
        url: CLOSE_EXPIRED,
    });
    return useCustomMutation(BASE_URL, createRequest, {
        onSuccess: () => NotificationManager.success("Истекшие заявки успешно закрыты"),
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при закрытии истекших заявок на ВБР"),
    });
}

export function useWbrApplicationRevisionPlaces(id) {
    return useCustomQuery(
        `${BASE_URL}/${id}${REVISION_PLACES_URL_PART}`,
        {},
        {
            onError: (error) =>
                NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при загрузке заявки на ВБР")}</Translation>),
        }
    );
}
