export const getOfficeHoursView = (day, officeHours, noEmptyText) => {
    const workingDay = officeHours.filter((d) => d.dayOfWeek === day);
    return workingDay && workingDay[0] ? workingDay[0].view : noEmptyText ? undefined : "не указано";
};

export const workingDaysView = (officeHours) => {
    return `[Понедельник] : ${getOfficeHoursView("MONDAY", officeHours)}
   [Вторник] : ${getOfficeHoursView("TUESDAY", officeHours)}
   [Среда] : ${getOfficeHoursView("WEDNESDAY", officeHours)}
   [Четверг] : ${getOfficeHoursView("THURSDAY", officeHours)}
   [Пятница] : ${getOfficeHoursView("FRIDAY", officeHours)}
   [Суббота] : ${getOfficeHoursView("SATURDAY", officeHours)}
   [Воскресенье] : ${getOfficeHoursView("SUNDAY", officeHours)}`;
};
