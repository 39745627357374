import {
    useDictionaryCreateMutation,
    useDictionaryDeleteMutation,
    useDictionaryListQuery,
    useDictionaryOneQuery,
    useDictionaryPageQuery,
} from "./dictionaryQueries";

const baseUrl = "/wbr-application-status-change-reasons";
const itemName = "причины изменения статуса заявки";
const dictionaryName = "причин изменения статуса заявки";

export function useWbrApplicationChangeStatusReason(id) {
    return useDictionaryOneQuery(baseUrl, id, { dictionaryItemName: itemName });
}

export function useWbrApplicationChangeStatusReasonsList() {
    return useDictionaryListQuery(baseUrl, { dictionaryName: dictionaryName, select: (data) => data.content });
}

export function useWbrApplicationChangeStatusReasonsPage(searchParams) {
    return useDictionaryPageQuery(baseUrl, searchParams, { dictionaryName: dictionaryName });
}

export function useWbrApplicationChangeStatusReasonSaveMutation() {
    return useDictionaryCreateMutation(baseUrl, { dictionaryItemName: itemName });
}

export function useWbrApplicationChangeStatusReasonDeleteMutation() {
    return useDictionaryDeleteMutation(baseUrl, { dictionaryItemName: itemName });
}
