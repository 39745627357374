import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import CompareBlockTable from "../../../../../../components/table/CompareBlockTable";
import CountryFlag from "../../../../../../components/countryFlag/CountryFlag";
import IconedLink from "../../../../../../components/page/IconedLink";

const WbrApplicationGeneralInfo = ({ application, applicationForCompare }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const getBusinessEntityView = (entity) => {
        if (entity) {
            return (
                <Fragment>
                    {(entity.view || entity.address?.addressView) && (
                        <span>
                            {entity.view || unknown} {entity.address?.addressView && ` (${entity.address?.addressView})`}
                            <br />
                        </span>
                    )}
                    {(entity.enView || entity.address?.enAddressView) && (
                        <span>
                            {entity.enView || unknown} {entity.address?.enAddressView && ` (${entity.address?.enAddressView})`}
                            <br />
                        </span>
                    )}
                    {entity.phone && (
                        <Fragment>
                            {t("Телефон")}: {entity.phone}
                            <br />
                        </Fragment>
                    )}
                    {entity.email && (
                        <Fragment>
                            {t("E-mail")}: <IconedLink.Email href={entity.email} />
                            <br />
                        </Fragment>
                    )}
                </Fragment>
            );
        }
        return unknown;
    };

    const tableRows = [
        {
            header: {
                key: "exitDate",
                title: t("Дата отгрузки"),
            },
            dataFormatter: (item) => item.consignment.exitDate ?? unknown,
        },
        {
            header: {
                key: "exportCountry",
                title: t("Страна отправления"),
            },
            dataFormatter: (item) => <CountryFlag country={item.consignment.exportCountry} nameField={"view"} />,
            compareData: (item) => item.consignment.exportCountry.guid,
        },
        {
            header: {
                key: "importCountry",
                title: t("Страна назначения"),
            },
            dataFormatter: (item) => <CountryFlag country={item.consignment.importCountry} nameField={"view"} />,
            compareData: (item) => item.consignment.importCountry.guid,
        },
        {
            header: {
                key: "entryBorderControlPost",
                title: t("Пункт въезда в страну назначения"),
            },
            dataFormatter: (item) =>
                item.consignment.entryBorderControlPost ? (
                    <Fragment>
                        <CountryFlag country={item.consignment.importCountry} nameField={"englishName"} />,{" "}
                        {item.consignment.entryBorderControlPost.enName}
                    </Fragment>
                ) : (
                    unknown
                ),
            compareData: (item) => item.consignment.entryBorderControlPost.enName,
        },
        {
            header: {
                key: "consignor",
                title: t("Грузоотправитель"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.consignor) ?? unknown,
            compareData: (item) => item.consignment.consignor.view,
        },
        {
            header: {
                key: "consignee",
                title: t("Грузополучатель"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.consignee) ?? unknown,
            compareData: (item) => item.consignment.consignee.view,
        },
    ];

    return (
        <Fragment>
            <BlockHeader>{t("Общая информация")}</BlockHeader>
            <CompareBlockTable data={application} comparedData={applicationForCompare} rows={tableRows} />
        </Fragment>
    );
};

export default WbrApplicationGeneralInfo;
