import { DropdownButton } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { forceDownloadFile } from "../../../../../../utils/viewUtils";
import { getWbrApplicationServiceActFileUrl, getWbrApplicationServiceContractFileUrl } from "../../../../api/WbrApplicationDocumentsQueries";

const WbrApplicationDownloadDropdown = ({ applicationId, contractInfo, acceptanceCertificateInfo }) => {
    const { t } = useTranslation();

    const dropdownItems = [
        contractInfo?.contract?.id &&
            contractInfo?.isReceived && {
                text: t("Договор"),
                onClick: () => forceDownloadFile(getWbrApplicationServiceContractFileUrl(applicationId)),
            },
        acceptanceCertificateInfo?.acceptanceCertificate?.id && {
            text: t("Акт выполненных работ"),
            onClick: () => forceDownloadFile(getWbrApplicationServiceActFileUrl(applicationId)),
        },
    ];

    const itemsToDisplay = dropdownItems.filter(Boolean);
    return (
        <div className="inline">
            {itemsToDisplay.length > 0 ? (
                <DropdownButton id="download_dropdown_btn" text={t("Скачать")} color="primary" size="sm" icon={"download"} items={itemsToDisplay} />
            ) : null}
        </div>
    );
};

export default WbrApplicationDownloadDropdown;
