import { useState } from "react";

const ButtonWithConfirmation = ({ button = (onClose) => {}, modal = (handleClose) => {} }) => {
    const [isModalShow, setIsModalShow] = useState(false);

    const onClick = () => {
        setIsModalShow(true);
    };

    const handleClose = (action) => {
        setIsModalShow(false);
    };

    return (
        <div className="inline">
            {button(onClick)}
            {isModalShow && modal(handleClose)}
        </div>
    );
};

export default ButtonWithConfirmation;
