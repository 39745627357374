export const documentTypes = {
    // Товарно-транспортная накладная
    billOfLading: {
        id: "655365a7-e916-4ff8-93a4-4fbbef149578",
    },
    // Коносамент
    billOfLadingKonosament: {
        id: "8ca7caa7-6eef-47d1-9423-5e1935b0a309",
    },
    // CMR
    cmr: {
        id: "686456b9-3b88-4e8b-8cac-5384dac214a6",
    },
    // Авианакладная
    airWaybill: {
        id: "0bb08059-c5be-491c-a29a-eb1982dd2b33",
    },
    // Транспортная накладная
    waybill: {
        id: "9d0dfe34-d980-4758-a64a-4a275fd1cfba",
    },
    // ТОРГ-12
    torg12: {
        id: "806a130c-40f3-497a-8daa-9a3364459b8d",
    },
    // Акт отбора пробы на исследование
    permitOfControlled: {
        id: "f09931d7-330a-4fc6-9540-9b95dcb11564",
    },
    // ТОРГ-13
    torg13: {
        id: "40f8ace2-e4c9-43a9-becc-568a3a42826f",
    },
    // Ветеринарный сертификат третьих стран на ввоз продукции на территорию РФ
    vetCertForProductMovingInRussia: {
        id: "4e0c6fc6-1cc1-488d-920b-53b854061ded",
    },
    // Ветеринарный сертификат страны ТС на ввоз продукции на территорию РФ
    vetCertForImportIntoRussia: {
        id: "ede15268-1b55-4bf5-b5ad-84201a71ceed",
    },
    // Ветеринарный сертификат РФ на вывоз продукции на территорию стран ТС
    vetCertForExportToUC: {
        id: "d4922c8a-b0e5-48b9-a7d5-1a4d9e5dbd7b",
    },
    // Ветеринарный сертификат РФ на вывоз продукции на территорию третьих стран
    vetCertForExportToTheir: {
        id: "4415d914-1349-4717-a229-7deeb8d20b35",
    },
    // Заказ
    order: {
        id: "1b19e97b-02f4-4fff-a39a-e5b03226418c",
    },
    // Паспорт гражданина РФ
    passportOfRussian: {
        id: "de018dd0-497a-4b39-802e-5db66211e4de",
    },
    // Паспорт иностранного гражданина
    passportOfForeign: {
        id: "e7dafa4a-d970-4b7e-8e63-62a7c33a4d9b",
    },
    // Паспорт гражданина Республики Казахстан
    passportOfKazahstanian: {
        id: "bac72e3b-b197-4741-909e-65f2659d1bb7",
    },
    // Паспорт гражданина Республики Беларусь
    passportOfBelarusian: {
        id: "c4fc269e-00c7-4e9c-bcee-94a009cba746",
    },
    // Паспорт гражданина Республики Армения
    passportOfArmenian: {
        id: "8d77d8c6-8419-4914-817a-ef088e110166",
    },
    // Паспорт гражданина Республики Киргизия
    passportOfKyrgyzstanian: {
        id: "efcee126-1a3d-4c94-b159-564f61fc83da",
    },
    // Универсальный передаточный документ (УПД)
    universalTransfer: {
        id: "f53ee939-8a0c-4315-91cd-8a71316d0b77",
    },
    // Электронный ветеринарный производственный сертификат
    eVetProducionCert: {
        id: "40ac6c51-f41a-4f80-9bba-faf5ca15f1be",
    },
    // Свидетельство о государственной регистрации
    certOfStateRegistration: {
        id: "87c27c94-b77b-4381-859d-c2cef4e21a9f",
    },
    // Сертификат подтверждения соответствия продукта требованиям и стандартам Халяль
    certOfConformityHalal: {
        id: "536cb2ba-92cb-47b4-a5a1-d907bc8c8b05",
    },
    // Ветеринарное разрешение на экспорт продукции с территории ТС в третьи страны *
    vetPermitToExportProductsToCU: {
        id: "18190349-3f7c-45f9-bfec-01b254faa8ed",
    },
    // Сертификат качества продукции, а также иной документ, подтверждающий качество
    productQualityCert: {
        id: "9158132b-3e88-4e50-81ee-569453518880",
    },
    // Контракт на поставку продукции (копия контракта) *
    contract: {
        id: "404bd9fa-8832-41d7-b804-9543aedfe32a",
    },
    // Упаковочный лист
    packingList: {
        id: "39efa76c-ddc8-47cc-8763-b98f22c531f3",
    },
    // Заключение или иной документ, подтверждающий факт проведения дезинфекции транспортного средства
    confirmingDisinfectionOfVehicle: {
        id: "9adb0c4a-5133-41bd-8e3a-0af70a7acaa4",
    },
    // Заключение о соответствии продукции установленным требованиям *
    confirmingProductRequirements: {
        id: "e9de3abd-843b-401c-a6db-a6115ab5fa5a",
    },
    // Протокол лабораторных исследований
    labResearchProtocol: {
        id: "ef961531-1b24-4cdd-8ebf-de7ae5fd1313",
    },
    // Разрешение на вылов водных биологических ресурсов
    confirmingCatchingArea: {
        id: "eff5278c-46a9-41e5-b79b-236d12566a90",
    },
    // Договор фрахтования судна *
    freightContract: {
        id: "70eb7d18-0011-4cc2-a22f-ef46faf9fffe",
    },
    // Ветеринарный паспорт животного
    petPassport: {
        id: "4d3aa830-1358-4328-8434-a588d388e21a",
    },
    // Заключение о ветеринарно-санитарном соответствии экспортируемой партии рыбной продукции и нерыбных объектов промысла требованиям страны-импортера
    complianceOfFish: {
        id: "31d0ddf1-4c38-4193-92e6-8505cd92658e",
    },
    // Разрешение Россельхознадзора на ввоз/вывоз/транзит подконтрольной продукции
    rshnPermitOfProducts: {
        id: "45268de9-6cc8-4f72-b4ee-fd7926a127ec",
    },
    // Разрешение ветеринарного управления субъекта страны ТС на ввоз продукции
    permissionOfVetDepartmentForImport: {
        id: "3aec81c1-452a-42ae-90fb-77cc4fa4f44e",
    },
    // Ветеринарный сертификат на перемещение внутри РФ
    vetCertForMovingInRussia: {
        id: "58271d64-d6a6-4502-a5fb-8b1e4d66cb4a",
    },
    // Регистрационное удостоверение лекарственного препарата или фармацевтической субстанции
    registrationCertOfMedicinal: {
        id: "1a84be60-0284-4aad-ab37-ca3b01260ee5",
    },
    // Анкета-вопросник для обследования предприятия
    questionnaireForEnterprise: {
        id: "51c53526-0ee2-4c41-b3aa-5332bf08ad80",
    },
    // Декларация о соответствии
    conformityDeclaration: {
        id: "847f960c-449f-48d5-9b10-1caef4230de4",
    },
    // Сертификат соответствия
    conformityCertificate: {
        id: "daf06817-cdf1-4855-9f96-83f9ce959dfb",
    },
    // Образец маркировки продукции
    productLavelingSample: {
        id: "b90be6f6-9c94-4950-a873-71fae6a1d92a",
    },
    // Технологический журнал производства продукции
    techJournalOfProduction: {
        id: "6f47cc74-a283-4045-b5d3-d68be8b44e0a",
    },
    // Дополнительная документация к контракту на поставку продукции (Доп.соглашение, Агентский договор и т.п.)
    additionalToContractForSupply: {
        id: "72ea5d6a-b33d-48e1-9d51-b3581fc7981b",
    },
    // Учетные документы на основе НАССР
    haccpBased: {
        id: "68089734-9272-4dc5-999e-0964924cdbed",
    },
    // Счет на оплату
    invoice: {
        id: "5bc6018e-83a4-42fc-b227-cd3100d750c4",
    },
    // Договор на оказание услуг
    serviceContract: {
        id: "f6e6035a-0b74-4921-9139-4bc395634c3e",
    },
    // Акт выполненных работ
    acceptanceCertificate: {
        id: "270c8b1c-8bd0-407a-981f-e5d8d0e4eaff",
    },
    // Спецификация к счету на оплату
    invoiceSpecification: {
        id: "0ef3ae29-ccd2-4187-96eb-0403219bb9ec",
    },
    // Договор с визуализацией подписей УКЭП
    ukepSignatureContract: {
        id: "2be2a70e-a603-4cf7-b5b9-babd6388e7d9",
    },
};
