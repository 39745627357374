import { Translation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";

import { filterRetry, useCustomQuery } from "../../../utils/commonQueryBase";
import { getMercuryBaseUrl, getVestaBaseUrl } from "../../../utils/constants/external-services-base-url";
import { wbrApplicationInvoiceVatModeTypes } from "../../../utils/constants/wbr/wbr-constants";
import NotificationManager from "../../../common/utils/notification/NotificationManager";

const BASE_URL = "/rest-api/wbr/application";
const CONTRACT_URL_PART = "/service-contract";
const CONTRACT_FILES_BASIS_URL_PART = `${CONTRACT_URL_PART}/files/basis`;
const CONTRACT_FILES_CONTRACT_URL_PART = `${CONTRACT_URL_PART}/files/contract`;
const INVOICE_URL_PART = "/invoice";
const INVOICE_FILES_INVOICE_URL_PART = `${INVOICE_URL_PART}/files/invoice`;
const INVOICE_FILES_SPECIFICATION_URL_PART = `${INVOICE_URL_PART}/files/specification`;
const INVOICE_PAYMENT_URL_PART = "/invoice-payment";
const ACCEPTANCE_CERTIFICATE_URL_PART = "/acceptance-certificate";
const ACCEPTANCE_CERTIFICATE_FILES_CERTIFICATE_URL_PART = `${ACCEPTANCE_CERTIFICATE_URL_PART}/files/certificate`;

export const getWbrApplicationDocumentFileDownloadUrl = (applicationId, fileId) =>
    `/rest-api/wbr/application/${applicationId}/documents/files/${fileId}`;

export const getWbrApplicationContractBasisFileUrl = (applicationId) => `${BASE_URL}/${applicationId}${CONTRACT_FILES_BASIS_URL_PART}`;

export const getWbrApplicationServiceContractFileUrl = (applicationId) => `${BASE_URL}/${applicationId}${CONTRACT_FILES_CONTRACT_URL_PART}`;

export const getWbrApplicationServiceActFileUrl = (applicationId) => `${BASE_URL}/${applicationId}${ACCEPTANCE_CERTIFICATE_FILES_CERTIFICATE_URL_PART}`;

export const getWbrApplicationInvoiceFileUrl = (applicationId) => `${BASE_URL}/${applicationId}${INVOICE_FILES_INVOICE_URL_PART}`;

export const getWbrApplicationInvoiceSpecificationFileUrl = (applicationId) => `${BASE_URL}/${applicationId}${INVOICE_FILES_SPECIFICATION_URL_PART}`;

export const getVetDocumentExternalUrl = (id) => {
    return `https://${getMercuryBaseUrl()}/pub/operatorui?_action=findVetDocumentFormByUuid&uuid=${id}`;
};

export const getLaboratoryResearchExternalUrl = (id) => {
    return `https://${getVestaBaseUrl()}/api/common/documents/download/${id}`;
};

export const useWbrApplicationContract = (id, enabled = true) => {
    return useCustomQuery(
        `${BASE_URL}/${id}${CONTRACT_URL_PART}`,
        {},
        {
            enabled: !!enabled,
            retry: filterRetry(3, { notRetryCode: ["ECERT60001"] }),
            onError: (error) => {
                NotificationManager.error(
                    error,
                    <Translation>{(t) => t("Произошла ошибка при загрузке сведений для заключения договора для заявки на ВБР")}</Translation>
                );
            },
        }
    );
};

export const useWbrApplicationInvoice = (id, enabled) => {
    return useCustomQuery(
        `${BASE_URL}/${id}${INVOICE_URL_PART}`,
        {},
        {
            retry: filterRetry(3, { notRetryCode: ["ECERT60403", "ECERT60404"] }),
            enabled: !!enabled,
            select: (invoice) => {
                const filledInvoice = cloneDeep(invoice);
                filledInvoice.vatRate = filledInvoice.vatRate.toString();
                filledInvoice.useVat = filledInvoice.vatMode !== wbrApplicationInvoiceVatModeTypes.WITHOUT_VAT;
                filledInvoice.includeVat = filledInvoice.vatMode === wbrApplicationInvoiceVatModeTypes.INCLUDED;
                filledInvoice.invoiceFile = fillFiles([filledInvoice.invoiceFile].filter(Boolean), "Файл счета", "pdf");
                filledInvoice.specificationFile = fillFiles([filledInvoice.specificationFile].filter(Boolean), "Спецификация", "pdf");
                return filledInvoice;
            },
        }
    );
};

export const useWbrApplicationInvoicePayment = (id, enabled = true) => {
    return useCustomQuery(`${BASE_URL}/${id}${INVOICE_PAYMENT_URL_PART}`, {}, { enabled: !!enabled });
};

export const useWbrApplicationAcceptanceCertificate = (id, enabled = true) => {
    return useCustomQuery(`${BASE_URL}/${id}${ACCEPTANCE_CERTIFICATE_URL_PART}`, {}, { enabled: !!enabled });
};

const fillFiles = (files, name = "Файл", extension) => {
    if (!files) return null;
    return files.map((file, index) => ({ ...file, name: file.nativeName ?? file.name ?? `${extension ? `${name}.${extension}` : name}${files.length > 1 ? ` ${index + 1}`:""}`, type: "application/pdf", isMock: true }));
};
