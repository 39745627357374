import React from "react";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";

const ApplicationView = ({ department }) => {
    const { t } = useTranslation();
    const commonRows = [
        {
            header: {
                key: "forRats",
                title: t('Доступна для подачи заявок из ИС "Одно окно"'),
            },
            getCellClass: (item) => (item?.synced ? "dark-blue light-blue-background" : ""),
            dataFormatter: (item) => (item?.synced ? t("Да") : t("Нет")),
        },
        {
            header: {
                key: "forPets",
                title: t("Доступна для подачи заявок из ECert.Питомцы"),
            },
            getCellClass: (item) => (item?.forPetsIssuer ? "dark-blue light-blue-background" : ""),
            dataFormatter: (item) => (item?.forPetsIssuer ? t("Да") : t("Нет")),
        },
    ];

    return (
        <div className={"row"}>
            <SubHeader header={t("Сведения о заявках")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__common"
                    itemList={department ? [{ item: department }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={commonRows}
                />
            </div>
        </div>
    );
};

export default ApplicationView;
