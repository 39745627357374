import React from "react";
import { DropdownButton, TextareaField } from "vetrf-ui";
import isEmpty from "lodash/isEmpty";
import { hasAuthority } from "../../../../utils/authority-utils";
import { wbr_conclusion_record_withdrawn_org } from "../../../../common/constants/permissions";
import { useTranslation } from "react-i18next";
import { isIssued } from "../../../../utils/eCert-status-utils";
import { checkInstitution } from "../../../../utils/check-rights";
import { FormProvider, useForm } from "react-hook-form";
import { useIsWbrConclusionMutating, useWithdrawWbrConclusionMutation } from "../../../wbrConclusionQuery";
import { useUserContext } from "../../../../session/UserContextProvider";

const DropdownOtherButton = ({ conclusion, saving }) => {
    const { t } = useTranslation("translation");
    const { user } = useUserContext();
    const withdrawMutation = useWithdrawWbrConclusionMutation();
    const isSaving = useIsWbrConclusionMutating();

    const confirmWithdraw = (withdrawData) => {
        const requestData = { id: conclusion?.id, withdrawInfo: withdrawData };
        withdrawMutation.mutate(requestData, {});
    };

    const methods = useForm();
    const { handleSubmit } = methods;

    const dropdownItems = [];
    const body = (
        <FormProvider {...methods}>
            <form id={"withdrawForm"} name={"withdraw"} className="form-horizontal" onSubmit={handleSubmit(confirmWithdraw)}>
                <p className="confirm__body__text">{t("Пожалуйста, укажите причину аннулирования заключения.")}</p>
                <TextareaField
                    required
                    labelText="Причина аннулирования"
                    maxLength={{
                        message: "Слишком длинный текст",
                        value: 255,
                    }}
                    name="reason"
                    rows={8}
                />
            </form>
        </FormProvider>
    );
    if (
        isIssued(conclusion) &&
        hasAuthority(wbr_conclusion_record_withdrawn_org, user.authorities) &&
        checkInstitution(conclusion, user)
    ) {
        dropdownItems.push({
            icon: "times-circle",
            text: t("Аннулировать"),
            disabled: saving,
            confirmHeaderText: `Аннулирование заключения №${conclusion.referenceNumber}`,
            confirmBodyContent: body,
            confirmBtn: {
                text: t("Аннулировать"),
                className: "btn btn-danger",
                onConfirm: handleSubmit(confirmWithdraw),
                disabled: isSaving,
            },
            itemType: "confirm",
        });
    }
    if (conclusion?.id) {
        dropdownItems.push({
            icon: "copy",
            text: t("Создать копию"),
            url: `/wbr-conclusion/record/${conclusion?.id}/copy`,
        });
    }

    return (
        !isEmpty(dropdownItems) && (
            <div className="inline">
                <DropdownButton text={t("Другие действия")} color="grey" size="sm" icon="bars" items={dropdownItems} />
            </div>
        )
    );
};

export default DropdownOtherButton;
