import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ScrollToTop } from "vetrf-ui";

import App from "./App";
import ErrorEventLogProducer from "./utils/logging/producers/ErrorEventLogProducer";
import FetchingPageIndicator from "./components/fetching/FetchingPageIndicator";
import GlobalErrorBoundary from "./errors/GlobalErrorBoundary";
import logSender from "./utils/logging/LogSender";
import store from "./store";
import SystemNotificationProvider from "./system-notification/containers/components/SystemNotificationProvider";
import UserActionsLogProducer from "./utils/logging/producers/UserActionsLogProducer";
import UserContextProvider from "./session/UserContextProvider";
import { LogManager } from "./utils/logging/base/LogManager";
import { NotificationProvider } from "./common/utils/notification/NotificationProvider";
import { PageParamsProvider } from "./utils/usePageParams";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./i18n";

import "./vendor/styles/vendor.css";
import "vetrf-ui/dist/vetrf-ui.css";
import "./assets/css/app.css";
import "./assets/css/404.css";
import "./assets/css/filter-status-badge.css";
import "./assets/css/option-switch.css";
import "./assets/css/complex-date.css";
import "./assets/css/complex-date-list.css";
import "./assets/css/form-group.css";
import "./assets/css/dropdown-other-button.css";
import "./assets/css/temp.css";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 min
            cacheTime: 10 * 60 * 1000, // 10 min
        },
    },
});

ReactDOM.render(
    <GlobalErrorBoundary>
        <BrowserRouter>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <UserContextProvider>
                        <PageParamsProvider>
                            <SystemNotificationProvider>
                                <ReactQueryDevtools position="bottom-left" />
                                <NotificationProvider />
                                <LogManager listeners={[logSender]} producers={[ErrorEventLogProducer, UserActionsLogProducer]} />
                                <ScrollToTop>
                                    <Suspense fallback={<FetchingPageIndicator />}>
                                        <App />
                                    </Suspense>
                                </ScrollToTop>
                            </SystemNotificationProvider>
                        </PageParamsProvider>
                    </UserContextProvider>
                </QueryClientProvider>
            </Provider>
        </BrowserRouter>
    </GlobalErrorBoundary>,
    document.getElementById("root")
);
