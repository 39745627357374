import { Button, FileField, InputField, NumberField, TextareaField } from "vetrf-ui";
import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

import { getWbrApplicationPaymentTypeView, wbrApplicationPaymentTypes } from "../../../../../utils/constants/wbr/wbr-application-payment-type";
import { validateBik, validateCorrespondentAccountNumber, validatePaymentAccountNumber } from "../../../../../utils/field-validate-utils";
import { validateFilesCount } from "../components/toolbar/WbrApplicationSendContractConfirmationButton";
import AlertWithIcon from "../../../../../components/page/AlertWithIcon";
import BlockHeader from "../../../../../components/page/BlockHeader";
import Container from "../../../../../components/page/Container";
import EBooleanSelectField from "../../../../../components/form/field/EBooleanSelectField";
import EInputField from "../../../../../components/form/field/EInputField";
import ENumberField from "../../../../../components/form/field/ENumberField";
import ESelectField from "../../../../../components/form/field/ESelectField";
import FormActionsPanel from "../../../../../components/page/FormActionsPanel";
import RadioButtonField from "../../../../../components/form/RadioButtonField";
import ShortFormProvider from "../../../../../components/form/ShortFormProvider";

const defaultVatRate = "20";

const getInvoiceDefaultValues = (isPrepayment, paymentRecipient) => {
    return {
        paymentType: isPrepayment ? wbrApplicationPaymentTypes.PREPAYMENT : wbrApplicationPaymentTypes.BEFORE_RESULTS,
        invoice: {
            useVat: true,
            includeVat: false,
            vatRate: defaultVatRate,
            currency: "RUB",
            paymentRecipient,
            invoiceFile: null,
            specificationFile: null,
        },
    };
};

const vatRates = [
    {
        value: "10",
    },
    {
        value: "20",
    },
];

const zeroVat = {
    value: "0",
};

const getPaymentTypeOptions = (isPrepayment, t) => [
    {
        text: getWbrApplicationPaymentTypeView(wbrApplicationPaymentTypes.PREPAYMENT, t),
        value: wbrApplicationPaymentTypes.PREPAYMENT,
        disabled: !isPrepayment,
    },
    {
        text: getWbrApplicationPaymentTypeView(wbrApplicationPaymentTypes.BEFORE_RESULTS, t),
        value: wbrApplicationPaymentTypes.BEFORE_RESULTS,
        disabled: isPrepayment,
    },
    {
        text: getWbrApplicationPaymentTypeView(wbrApplicationPaymentTypes.FREE_PAYMENT, t),
        value: wbrApplicationPaymentTypes.FREE_PAYMENT,
        disabled: isPrepayment,
    },
];

const WbrApplicationPaymentForm = ({ isPrepayment, invoiceInfo, bankDetails, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const methods = useForm();
    const { watch, reset, setValue, getValues, handleSubmit, clearErrors } = methods;
    const useVat = watch("invoice.useVat");

    const isUpdate = !!invoiceInfo?.invoice?.id;

    useEffect(() => {
        if (invoiceInfo) {
            reset(invoiceInfo);
        } else {
            reset(getInvoiceDefaultValues(isPrepayment, bankDetails.paymentRecipient));
        }
    }, [bankDetails, invoiceInfo, isPrepayment, reset]);

    const recalculateAmount = (totalAmount, vatRate) => {
        const total = new BigNumber(totalAmount);
        const rate = new BigNumber(vatRate);
        if (!total.isNaN() && !rate.isNaN()) {
            const isIncludeVat = getValues("invoice.includeVat");
            const vatAmount = total.multipliedBy(rate).dividedBy(rate.plus(100)).toFixed(2);
            const subTotalAmount = isIncludeVat
                ? total.toFixed(2, BigNumber.ROUND_HALF_DOWN)
                : total.minus(vatAmount).toFixed(2, BigNumber.ROUND_HALF_DOWN);
            setValue("invoice.vatAmount", vatAmount);
            setValue("invoice.subTotalAmount", subTotalAmount);
            clearErrors("invoice.vatAmount");
            clearErrors("invoice.subTotalAmount");
        } else {
            setValue("invoice.vatAmount", 0);
            setValue("invoice.subTotalAmount", total.isNaN() ? 0 : total.toFixed(2));
            clearErrors("invoice.vatAmount");
            clearErrors("invoice.subTotalAmount");
        }
    };

    const revalidateAccountNumbers = () => {
        clearErrors("invoice.paymentRecipient.paymentAccountNumber");
        clearErrors("invoice.paymentRecipient.correspondentAccountNumber");
    };

    const onChangeUseVatHandler = (value) => {
        const total = getValues("invoice.totalAmount");
        if (!value) {
            setValue("invoice.includeVat", false);
            setValue("invoice.vatRate", "0");
            setValue("invoice.vatAmount", 0);
            recalculateAmount(total, 0);
        } else {
            setValue("invoice.vatRate", defaultVatRate);
            recalculateAmount(total, defaultVatRate);
        }
    };

    const onChangeIncludeVatHandler = (value) => {
        const total = getValues("invoice.totalAmount");
        const rate = getValues("invoice.vatRate");
        recalculateAmount(total, rate);
    };

    const onChangeTotalAmountHandler = (value) => {
        if (useVat) {
            const vatRate = getValues("invoice.vatRate");
            recalculateAmount(value, vatRate);
        } else {
            setValue("invoice.subTotalAmount", Number.parseFloat(value).toFixed(2));
        }
    };

    const onChangeVatRate = (value) => {
        const totalAmount = getValues("invoice.totalAmount");
        recalculateAmount(totalAmount, value);
    };

    const cantChangeAlert = (
        <AlertWithIcon messageType="info">{t("Заявку будет невозможно взять в работу до поступления сведений об оплате")}</AlertWithIcon>
    );

    return (
        <Fragment>
            <Container>
                {isPrepayment && cantChangeAlert}
                <ShortFormProvider methods={methods}>
                    <BlockHeader>{t("Файл счета и дополнительные сведения")}</BlockHeader>
                    <FileField
                        name={"invoice.invoiceFile"}
                        labelText={t("Файл")}
                        required
                        disabled={isUpdate}
                        acceptedFileTypes={["application/pdf"]}
                        maxFileSize={{ value: "5MB", message: "Максимально допустимый размер файла 5 мегабайт" }}
                        validate={validateFilesCount(1)}
                    />
                    <RadioButtonField
                        name="paymentType"
                        labelText={t("Вид оплаты")}
                        required
                        disabled
                        options={getPaymentTypeOptions(isPrepayment, t)}
                    />
                    <BlockHeader>{t("Сведения для оплаты")}</BlockHeader>
                    <ENumberField
                        name="invoice.totalAmount"
                        labelText={t("Сумма счета (руб.)")}
                        required
                        disabled={isUpdate}
                        format="float"
                        numberOfDecimalPlaces={2}
                        onChange={onChangeTotalAmountHandler}
                    />
                    <EBooleanSelectField
                        name="invoice.useVat"
                        labelText={t("Использовать НДС")}
                        required
                        disabled={isUpdate}
                        onChange={onChangeUseVatHandler}
                    />
                    <EBooleanSelectField
                        name="invoice.includeVat"
                        labelText={t("НДС включен в общую сумму товаров / работ / услуг")}
                        required
                        disabled={isUpdate || !useVat}
                        onChange={onChangeIncludeVatHandler}
                    />
                    <ESelectField
                        name="invoice.vatRate"
                        labelText={t("Ставка НДС (%)")}
                        required
                        disabled={isUpdate || !useVat}
                        valueKey="value"
                        labelKey="value"
                        isResultValueKey
                        options={useVat ? vatRates : [...vatRates, zeroVat]}
                        onChange={onChangeVatRate}
                    />
                    <NumberField
                        name="invoice.vatAmount"
                        labelText={t("Сумма НДС (руб.)")}
                        required
                        disabled={isUpdate || !useVat}
                        format="float"
                        numberOfDecimalPlaces={2}
                    />
                    <NumberField
                        name="invoice.subTotalAmount"
                        labelText={t("Общая сумма товаров / работ / услуг (руб.)")}
                        required
                        disabled
                        format="float"
                    />
                    <TextareaField name="invoice.paymentPurpose" labelText={t("Назначение платежа")} required disabled={isUpdate} maxLength={210} />
                    <EInputField
                        name="invoice.paymentRecipient.bic"
                        labelText={t("БИК банка получателя")}
                        required
                        disabled={isUpdate}
                        maxLength={9}
                        validate={validateBik()}
                        onChange={revalidateAccountNumbers}
                    />
                    <TextareaField
                        name="invoice.paymentRecipient.bankName"
                        labelText={t("Наименование банка получателя")}
                        required
                        disabled={isUpdate}
                        maxLength={140}
                    />
                    <InputField
                        name="invoice.paymentRecipient.paymentAccountNumber"
                        labelText={t("Номер расчетного счета получателя")}
                        required
                        disabled={isUpdate}
                        maxLength={20}
                        validate={validatePaymentAccountNumber(getValues("invoice.paymentRecipient.bic"))}
                    />
                    <InputField
                        name="invoice.paymentRecipient.correspondentAccountNumber"
                        labelText={t("Корреспондентский номер счета банка получателя")}
                        required
                        disabled={isUpdate}
                        maxLength={20}
                        validate={validateCorrespondentAccountNumber(getValues("invoice.paymentRecipient.bic"))}
                    />
                    <InputField name="invoice.paymentRecipient.inn" labelText={t("ИНН получателя платежа")} required disabled />
                    <FileField
                        name={"invoice.specificationFile"}
                        labelText={t("Спецификация")}
                        disabled={isUpdate}
                        acceptedFileTypes={["application/pdf"]}
                        maxFileSize={{ value: "5MB", message: "Максимально допустимый размер файла 5 мегабайт" }}
                        validate={validateFilesCount(1)}
                    />
                </ShortFormProvider>
            </Container>
            <FormActionsPanel>
                <Button id="cancel_btn" text={t("Отмена")} type="submit" size="sm" onClick={onCancel} />
                <FormActionsPanel.RightSide>
                    <Button id="submit_btn" text={t("Отправить")} type="submit" size="sm" color="success" onClick={handleSubmit(onSubmit)} />
                </FormActionsPanel.RightSide>
            </FormActionsPanel>
        </Fragment>
    );
};

const WbrApplicationPaymentFormToolbar = ({ onCancel }) => {
    const { t } = useTranslation();

    return (
        <div className="pull-right inline actions">
            <Button id="request_contract_btn" text={t("Отмена")} size="sm" icon="arrow-left" onClick={onCancel} />
        </div>
    );
};

WbrApplicationPaymentForm.Toolbar = WbrApplicationPaymentFormToolbar;
export default WbrApplicationPaymentForm;
