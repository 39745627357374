import { DateFormGroup } from "vetrf-ui";
import React, { useEffect, useState } from "react";

import { formatDateByPattern, isValidDate, parseDateByPattern } from "../../utils/date-utils";

const FormatDateFormGroup = ({ valuePattern = "dd.MM.yyyy HH:mm", onChange, ...props }) => {
    const [view, setView] = useState(getValueView(props.value, valuePattern, props.dateFormat, props.timeFormat));

    useEffect(() => {
        if (props.value) {
            setView(getValueView(props.value, valuePattern, props.dateFormat, props.timeFormat));
        } else {
            setView(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChangeHandler = (value, field) => {
        setView(value);
        if (value) {
            const pattern = mergePattern(props.dateFormat, props.timeFormat);
            if (value.length === pattern.length) {
                const date = parseDateByPattern(value, pattern);
                if (isValidDate(date)) {
                    const formattedValue = formatDateByPattern(date, valuePattern);
                    onChange(formattedValue, field);
                }
            }
        } else {
            onChange(null, field);
        }
    };

    return <DateFormGroup {...props} value={view} onChange={onChangeHandler} />;
};

const mergePattern = (dateFormat, timeFormat) => {
    return timeFormat ? `${dateFormat ?? "dd.MM.yyyy"} ${timeFormat === true ? "HH:mm" : timeFormat}` : dateFormat ?? "dd.MM.yyyy";
};

const getValueView = (value, valuePattern, dateFormat, timeFormat) => {
    const date = parseDateByPattern(value, valuePattern);
    if (isValidDate(date)) {
        return formatDateByPattern(date, mergePattern(dateFormat, timeFormat));
    }
    return value;
};

export default FormatDateFormGroup;
