import { InputFormGroup } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import React, { useEffect, useRef, useState } from "react";

import { formatDateByPattern, isValidDate, parseDateByPattern } from "../../utils/date-utils";
import FormatDateFormGroup from "./FormatDateFormGroup";
import SeparatedDateTimeFormGroup from "./SeparatedDateTimeFormGroup";

export const PRECISION_TYPE = {
    TIME: "TIME",
    DAY: "DAY",
    DAY_REVERSED: "DAY_REVERSED",
    MONTH: "MONTH",
    MONTH_REVERSED: "MONTH_REVERSED",
    YEAR: "YEAR",
    NOT_LIMITED: "NOT_LIMITED",
};

export const PRECISION_TYPE_VIEW = {
    TIME: "ДД.ММ.ГГГГ чч:мм",
    DAY: "ДД.ММ.ГГГГ",
    DAY_REVERSED: "ГГГГ.ММ.ДД",
    MONTH: "ММ.ГГГГ",
    MONTH_REVERSED: "ГГГГ.ММ",
    YEAR: "ГГГГ",
    NOT_LIMITED: "не ограничен",
};

export const MAX_DATE_TIME_STRING = "01.01.9999 00:00";

export const DEFAULT_FLOATING_DATE_PATTERNS = {
    [PRECISION_TYPE.TIME]: {
        pattern: "dd.MM.yyyy HH:mm",
        regexp: /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.TIME],
    },
    [PRECISION_TYPE.DAY]: {
        pattern: "dd.MM.yyyy",
        regexp: /^\d{2}\.\d{2}\.\d{4}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.DAY],
    },
    [PRECISION_TYPE.DAY_REVERSED]: {
        pattern: "yyyy.MM.dd",
        regexp: /^\d{4}\.\d{2}\.\d{2}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.DAY_REVERSED],
    },
    [PRECISION_TYPE.MONTH]: {
        pattern: "MM.yyyy",
        regexp: /^\d{2}\.\d{4}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.MONTH],
    },
    [PRECISION_TYPE.MONTH_REVERSED]: {
        pattern: "yyyy.MM",
        regexp: /^\d{4}\.\d{2}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.MONTH_REVERSED],
    },
    [PRECISION_TYPE.YEAR]: {
        pattern: "yyyy",
        regexp: /^\d{4}$/g,
        viewPattern: PRECISION_TYPE_VIEW[PRECISION_TYPE.YEAR],
    },
};

const FloatingDateFormGroupWrapper = ({ precisions = [PRECISION_TYPE.DAY], valuePattern = "dd.MM.yyyy HH:mm", ...props }) => {
    const onSimpleDateChangeHandler = (precision) => (value, field) => {
        props.onChange({ value, precision }, field);
    };

    if (precisions.length === 1 && precisions[0] === PRECISION_TYPE.DAY) {
        return (
            <FormatDateFormGroup
                {...props}
                value={props.value?.value}
                valuePattern={valuePattern}
                onChange={onSimpleDateChangeHandler(PRECISION_TYPE.DAY)}
            />
        );
    }

    if (precisions.length === 1 && precisions[0] === PRECISION_TYPE.TIME) {
        return (
            <FormatDateFormGroup
                {...props}
                timeFormat
                value={props.value?.value}
                valuePattern={valuePattern}
                onChange={onSimpleDateChangeHandler(PRECISION_TYPE.TIME)}
            />
        );
    }

    if (precisions.length === 2 && precisions.includes(PRECISION_TYPE.DAY) && precisions.includes(PRECISION_TYPE.TIME)) {
        return <SeparatedDateTimeFormGroup {...props} />;
    }

    return <FloatingDateFormGroup {...props} precisions={precisions} valuePattern={valuePattern} />;
};

const FloatingDateFormGroup = ({
    precisions = [PRECISION_TYPE.DAY],
    patterns = DEFAULT_FLOATING_DATE_PATTERNS,
    valuePattern = "dd.MM.yyyy HH:mm",
    value,
    ...props
}) => {
    const { t } = useTranslation();
    const [view, setView] = useState(dateToInputView(value, { patterns, valuePattern }));
    const precisionsRef = useRef();

    useEffect(() => {
        if (!isEqual(precisions, precisionsRef.current)) {
            precisionsRef.current = precisions;
            setView(dateToInputView(value, { patterns, valuePattern }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precisions]);

    const onChangeHandler = (view, field) => {
        setView(view);
        const precision = getPrecisionForInputView(view, precisions);
        const mappedValue = parseToPattern(view, patterns[precision].pattern, valuePattern);
        props.onChange({ value: mappedValue, precision }, field);
    };

    const onInputValidate = (value) => {
        if (value && !precisions.some((precision) => patterns[precision]?.regexp && value.match(patterns[precision].regexp))) {
            return t("Введенная дата не соответствует формату");
        }
        if (!isDateViewValid(value, precisions, patterns)) {
            return t("Введенная дата имеет неверное значение");
        }
        if (props.customValidate) {
            const precision = getPrecisionForInputView(value, precisions);
            const mappedValue = parseToPattern(value, patterns[precision].pattern, valuePattern);
            const result = props.customValidate({ value: mappedValue, precision });
            if (result !== true) {
                return result;
            }
        }
        return true;
    };

    return (
        <InputFormGroup
            {...props}
            value={view}
            onChange={onChangeHandler}
            help={props.help ?? `${t("Введите дату в формате")} ${getDatePrecisionPatternsView(precisions, t)}`}
            customValidate={onInputValidate}
        />
    );
};

export const isDateViewValid = (view, precisions, patterns) => {
    let isValidView = false;
    for (let i = 0; i < precisions.length; i++) {
        const precision = precisions[i];
        const date = parseDateByPattern(view, patterns[precision].pattern);
        if (isValidDate(date) && date.getFullYear() > 1000) {
            isValidView = true;
            break;
        }
    }
    return isValidView;
};

export const getPrecisionForInputView = (text, allowedPrecisions = [PRECISION_TYPE.DAY]) => {
    if (allowedPrecisions.length === 0) return PRECISION_TYPE.TIME;
    if (!text) return allowedPrecisions[0];
    const length = text.length;
    if (length <= 4 && allowedPrecisions.includes(PRECISION_TYPE.YEAR)) {
        // 2007
        return PRECISION_TYPE.YEAR;
    } else if (length <= 7) {
        if (allowedPrecisions.includes(PRECISION_TYPE.MONTH) && text.indexOf(".") === 2) {
            // 05.2007
            return PRECISION_TYPE.MONTH;
        } else if (allowedPrecisions.includes(PRECISION_TYPE.MONTH_REVERSED) && text.indexOf(".") === 4) {
            // 2007.05
            return PRECISION_TYPE.MONTH_REVERSED;
        }
    } else if (length <= 10) {
        if (allowedPrecisions.includes(PRECISION_TYPE.DAY) && text.indexOf(".") === 2) {
            // 20.05.2007
            return PRECISION_TYPE.DAY;
        } else if (allowedPrecisions.includes(PRECISION_TYPE.DAY_REVERSED) && text.indexOf(".") === 4) {
            // 2007.05.20
            return PRECISION_TYPE.DAY_REVERSED;
        }
    } else if (allowedPrecisions.includes(PRECISION_TYPE.TIME)) {
        // 20.05.2007 13:37
        return PRECISION_TYPE.TIME;
    }
    return allowedPrecisions[0];
};

const dateToInputView = (date, { patterns, valuePattern }) => {
    if (date?.precision === PRECISION_TYPE.NOT_LIMITED) {
        return null;
    }
    return getFloatingDateView(date, { patterns, valuePattern });
};

export const parseToPattern = (dateView, from, to) => {
    const date = parseDateByPattern(dateView, from);
    if (isValidDate(date)) {
        return formatDateByPattern(date, to);
    }
    return dateView;
};

const DEFAULT_FLOATING_DATE_VIEW_OPTIONS = {
    patterns: DEFAULT_FLOATING_DATE_PATTERNS,
    valuePattern: "dd.MM.yyyy HH:mm",
    view: { [PRECISION_TYPE.NOT_LIMITED]: PRECISION_TYPE_VIEW[PRECISION_TYPE.NOT_LIMITED] },
};

export const getFloatingDateView = (date, options = DEFAULT_FLOATING_DATE_VIEW_OPTIONS) => {
    if (!date) return;
    const mergedOptions = merge(DEFAULT_FLOATING_DATE_VIEW_OPTIONS, options);
    const view = mergedOptions.view?.[date.precision];
    if (view) return view;

    const value = date.value;
    const dateValue = parseDateByPattern(value, mergedOptions.valuePattern);
    if (isValidDate(dateValue) && date.precision) {
        return formatDateByPattern(dateValue, mergedOptions.patterns[date.precision].pattern);
    }
    return value;
};

export const getDatePrecisionPatternsView = (precisions, t) => {
    const patternsView = precisions.map((precision) => t(PRECISION_TYPE_VIEW[precision])).join(", ");
    const index = patternsView.lastIndexOf(",");
    if (index > -1) {
        return `${patternsView.substring(0, index)} ${t("или")}${patternsView.substring(index + 1)}`;
    }
    return patternsView;
};

export default FloatingDateFormGroupWrapper;
