import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { clearAllNotifications, notifyError, notifySuccess } from "../../store/notification/service";
import NotificationManager from "./NotificationManager";

export function NotificationProvider() {
    const dispatch = useDispatch();

    function notify(error, title, message) {
        if (error === null) dispatch(notifySuccess(title, message));
        else dispatch(notifyError(error, title, message));
    }

    function clearAll() {
        dispatch(clearAllNotifications());
    }

    useEffect(() => {
        NotificationManager.register({ notify, clearAll });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}
