import { Button, Page, ProfileTable, SubHeader } from "vetrf-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { business_participant_modify } from "../../../common/constants/permissions";
import { RUSSIA, getUnknownNormalText } from "../../../utils/constants/constants";
import { useBusinessParticipantQuery } from "../../api/businessParticipantsQueries";
import BusinessParticipantDeleteButton from "./inner/BusinessParticipantDeleteButton";
import BusinessParticipantSynchronizationConfirmButton from "./inner/BusinessParticipantSynchronizationConfirmButton";
import CountryFlag from "../../../components/countryFlag/CountryFlag";
import PrivateContent from "../../../components/private-route/PrivateContent";

const ViewFormPage = () => {
    const { id } = useParams();
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const { data: participant } = useBusinessParticipantQuery(id);

    const title = t("Хозяйствующий субъект");
    const unknown = getUnknownNormalText(t);

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "participant_list_page",
            link: "/participants/",
            text: t("Список хозяйствующих субъектов"),
        },
        {
            active: true,
            text: title,
        },
    ];

    const toolbar = participant?.id && (
        <Fragment>
            <div className="actions pull-right">
                <PrivateContent authority={business_participant_modify}>
                    {participant.country?.guid === RUSSIA.guid && (
                        <BusinessParticipantSynchronizationConfirmButton id="sync_btn" item={participant} />
                    )}
                    <Button id="edit_btn" text={t("Редактировать")} href={`edit`} color="purple" size="sm" icon="edit" />
                    <BusinessParticipantDeleteButton id="delete_btn" participant={participant} />
                </PrivateContent>
            </div>
        </Fragment>
    );

    const rows = [
        participant?.country?.guid === RUSSIA.guid && {
            header: {
                key: "syncStatus",
                title: t("Статус синхронизации"),
            },
            dataFormatter: (item) =>
                item ? (
                    <Fragment>
                        <span className={item?.businessEntityGuid ? "fa fa-link green" : "fa fa-link grey"} />{" "}
                        {item?.businessEntityGuid ? "Синхронизировано" : "Не синхронизировано"}
                    </Fragment>
                ) : (
                    unknown
                ),
        },
        {
            header: {
                key: "name",
                title: t("Наименование"),
            },
            dataFormatter: (item) => item?.name || unknown,
        },
        {
            header: {
                key: "englishName",
                title: t("Наименование на английском"),
            },
            dataFormatter: (item) => item?.englishName || unknown,
        },
        {
            header: {
                key: "country",
                title: t("Страна"),
            },
            dataFormatter: (item) => (item?.country ? <CountryFlag country={item.country} nameField={"view"} /> : unknown),
        },
        {
            header: {
                key: "address",
                title: t("Адрес"),
            },
            dataFormatter: (item) => item?.address || unknown,
        },
        {
            header: {
                key: "englishAddress",
                title: t("Адрес на английском"),
            },
            dataFormatter: (item) => item?.englishAddress || unknown,
        },
    ].filter(Boolean);

    return (
        <Page header={title} breadcrumbs={breadcrumbs} toolbar={toolbar} title={title}>
            <div className="row">
                <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0">
                    <SubHeader header={t("Сведения о хозяйствующем субъекте")} />
                    <div className="wraptablebig">
                        <ProfileTable
                            id="common"
                            itemList={participant ? [{ item: participant }] : [{}]}
                            tableClassName="table color-table-border font-weight-normal"
                            rows={rows}
                        />
                    </div>
                </div>
            </div>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="back_btn" size="sm" icon="list" text={t("К списку")} onClick={() => navigate("..")} />
                {toolbar}
            </div>
        </Page>
    );
};

export default ViewFormPage;
