import NotificationManager from "../common/utils/notification/NotificationManager";
import { useCustomQuery, useCustomMutation, setQueryParams, usePageQuery } from "../utils/commonQueryBase";

const baseUrl = "/rest-api/notifications/";
const REFETCH_NEW_NOTIFICATIONS_INTERVAL = 60000;

// мутация (костыль), чтобы страница не подгружалась при изменении параметров страницы
// а именно при изменении offset, не нужно заново загружать текущую страницу
export function useUnreadNotificationsPageMutation() {
    const createRequest = (data) => ({
        type: "POST",
        url: setQueryParams("unread", data.pageParams),
        body: data.searchParams ?? {},
    });

    return useCustomMutation(baseUrl, createRequest, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка уведомлений."),
    });
}

export function useNotificationsPage(pageParams, searchParams, onSuccess) {
    return usePageQuery(baseUrl, pageParams, searchParams, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка уведомлений."),
        onSuccess: onSuccess,
    });
}

export function useNewNotificationsPage(enabled, afterDate, onSuccess) {
    const querySettings = { params: { afterDate } };
    return useCustomQuery(baseUrl + "new", querySettings, {
        enabled,
        refetchInterval: REFETCH_NEW_NOTIFICATIONS_INTERVAL,
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка уведомлений."),
        onSuccess: onSuccess,
    });
}

export function useMarkNotificationMutation() {
    const createRequest = (data) => ({
        type: "POST",
        url: `${data.id}/markAsRead`,
    });

    return useCustomMutation(baseUrl, createRequest, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при пометке уведомления как прочитанного."),
    });
}

export function useMarkAllNotificationMutation() {
    const createRequest = () => ({
        type: "POST",
        url: "/markAsRead",
    });

    return useCustomMutation(baseUrl, createRequest, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при пометке уведомления как прочитанного."),
    });
}

export function useEventTypesQuery(onSuccess) {
    return useCustomQuery(
        baseUrl + "event-types",
        {},
        {
            refetchOnWindowFocus: false,
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка типов уведомлений."),
            onSuccess: onSuccess,
        }
    );
}
