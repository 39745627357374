export const MARITIME_TRANSPORT_GUID = "32c2e908-7bcc-470e-b219-bb14c1b842ca";
export const INLAND_WATER_TRANSPORT_GUID = "d7102953-dc71-439e-b442-8b7c6681282e";
export const ROAD_TRANSPORT_TRANSPORT_GUID = "f76a39ae-adad-4a46-afb2-81a2de7435b7";
export const AIR_TRANSPORT_GUID = "405896b5-1f77-4d21-95fa-e0c623f698e6";
export const RAIL_TRANSPORT_GUID = "e9c94066-c86f-4cd7-92bb-f617ddef7ab0";
export const TRANSPORT_NOT_APPLICABLE_GUID = "fd7dbc0a-8aa6-4664-b044-1779da1b95e6";

export const getTransportNumberHelp = (transportMovement) => {
    const type = transportMovement?.transportType?.id;
    switch (type) {
        case MARITIME_TRANSPORT_GUID:
            return "Для морского транспорта указывается номер и название судна";
        case INLAND_WATER_TRANSPORT_GUID:
            return "Для речного транспорта указывается номер и название судна";
        case ROAD_TRANSPORT_TRANSPORT_GUID:
            return "Для автотранспорта указывается номер транспортного средства";
        case AIR_TRANSPORT_GUID:
            return "Для авиатранспорта указывается номер рейса";
        case RAIL_TRANSPORT_GUID:
            return "Для ж/д транспорта указывается номер поезда";
        case TRANSPORT_NOT_APPLICABLE_GUID:
            return "Для перемещения без транспорта указывается фактический способ перемещения, например: walker";
        default:
            return "";
    }
};
