import ajax from "../../utils/ajax";

import { TRANSPORT_TYPE_LIST_LOAD, TRANSPORT_TYPE_LIST_RECEIVE, TRANSPORT_TYPE_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const transportTypesLoad = () => ({
    type: TRANSPORT_TYPE_LIST_LOAD,
});

export const transportTypesReceive = (transportTypes) => ({
    type: TRANSPORT_TYPE_LIST_RECEIVE,
    transportTypes,
});

export const transportTypesLoadError = (error) => ({
    type: TRANSPORT_TYPE_LIST_ERROR,
    error,
});

export const requestTransportTypes = () => (dispatch) => {
    dispatch(transportTypesLoad());
    request({
        type: "POST",
        url: "transportTypes",
    })
        .then((response) => {
            dispatch(transportTypesReceive(response));
        })
        .catch((error) => {
            dispatch(transportTypesLoadError(error));
        });
};
