import { DISEASES_LIST_LOAD, DISEASES_LIST_RECEIVE, DISEASES_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    diseases: [],
    error: null,
    loading: false,
};

const getList = (action) => (action.diseases && action.diseases.length > 0 ? action.diseases : []);

const diseasesLoad = (state, action) => ({ ...state, loading: true });

const diseasesReceive = (state, action) => ({
    ...state,
    error: null,
    diseases: getList(action),
    loading: false,
});

const diseasesError = (state, action) => ({
    ...state,
    error: action.error,
    diseases: [],
    loading: false,
});

export default function diseases(state = initialState, action) {
    switch (action.type) {
        case DISEASES_LIST_LOAD:
            return diseasesLoad(state, action);
        case DISEASES_LIST_RECEIVE:
            return diseasesReceive(state, action);
        case DISEASES_LIST_ERROR:
            return diseasesError(state, action);
        default:
            return state;
    }
}
