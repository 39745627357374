import React from "react";
import { Alert, Page } from "vetrf-ui";

const breadcrumbs = [
    {
        link: "/",
        text: "Главная",
    },
    {
        text: "403",
    },
];

const AccessDenied = () => (
    <Page title="403" header="Ошибка авторизации" breadcrumbs={breadcrumbs}>
        <div className="col-xs-6 col-xs-offset-3">
            <Alert messageType="danger">
                <h3 className="no-margin-top">
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp; Доступ запрещен!
                </h3>
                <p>Произошла ошибка авторизации при выполнении операции. Данная страница не является публичной.</p>
                <p>Возможно завершилась сессия, попробуйте перезайти в систему.</p>
            </Alert>
        </div>
    </Page>
);

export default AccessDenied;
