import { FormProvider } from "react-hook-form";

const ShortFormProvider = ({ id, methods, onSubmit, innerRef, children }) => {
    return (
        <FormProvider {...methods}>
            <form id={id} onSubmit={methods.handleSubmit(onSubmit)} className="form-horizontal css-form" ref={innerRef}>
                {children}
            </form>
        </FormProvider>
    );
};

export default ShortFormProvider;
