import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { documentTypes } from "../../../../../../utils/constants/source-document-types";
import AdditionalContractDocumentsTable from "./documents/AdditionalContractDocumentsTable";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import ContractDocumentTable from "./documents/ContractDocumentTable";
import HaccpJournalDocumentsTable from "./documents/HaccpJournalDocumentsTable";
import JournalTable from "./documents/JournalTable";

const journalDocumentType = documentTypes.techJournalOfProduction;
const contractDocumentType = documentTypes.contract;
const additionalContractDocumentType = documentTypes.additionalToContractForSupply;
const haccpDocumentType = documentTypes.haccpBased;

const WbrApplicationDocuments = ({ application, applicationForCompare }) => {
    const { t } = useTranslation();
    const filterAvailableDocuments = (allDocuments) => allDocuments?.filter((document) => !document.externalItemKey);
    const filterJournalDocuments = (documents) => documents.filter((document) => document.type?.id === journalDocumentType.id);
    const filterContractDocuments = (documents) => documents.filter((document) => document.type?.id === contractDocumentType.id);
    const filterAdditionalContractDocuments = (documents) => documents.filter((document) => document.type?.id === additionalContractDocumentType.id);
    const filterHaccpJournalDocuments = (documents) => documents.filter((document) => document.type?.id === haccpDocumentType.id);

    const applicationDocuments = filterAvailableDocuments(application.documents);
    const applicationDocumentsForCompare = applicationForCompare && filterAvailableDocuments(applicationForCompare.documents);
    return (
        <Fragment>
            <BlockHeader className="margin-top-3">{t("Прикрепленные документы заявки")}</BlockHeader>
            <JournalTable
                documents={filterJournalDocuments(applicationDocuments)}
                documentsForCompare={applicationForCompare && filterJournalDocuments(applicationDocumentsForCompare)}
                applicationId={application.id}
            />
            <ContractDocumentTable
                documents={filterContractDocuments(applicationDocuments)}
                documentsForCompare={applicationForCompare && filterContractDocuments(applicationDocumentsForCompare)}
            />
            {(filterAdditionalContractDocuments(applicationDocuments).length > 0 ||
                (applicationForCompare && filterAdditionalContractDocuments(applicationDocumentsForCompare).length > 0)) && (
                <AdditionalContractDocumentsTable
                    documents={filterAdditionalContractDocuments(applicationDocuments)}
                    documentsForCompare={applicationForCompare && filterAdditionalContractDocuments(applicationDocumentsForCompare)}
                />
            )}
            <HaccpJournalDocumentsTable
                documents={filterHaccpJournalDocuments(applicationDocuments)}
                documentsForCompare={applicationForCompare && filterHaccpJournalDocuments(applicationDocumentsForCompare)}
                applicationId={application.id}
            />
        </Fragment>
    );
};

export default WbrApplicationDocuments;
