import React, { createContext, useContext, useEffect, useState } from "react";
import { useSessionMutation } from "./api/sessionQueries";

const UserContext = createContext({});

export function useUserContext() {
    const { user, requestSession } = useContext(UserContext);

    return {
        user,
        authorities: user.authorities,
        requestSession,
    };
}

const UserContextProvider = ({children}) => {
    const [user, setUser] = useState({});
    const sessionMutation = useSessionMutation();

    const requestSession = () => {
        sessionMutation.mutate({}, { onSuccess: (data) => setUser(data) });
    };

    useEffect(() => {
        requestSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UserContext.Provider value={{ user, requestSession }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
