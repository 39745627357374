import { Button } from "vetrf-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { useOfficialVeterinariansPageByOrgQuery } from "../../api/officialVeterinarianQueries";
import { veterinarian_modify } from "../../../common/constants/permissions";
import BlinkingFetchingContainer from "../../../components/fetching/BlinkingFetchingContainer";
import OfficialVeterinarianPageableTable from "./components/OfficialVeterinarianPageableTable";
import OfficialVeterinarianSearchWrapper from "./components/OfficialVeterinarianSearchWrapper";
import PageContent from "../../../components/page/PageContent";
import PrivateContent from "../../../components/private-route/PrivateContent";
import usePageParams from "../../../utils/usePageParams";
import WideWrapper from "../../../components/page/WideWrapper";

const OfficialVeterinarianListPage = () => {
    const { t } = useTranslation("translations");
    const title = t("Список ветеринарных врачей");

    const { setPage, setSize, pageParams, searchParams, setSearchParams } = usePageParams("OfficialVeterinarian");
    const { data: officialVeterinarians, isFetching: isLoading } = useOfficialVeterinariansPageByOrgQuery(pageParams, searchParams);
    const additionalToolbar = (
        <PrivateContent authority={veterinarian_modify}>
            <Link to={"create"}>
                <Button id="add_btn" icon="plus" color="primary" size="sm" text={t("Добавить")} />
            </Link>
        </PrivateContent>
    );

    return (
        <OfficialVeterinarianSearchWrapper searchParams={searchParams} setSearchParams={setSearchParams}>
            {(searchForm, budgeList) => (
                <PageContent title={title} toolbar={additionalToolbar} secondLineInfo={budgeList}>
                    <WideWrapper>
                        {searchForm}
                        <BlinkingFetchingContainer isFetching={isLoading}>
                            <OfficialVeterinarianPageableTable page={officialVeterinarians} onChangePage={setPage} onChangeSizePerPage={setSize} />
                        </BlinkingFetchingContainer>
                    </WideWrapper>
                </PageContent>
            )}
        </OfficialVeterinarianSearchWrapper>
    );
};

export default OfficialVeterinarianListPage;
