import { Button, PaginationRow, Table } from "vetrf-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { getTransportListView, getWeightView } from "../../../../../utils/viewUtils";
import { getUnknownNormalText } from "../../../../../utils/constants/constants";
import { getWbrApplicationStatusView, isWbrAppInStatus, wbrApplicationStatuses } from "../../../../../utils/constants/wbr/wbr-application-statuses";
import { parseDate, parseDateTime } from "../../../../../utils/date-utils";
import { viewRoute } from "../../../../WbrConclusionRoutes";
import { WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";
import { WBR_APPLICATION_IN_PROCESS_REMAINING_DAYS } from "../../../../../utils/constants/wbr/wbr-constants";
import CountryFlag from "../../../../../components/countryFlag/CountryFlag";

const getConsignorView = (consignor) => {
    return `${consignor.view}${consignor.inn ? ` (${consignor.inn})` : ""}`;
};

const getItemView = (item) => {
    return `${item.subProduct?.name}, ${getWeightView(item.netWeight)}`;
};

const getItemsView = (items) => {
    if (!items) return null;
    if (items.length <= 1) {
        return getItemView(items[0]);
    }
    return (
        <ul className="list-unstyled">
            {items.slice().map((item, index) => (
                <li key={index}>
                    <i className="ace-icon fa fa-caret-right blue" />
                    {getItemView(item)}
                </li>
            ))}
        </ul>
    );
};

const getApplicationIcon = (source, status) => {
    return <i className="ace-icon fa fa-window-restore" />;
};

const WbrApplicationPageableTable = ({ page, onChangePage, onChangeSizePerPage, sortedId }) => {
    const { t, i18n } = useTranslation();
    const nameField = i18n.resolvedLanguage === "ru" ? "name" : "englishName";
    const unknown = getUnknownNormalText(t);

    const getReviewPeriodView = (application) => {
        if (isWbrAppInStatus(application, [wbrApplicationStatuses.SENT_FOR_REVISION])) return t("Приостановлен");
        if (application.expireDate) return application.expireDate;
        return t("Срок не установлен");
    };

    const getReviewPeriodClassName = (application) => {
        if (!application.expireDate) return "";
        let workDays;
        if (isWbrAppInStatus(application, wbrApplicationStatuses.SENT_FOR_REVISION)) {
            const inProgressDate = parseDateTime(getChangeDateInHistory(application.statusHistory, wbrApplicationStatuses.IN_PROCESS));
            const revisionDate = parseDateTime(getChangeDateInHistory(application.statusHistory, wbrApplicationStatuses.SENT_FOR_REVISION));
            workDays = WBR_APPLICATION_IN_PROCESS_REMAINING_DAYS - getInaccurateWorkDaysBetween(inProgressDate, revisionDate);
        } else {
            workDays = getInaccurateWorkDaysCountFromNow(application.expireDate);
        }
        if (workDays <= 2) return "light-red-background";
        else if (workDays <= 4) return "light-orange-background";
        else return "light-green-background";
    };

    const getRowClassName = (application) => {
        if (!application.expireDate || !isWbrAppInStatus(application, wbrApplicationStatuses.NEW)) return "";
        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);
        const expireDate = parseDate(application.expireDate);
        if (expireDate < dateNow) return "light-red-background";
        return "";
    };

    const columns = [
        {
            key: "qualifier",
            title: "",
            className: "align-center",
            dataFormatter: (item) => getApplicationIcon(item.source),
            getClassName: getRowClassName,
        },
        {
            key: "referenceNumber",
            title: t("Номер заявки"),
            className: "align-center",
            dataFormatter: (item) => <Link to={WBR_APP_ROUTE.VIEW.getFullPath(item.id)}>{item.referenceNumber || unknown}</Link>,
            getClassName: getRowClassName,
        },
        {
            key: "documentStatus",
            title: t("Статус"),
            dataFormatter: (item) => getWbrApplicationStatusView(item.status, t),
            getClassName: getRowClassName,
        },
        {
            key: "receiveDateTime",
            title: t("Дата подачи заявки"),
            className: "align-center",
            dataFormatter: (item) => item.receiveDateTime ?? unknown,
            getClassName: (item) => getRowClassName(item) + " align-center",
        },
        {
            key: "reviewPeriod",
            title: t("Срок рассмотрения заявки"),
            dataFormatter: (item) => getReviewPeriodView(item),
            getClassName: (item) => getReviewPeriodClassName(item) + " align-center",
        },
        {
            key: "country",
            title: t("Страна назначения"),
            dataFormatter: (item) => <CountryFlag country={item.consignment.importCountry} nameField={nameField} />,
            getClassName: getRowClassName,
        },
        {
            key: "consignor",
            title: t("Грузоотправитель"),
            dataFormatter: (item) => getConsignorView(item.consignment.consignor),
            getClassName: getRowClassName,
        },
        {
            key: "item",
            title: t("Сведения о грузе"),
            dataFormatter: (item) => getItemsView(item.consignment.items) ?? unknown,
            getClassName: getRowClassName,
        },
        {
            key: "transportMovement",
            title: t("Сведения о транспорте"),
            dataFormatter: (item) => getTransportListView(item.consignment.transportMovements, unknown),
            getClassName: getRowClassName,
        },
        {
            key: "conclusion",
            title: t("Заключение"),
            dataFormatter: (item) =>
                item.conclusion?.referenceNumber && (
                    <Link to={viewRoute.replace(":id", item.conclusion.id)}>{item.conclusion.referenceNumber || unknown}</Link>
                ),
            getClassName: getRowClassName,
        },
        {
            key: "actions",
            title: "",
            width: "40px",
            className: "align-center",
            buttonFormatter: (item, tableId, index) => {
                const viewButtonId = `${tableId}__view_${index}`;
                return (
                    <span>
                        <Button
                            href={WBR_APP_ROUTE.VIEW.getFullPath(item.id)}
                            id={viewButtonId}
                            icon="bars"
                            iconSize={130}
                            onlyIcon={true}
                            tooltip={t("Просмотр")}
                        />
                    </span>
                );
            },
            getClassName: getRowClassName,
        },
    ];

    const pagination = (
        <PaginationRow
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            className="table table-striped table-bordered table-hover"
            dataList={page?.content}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

function getChangeDateInHistory(history, status) {
    const statusChange = history.findLast((change) => change.status === status);
    return statusChange?.changeDate;
}

function getInaccurateWorkDaysCountFromNow(date) {
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const expireDate = parseDate(date);
    if (expireDate < dateNow) return 0;
    return getWeekdayCount(dateNow, expireDate, true);
}

function getInaccurateWorkDaysBetween(startDate, endDate, includeStart = false) {
    if (!startDate || !endDate) return 0;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    return getWeekdayCount(startDate, endDate, includeStart);
}

function getWeekdayCount(startDate, endDate, includeStartDay = false) {
    const oneDay = 24 * 60 * 60 * 1000;
    let currentDate = new Date(startDate.getTime());
    let workingDays = 0;
    while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
            workingDays++;
        }
        currentDate.setTime(currentDate.getTime() + oneDay);
    }
    return workingDays - (includeStartDay ? 1 : 0);
}

export default WbrApplicationPageableTable;
