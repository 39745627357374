import logger from "../base/LogManager";
import useClickElementEffect from "./utils/useClickElementEffect";
import useLocationEffect from "./utils/useLocationEffect";

const UserActionsLogProducer = () => {
    useLocationEffect(onActionHandler);
    useClickElementEffect(onActionHandler);

    function onActionHandler(message) {
        logger.debug(message);
    }

    return null;
};

export default UserActionsLogProducer;
