import { breeds } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка пород";

export const requestBreeds = () =>
    createLoadAsyncAction(
        breeds,
        request,
        {
            type: "GET",
            url: "breeds/all",
        },
        errorMessage
    );
