import { handleActions } from "redux-actions";
import { session, userSettings } from "./actions";

const initialState = {
    authentication: {
        currentUser: {},
        fail: {},
        loading: false,
    },
};

export default handleActions(
    {
        [session.load.request]: (state) => ({
            ...state,
            authentication: { ...state.authentication, fail: {}, loading: true },
        }),
        [session.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            authentication: {
                currentUser: payload,
                fail: {},
                loading: false,
            },
        }),
        [session.load.error]: (state, action) => ({
            ...initialState,
            authentication: {
                currentUser: {},
                fail: action.payload,
                loading: false,
            },
        }),
        [userSettings.saving.request]: (state) => ({
            ...state,
            authentication: { ...state.authentication, fail: {}, loading: true },
        }),
        [userSettings.saving.success]: (state, { payload = {} }) => ({
            ...state,
            authentication: {
                currentUser: {
                    ...state.authentication.currentUser,
                    position: payload.position,
                    fioEnglish: payload.fioEnglish,
                    positionEnglish: payload.positionEnglish,
                    department: payload.department && payload.department,
                },
                fail: {},
                loading: false,
            },
        }),
        [userSettings.saving.error]: (state, action) => ({
            ...state,
            authentication: {
                ...state.authentication,
                fail: action.payload,
                loading: false,
            },
        }),
    },
    initialState
);
