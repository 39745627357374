import { Route, Routes } from "react-router-dom";
import { Translation } from "react-i18next";
import { useState } from "react";

import PageNotFound from "../../errors/PageNotFound";
import RequireAuth from "../../components/private-route/RequireAuth";
import WbrApplicationListPage from "./containers/list/WbrApplicationListPage";
import WbrApplicationPaymentFormPage from "./containers/view/payment/WbrApplicationPaymentFormPage";
import WbrApplicationPaymentPage from "./containers/view/payment/WbrApplicationPaymentPage";
import WbrApplicationRejectPage from "./containers/view/WbrApplicationRejectPage";
import WbrApplicationRevisionPage from "./containers/view/WbrApplicationRevisionPage";
import WbrApplicationRevisionViewPage from "./containers/view/WbrApplicationRevisionViewPage";
import WbrApplicationViewPage from "./containers/view/WbrApplicationViewPage";
import {
    wbr_application_actions,
    wbr_application_documents_actions,
    wbr_application_view_all,
    wbr_application_view_org,
} from "../../common/constants/permissions";

const BASE_URL = "/wbr-conclusion/application";
const LIST_URL_PART = "";
const VIEW_URL_PART = ":id";
const HISTORY_URL_PART = ":id/history/:statusChangeId";
const HISTORY_COMPARE_URL_PART = ":id/history/:statusChangeId/:anotherStatusChangeId";
const PAYMENT_URL_PART = ":id/payment";
const PAYMENT_ACTION_URL_PART = ":id/payment/:action";
const REJECT_URL_PART = ":id/reject";
const REVISION_URL_PART = ":id/for-revision";

export const WBR_APP_PAYMENT_ACTIONS = {
    EDIT: "edit",
};

export const WBR_APP_ROUTE = {
    LIST: { getFullPath: () => BASE_URL, getTitle: (t) => t("Список заявок на ВБР") },
    VIEW: { getFullPath: (id) => `${BASE_URL}/${id}`, getTitle: (t) => t("Просмотр заявки на ВБР") },
    HISTORY_VIEW: {
        getFullPath: (applicationId, statusChangeId, anotherStatusChangeId) =>
            `${BASE_URL}/${applicationId}/history/${statusChangeId}${anotherStatusChangeId ? `/${anotherStatusChangeId}` : ""}`,
        getTitle: (isCompare, t) => isCompare ? t("Просмотр доработанной заявки") : t("Просмотр заявки, отправленной на доработку"),
        getChapter: (t) => WBR_APP_ROUTE.VIEW.getTitle(t),
    },
    PAYMENT_VIEW: {
        getFullPath: (id) => `${BASE_URL}/${id}/payment`,
        getTitle: (t) => t("Сведения об оплате"),
        getChapter: (t) => WBR_APP_ROUTE.VIEW.getTitle(t),
    },
    PAYMENT_ACTION: {
        getFullPath: (id, action) => `${BASE_URL}/${id}/payment/${action}`,
        getTitle: (t, action) => t("Сведения об оплате"),
        getChapter: (t) => WBR_APP_ROUTE.VIEW.getTitle(t),
    },
    CLOSING: {
        getFullPath: (id) => `${BASE_URL}/${id}/closing`,
        getTitle: (t) => t("Регистрация закрывающих документов"),
    },
    REVISION: {
        getFullPath: (id) => `${BASE_URL}/${id}/for-revision`,
        getTitle: (t) => t("Отправка заявки на доработку"),
        getChapter: (t) => WBR_APP_ROUTE.VIEW.getTitle(t),
    },
    REJECT: {
        getFullPath: (id) => `${BASE_URL}/${id}/reject`,
        getTitle: (t) => t("Отклонение заявки"),
        getChapter: (t) => WBR_APP_ROUTE.VIEW.getTitle(t),
    },
};

export const WBR_APP_BREADCRUMBS = {
    getRootBreadcrumbs: () => [{ link: WBR_APP_ROUTE.LIST.getFullPath(), text: <Translation>{(t) => WBR_APP_ROUTE.LIST.getTitle(t)}</Translation> }],
    getViewBreadcrumbs: (id) => [
        ...WBR_APP_BREADCRUMBS.getRootBreadcrumbs(),
        { link: WBR_APP_ROUTE.VIEW.getFullPath(id), text: <Translation>{(t) => WBR_APP_ROUTE.VIEW.getTitle(t)}</Translation> },
    ],
};

const WbrApplicationRoutes = () => {
    const [revisionState, setRevisionState] = useState();

    return (
        <Routes>
            <Route
                exact
                path={LIST_URL_PART}
                element={
                    <RequireAuth someAuthority={[wbr_application_view_all, wbr_application_view_org]}>
                        <WbrApplicationListPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={VIEW_URL_PART}
                element={
                    <RequireAuth someAuthority={[wbr_application_view_all, wbr_application_view_org]}>
                        <WbrApplicationViewPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={HISTORY_URL_PART}
                element={
                    <RequireAuth someAuthority={[wbr_application_view_all, wbr_application_view_org]}>
                        <WbrApplicationRevisionViewPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={HISTORY_COMPARE_URL_PART}
                element={
                    <RequireAuth someAuthority={[wbr_application_view_all, wbr_application_view_org]}>
                        <WbrApplicationRevisionViewPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={PAYMENT_URL_PART}
                element={
                    <RequireAuth authority={wbr_application_documents_actions}>
                        <WbrApplicationPaymentPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={PAYMENT_ACTION_URL_PART}
                element={
                    <RequireAuth authority={wbr_application_documents_actions}>
                        <WbrApplicationPaymentFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={REVISION_URL_PART}
                element={
                    <RequireAuth authority={wbr_application_actions}>
                        <WbrApplicationRevisionPage revisionState={revisionState} setRevisionState={setRevisionState} />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={REVISION_URL_PART}
                element={
                    <RequireAuth authority={wbr_application_actions}>
                        <WbrApplicationRevisionPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path={REJECT_URL_PART}
                element={
                    <RequireAuth authority={wbr_application_actions}>
                        <WbrApplicationRejectPage />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default WbrApplicationRoutes;
