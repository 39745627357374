import React from "react";
import isEmpty from "lodash/isEmpty";
import ProcessingFilterBadge from "./ProcessingFilterBadge";
import uniqueId from "lodash/uniqueId";

const badgeKeyPrefix = "filter-badge_";

const pushAll = (inputList, outputList, key, handleClick, getText) => {
    inputList &&
        inputList.forEach((item, index) =>
            outputList.push(
                <ProcessingFilterBadge key={uniqueId(badgeKeyPrefix)} text={getText(item)} handleClick={handleClick(`${key}[${index}]`)} />
            )
        );
};

const defaultDate = "...";

const getProcessingFilterBadgeList = (filterObj, resetSearchFormAndSearch) => {
    let badgeList = [];
    if (!isEmpty(filterObj) && resetSearchFormAndSearch) {
        for (let key in filterObj) {
            let text;
            switch (key) {
                case "shoulderNumber": {
                    text = "";
                    break;
                }
                case "countryList": {
                    pushAll(filterObj[key], badgeList, key, resetSearchFormAndSearch, (country) => country && country);
                    break;
                }
                case "operationTypeList": {
                    pushAll(filterObj[key], badgeList, key, resetSearchFormAndSearch, (logType) => logType && logType);
                    break;
                }
                case "statusList": {
                    pushAll(filterObj[key], badgeList, key, resetSearchFormAndSearch, (status) => status && status);
                    break;
                }
                default: // do nothing
            }

            if (key !== "countryList" && key !== "operationTypeList" && key !== "statusList") {
                let value = filterObj[key];
                if (key === "insertDateInterval" || key === "approvedDateInterval") {
                    value = `${filterObj[key].startDate || defaultDate} - ${filterObj[key].endDate || defaultDate}`;
                }
                badgeList.push(
                    <ProcessingFilterBadge
                        key={uniqueId(badgeKeyPrefix)}
                        text={text ? `${text}: ${value}` : value}
                        handleClick={resetSearchFormAndSearch(key)}
                    />
                );
            }
        }
    }

    return badgeList;
};

export default getProcessingFilterBadgeList;
