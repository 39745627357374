import { Button } from "vetrf-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { canCompleteRegistrationWbrApplicationDocuments } from "../WbrApplicationClosePanel";
import { WBR_APP_PAYMENT_ACTIONS, WBR_APP_ROUTE } from "../../../../WbrApplicationRoutes";
import { wbr_application_actions, wbr_application_documents_actions, wbr_application_reject } from "../../../../../../common/constants/permissions";
import { WBR_CONCLUSION_ROUTE } from "../../../../../WbrConclusionRoutes";
import ButtonWithConfirmation from "../../../../../../components/modal/ButtonWithConfirmation";
import ModalBox from "../../../../../../components/modal/ModalBox";
import PrivateContent from "../../../../../../components/private-route/PrivateContent";
import WbrApplicationDownloadDropdown from "./WbrApplicationDownloadDropdown";
import WbrApplicationProcessConfirmationButton from "./WbrApplicationProcessConfirmationButton";
import WbrApplicationResearchFinishedConfirmationButton from "./WbrApplicationResearchFinishedConfirmationButton";
import WbrApplicationSendActConfirmationButton from "./WbrApplicationSendActConfirmationButton";
import WbrApplicationSendContractConfirmationButton from "./WbrApplicationSendContractConfirmationButton";
import {
    isWbrAppInStatus,
    wbrApplicationStatuses as statuses,
    wbrApplicationStatuses,
} from "../../../../../../utils/constants/wbr/wbr-application-statuses";
import {
    useWbrApplicationCompleteRegistrationMutation,
    useWbrApplicationConfirmContractMutation,
    useWbrApplicationPrepareContractMutation,
    useWbrApplicationRequestContractMutation,
} from "../../../../api/WbrApplicationStatusQueries";

const WbrApplicationToolbar = ({ application }) => {
    const { t } = useTranslation();

    const id = application.id;
    const requestContractMutation = useWbrApplicationRequestContractMutation(id);
    const prepareContractMutation = useWbrApplicationPrepareContractMutation(id);
    const confirmContractMutation = useWbrApplicationConfirmContractMutation(id);
    const completeRegistrationMutation = useWbrApplicationCompleteRegistrationMutation(id);

    const requestContract = () => {
        requestContractMutation.mutate();
    };

    const prepareContract = () => {
        prepareContractMutation.mutate();
    };

    const confirmContract = () => {
        confirmContractMutation.mutate();
    };

    const completeRegistration = () => {
        completeRegistrationMutation.mutate();
    };

    const contractInfo = application.contractInfo;
    const invoiceInfo = application.invoiceInfo;
    const invoicePaymentInfo = application.invoicePaymentInfo;
    const acceptanceCertificateInfo = application.acceptanceCertificateInfo;
    const canCompleteRegistration = canCompleteRegistrationWbrApplicationDocuments(application);
    const canCreateConclusion =
        !application?.conclusion &&
        isWbrAppInStatus(application, [statuses.RESEARCH_FINISHED, statuses.WAITING_PAYMENT, statuses.PAYMENT_INFO_RECEIVED]);
    const canSendAct =
        !acceptanceCertificateInfo?.acceptanceCertificate?.id &&
        isWbrAppInStatus(application, [statuses.RESEARCH_FINISHED, statuses.WAITING_PAYMENT, statuses.PAYMENT_INFO_RECEIVED]);

    const isHasContract = contractInfo?.contract?.id;
    const isContractConfirmed = contractInfo?.isConfirmed;
    const isInvoiceSent = invoiceInfo?.invoice?.id;
    const isPaymentReceived = invoicePaymentInfo?.invoicePayment?.id;

    return (
        <Fragment>
            <PrivateContent authority={wbr_application_documents_actions}>
                {!isHasContract && !isContractConfirmed && isWbrAppInStatus(application, statuses.NEW) && (
                    <ButtonWithConfirmation
                        button={(onClick) => (
                            <Button
                                id="request_contract_btn"
                                text={t("Запросить сведения для договора")}
                                color="primary"
                                size="sm"
                                icon="level-down"
                                onClick={onClick}
                            />
                        )}
                        modal={(handleClose) => (
                            <ModalBox
                                header={t("Запросить сведения для договора")}
                                closeAfterConfirm
                                onConfirm={requestContract}
                                onClose={handleClose}
                                children={t(
                                    "Отправить заказчику запрос дополнительных сведений для заключения договора (ФИО уполномоченного лица, номер расчетного счета и т.д.)?"
                                )}
                            />
                        )}
                    />
                )}
                {isHasContract && !isContractConfirmed && isWbrAppInStatus(application, [statuses.NEW, statuses.DATA_FOR_CONTRACT_RECEIVED]) && (
                    <ButtonWithConfirmation
                        button={(onClick) => (
                            <Button
                                id="prepare_contract_btn"
                                text={t("Подготовить договор")}
                                color="primary"
                                size="sm"
                                icon="pencil-square-o"
                                onClick={onClick}
                            />
                        )}
                        modal={(onClose) => (
                            <ModalBox
                                header={t("Подготовить проект договора")}
                                closeAfterConfirm
                                onConfirm={prepareContract}
                                onClose={onClose}
                                children={t('Перевести заявку в статус "Подготовка договора"?')}
                            />
                        )}
                    />
                )}
                {!isContractConfirmed &&
                    isWbrAppInStatus(application, [statuses.NEW, statuses.DATA_FOR_CONTRACT_RECEIVED, statuses.CONTRACT_PREPARING]) && (
                        <ButtonWithConfirmation
                            button={(onClick) => (
                                <Button
                                    id="confirm_contract_btn"
                                    text={t("Договор заключен")}
                                    color="success"
                                    size="sm"
                                    icon="check-square-o"
                                    onClick={onClick}
                                />
                            )}
                            modal={(onClose) => (
                                <ModalBox header={t("Договор заключен")} closeAfterConfirm onClose={onClose} onConfirm={confirmContract}>
                                    {t('С заказчиком уже имеется действующий договор и отправка проекта договора через ИС "ВетИС" не требуется?')}
                                </ModalBox>
                            )}
                        />
                    )}
                {!isContractConfirmed && isWbrAppInStatus(application, [statuses.CONTRACT_PREPARING]) && (
                    <WbrApplicationSendContractConfirmationButton applicationId={id} />
                )}
                <WbrApplicationDownloadDropdown
                    applicationId={id}
                    contractInfo={contractInfo}
                    acceptanceCertificateInfo={acceptanceCertificateInfo}
                />
                {isContractConfirmed &&
                    isWbrAppInStatus(application, [
                        statuses.NEW,
                        statuses.DATA_FOR_CONTRACT_RECEIVED,
                        statuses.CONTRACT_PREPARING,
                        statuses.CONTRACT_RECEIVED,
                    ]) && (
                        <Button
                            id="request_prepayment_btn"
                            text={t("Запросить предоплату")}
                            color="success"
                            size="sm"
                            icon="money"
                            href={WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(id, WBR_APP_PAYMENT_ACTIONS.EDIT)}
                        />
                    )}
                {!isInvoiceSent &&
                    isWbrAppInStatus(application, [
                        statuses.IN_PROCESS,
                        statuses.SENT_FOR_REVISION,
                        statuses.REPEAT_NEW,
                        statuses.RESEARCH_FINISHED,
                    ]) && (
                        <Button
                            id="request_payment_btn"
                            text={t("Отправить счет")}
                            color="success"
                            size="sm"
                            icon="money"
                            href={WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(id, WBR_APP_PAYMENT_ACTIONS.EDIT)}
                        />
                    )}
                {isInvoiceSent &&
                    !isPaymentReceived &&
                    isWbrAppInStatus(application, [statuses.IN_PROCESS, statuses.SENT_FOR_REVISION, statuses.REPEAT_NEW]) && (
                        <Button
                            id="change_invoice_btn"
                            text={t("Изменить условия оплаты")}
                            color="primary"
                            size="sm"
                            icon="money"
                            href={WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(id, WBR_APP_PAYMENT_ACTIONS.EDIT)}
                        />
                    )}
                {isInvoiceSent && (
                    <Button
                        id="view_invoice_btn"
                        text={t("Посмотреть сведения об оплате")}
                        color="primary"
                        size="sm"
                        icon="money"
                        href={WBR_APP_ROUTE.PAYMENT_VIEW.getFullPath(id)}
                    />
                )}
            </PrivateContent>
            <PrivateContent authority={wbr_application_actions}>
                {(isWbrAppInStatus(application, [wbrApplicationStatuses.CONTRACT_RECEIVED, wbrApplicationStatuses.REPEAT_NEW]) ||
                    (isContractConfirmed &&
                        isWbrAppInStatus(application, [
                            wbrApplicationStatuses.NEW,
                            wbrApplicationStatuses.DATA_FOR_CONTRACT_RECEIVED,
                            wbrApplicationStatuses.CONTRACT_PREPARING,
                            wbrApplicationStatuses.PREPAYMENT_INFO_RECEIVED,
                        ]))) && <WbrApplicationProcessConfirmationButton application={application} />}
                {isWbrAppInStatus(application, [statuses.IN_PROCESS]) && (
                    <Button
                        id="revision_btn"
                        text={t("Отправить на доработку")}
                        color="primary"
                        size="sm"
                        icon="share-square-o"
                        href={WBR_APP_ROUTE.REVISION.getFullPath(id)}
                    />
                )}
                {isWbrAppInStatus(application, [statuses.IN_PROCESS]) && (
                    <WbrApplicationResearchFinishedConfirmationButton application={application} />
                )}
                {canCreateConclusion && (
                    <Button
                        id="create_conclusion_btn"
                        text={t("Оформить заключение")}
                        color="success"
                        size="sm"
                        icon="file-text"
                        href={WBR_CONCLUSION_ROUTE.CREATE_FROM_APPLICATION.getFullPath(id)}
                    />
                )}
            </PrivateContent>
            <PrivateContent authority={wbr_application_documents_actions}>
                {canSendAct && <WbrApplicationSendActConfirmationButton applicationId={id} />}
            </PrivateContent>
            <PrivateContent authority={wbr_application_actions}>
                {canCompleteRegistration && (
                    <Button
                        id="finish_registration_btn"
                        text={t("Завершить регистрацию")}
                        color="success"
                        size="sm"
                        icon="check-square-o"
                        onClick={completeRegistration}
                    />
                )}
            </PrivateContent>
            <PrivateContent authority={wbr_application_reject}>
                {isWbrAppInStatus(application, [
                    statuses.NEW,
                    statuses.DATA_FOR_CONTRACT_RECEIVED,
                    statuses.CONTRACT_PREPARING,
                    statuses.CONTRACT_RECEIVED,
                    statuses.PREPAYMENT_INFO_RECEIVED,
                    statuses.ACT_RECEIVED,
                ]) && (
                    <Button id="reject_btn" text={t("Отклонить")} color="danger" size="sm" icon="ban" href={WBR_APP_ROUTE.REJECT.getFullPath(id)} />
                )}
            </PrivateContent>
        </Fragment>
    );
};

export default WbrApplicationToolbar;
