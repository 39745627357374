import { Button, DateFormGroup, deleteIn, FileFormGroup, Icon, InputFormGroup, RadioBtnFormGroup, SelectFormGroup, setIn } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { ERROR, VALID } from "../../../../components/sidePanel/constants/FormStatus";
import { getFilterCountryList } from "../../../../utils/filterCountry";
import { isEmptyValidationError } from "../../../../utils/isEmptyAfterOmitEmpty";

export const getPartFromReferenceNumber = (referenceNumber) => {
    if (referenceNumber) {
        const refPart = parseInt(referenceNumber.slice(-5), 10);
        return refPart.toString(10);
    }
    return "0";
};

const wbrComplianceDecision = {
    MEET_REQUIREMENTS: "MEET_REQUIREMENTS",
    NOT_MEET_REQUIREMENTS: "NOT_MEET_REQUIREMENTS",
};

const WbrConclusionCommonForm = ({
    countries,
    onChangeHandler,
    submitHandler,
    statusHandler,
    conclusion,
    files,
    user,
    onChangeHandlerPDF,
    cancelButton,
}) => {
    const { t } = useTranslation("translation");
    const invalidFields = useRef({});
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keydownHandler = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            nextOnClickHandler();
        }
    };

    const addPdf = (file) => {
        statusHandler();
        onChangeHandlerPDF && onChangeHandlerPDF(file);
    };

    const onChange = (value, path) => {
        statusHandler();
        onChangeHandler && onChangeHandler(value, path);
    };

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    let importCountryList = countries ? getFilterCountryList(countries) : [];
    const nextOnClickHandler = () => {
        setShowError(true);
        setTimeout(() => {
            const status = isEmptyValidationError(invalidFields) && files && files[0] ? VALID : ERROR;
            submitHandler(status);
        }, 300);
    };

    const wbrComplianceDecisions = [
        {
            text: (
                <Fragment>
                    <Icon icon="check-circle" color="green" /> {t("соответствует требованиям страны-импортера (выбрано по умолчанию)")}
                </Fragment>
            ),
            value: wbrComplianceDecision.MEET_REQUIREMENTS,
        },
        {
            text: (
                <Fragment>
                    <Icon icon="times-circle" color="red" /> {t("не соответствует требованиям страны-импортера")}
                </Fragment>
            ),
            value: wbrComplianceDecision.NOT_MEET_REQUIREMENTS,
        },
    ];

    return (
        <form className="form-horizontal css-form">
            <h3 className="header smaller lighter blue">{t("Общие сведения")}</h3>
            <SelectFormGroup
                labelText={t("Страна отправления")}
                require
                disabled
                options={countries}
                valueKey="guid"
                labelKey="view"
                value={conclusion?.consignment?.exportCountry}
                onChange={onChange}
                field="conclusion.consignment.exportCountry"
                placeholder={t("Выберите страну...")}
                showError={showError}
                errorHandler={errorHandler}
                help=""
            />
            <SelectFormGroup
                labelText={t("Страна назначения")}
                require
                options={importCountryList}
                valueKey="guid"
                labelKey="view"
                value={conclusion?.consignment?.importCountry}
                onChange={onChange}
                field="conclusion.consignment.importCountry"
                placeholder={t("Выберите страну...")}
                showError={showError}
                errorHandler={errorHandler}
                help=""
            />
            <RadioBtnFormGroup
                labelText={t("Решение")}
                require
                options={wbrComplianceDecisions}
                value={conclusion?.complianceConclusion?.decision}
                onChange={onChange}
                field="conclusion.complianceConclusion.decision"
            />
            <InputFormGroup
                require={conclusion?.complianceConclusion?.decision === wbrComplianceDecision.NOT_MEET_REQUIREMENTS}
                disabled={conclusion?.complianceConclusion?.decision !== wbrComplianceDecision.NOT_MEET_REQUIREMENTS}
                labelText={t("Причина решения о несоответствии")}
                onChange={onChange}
                field="conclusion.complianceConclusion.conclusion"
                value={conclusion.complianceConclusion?.conclusion}
                showError={showError}
                maxLength={255}
                errorHandler={errorHandler}
            />
            <InputFormGroup
                require={conclusion?.complianceConclusion?.decision === wbrComplianceDecision.MEET_REQUIREMENTS}
                disabled={conclusion?.complianceConclusion?.decision !== wbrComplianceDecision.MEET_REQUIREMENTS}
                labelText={t("Номер заключения")}
                onChange={onChange}
                field="conclusion.referenceNumber"
                value={conclusion.referenceNumber}
                showError={showError}
                maxLength={255}
                errorHandler={errorHandler}
            />
            <DateFormGroup
                labelText={t("Дата оформления")}
                require
                validateDateFormat
                onChange={onChange}
                field="conclusion.issueDateTime"
                value={conclusion.issueDateTime && conclusion.issueDateTime}
                showError={showError}
                errorHandler={errorHandler}
                help=""
            />
            <SelectFormGroup
                labelText={t("Специалист, оформивший заключение")}
                require
                disabled
                placeholder={t("Поиск...")}
                labelKey="view"
                valueKey="id"
                value={user}
                field="conclusion.createdByUser"
                options={[user]}
                showError={showError}
                errorHandler={errorHandler}
                onChange={onChange}
            />
            <InputFormGroup
                labelText={t("Контактный телефон специалиста")}
                onChange={onChange}
                field="conclusion.complianceConclusion.decisionPerson.phone"
                value={conclusion.complianceConclusion?.decisionPerson?.phone}
                showError={showError}
                maxLength={255}
                errorHandler={errorHandler}
            />

            <h3 className="header smaller lighter blue">{t("Скан-документ заключения")}</h3>
            <FileFormGroup
                labelText={t("Файл")}
                field={"file"}
                value={files}
                showError={showError}
                acceptedFileTypes={["application/pdf"]}
                require={true}
                allowMultiple={false}
                onChange={addPdf}
                errorHandler={errorHandler}
                maxFileSize={{
                    value: "10MB",
                    errorText: "Максимально допустимый размер файла 10 мегабайт",
                }}
            />
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                {cancelButton}
                <div className="pull-right">
                    <Button id="next_btn" text={t("Применить")} color="info" size="sm" icon="check" onClick={nextOnClickHandler} />
                </div>
            </div>
        </form>
    );
};

export default WbrConclusionCommonForm;
