import { useUserContext } from "../../session/UserContextProvider";
import { hasAuthority, hasEveryAuthority, hasSomeAuthority } from "../../utils/authority-utils";

const PrivateContent = ({ children, isAuthorized = true, authority, someAuthority, everyAuthority }) => {
    const { user, authorities } = useUserContext();

    return isAuthorized === !!user.id &&
        (!isAuthorized ||
            (!authority && !someAuthority && !everyAuthority) ||
            (authority && hasAuthority(authority, authorities)) ||
            (someAuthority && hasSomeAuthority(someAuthority, authorities)) ||
            (everyAuthority && hasEveryAuthority(everyAuthority)))
        ? children ?? null
        : null;
};

export default PrivateContent;
