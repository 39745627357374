export const transformL10nECertStatus = (status, t) => {
    switch (status) {
        case "NEW":
            return t("Создан");
        case "IN_PROCESS":
            return t("Начата процедура оформления");
        case "ISSUED":
            return t("Оформлен");
        case "WITHDRAWN":
            return t("Аннулирован");
        case "APPROVED":
            return t("Оформлен");
        case "REPLACED":
            return t("Заменен");
        case "ACKNOWLEDGED":
            return t("Подтвержден");
        case "CONFIRMED":
            return t("Подтвержден");
        case "REJECTED":
            return t("Отклонена");
        default:
            return t("Не указано");
    }
};

export const transformECertStatus = (status) => {
    switch (status) {
        case "NEW":
            return "Создан";
        case "IN_PROCESS":
            return "Начата процедура оформления";
        case "ISSUED":
            return "Оформлен";
        case "WITHDRAWN":
            return "Аннулирован";
        case "APPROVED":
            return "Оформлен";
        case "REPLACED":
            return "Заменен";
        case "ACKNOWLEDGED":
            return "Подтвержден";
        case "CONFIRMED":
            return "Подтвержден";
        default:
            return "Не указано";
    }
};
