export const NOT_FOUND = -1;

export const asyncActions = {
    REQUEST: null,
    SUCCESS: null,
    ERROR: null,
};

export const loadActions = {
    LOAD: asyncActions,
};

export const defaultGetRequestParams = {
    type: "GET",
};

export const emptyText = "не указано";

export const MAX_STRING_LENGTH = 255;

export const errorText = "Данное поле должно быть заполнено";
