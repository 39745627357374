import ajax from "../../utils/ajax";

import { ECERT_IMPORT_LOAD, ECERT_IMPORT_ERROR, ECERT_IMPORT_RECEIVE } from "../constants/ActionTypes";

let request = ajax("/rest-api/import/ecert");

export const eCertImportLoad = (id) => ({
    type: ECERT_IMPORT_LOAD,
    id,
});

export const eCertImportReceive = (eCert) => ({
    type: ECERT_IMPORT_RECEIVE,
    eCert,
});

export const eCertImportLoadError = (error) => ({
    type: ECERT_IMPORT_ERROR,
    error,
});

export const requestECertImport = (id) => (dispatch) => {
    dispatch(eCertImportLoad(id));
    let url = "/" + id;
    request({
        url: url,
        type: "GET",
    })
        .then((response) => {
            dispatch(eCertImportReceive(response));
        })
        .catch((error) => {
            dispatch(eCertImportLoadError(error));
        });
};
