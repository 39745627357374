import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ajax from "../../../utils/ajax";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError } from "../../../common/store/notification/service";

let request = ajax("/rest-api/application/");

export const requestApplication = createAsyncThunk("application/view", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при загрузке заявки."));
        })
        .finally(() => {
            dispatch(completeLoading());
        });
});

export const requestECertListForApplication = createAsyncThunk("application/ecertList", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id + "/ecert-list",
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при загрузке списка сертификатов."));
        })
        .finally(() => {
            dispatch(completeLoading());
        });
});

export const requestClosingApplication = createAsyncThunk("application/close", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id + "/complete",
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при закрытии заявки."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: id }));
            dispatch(requestECertListForApplication({ id: id }));
            dispatch(completeLoading());
        });
});

export const requestProcessingApplication = createAsyncThunk("application/process", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id + "/process",
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при взятии заявки на рассмотрение."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: id }));
            dispatch(completeLoading());
        });
});

export const requestResearchApplication = createAsyncThunk("application/research", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id + "/research",
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при отправке заявки на доп лабораторные исследования."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: id }));
            dispatch(completeLoading());
        });
});

export const requestRejectApplication = createAsyncThunk("application/reject", async (arg, { dispatch, getState }) => {
    dispatch(startLoading());
    return request({
        type: "POST",
        url: "reject",
        data: arg,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при отклонении заявки."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: arg?.application?.id }));
            dispatch(completeLoading());
        });
});

export const requestApproveApplication = createAsyncThunk("application/approve", async ({ id }, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: id + "/approve",
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при принятии заявки."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: id }));
            dispatch(completeLoading());
        });
});

export const requestRevisionApplication = createAsyncThunk("application/revision", async (arg, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "POST",
        url: "revision",
        data: arg,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при отправке на заявки на доработку."));
        })
        .finally(() => {
            dispatch(requestApplication({ id: arg?.application?.id }));
            dispatch(completeLoading());
        });
});

const initialState = {
    result: {},
    ecertList: {
        applicationId: null,
        resultList: [],
    },
    closing: false,
};

const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {},
    extraReducers: {
        [requestApplication.fulfilled]: (state, action) => {
            state.result = action.payload;
        },
        [requestApplication.rejected]: (state, action) => {
            state.result = {};
        },
        [requestECertListForApplication.pending]: (state, action) => {
            state.ecertList.applicationId = action.meta.arg.id;
        },
        [requestECertListForApplication.fulfilled]: (state, action) => {
            state.ecertList.resultList = action.payload;
        },
        [requestECertListForApplication.rejected]: (state, action) => {
            state.ecertList = initialState.ecertList;
        },
        [requestClosingApplication.pending]: (state, action) => {
            state.closing = true;
        },
        [requestClosingApplication.fulfilled]: (state, action) => {
            state.closing = false;
        },
        [requestClosingApplication.rejected]: (state, action) => {
            state.closing = false;
        },
        [requestProcessingApplication.pending]: (state, action) => {
            state.closing = true;
        },
        [requestProcessingApplication.fulfilled]: (state, action) => {
            state.closing = false;
        },
        [requestProcessingApplication.rejected]: (state, action) => {
            state.closing = false;
        },
        [requestRejectApplication.pending]: (state, action) => {
            state.closing = true;
        },
        [requestRejectApplication.fulfilled]: (state, action) => {
            state.closing = false;
        },
        [requestRejectApplication.rejected]: (state, action) => {
            state.closing = false;
        },
        [requestApproveApplication.pending]: (state, action) => {
            state.closing = true;
        },
        [requestApproveApplication.fulfilled]: (state, action) => {
            state.closing = false;
        },
        [requestApproveApplication.rejected]: (state, action) => {
            state.closing = false;
        },
        [requestRevisionApplication.pending]: (state, action) => {
            state.closing = true;
        },
        [requestRevisionApplication.fulfilled]: (state, action) => {
            state.closing = false;
        },
        [requestRevisionApplication.rejected]: (state, action) => {
            state.closing = false;
        },
    },
});

export default applicationSlice.reducer;
