import { clauses } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/ecert");
const errorMessage = "Произошла ошибка при загрузке списка кляуз";

export const requestClausePatterns = (eCert) =>
    createLoadAsyncAction(
        clauses,
        request,
        {
            type: "GET",
            url: `/${eCert.id}/clause`,
        },
        errorMessage
    );

export const clearClause = () => (dispatch) => {
    dispatch(clauses.clear());
};
