import ajax from "../../utils/ajax";

import { DATE_TIME_FORMAT_LIST_LOAD, DATE_TIME_FORMAT_LIST_RECEIVE, DATE_TIME_FORMAT_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const dateTimeFormatsLoad = () => ({
    type: DATE_TIME_FORMAT_LIST_LOAD,
});

export const dateTimeFormatsReceive = (dateTimeFormats) => ({
    type: DATE_TIME_FORMAT_LIST_RECEIVE,
    dateTimeFormats,
});

export const dateTimeFormatsLoadError = (error) => ({
    type: DATE_TIME_FORMAT_LIST_ERROR,
    error,
});

export const requestDateTimeFormats = () => (dispatch) => {
    dispatch(dateTimeFormatsLoad());
    request({
        type: "GET",
        url: "dateTimeFormats",
    })
        .then((response) => {
            dispatch(dateTimeFormatsReceive(response));
        })
        .catch((error) => {
            dispatch(dateTimeFormatsLoadError(error));
        });
};
