import React from "react";
import { useIsFetching } from "react-query";

export default function FetchingIndicator() {
    const isFetching = useIsFetching();

    if (!isFetching) return <></>;
    return (
        <div className="ipl-progress-indicator-loader" id="ipl-progress-indicator-loader">
            <div className="ipl-progress-indicator-head">
                <div className="first-indicator" />
                <div className="second-indicator" />
            </div>
        </div>
    );
}
