import { useQueryClient } from "react-query";

import {
    API_URL,
    useDictionaryCreateMutation,
    useDictionaryDeleteMutation,
    useDictionaryListQuery,
    useDictionaryOneQuery,
    useDictionaryPageQuery,
} from "./dictionaryQueries";
import { useCustomMutation } from "../../utils/commonQueryBase";

const baseUrl = "/business-participants";
const byOrgUrlPart = "/by-org";
const syncPart = "/sync";
const forSyncPart = "/forSync";

const itemName = "хозяйствующего субъекта";
const dictionaryName = "хозяйствующих субъектов";

export function useBusinessParticipantQuery(id) {
    return useDictionaryOneQuery(baseUrl, id, { dictionaryItemName: itemName });
}

export function useBusinessParticipantsListQuery() {
    return useDictionaryListQuery(baseUrl, { dictionaryName: dictionaryName });
}

export function useBusinessParticipantsPageByOrgQuery(pageParams, searchParams) {
    return useDictionaryPageQuery(baseUrl + byOrgUrlPart, { ...pageParams, ...searchParams }, {
        dictionaryName: dictionaryName,
    });
}

export function useBusinessParticipantSaveMutation() {
    const queryClient = useQueryClient();
    return useDictionaryCreateMutation(baseUrl, {
        dictionaryItemName: itemName,
        onSuccess: () => {
            queryClient.invalidateQueries([API_URL + baseUrl + byOrgUrlPart]);
        },
    });
}

export function useBusinessParticipantDeleteMutation() {
    const queryClient = useQueryClient();
    return useDictionaryDeleteMutation(baseUrl, {
        dictionaryItemName: itemName,
        onSuccess: () => {
            queryClient.invalidateQueries([API_URL + baseUrl + byOrgUrlPart]);
        },
    });
}

export function useBusinessParticipantSynchronize() {
    const queryClient = useQueryClient();
    const syncRequest = (data) => ({ type: "POST", url: `${data.id}${syncPart}` });
    return useCustomMutation(API_URL + baseUrl, syncRequest, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([API_URL + baseUrl + byOrgUrlPart]);
            queryClient.invalidateQueries([API_URL + baseUrl, data.id]);
        },
    });
}

export function useBusinessParticipantGetCerberBP(id) {
    return useDictionaryOneQuery(baseUrl, id && `/${id}${forSyncPart}`, { dictionaryName: dictionaryName });
}
