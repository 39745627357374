import { officialVeterinarians } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction, createPageableRequestParams } from "../../../common/utils/service-utils";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError, notifySuccess } from "../../../common/store/notification/service";

const request = ajax("/rest-api/dictionary/");
const loadListErrorMessage = "Произошла ошибка при загрузке списка ветеринарных врачей";
const loadItemErrorMessage = "Произошла ошибка при загрузке сведений о ветеринарном враче";

export const requestVeterinarians = (pageable) =>
    createLoadAsyncAction(
        officialVeterinarians.page,
        request,
        createPageableRequestParams(pageable, "official-veterinarians/by-org"),
        loadListErrorMessage
    );

export const requestVeterinariansForCreate = () =>
    createLoadAsyncAction(
        officialVeterinarians,
        request,
        {
            type: "GET",
            url: "official-veterinarians/by-org-list",
        },
        loadListErrorMessage
    );

export const requestItem = (id) =>
    createLoadAsyncAction(officialVeterinarians.item, request, { type: "GET", url: `official-veterinarians/${id}` }, loadItemErrorMessage);

export const deleteItem = (item) => (dispatch) => {
    dispatch(officialVeterinarians.delete.request());
    dispatch(startLoading());

    request({
        url: `official-veterinarians/delete/${item.id}`,
        type: "DELETE",
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно удалена из справочника"));
            dispatch(completeLoading());
            dispatch(officialVeterinarians.delete.success());
        })
        .catch((error) => {
            dispatch(notifyError(error.status, "Произошла ошибка при удалении записи"));
            dispatch(completeLoading());
            dispatch(officialVeterinarians.delete.error(error));
        });
};

export const requestSaveItem = (item) => (dispatch) => {
    dispatch(officialVeterinarians.save.request());
    dispatch(startLoading());

    request({
        url: `official-veterinarians/`,
        data: item,
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно сохранена в справочник"));
            dispatch(completeLoading());
            dispatch(officialVeterinarians.save.success());
        })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при сохранении записи в справочник"));
            dispatch(completeLoading());
            dispatch(officialVeterinarians.save.error(error));
        });
};
