import React from "react";
import PropTypes from "prop-types";
import { Button, PaginationRow, Table } from "vetrf-ui";
import { transformDateByFormat } from "../../../../../utils/transformers/transformDateByFormat";

const unknown = "не указано";

const LogsListTable = (props) => {
    const { page, handleChangePage, handleChangeSizePerPage, sizePerPage, itemCount = 0, list = [], sortedId } = props;

    const columns = [
        {
            key: "id",
            title: "№",
            className: "align-left",
            width: "2%",
            dataFormatter: (item) => item.id || unknown,
        },
        {
            key: "shoulderNumber",
            title: "Номер сертификата",
            className: "align-left",
            width: "16%",
            dataFormatter: (item) => item.shoulderNumber || unknown,
        },
        {
            key: "operationType",
            title: "Тип операции",
            className: "align-left",
            width: "16%",
            dataFormatter: (item) => item.operationType || unknown,
        },
        {
            key: "countryCode",
            title: "Страна",
            className: "align-left",
            width: "2%",
            dataFormatter: (item) => item.country.code || unknown,
        },
        {
            key: "result",
            title: "Результат",
            className: "align-left",
            width: "2%",
            dataFormatter: (item) => (item.result ? "OK" : "Error"),
        },
        {
            key: "insertDate",
            title: "Дата начала",
            className: "align-left",
            width: "16%",
            dataFormatter: (item) => transformDateByFormat(item.insertDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
        },
        {
            key: "endDate",
            title: "Дата окончания",
            className: "align-left",
            width: "16%",
            dataFormatter: (item) => transformDateByFormat(item.endDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
        },
        {
            key: "error",
            title: "Ошибка",
            width: "16%",
            dataFormatter: (item) => {
                return (
                    item.error && (
                        <details>
                            <summary>Подробнее</summary>
                            {item.error}
                        </details>
                    )
                );
            },
        },
        {
            key: "views",
            title: "",
            width: "2%",
            className: "align-center",
            dataFormatter: (item) => (
                <span>
                    <Button href={`/import/cert/${item.ecertID}`} onlyIcon={true} icon="bars" iconSize={130} tooltip={"Просмотр"} />
                </span>
            ),
        },
    ];

    const pagination = (
        <PaginationRow
            page={page + 1}
            onChangePage={handleChangePage}
            onChangeSizePerPage={handleChangeSizePerPage}
            sizePerPage={sizePerPage}
            itemCount={itemCount}
        />
    );

    return (
        <Table
            className="table table-striped table-bordered table-hover"
            dataList={list}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

LogsListTable.propTypes = {
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleChangePage: PropTypes.func,
    handleChangeSizePerPage: PropTypes.func,
    sort: PropTypes.func,
    sizePerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemCount: PropTypes.number,
    sortedId: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
};

export default LogsListTable;
