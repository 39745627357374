import { memo, useEffect, useRef } from "react";

import { filterPathname } from "./producers/utils/useLocationEffect";
import { logLevel } from "./base/LogManager";
import { useSendLogMutation } from "./api/loggingQueries";

const canSendLog = (message) => {
    const levelCheck = () => message.level === logLevel.ERROR;
    const statusCheck = () => !message.data || message.data.status !== 403;
    const urlCheck = () => !["/rest-api/session", "/rest-api/info", "/rest-api/report"].some((url) => message.value.includes(url));
    const contentCheck = () => !["NoModificationAllowedError", "zaloJSV2"].some(value => message.value.includes(value)); // Firefox issue
    return message && levelCheck() && statusCheck() && urlCheck() && contentCheck();
};

const LogSender = ({ messages }) => {
    const startTimeRef = useRef(Date.now());
    const isEnabledRef = useRef(true);
    const loggerMutation = useSendLogMutation();
    const lastMessageRef = useRef();

    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessageRef.current !== lastMessage?.value && canSendLog(lastMessage)) {
            const formattedMessage = formatMessage(lastMessage, messages, startTimeRef.current);
            const uri = filterPathname(window.location.pathname);
            if (isEnabledRef.current) {
                loggerMutation.mutate({ message: formattedMessage, uri }, { onError: () => (isEnabledRef.current = false) });
            }
            lastMessageRef.current = lastMessage.value;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    return null;
};
const MemorizedLogSender = memo(LogSender);

function formatMessage(message, messages, startTime) {
    const version = process.env.REACT_APP_VERSION ?? "dev";
    const details = message.data ? `: ${JSON.stringify(message.data)}` : "";
    const formatHistory = (messages) =>
        messages
            .reverse()
            .map((message) => {
                const time = convertMsToTime(message.timestamp - startTime);
                const text = message.value.split("\n")[0];
                return `\t${time} ${text}`;
            })
            .join("\n");
    const formattedMessage = `Front-end exception (${version})\n${message.value}${details}\n${formatHistory(messages)}`;
    return formattedMessage.replace(new RegExp(`/${window.location.origin}/g`), "");
}

function convertMsToTime(milliseconds) {
    const padTo2Digits = (num) => num.toString().padStart(2, "0");
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    if (hours > 0) {
        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    } else {
        return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    }
}

const logSender = {
    component: (messages) => <MemorizedLogSender messages={messages} />,
};

export default logSender;
