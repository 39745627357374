export const PROFILE = {
    PROD: "PROD",
    TEST: "TEST",
    DEMO: "DEMO",
    HOTFIX: "HOTFIX",
    DEV: "DEV",
};

const PROFILE_BY_HOST = {
    "ecert.vetrf.ru": PROFILE.PROD,
    "test-ecert.vetrf.ru": PROFILE.TEST,
    "demo-ecert.vetrf.ru": PROFILE.DEMO,
    "hotfix-ecert.vetrf.ru": PROFILE.HOTFIX,
    "dev-ecert.vetrf.ru": PROFILE.DEV,
};

const PROFILE_PROPERTIES = {
    [PROFILE.PROD]: {
        mercuryBaseUrl: "mercury.vetrf.ru",
        vestaBaseUrl: "vesta.vetrf.ru",
        cerberusBaseUrl: "cerberus.vetrf.ru",
    },
    [PROFILE.TEST]: {
        mercuryBaseUrl: "test-mercury.vetrf.ru",
        vestaBaseUrl: "test-vesta.vetrf.ru",
        cerberusBaseUrl: "test-cerberus.vetrf.ru",
    },
    [PROFILE.DEMO]: {
        mercuryBaseUrl: "demo-mercury.vetrf.ru",
        vestaBaseUrl: "demo-vesta.vetrf.ru",
        cerberusBaseUrl: "demo-cerberus2.vetrf.ru",
    },
    [PROFILE.HOTFIX]: {
        mercuryBaseUrl: "hotfix-mercury.vetrf.ru",
        vestaBaseUrl: "hotfix-vesta.vetrf.ru",
        cerberusBaseUrl: "hotfix-cerberus.vetrf.ru",
    },
    [PROFILE.DEV]: {
        mercuryBaseUrl: "dev-mercury.vetrf.ru",
        vestaBaseUrl: "dev-vesta.vetrf.ru",
        cerberusBaseUrl: "dev-cerberus.vetrf.ru",
    },
};

export const getHost = () => {
    return window.location.host;
};

export const getCurrentProfile = () => {
    const host = getHost();
    return PROFILE_BY_HOST?.[host] ?? PROFILE.TEST;
};

export const getMercuryBaseUrl = () => {
    return PROFILE_PROPERTIES[getCurrentProfile()].mercuryBaseUrl ?? "mercury.vetrf.ru";
};

export const getVestaBaseUrl = () => {
    return PROFILE_PROPERTIES[getCurrentProfile()].vestaBaseUrl ?? "vesta.vetrf.ru";
};

export const getCerberusBaseUrl = () => {
    return PROFILE_PROPERTIES[getCurrentProfile()].cerberusBaseUrl ?? "cerberus.vetrf.ru";
};
