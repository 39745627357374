import {
    DOCUMENT_TYPE_LIST_LOAD,
    DOCUMENT_TYPE_LIST_RECEIVE,
    DOCUMENT_TYPE_LIST_ERROR,
    CLAUSE_PATTERN_LIST_LOAD,
    CLAUSE_PATTERN_LIST_RECEIVE,
    CLAUSE_PATTERN_LIST_ERROR,
} from "../constants/ActionTypes";

const initialState = {
    eCertDocumentTypes: [],
    error: null,
    loading: false,
};

const eCertDocumentTypesLoad = (state, action) => ({ ...state, loading: true });

const getECertDocumentTypes = (action) => (action.eCertDocumentTypes && action.eCertDocumentTypes.length > 0 ? action.eCertDocumentTypes : []);

const eCertDocumentTypesReceive = (state, action) => ({
    ...state,
    error: null,
    eCertDocumentTypes: getECertDocumentTypes(action),
    loading: false,
});

const eCertDocumentTypesError = (state, action) => ({
    ...state,
    error: action.error,
    eCertDocumentTypes: [],
    loading: false,
});
const clausePatternsReceive = (state, action) => ({
    ...state,
    error: null,
    clausePatternsByDocumentTypes: {
        documentType: action.documentType,
        clausePatterns: action.clausePatterns,
    },
    loading: false,
});

export default function eCertDocumentTypes(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_TYPE_LIST_LOAD:
        case CLAUSE_PATTERN_LIST_LOAD:
            return eCertDocumentTypesLoad(state, action);
        case DOCUMENT_TYPE_LIST_RECEIVE:
            return eCertDocumentTypesReceive(state, action);
        case DOCUMENT_TYPE_LIST_ERROR:
        case CLAUSE_PATTERN_LIST_ERROR:
            return eCertDocumentTypesError(state, action);
        case CLAUSE_PATTERN_LIST_RECEIVE:
            return clausePatternsReceive(state, action);

        default:
            return state;
    }
}
