import React from "react";
import PropTypes from "prop-types";

const ShortcutButton = ({ id, href, title, icon, className }) => (
    <a id={id} className={className} href={href} target="_blank" rel="noopener noreferrer" title={title}>
        <i className={icon} />
    </a>
);

ShortcutButton.propTypes = {
    id: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
};

export default ShortcutButton;
