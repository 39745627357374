import { useTranslation } from "react-i18next";
import React from "react";

import { useGetDepartmentPage } from "../../api/departmentQuery";
import BlinkingFetchingContainer from "../../../components/fetching/BlinkingFetchingContainer";
import DepartmentPageableTable from "../components/DepartmentPageableTable";
import DepartmentSearchWrapper from "../components/DepartmentSearchWrapper";
import PageContent from "../../../components/page/PageContent";
import usePageParams from "../../../utils/usePageParams";
import WideWrapper from "../../../components/page/WideWrapper";

const DepartmentListPage = () => {
    const { t } = useTranslation();

    const { pageParams, setPage, setSize, searchParams, setSearchParams } = usePageParams("departments");
    const { data: participants, isFetching: isLoading } = useGetDepartmentPage(pageParams, searchParams);

    const title = t("Места оформления");
    const subTitle = t("в которых возможно оформление экспортных сертификатов");

    return (
        <DepartmentSearchWrapper searchParams={searchParams} setSearchParams={setSearchParams}>
            {(searchForm, budgeList) => (
                <PageContent title={title} subHeader={subTitle} secondLineInfo={budgeList}>
                    <WideWrapper>
                        {searchForm}
                        <BlinkingFetchingContainer isFetching={isLoading}>
                            <DepartmentPageableTable page={participants} onChangePage={setPage} onChangeSizePerPage={setSize} />
                        </BlinkingFetchingContainer>
                    </WideWrapper>
                </PageContent>
            )}
        </DepartmentSearchWrapper>
    );
};

export default DepartmentListPage;
