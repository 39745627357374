import { Translation, useTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";

import { createFormData, useActionMutation, useCustomMutation } from "../../../utils/commonQueryBase";
import { wbrApplicationInvoiceVatModeTypes } from "../../../utils/constants/wbr/wbr-constants";
import NotificationManager from "../../../common/utils/notification/NotificationManager";

const BASE_URL = "/rest-api/wbr/application";
const REQEST_CONTRACT_URL_PART = "/request-contract";
const PREPARE_CONTRACT_URL_PART = "/prepare-contract";
const SEND_CONTRACT_URL_PART = "/send-contract";
const CONFIRM_CONTRACT_URL_PART = "/confirm-contract";
const REQUEST_PAYMENT_URL_PART = "/request-payment";
const CONFIRM_PAYMENT_URL_PART = "/confirm-payment";
const PROCESS_URL_PART = "/process";
const UPDATE_PAYMENT_URL_PART = "/update-payment";
const RESEARCH_FINISHED_URL_PART = "/research-finished";
const SEND_ACT = "/send-acceptance-certificate";
const COMPLETE_REGISTRATION = "/complete-registration";
const REVISION_URL_PART = "/revision";
const REJECT_URL_PART = "/reject";

export function useWbrApplicationRequestContractMutation(id) {
    const { t } = useTranslation();
    return useActionMutation(BASE_URL, id, REQEST_CONTRACT_URL_PART, {
        onError: (error) => NotificationManager.error(error, t("Не удалось запросить сведения для договора")),
    });
}

export function useWbrApplicationPrepareContractMutation(id) {
    const { t } = useTranslation();
    return useActionMutation(BASE_URL, id, PREPARE_CONTRACT_URL_PART, {
        onError: (error) => NotificationManager.error(error, t("Не удалось подготовить проект договора")),
    });
}

export function useWbrApplicationSendContractMutation(id) {
    const sendRequest = (data) => {
        let document = cloneDeep(data);
        const files = extractAndLinkFiles(document, ["contractFile", "signatures"]);
        const formData = createFormData({ document, files });
        return {
            type: "POST",
            url: `/${id}${SEND_CONTRACT_URL_PART}`,
            uploadFile: true,
            data: formData,
            headers: {},
        };
    };

    return useCustomMutation(BASE_URL, sendRequest);
}

export function useWbrApplicationConfirmContractMutation(id) {
    return useActionMutation(BASE_URL, id, CONFIRM_CONTRACT_URL_PART, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при заключении договора заявки на ВБР")}</Translation>),
    });
}

export function useWbrApplicationRequestPaymentMutation(id) {
    const { t } = useTranslation();

    return useCustomMutation(BASE_URL, updateInvoiceRequest(id, REQUEST_PAYMENT_URL_PART), {
        onError: (error) => NotificationManager.error(error, t("Не удалось отправить договор")),
    });
}

export function useWbrApplicationConfirmPaymentMutation(id) {
    return useActionMutation(BASE_URL, id, CONFIRM_PAYMENT_URL_PART, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при подтверждении оплаты заявки на ВБР")}</Translation>),
    });
}

export function useWbrApplicationProcessMutation(id) {
    return useActionMutation(BASE_URL, id, PROCESS_URL_PART, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при взятии заявки на ВБР в работу")}</Translation>),
    });
}

export function useWbrApplicationUpdatePaymentMutation(id) {
    return useCustomMutation(BASE_URL, updateInvoiceRequest(id, UPDATE_PAYMENT_URL_PART), {
        onError: (error) => NotificationManager.error(error, <Translation>{(t) => t("Не удалось отправить договор")}</Translation>),
    });
}

export function useWbrApplicationResearchFinishedMutation(id) {
    return useActionMutation(BASE_URL, id, RESEARCH_FINISHED_URL_PART, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при завершении рассмотрения заявки на ВБР")}</Translation>),
    });
}

export function useWbrApplicationSendActMutation(id) {
    const { t } = useTranslation();
    const sendRequest = (data) => {
        let document = cloneDeep(data);
        const files = extractAndLinkFiles(document, ["certificateFile", "signatures"]);
        const formData = createFormData({ certificate: document, files });
        return {
            type: "POST",
            url: `/${id}${SEND_ACT}`,
            uploadFile: true,
            data: formData,
            headers: {},
        };
    };

    return useCustomMutation(BASE_URL, sendRequest, {
        onError: (error) => NotificationManager.error(error, t("Не удалось отправить акт выполненных работ")),
    });
}

export function useWbrApplicationCompleteRegistrationMutation(id) {
    return useActionMutation(BASE_URL, id, COMPLETE_REGISTRATION, {
        onError: (error) =>
            NotificationManager.error(
                error,
                <Translation>{(t) => t("Произошла ошибка при завершении регистрации закрывающих документов заявки на ВБР")}</Translation>
            ),
    });
}

export function useWbrApplicationRevisionMutation(id) {
    return useActionMutation(BASE_URL, id, REVISION_URL_PART, {
        onError: (error) =>
            NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при отправке заявки на ВБР на доработку")}</Translation>),
    });
}

export function useWbrApplicationRejectMutation(id) {
    return useActionMutation(BASE_URL, id, REJECT_URL_PART, {
        onError: (error) => NotificationManager.error(error, <Translation>{(t) => t("Произошла ошибка при отклонении заявки на ВБР")}</Translation>),
    });
}

function updateInvoiceRequest(id, urlPart) {
    return (data) => {
        let invoiceInfo = cloneDeep(data);
        invoiceInfo.invoice.vatMode = getVatMode(invoiceInfo.invoice);
        const files = extractAndLinkFiles(invoiceInfo.invoice, ["invoiceFile", "specificationFile", "signatures"]);
        const formData = createFormData({ invoice: invoiceInfo, files });
        return {
            type: "POST",
            url: `/${id}${urlPart}`,
            uploadFile: true,
            data: formData,
            headers: {},
        };
    };
}

function getVatMode({ useVat, includeVat }) {
    if (useVat === false) {
        return wbrApplicationInvoiceVatModeTypes.WITHOUT_VAT;
    }
    if (includeVat === true) {
        return wbrApplicationInvoiceVatModeTypes.INCLUDED;
    }
    return wbrApplicationInvoiceVatModeTypes.NOT_INCLUDED;
}

function convertOneFile(parent, fileField) {
    const file = parent[fileField]?.[0];
    if (!file) {
        parent[fileField] = null;
        return null;
    }
    parent[fileField] = { nativeName: file.name, size: file.size };
    return file;
}

function extractAndLinkFiles(parent, fileFields = []) {
    return fileFields.map((fileField) => convertOneFile(parent, fileField)).filter(Boolean);
}
