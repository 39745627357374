import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ajax from "../../../utils/ajax";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError } from "../../../common/store/notification/service";

let request = ajax("/rest-api/application/research/add");

export const createAppResearch = createAsyncThunk("/research/add", async (arg, { dispatch, getState }) => {
    dispatch(startLoading());
    let addLabResearch = { ...arg };
    addLabResearch.research.operator = {
        guid: addLabResearch.research.operator.guid,
        name: addLabResearch.research.operator.fullName,
    };
    return request({
        type: "POST",
        data: arg,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при сохранении лабораторных исследований для заявки."));
        })
        .finally(() => {
            dispatch(completeLoading());
        });
});

const initialState = {
    result: {},
};

const researchSlice = createSlice({
    name: "research",
    initialState,
    reducers: {},
    extraReducers: {
        [createAppResearch.fulfilled]: (state, action) => {
            state.result = action.payload;
        },
        [createAppResearch.rejected]: (state, action) => {
            state.result = {};
        },
    },
});

export default researchSlice.reducer;
