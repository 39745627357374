import { subProducts } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка видов продукции";

export const requestSubProducts = (productGuid) =>
    createLoadAsyncAction(
        subProducts,
        request,
        {
            type: "GET",
            url: `sub-products?productGuid=${productGuid}`,
        },
        errorMessage
    );
