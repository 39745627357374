import React from "react";

import { SubHeader, Table } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { getUnknownCursiveText } from "../../../../utils/constants/constants";

const WbrConclusionConsignmentItemsView = ({ conclusion }) => {
    const { t } = useTranslation("translation");
    const unknown = getUnknownCursiveText(t);
    const columns = [
        {
            key: "originCountry",
            title: t("Страна происхождения"),
            className: "align-center",
            dataFormatter: (item) => (item.originCountry ? item.originCountry?.view : unknown),
        },
        {
            key: "subProduct",
            title: t("Вид продукции"),
            className: "align-center",
            dataFormatter: (item) => (item.subProduct ? item.subProduct?.name : unknown),
        },
        {
            key: "cnCode",
            title: t("ТН ВЭД код"),
            className: "align-center",
            dataFormatter: (item) => (item.cnCode ? item.cnCode : unknown),
        },
        {
            key: "netWeight",
            title: t("Объем"),
            className: "align-center",
            dataFormatter: (item) => (item.netWeight ? <span>{item.netWeight + " " + item.unitMeasure?.shortName}</span> : unknown),
        },
    ];

    return (
        <div className={"row"}>
            <SubHeader header={t("Сведения о грузе")} />
            <div className={"wraptablebig"}>
                <Table
                    id="preview__consignment"
                    className="table table-striped table-bordered table-hover"
                    dataList={conclusion?.consignment?.consignmentItems}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export default WbrConclusionConsignmentItemsView;
