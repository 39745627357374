import { setIn } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { hasAuthority } from "../../../utils/authority-utils";
import { useGetWbrConclusionsPage } from "../../wbrConclusionQuery";
import { useUserContext } from "../../../session/UserContextProvider";
import { wbr_conclusion_record_modify } from "../../../common/constants/permissions";
import BlinkingFetchingContainer from "../../../components/fetching/BlinkingFetchingContainer";
import PageContent from "../../../components/page/PageContent";
import usePageParams from "../../../utils/usePageParams";
import WbrConclusionPageableTable from "./components/WbrConclusionPageableTable";
import WbrConclusionSearchWrapper from "./components/WbrConclusionSearchWrapper";
import WideWrapper from "../../../components/page/WideWrapper";

const WbrConclusionListPage = () => {
    const { t } = useTranslation("translations");
    const { user } = useUserContext();
    const title = t("Заключения ВБР");

    const [enableWbrConclusionsQuery, setEnableWbrConclusionsQuery] = useState(false);
    const { pageParams, setPage, setSize, searchParams, setSearchParams, isParamsInitialized: isPageParamsInit  } = usePageParams("WbrConclusion");
    const { data: conclusions, isFetching: isLoading } = useGetWbrConclusionsPage(pageParams, searchParams, enableWbrConclusionsQuery);

    useEffect(() => {
        if (user && !searchParams.createdByInstitution && hasAuthority(wbr_conclusion_record_modify, user.authorities) && user.institution?.guid) {
            setSearchParams(setIn(searchParams, "createdByInstitution", user.institution.guid));
        }
        setEnableWbrConclusionsQuery(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageParamsInit, user]);

    return (
        <WbrConclusionSearchWrapper searchParams={searchParams} setSearchParams={setSearchParams}>
            {(searchForm, budgeList, searchToggleButton) => (
                <PageContent title={title} toolbar={searchToggleButton} secondLineInfo={budgeList}>
                    <WideWrapper>
                        {searchForm}
                        <BlinkingFetchingContainer isFetching={isLoading}>
                            <WbrConclusionPageableTable page={conclusions} onChangePage={setPage} onChangeSizePerPage={setSize} />
                        </BlinkingFetchingContainer>
                    </WideWrapper>
                </PageContent>
            )}
        </WbrConclusionSearchWrapper>
    );
};

export default WbrConclusionListPage;
