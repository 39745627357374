import {
    ECERT_IMPORT_LIST_LOAD,
    ECERT_IMPORT_LIST_RECEIVE,
    ECERT_IMPORT_LIST_ERROR,
    ECERT_IMPORT_LOAD,
    ECERT_IMPORT_RECEIVE,
    ECERT_IMPORT_ERROR,
    UNCEFACT_XML_LOAD,
    UNCEFACT_XML_RECEIVE,
    UNCEFACT_XML_LOAD_ERROR,
    ECERT_IMPORT_LIST_FILTER_CLEAR,
    ECERT_IMPORT_LIST_FILTER_SET,
} from "../constants/ActionTypes";

const initialState = {
    eCert: {},
    list: {},
    filterObj: {},
    searchFormValue: {},
    error: null,
    loading: false,
    xmlLoading: false,
    saving: false,
    xmlData: "",
};

const unCefactXMLLoad = (state, action) => ({ ...state, xmlLoading: true });

const unCefactXMLReceive = (state, action) => ({
    ...state,
    error: null,
    xmlData: action.xmlData,
    xmlLoading: false,
});

const unCefactXMLLoadError = (state, action) => ({
    ...state,
    error: action.error,
    xmlData: {},
    xmlLoading: false,
});

const eCertImportListLoad = (state, action) => ({
    ...state,
    loading: true,
    filterObj: action.filterObj,
});

const eCertImportListReceive = (state, action) => ({
    ...state,
    error: null,
    list: action.eCerts,
    loading: false,
});

const eCertImportListLoadError = (state, action) => ({
    ...state,
    error: action.error,
    list: {},
    loading: false,
});

const eCertImportLoad = (state, action) => ({ ...state, loading: true });

const eCertImportReceive = (state, action) => ({
    ...state,
    error: null,
    eCert: action.eCert,
    loading: false,
});

const eCertImportLoadError = (state, action) => ({
    ...state,
    error: action.error,
    eCert: {},
    loading: false,
});

const setECertImportListFilter = (state, action) => ({
    ...state,
    searchFormValue: action.filter,
});

const clearECertImportListFilter = (state, action) => ({
    ...state,
    searchFormValue: {},
});

export default function report(state = initialState, action) {
    switch (action.type) {
        case ECERT_IMPORT_LIST_LOAD:
            return eCertImportListLoad(state, action);
        case ECERT_IMPORT_LIST_RECEIVE:
            return eCertImportListReceive(state, action);
        case ECERT_IMPORT_LIST_ERROR:
            return eCertImportListLoadError(state, action);
        case ECERT_IMPORT_LOAD:
            return eCertImportLoad(state, action);
        case ECERT_IMPORT_RECEIVE:
            return eCertImportReceive(state, action);
        case ECERT_IMPORT_ERROR:
            return eCertImportLoadError(state, action);
        case UNCEFACT_XML_LOAD:
            return unCefactXMLLoad(state, action);
        case UNCEFACT_XML_RECEIVE:
            return unCefactXMLReceive(state, action);
        case UNCEFACT_XML_LOAD_ERROR:
            return unCefactXMLLoadError(state, action);
        case ECERT_IMPORT_LIST_FILTER_SET:
            return setECertImportListFilter(state, action);
        case ECERT_IMPORT_LIST_FILTER_CLEAR:
            return clearECertImportListFilter(state, action);

        default:
            return state;
    }
}
