import { users } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка пользователей";

export const requestUsersByOrganization = () =>
    createLoadAsyncAction(
        users,
        request,
        {
            type: "POST",
            url: "users",
        },
        errorMessage
    );

export const requestUsers = () =>
    createLoadAsyncAction(
        users,
        request,
        {
            type: "POST",
            url: "users",
        },
        errorMessage
    );

export const requestUsersWhereVeterinarianIsNull = () =>
    createLoadAsyncAction(
        users.withoutVeterinarian,
        request,
        {
            type: "GET",
            url: "users/veterinarian-is-null",
        },
        errorMessage
    );
