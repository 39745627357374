import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../../utils/constants/constants";
import { getWbrApplicationFileDownloadUrl } from "../../../../../api/WbrApplicationQueries";
import BlockHeader from "../../../../../../../components/page/BlockHeader";
import CompareTable from "../../../../../../../components/table/CompareTable";
import IconedLink from "../../../../../../../components/page/IconedLink";
import SeparatedList from "../../../../../../../components/list/SeparatedList";

const HaccpJournalDocumentsTable = ({ applicationId, documents, documentsForCompare }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const tableRows = [
        {
            key: "document",
            title: t("Документ"),
            dataFormatter: (item) => (
                <Fragment>
                    {item.index != null ? `${item.index + 1}. ` : ""}
                    {item.name ?? unknown}
                    {item.issueDate ? ` ${t("от")} ${item.issueDate}` : ""}
                </Fragment>
            ),
            compareData: (item) => [item.name, item.issueDate].join("-"),
        },
        {
            key: "file",
            title: t("Файл"),
            dataFormatter: (item) => (
                <SeparatedList
                    items={item.files.map((file) => (
                        <IconedLink.Download file={file} href={getWbrApplicationFileDownloadUrl(applicationId, file.id)} />
                    ))}
                    empty={unknown}
                />
            ),
            compareData: (item) => item.files.map((file) => file.id).join("-"),
        },
    ];

    return (
        <Fragment>
            <BlockHeader level={4}>{t("Копии журналов по системе НАССР")}</BlockHeader>
            <div className="wraptablebig">
                <CompareTable
                    dataList={documents?.map((document, index) => ({ ...document, index })) ?? []}
                    dataListToCompare={documentsForCompare}
                    columns={tableRows}
                />
            </div>
        </Fragment>
    );
};

export default HaccpJournalDocumentsTable;
