import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const XmlView = (props) => {
    const { xml } = props;
    return <pre className="block-scroll beauty-xml">{xml}</pre>;
};

XmlView.propTypes = {
    xml: PropTypes.string,
};

export default withTranslation("translations")(XmlView);
