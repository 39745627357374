import React, { Component, Fragment } from "react";
import { Icon } from "vetrf-ui";
import { withTranslation } from "react-i18next";

class TransportEquipmentSpoiler extends Component {
    state = {
        showModal: false,
    };

    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.open) {
            return { ...state, showModal: true };
        }
        return null;
    }

    handleClick() {
        const { fixed } = this.props;
        if (!fixed) {
            this.setState({ showModal: !this.state.showModal });
        }
    }

    render() {
        const { children, t, fixed } = this.props;
        const { showModal } = this.state;

        const buttonId = "transport_equipment_spoiler__toggle_btn";
        return (
            <Fragment>
                {" "}
                {showModal && (
                    <h3
                        id={buttonId}
                        className={!fixed ? "header smaller lighter blue cur-pointer" : "header smaller lighter blue"}
                        onClick={this.handleClick}
                    >
                        <Icon icon={!fixed && "angle-double-down"} />
                        {t("Сведения о контейнере или транспортном оборудовании")}
                    </h3>
                )}
                {!showModal && (
                    <h3
                        id={buttonId}
                        className={!fixed ? "header smaller lighter blue dashed cur-pointer" : "header smaller lighter blue"}
                        onClick={this.handleClick}
                    >
                        <Icon icon={!fixed && "angle-double-right"} />
                        {t("Сведения о контейнере или транспортном оборудовании")}
                    </h3>
                )}
                <p className="help-block">Заполните в случае перевозки с использованием контейнера/оборудования</p>
                {(showModal || fixed) && <div className={"panel-collapse collapse in"}>{children}</div>}
            </Fragment>
        );
    }
}

TransportEquipmentSpoiler.propTypes = {};
export default withTranslation("translations")(TransportEquipmentSpoiler);
