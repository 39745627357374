import { handleActions } from "redux-actions";
import { authorityDepartments } from "./actions";

const initialState = {
    departments: [],
    page: {},
    item: {},
    error: null,
    loading: false,
};

export default handleActions(
    {
        [authorityDepartments.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [authorityDepartments.load.success]: (state, { payload = {} }) => ({
            ...state,
            departments: payload,
        }),
        [authorityDepartments.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [authorityDepartments.page.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [authorityDepartments.page.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            page: payload,
            loading: false,
        }),
        [authorityDepartments.page.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [authorityDepartments.save.request]: (state) => ({
            ...state,
            error: null,
            saving: true,
        }),
        [authorityDepartments.save.success]: (state) => ({
            ...state,
            departments: [],
            page: {},
            error: null,
            saving: false,
        }),
        [authorityDepartments.save.error]: (state, action) => ({
            ...state,
            error: action.payload,
            saving: false,
        }),
        [authorityDepartments.delete.request]: (state) => ({
            ...state,
            error: null,
            deleting: true,
        }),
        [authorityDepartments.delete.success]: (state) => ({
            ...state,
            departments: [],
            page: {},
            error: null,
            deleting: false,
        }),
        [authorityDepartments.delete.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [authorityDepartments.item.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [authorityDepartments.item.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            item: payload,
            loading: false,
        }),
        [authorityDepartments.item.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
    },
    initialState
);
