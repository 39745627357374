import { LOGS_IMPORT_LIST_FILTER_CLEAR, LOGS_IMPORT_LIST_FILTER_SET } from "../constants/ActionTypes";

export const logListFilterSet = (filter) => ({
    type: LOGS_IMPORT_LIST_FILTER_SET,
    filter,
});

export const logListFilterClear = () => ({
    type: LOGS_IMPORT_LIST_FILTER_CLEAR,
});

export const setFilter = (filterObj, pageable) => (dispatch) => {
    dispatch(logListFilterSet(filterObj, pageable));
};

export const clearFilter = () => (dispatch) => {
    dispatch(logListFilterClear());
};
