import React from "react";
import PropTypes from "prop-types";

const NavbarButtons = ({ children }) => {
    return (
        <div className="navbar-buttons navbar-header pull-right" role="navigation">
            <ul className="nav ace-nav">{children}</ul>
        </div>
    );
};

NavbarButtons.propTypes = {
    children: PropTypes.node,
};

export default NavbarButtons;
