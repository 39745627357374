import React from "react";

import { InternalErrorModal, compressError } from "./InternalErrorPage";
import { reportBaseUrl } from "../utils/logging/api/loggingQueries";

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }

    static getDerivedStateFromError(error) {
        if (!error) {
            return { hasError: true, error: { message: "Unknown exception" } };
        }
        const formattedError = `${error.name}: ${error.message}\n${error.stack
            ?.split(/\r?\n/)
            .slice(0, 10)
            .join("\n")
            .replace(/^/gm, "\t")}\n\nHistory unavailable`;
        fetch(reportBaseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({ message: formattedError, uri: window.location.href }),
        });
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return <InternalErrorModal errorMessage={compressError(this.state.error?.message, this.state.error?.stack)} />;
        }
        return this.props.children;
    }
}

export default GlobalErrorBoundary;
