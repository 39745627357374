import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useListQuery } from "../../utils/commonQueryBase";

const baseUrl = "/rest-api/dictionary/sub-products";

export function useGetSubProductList(searchParams, depends) {
    return useListQuery(baseUrl, searchParams, {
        staleTime: 5 * 60 * 1000, //5 минут таймаут для кеша загрузки
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка видов  продукции."),
        enabled: !!depends,
    });
}
