import { Fragment } from "react";
import { ProfileTable } from "vetrf-ui";
import isObject from "lodash/isObject";

const CompareBlockTable = ({ data, comparedData, rows }) => {
    const mergedRows = comparedData && rows.map((row) => {
        const isDataEquals = compareRows(row, data, comparedData);
        if (isDataEquals) {
            return row;
        } else {
            return {
                ...row,
                dataFormatter: () => (
                    <Fragment>
                        <s>{row.dataFormatter(comparedData)}</s>
                        <br />
                        {row.dataFormatter(data)}
                    </Fragment>
                ),
                getCellClass: () => "dark-blue light-orange-background",
            };
        }
    });

    return (
        <div className="wraptablebig">
            <ProfileTable
                itemList={data ? [{ item: data }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={comparedData ? mergedRows : rows}
            />
        </div>
    );
};

const compareRows = (row, data, comparedData) => {
    if (row.compareData) {
        return row.compareData(data) === row.compareData(comparedData);
    }
    const formattedData = row.dataFormatter(data);
    const formattedComparedData = row.dataFormatter(comparedData);
    if (!isObject(formattedData) && !isObject(formattedComparedData)) {
        return formattedData === formattedComparedData;
    }
    return false;
};

export default CompareBlockTable;
