import { Fragment } from "react";
import { Icon, withTooltip } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import CompareBlockTable from "../../../../../../components/table/CompareBlockTable";
import IconedLink from "../../../../../../components/page/IconedLink";
import ItemsList from "../../../../../../components/list/ItemsList";
import {
    AIR_TRANSPORT_GUID,
    MARITIME_TRANSPORT_GUID,
    RAIL_TRANSPORT_GUID,
    ROAD_TRANSPORT_TRANSPORT_GUID,
} from "../../../../../../utils/help/TransportNumberHelp";

const getTransportNameByType = (type, t) => {
    if (!type) return null;
    switch (type.id) {
        case MARITIME_TRANSPORT_GUID:
            return t("Название судна");
        case ROAD_TRANSPORT_TRANSPORT_GUID:
            return t("Номер автомобиля");
        case RAIL_TRANSPORT_GUID:
            return t("Номер поезда");
        case AIR_TRANSPORT_GUID:
            return t("Номер авиарейса");
        default:
            return t("Номер");
    }
};

const IconTooltip = withTooltip(Icon);

const WbrApplicationRouteInfo = ({ application, applicationForCompare }) => {
    const { t, i18n } = useTranslation();
    const unknown = getUnknownNormalText(t);
    const nameField = i18n.resolvedLanguage === "ru" ? "name" : "englishName";

    const getBusinessEntityView = (entity) => {
        if (entity) {
            return (
                <Fragment>
                    {(entity.view || entity.name || entity.address?.addressView) && (
                        <span>
                            {entity.view ?? entity.name ?? unknown} {entity.address?.addressView && ` (${entity.address?.addressView})`}
                            <br />
                        </span>
                    )}
                    {(entity.enView || entity.enName || entity.address?.enAddressView) && (
                        <span>
                            {entity.enView ?? entity.enName ?? unknown} {entity.address?.enAddressView && ` (${entity.address?.enAddressView})`}
                            <br />
                        </span>
                    )}
                    {entity.phone && (
                        <Fragment>
                            {t("Телефон")}: {entity.phone}
                            <br />
                        </Fragment>
                    )}
                    {entity.email && (
                        <Fragment>
                            {t("E-mail")}: <IconedLink.Email href={entity.email} />
                            <br />
                        </Fragment>
                    )}
                </Fragment>
            );
        }
        return unknown;
    };

    const getTransportMovementsView = (transportMovements) => {
        if (!transportMovements || !transportMovements.length) return null;
        return (
            <ItemsList
                items={transportMovements.map((transportMovement) => {
                    return (
                        <span>
                            {transportMovement.transportType?.[nameField]} ({getTransportNameByType(transportMovement?.transportType, t)}:{" "}
                            {transportMovement.transport?.number})
                        </span>
                    );
                })}
                empty={unknown}
            />
        );
    };

    const getTransportEquipmentsView = (transportEquipments) => {
        if (!transportEquipments || !transportEquipments.length) return null;
        return (
            <ItemsList
                items={transportEquipments.map((transportMovement) => {
                    return (
                        <span>
                            {transportMovement.type?.[nameField] ?? unknown}: {transportMovement.number ?? unknown}
                            {transportMovement.affixedSeal && (
                                <span>
                                    {", "}
                                    {t("Пломба")}: {transportMovement.affixedSeal}
                                </span>
                            )}
                        </span>
                    );
                })}
                empty={unknown}
            />
        );
    };

    const tableRows = [
        {
            header: {
                key: "departureLocation",
                title: t("Место отправки"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.departureLocation) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.departureLocation),
        },
        {
            header: {
                key: "loadingLocation",
                title: t("Место погрузки"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.loadingLocation) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.loadingLocation),
        },
        {
            header: {
                key: "unloadingLocation",
                title: t("Место назначения"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.unloadingLocation) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.unloadingLocation),
        },
        {
            header: {
                key: "responsible",
                title: t("Ответственное за груз лицо (в ЕС/Израиле)"),
            },
            dataFormatter: (item) => getBusinessEntityView(item.consignment.responsible) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.responsible),
        },
        {
            header: {
                key: "transportMovements",
                title: t("Транспорт"),
            },
            dataFormatter: (item) => getTransportMovementsView(item.consignment.transportMovements) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.transportMovements),
        },
    ];

    if (application.consignment.transportEquipments?.length) {
        tableRows.push({
            header: {
                key: "transportEquipments",
                title: t("Оборудование"),
            },
            dataFormatter: (item) => getTransportEquipmentsView(item.consignment.transportEquipments) ?? unknown,
            compareData: (item) => JSON.stringify(item.consignment.transportEquipments),
        });
    }

    return (
        <Fragment>
            <BlockHeader>
                {t("Информация о маршруте и транспорте")}{" "}
                <IconTooltip
                    icon="info-circle"
                    tooltip={t("В данном блоке указывается только информация о перемещении продукции с площадки отгрузки на экспорт")}
                />
            </BlockHeader>
            <CompareBlockTable data={application} comparedData={applicationForCompare} rows={tableRows} />
        </Fragment>
    );
};

export default WbrApplicationRouteInfo;
