import { isEmpty } from "lodash";
import { filterDeep } from "deepdash/es/standalone";

const omitRecursiveEmpty = (value) => {
    return filterDeep(value, (v, k, parent) => {
        return !isEmpty(v);
    });
};

const isEmptyAfterOmitEmpty = (value) => {
    let result = omitRecursiveEmpty(value);
    return isEmpty(result);
};

const isEmptyValidationError = (value) => {
    return isEmpty(
        filterDeep(value, (v, k, parent) => {
            return typeof v == "boolean" && v;
        })
    );
};

export { isEmptyValidationError, isEmptyAfterOmitEmpty };
export default isEmptyAfterOmitEmpty;
