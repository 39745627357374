import get from "lodash/get";
import { Fragment } from "react";
import { Icon, deleteIn } from "vetrf-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { documentTypes } from "../../../../../../utils/constants/source-document-types";
import { getDateIntervalView, getWeightView } from "../../../../../../utils/viewUtils";
import { getLaboratoryResearchExternalUrl, getVetDocumentExternalUrl } from "../../../../api/WbrApplicationDocumentsQueries";
import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import { getWbrApplicationFileDownloadUrl } from "../../../../api/WbrApplicationQueries";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import CompareBlockTable from "../../../../../../components/table/CompareBlockTable";
import IconedLink from "../../../../../../components/page/IconedLink";
import ItemsList from "../../../../../../components/list/ItemsList";
import SeparatedList from "../../../../../../components/list/SeparatedList";
import TextButton from "../../../../../../components/button/TextButton";
import WbrApplicationItemRawInfo from "./WbrApplicationItemRawInfo";

const qualityDocumentTypes = [documentTypes.productQualityCert, documentTypes.conformityDeclaration, documentTypes.conformityCertificate];
const labResearchTypes = [documentTypes.labResearchProtocol];
const vetDocuments = [documentTypes.vetCertForMovingInRussia];
const requirementDocuments = [documentTypes.confirmingProductRequirements];
const sampleDocuments = [documentTypes.productLavelingSample];

const WbrApplicationItemInfo = ({ application, compareToApplication, index }) => {
    const { t, i18n } = useTranslation();
    const unknown = getUnknownNormalText(t);
    const [isInfoOpen, setIsInfoOpen] = useState(true);
    const [isTradeLineItemsOpen, setIsTradeLineItemsOpen] = useState(false);
    const nameField = i18n.resolvedLanguage === "ru" ? "name" : "englishName";

    const item = application.consignment.items[index];
    const comparedItem = compareToApplication?.consignment.items[index];

    const getItemDocuments = (item) => {
        const extractDocumentFromApplication = () => {
            if (get(application, `consignment.items[${index}]`) === item) {
                return application.documents ?? [];
            } else {
                return compareToApplication.documents ?? [];
            }
        };

        const applicationDocuments = extractDocumentFromApplication();
        if (!item.externalItemKey || !applicationDocuments.length) return [];
        return applicationDocuments.filter((doc) => doc.externalItemKey === item.externalItemKey);
    };

    const getPackagesView = (packages) => {
        if (!packages || !packages.length) return null;
        return (
            <ItemsList
                items={packages.map((packageItem) => {
                    return (
                        <span>
                            {packageItem.level?.[nameField] ?? unknown}: {packageItem.type?.[nameField] ?? unknown} ({packageItem.quantity} {t("шт.")}
                            )
                        </span>
                    );
                })}
                empty={unknown}
            />
        );
    };

    const getTradeLineItemsView = (tradeLineItems) => {
        const getTradeLineItemView = (item) => {
            return (
                <Fragment>
                    {item.batchNumber && (
                        <span>
                            {t("Номер партии")}: {item.batchNumber ?? unknown}
                            <br />
                        </span>
                    )}
                    {item.productionDateInterval && (
                        <span>
                            {t("Дата выработки")}: {getDateIntervalView(item.productionDateInterval) ?? unknown}
                            <br />
                        </span>
                    )}
                    {item.expiryDate && (
                        <span>
                            {t("Дата окончания срока годности")}: {getDateIntervalView(item.expiryDate) ?? unknown}
                            <br />
                        </span>
                    )}
                </Fragment>
            );
        };

        if (tradeLineItems.length > 1) {
            return (
                <Fragment>
                    {isTradeLineItemsOpen ? (
                        <SeparatedList items={tradeLineItems.map((line) => getTradeLineItemView(line))} empty={unknown} />
                    ) : (
                        <div className="falloff">{getTradeLineItemView(tradeLineItems[0])}</div>
                    )}
                    <div className="mt-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => setIsTradeLineItemsOpen(!isTradeLineItemsOpen)}>{isTradeLineItemsOpen ? t("Скрыть") : t("Развернуть")}</a>
                    </div>
                </Fragment>
            );
        }
        return getTradeLineItemView(tradeLineItems[0]);
    };

    const getConditionsView = (consignment) => {
        if (!consignment || (!consignment.storageConditions && !consignment.transportationConditions)) return null;
        const getConditionView = (condition, typeTitle, temperatureTitle) => {
            if (!condition) return null;
            return (
                <Fragment>
                    {condition.type && (
                        <span>
                            {typeTitle}: {condition.type[nameField]}
                            <br />
                        </span>
                    )}
                    {condition.type && (
                        <span>
                            {temperatureTitle}:{" "}
                            {`${condition.minTemperature ?? unknown}${condition.maxTemperature ? ` - ${condition.maxTemperature}` : ""}`}
                            <br />
                        </span>
                    )}
                </Fragment>
            );
        };

        return (
            <Fragment>
                {getConditionView(consignment.storageConditions, t("Режим хранения"), t("Температура хранения"))}
                {consignment.storageConditions && consignment.transportationConditions && <br />}
                {getConditionView(consignment.transportationConditions, t("Режим перевозки"), t("Температура перевозки"))}
            </Fragment>
        );
    };

    const filterDocumentsByTypes = (documents, neededTypes) => {
        const targetDocuments = documents.filter((doc) => neededTypes.map((type) => type?.id).includes(doc.type?.id));
        return targetDocuments.length ? targetDocuments : [];
    };

    const getItemDocumnetsOfTypes = (item, types) => {
        const itemDocuments = getItemDocuments(item);
        return filterDocumentsByTypes(itemDocuments, types);
    };

    const getQualityDocumentsView = (targetDocuments) => {
        if (!targetDocuments.length) return null;
        return (
            <SeparatedList
                items={targetDocuments.map((document) => (
                    <Fragment>
                        {document.type[nameField]}
                        {document.issueNumber ? ` № ${document.issueNumber}` : ""}
                        {document.issueDate ? ` ${t("от")} ${document.issueDate}` : ""}
                        <br />
                        {t("Выдан")}: {document.issuerView ?? unknown}
                        <br />
                        {document.validityDateInterval && (
                            <Fragment>
                                {t("Срок действия")}: {getDateIntervalView(document.validityDateInterval) ?? unknown}
                                <br />
                            </Fragment>
                        )}
                        {document.attributes?.additionalInfo && (
                            <Fragment>
                                {t("Доп. информация")}: {document.attributes.additionalInfo}
                                <br />
                            </Fragment>
                        )}
                    </Fragment>
                ))}
                empty={unknown}
            />
        );
    };

    const getResearchDocumentsView = (targetDocuments) => {
        if (!targetDocuments.length) return null;
        return (
            <SeparatedList
                items={targetDocuments.map((document) => (
                    <Fragment>
                        {document.issueNumber ?? document.externalUuid ?? unknown} {document.issueDate && ` ${t("от")} ${document.issueDate}`}
                        {document.externalUuid && (
                            <IconedLink.External title={t("Просмотр")} href={getLaboratoryResearchExternalUrl(document.externalUuid)} />
                        )}
                    </Fragment>
                ))}
                empty={unknown}
            />
        );
    };

    const getVetSourceDocumentsView = (targetDocuments) => {
        if (!targetDocuments.length) return null;
        return (
            <SeparatedList
                items={targetDocuments.map((document) => (
                    <Fragment>
                        {document.externalUuid ?? unknown} {document.issueDate && ` ${t("от")} ${document.issueDate}`}
                        {document.externalUuid && <IconedLink.External title={t("Просмотр")} href={getVetDocumentExternalUrl(document.uuid)} />}
                    </Fragment>
                ))}
                empty={unknown}
            />
        );
    };

    const getVetDocumentsView = (item) => {
        if (!item || !item.tradeLineItems || item.tradeLineItems.length === 0) return null;
        return (
            <SeparatedList
                items={item.tradeLineItems
                    .flatMap((tradeLineItem) => tradeLineItem.vetDocuments)
                    .filter(Boolean)
                    .map((document) => (
                        <Fragment>
                            {document.uuid ?? unknown}{" "}
                            {document.uuid && <IconedLink.External title={t("Просмотр")} href={getVetDocumentExternalUrl(document.uuid)} />}
                        </Fragment>
                    ))}
                empty={unknown}
            />
        );
    };

    const getRequirementDocumentsView = (targetDocuments) => {
        if (!targetDocuments.length) return null;
        return (
            <SeparatedList
                items={targetDocuments.map((document) => (
                    <Fragment>
                        {document.issueNumber ? document.issueNumber : unknown} {document.issueDate && ` ${t("от")} ${document.issueDate}`}
                        <br />
                        <ItemsList
                            items={document.files.map((file) => (
                                <IconedLink.Download file={file} href={getWbrApplicationFileDownloadUrl(application.id, file.id)} />
                            ))}
                            empty={unknown}
                        />
                    </Fragment>
                ))}
                empty={unknown}
            />
        );
    };

    const getSampleDocumentsView = (targetDocuments) => {
        if (!targetDocuments.length) return null;
        return (
            <SeparatedList
                items={targetDocuments.map((document) => (
                    <Fragment>
                        {document.name ?? unknown}
                        <br />
                        <SeparatedList
                            items={document.files.map((file) => (
                                <IconedLink.Download file={file} href={getWbrApplicationFileDownloadUrl(application.id, file.id)} />
                            ))}
                            empty={unknown}
                        />
                    </Fragment>
                ))}
                empty={unknown}
            />
        );
    };

    const tableRows = [
        {
            header: {
                key: "name",
                title: t("Наименование продукции"),
            },
            dataFormatter: (item) => `${item.name} / ${item.englishName ?? unknown}`,
        },
        {
            header: {
                key: "scientificName",
                title: t("Биологический вид"),
            },
            dataFormatter: (item) => item.scientificName ?? unknown,
        },
        {
            header: {
                key: "originType",
                title: t("Категория товара"),
            },
            dataFormatter: (item) => item.attributes?.originType?.view ?? unknown,
        },
        {
            header: {
                key: "processingTypes",
                title: t("Состояние продукта и вид обработки"),
            },
            dataFormatter: (item) => item.attributes?.processingTypes?.map((type) => type.view).join(", "),
        },
        {
            header: {
                key: "tnvedCode",
                title: t("Код ТН ВЭД / код HS"),
            },
            dataFormatter: (item) => item.tnvedCode ?? unknown,
        },
        {
            header: {
                key: "packages",
                title: t("Упаковка"),
            },
            dataFormatter: (item) => getPackagesView(item.packages) ?? unknown,
            compareData: (item) => item.packages.length,
        },
        {
            header: {
                key: "netWeight",
                title: t("Вес нетто"),
            },
            dataFormatter: (item) => getWeightView(item.netWeight) ?? unknown,
        },
        {
            header: {
                key: "grossWeight",
                title: t("Вес брутто"),
            },
            dataFormatter: (item) => getWeightView(item.attributes?.grossWeight) ?? unknown,
        },
        {
            header: {
                key: "tradeLineItems",
                title: t("Сведения о партиях"),
            },
            dataFormatter: (item) => getTradeLineItemsView(item.tradeLineItems) ?? unknown,
            compareData: (item) => item.tradeLineItems.length,
        },
        {
            header: {
                key: "conditions",
                title: t("Условия хранения и транспортировки"),
            },
            dataFormatter: () => getConditionsView(application.consignment) ?? unknown,
            compareData: () => "conditions",
        },
        {
            header: {
                key: "intendedUse",
                title: t("Продукты предназначены"),
            },
            dataFormatter: (item) => item.intendedUse?.view ?? unknown,
        },
        {
            header: {
                key: "productConsistency",
                title: t("Консистенция"),
            },
            dataFormatter: (item) => item.attributes?.productConsistency ?? unknown,
        },
        {
            header: {
                key: "productDepartureTemperature",
                title: t("Температура продукции в момент отгрузки, C"),
            },
            dataFormatter: (item) => item.attributes?.productDepartureTemperature ?? unknown,
        },
        {
            header: {
                key: "productGrade",
                title: t("Сорт"),
            },
            dataFormatter: (item) => item.attributes?.productGrade ?? unknown,
        },
        {
            header: {
                key: "normativeDocument",
                title: t("Показатели качества продукции соответствуют требованиям следующих нормативных документов"),
            },
            dataFormatter: (item) => item.attributes?.normativeDocument ?? unknown,
        },
        {
            header: {
                key: "qualityDocuments",
                title: t("Документы, подтверждающие качество и безопасность продукции"),
            },
            dataFormatter: (item) => getQualityDocumentsView(getItemDocumnetsOfTypes(item, qualityDocumentTypes)) ?? unknown,
            compareData: (item) => JSON.stringify(prepareDocumetsToCompare(getItemDocumnetsOfTypes(item, qualityDocumentTypes))),
        },
        {
            header: {
                key: "labResearchDocuments",
                title: t("Протоколы лабораторных исследований"),
            },
            dataFormatter: (item) => getResearchDocumentsView(getItemDocumnetsOfTypes(item, labResearchTypes)) ?? unknown,
            compareData: (item) => JSON.stringify(prepareDocumetsToCompare(getItemDocumnetsOfTypes(item, labResearchTypes))),
        },
        {
            header: {
                key: "vetDocuments",
                title: t("Ветеринарно-сопроводительные документы на экспортируемую продукцию"),
            },
            dataFormatter: (item) => getVetDocumentsView(item) ?? unknown,
            compareData: (item) => item.tradeLineItems?.flatMap((tradeLineItem) => tradeLineItem.vetDocuments).join("-"),
        },
        {
            header: {
                key: "vetDocumentsSource",
                title: t("Документы, подтверждающие происхождение сырья с предприятий или судов"),
            },
            dataFormatter: (item) => getVetSourceDocumentsView(getItemDocumnetsOfTypes(item, vetDocuments)) ?? unknown,
            compareData: (item) => JSON.stringify(prepareDocumetsToCompare(getItemDocumnetsOfTypes(item, vetDocuments))),
        },
        {
            header: {
                key: "requirementDocuments",
                title: t("Документы, подтверждающие соответствие импортного сырья требованиям Регламентов ЕС"),
            },
            dataFormatter: (item) => getRequirementDocumentsView(getItemDocumnetsOfTypes(item, requirementDocuments)) ?? unknown,
            compareData: (item) => JSON.stringify(prepareDocumetsToCompare(getItemDocumnetsOfTypes(item, requirementDocuments))),
        },
        {
            header: {
                key: "sampleDocuments",
                title: t("Образцы маркировки продукции на транспортной и потребительской таре"),
            },
            dataFormatter: (item) => getSampleDocumentsView(getItemDocumnetsOfTypes(item, sampleDocuments)) ?? unknown,
            compareData: (item) => JSON.stringify(prepareDocumetsToCompare(getItemDocumnetsOfTypes(item, sampleDocuments))),
        },
    ];

    const toolbar = (
        <div className="sm-header-toolbar pull-right">
            <TextButton text={isInfoOpen ? t("Скрыть") : t("Развернуть")} />
        </div>
    );
    return (
        <Fragment key={index}>
            <BlockHeader level={4} onClick={() => setIsInfoOpen(!isInfoOpen)} toolbar={toolbar}>
                <Icon icon={isInfoOpen ? "chevron-down" : "chevron-right"} /> {t("Позиция")} №{index + 1} : {item[nameField] || unknown}
            </BlockHeader>
            {isInfoOpen && (
                <Fragment>
                    <CompareBlockTable data={item} comparedData={comparedItem} rows={tableRows} />
                    <WbrApplicationItemRawInfo index={index} item={item} />
                </Fragment>
            )}
        </Fragment>
    );
};

const prepareDocumetsToCompare = (documents) => {
    return documents.map((document) => deleteIn(document, "externalItemKey"));
};

export default WbrApplicationItemInfo;
