import { SelectField } from "vetrf-ui";

import withOnChange from "./hoc/withOnChange";

/**
 * @param {callback} onChange - обратный вызов при изменении значения
 */
const ESelectField = ({ ...props }) => <SelectField {...props} />;

export default withOnChange(ESelectField);
