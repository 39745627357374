import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useCustomQuery } from "../../utils/commonQueryBase";

const baseUrl = "/rest-api/import/ecert";
const pageUrl = `${baseUrl}/search`;

export function useECertImportListPage(pageParams, searchParams, enabled) {
    return useCustomQuery(
        pageUrl,
        { method: "POST", params: pageParams, body: mapSearchParams(searchParams) },
        {
            enabled: enabled,
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка испортных сертифкатов"),
        }
    );
}

function mapSearchParams(params) {
    // 20.06.2023 -> 2023-06-20 00:00:00
    const mapDate = (date) => {
        if (!date) return;
        const dateParts = date.split(".");
        const year = dateParts[2];
        const month = dateParts[1];
        const day = dateParts[0];
        return `${year}-${month}-${day} 00:00:00`;
    };

    return {
        ...params,
        approvedDateInterval: params.approvedDateInterval && {
            startDate: mapDate(params.approvedDateInterval.startDateTime),
            endDate: mapDate(params.approvedDateInterval.endDateTime),
        },
    };
}
