import { combineReducers } from "redux";
import applicationList from "./slices/applicationListSlice";
import application from "./slices/applicationSlice";
import research from "./slices/researchSlice";

export default combineReducers({
    applicationList,
    application,
    research,
});
