import React from "react";
import PropTypes from "prop-types";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { withTranslation } from "react-i18next";
import { transformECertStatus } from "../../../../../utils/transformers/transformECertStatus";
import { transformDateByFormat } from "../../../../../utils/transformers/transformDateByFormat";

const CommonInformationECertView = (props) => {
    const { eCert } = props;
    const commonRows = [
        {
            header: {
                key: "shoulderNumber",
                title: "Номер",
                width: "12%",
            },
            dataFormatter: (item) => (item.shoulderNumber ? item.shoulderNumber : ""),
        },
        {
            header: {
                key: "approvedDate",
                title: "Дата оформления",
                width: "12%",
            },
            dataFormatter: (item) => transformDateByFormat(item.approvedDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
        },
        {
            header: {
                key: "status",
                title: "Статус",
                width: "12%",
            },
            dataFormatter: (item) => (item.status ? transformECertStatus(item.status) : ""),
        },
        {
            header: {
                key: "country",
                title: "Страна",
                width: "12%",
            },
            dataFormatter: (item) => (item.country ? item.country.code : ""),
        },
        {
            header: {
                key: "insertDate",
                title: "Дата загрузки",
                width: "12%",
            },
            dataFormatter: (item) => transformDateByFormat(item.insertDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
        },
    ];

    const rows = commonRows.filter((item) => {
        return !(eCert[item.header.key] === undefined);
    });

    return (
        <div>
            <SubHeader header={"Реквизиты документа"} />
            <ProfileTable
                itemList={eCert ? [{ item: eCert }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={rows}
            />
        </div>
    );
};

CommonInformationECertView.propTypes = {
    eCert: PropTypes.object,
};

export default withTranslation("translations")(CommonInformationECertView);
