import ajax from "../../utils/ajax";

import { DISEASES_LIST_LOAD, DISEASES_LIST_RECEIVE, DISEASES_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const diseaseLoad = () => ({
    type: DISEASES_LIST_LOAD,
});

export const diseaseReceive = (diseases) => ({
    type: DISEASES_LIST_RECEIVE,
    diseases,
});

export const diseaseLoadError = (error) => ({
    type: DISEASES_LIST_ERROR,
    error,
});

export const requestDiseases = () => (dispatch) => {
    dispatch(diseaseLoad());
    request({
        type: "GET",
        url: "diseases/all",
    })
        .then((response) => {
            dispatch(diseaseReceive(response));
        })
        .catch((error) => {
            dispatch(diseaseLoadError(error));
        });
};
