import React from "react";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { getUnknownCursiveText } from "../../../../utils/constants/constants";
import CountryFlag from "../../../../components/countryFlag/CountryFlag";
import uniqueId from "lodash/uniqueId";

const ActualObjectView = ({ department }) => {
    const { t } = useTranslation();
    const unknown = getUnknownCursiveText(t);
    const commonRows = [
        {
            header: {
                key: "sync",
                title: t("Статус синхронизации с ИС Цербер"),
            },
            getCellClass: (item) => (item?.synced ? "dark-blue light-green-background" : "dark-blue light-red-background"),
            dataFormatter: (item) => (item?.synced ? "Синхронизировано " : "Не синхронизировано"),
        },
        {
            header: {
                key: "number",
                title: t("Номер площадки"),
            },
            dataFormatter: (item) => (item?.number ? item?.number : unknown),
        },
        {
            header: {
                key: "name",
                title: t("Наименование"),
            },
            dataFormatter: (item) => item?.name || unknown,
        },
        {
            header: {
                key: "country",
                title: t("Страна"),
            },
            dataFormatter: (item) => {
                return item?.address?.country ? (
                    <CountryFlag key={uniqueId("transit_")} country={item?.address?.country} nameField={"view"} />
                ) : (
                    unknown
                );
            },
        },
        {
            header: {
                key: "region",
                title: t("Регион"),
            },
            dataFormatter: (item) => item?.address?.region?.view || unknown,
        },
        {
            header: {
                key: "address",
                title: t("Адрес"),
            },
            dataFormatter: (item) => item?.address?.addressView || unknown,
        },
        {
            header: {
                key: "enName",
                title: t("Наименование на английском"),
            },
            dataFormatter: (item) => item?.englishName || unknown,
        },
        {
            header: {
                key: "enAddress",
                title: t("Адрес на английском"),
            },
            dataFormatter: (item) => item?.address?.enAddressView || unknown,
        },
    ];

    return (
        <div className={"row"}>
            <SubHeader header={t("Сведения о площадке")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__common"
                    itemList={department ? [{ item: department }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={commonRows}
                />
            </div>
        </div>
    );
};

export default ActualObjectView;
