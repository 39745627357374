import { UNIT_MEASURE_LIST_LOAD, UNIT_MEASURE_LIST_RECEIVE, UNIT_MEASURE_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    unitMeasures: [],
    error: null,
    loading: false,
};

const unitMeasuresLoad = (state, action) => ({ ...state, loading: true });

const getUnitMeasures = (action) => (action.unitMeasures && action.unitMeasures.length > 0 ? action.unitMeasures : []);

const unitMeasuresReceive = (state, action) => ({
    ...state,
    error: null,
    unitMeasures: getUnitMeasures(action),
    loading: false,
});

const unitMeasuresError = (state, action) => ({
    ...state,
    error: action.error,
    unitMeasures: [],
    loading: false,
});

export default function unitMeasures(state = initialState, action) {
    switch (action.type) {
        case UNIT_MEASURE_LIST_LOAD:
            return unitMeasuresLoad(state, action);
        case UNIT_MEASURE_LIST_RECEIVE:
            return unitMeasuresReceive(state, action);
        case UNIT_MEASURE_LIST_ERROR:
            return unitMeasuresError(state, action);

        default:
            return state;
    }
}
