import { Translation, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useWbrApplicationInvoice } from "../../../api/WbrApplicationDocumentsQueries";
import { useUserContext } from "../../../../../session/UserContextProvider";
import { useWbrApplication } from "../../../api/WbrApplicationQueries";
import { useWbrApplicationRequestPaymentMutation, useWbrApplicationUpdatePaymentMutation } from "../../../api/WbrApplicationStatusQueries";
import { WBR_APP_BREADCRUMBS, WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";
import Container from "../../../../../components/page/Container";
import AlertWithIcon from "../../../../../components/page/AlertWithIcon";
import PageContent from "../../../../../components/page/PageContent";
import WbrApplicationPaymentForm from "./WbrApplicationPaymentForm";
import {
    isWbrAppInStatus,
    isWbrApplicationClosed,
    isWbrApplicationFinishing,
    wbrApplicationStatuses as statuses,
    wbrApplicationStatuses,
} from "../../../../../utils/constants/wbr/wbr-application-statuses";

export const wbrInvoiceChangeUnavailableByStatusAlert = (
    <AlertWithIcon messageType="danger">
        <Translation>
            {(t) => t("Добавление/изменение сведений об оплате недоступно для данной заявки на ВБР, так как она имеет неверный статус")}
        </Translation>
    </AlertWithIcon>
);

export const wbrInvoiceChangeUnavailableByPaymentAlert = (
    <AlertWithIcon messageType="danger">
        <Translation>
            {(t) => t("Добавление/изменение сведений об оплате недоступно для данной заявки на ВБР, так как оплата уже произведена")}
        </Translation>
    </AlertWithIcon>
);

const WbrApplicationPaymentFormPage = () => {
    const { t } = useTranslation();
    const { id, action } = useParams();
    const navigate = useNavigate();
    const { user } = useUserContext();

    const { data: application, isLoading: isApplicationLoading } = useWbrApplication(id);
    const canRequestInvoice = application?.invoiceInfo?.invoice?.id && !application?.invoicePaymentInfo;
    const { data: invoice, isLoading: isInvoiceLoading } = useWbrApplicationInvoice(id, canRequestInvoice);
    const requestPaymentQuery = useWbrApplicationRequestPaymentMutation(id);
    const updatePaymentQuery = useWbrApplicationUpdatePaymentMutation(id);

    const isPaymentReceived = application?.invoicePaymentInfo?.invoicePayment.id;
    const isLoading = isApplicationLoading || isInvoiceLoading;
    const isContractConfirmed = application?.contractInfo?.isConfirmed || application?.contractInfo?.isReceived;
    const isPrepayment = !isWbrAppInStatus(application, [
        statuses.IN_PROCESS,
        statuses.REPEAT_NEW,
        statuses.SENT_FOR_REVISION,
        statuses.RESEARCH_FINISHED,
        statuses.WAITING_PAYMENT,
    ]);
    const isValidStatus =
        application &&
        !isWbrApplicationClosed(application) &&
        !(application.invoiceInfo?.invoice.id && isWbrApplicationFinishing(application)) &&
        !isWbrAppInStatus(application, wbrApplicationStatuses.WAITING_PREPAYMENT);

    const canAccess = application?.laboratory.guid === user?.institution.guid;
    const canViewForm = !isPaymentReceived && isContractConfirmed && isValidStatus;

    const onSubmitHandler = (invoiceInfo) => {
        const isUpdate = !!invoiceInfo.invoice.id;
        if (isUpdate) {
            updatePaymentQuery.mutate(invoiceInfo, { onSuccess: () => navigate(WBR_APP_ROUTE.VIEW.getFullPath(id)) });
        } else {
            requestPaymentQuery.mutate(invoiceInfo, { onSuccess: () => navigate(WBR_APP_ROUTE.VIEW.getFullPath(id)) });
        }
    };

    const onCancelHandler = () => {
        navigate(WBR_APP_ROUTE.VIEW.getFullPath(id));
    };

    const title = WBR_APP_ROUTE.PAYMENT_ACTION.getTitle(t, action);

    const getPageAlert = () => {
        if (!application) return;
        if (!isValidStatus || !isContractConfirmed) {
            return wbrInvoiceChangeUnavailableByStatusAlert;
        }
        if (isPaymentReceived) {
            return wbrInvoiceChangeUnavailableByPaymentAlert;
        }
    };

    return (
        <PageContent
            title={title}
            chapter={WBR_APP_ROUTE.PAYMENT_ACTION.getChapter(t)}
            middleBreadcrumbs={WBR_APP_BREADCRUMBS.getViewBreadcrumbs(id)}
            toolbar={<WbrApplicationPaymentForm.Toolbar onCancel={onCancelHandler} />}
            isLoading={isLoading}
            canAccess={canAccess}
        >
            <Container>{getPageAlert()}</Container>
            {canViewForm && (
                <WbrApplicationPaymentForm
                    applicationId={application.id}
                    isPrepayment={isPrepayment}
                    invoiceInfo={invoice && { ...application.invoiceInfo, invoice }}
                    bankDetails={application.laboratory}
                    onSubmit={onSubmitHandler}
                    onCancel={onCancelHandler}
                />
            )}
        </PageContent>
    );
};

export default WbrApplicationPaymentFormPage;
