export const food_cert_create = "food_cert_create"; // Экспортные сертификаты.Создание
export const food_cert_edit = "food_cert_edit"; // Экспортные сертификаты.Редактирование
export const food_cert_issue = "food_cert_issue"; // Экспортные сертификаты.Оформление
export const food_cert_reissue = "food_cert_reissue"; // Экспортные сертификаты.Перевыпуск
export const food_cert_view_all = "food_cert_view_all"; // Экспортные сертификаты.Просмотр без ограничений
export const food_cert_view_org = "food_cert_view_org"; // Экспортные сертификаты.Просмотр в рамках ТУ
export const food_cert_withdrawn = "food_cert_withdrawn"; // Экспортные сертификаты.Аннулирование
export const food_cert_print_all = "food_cert_print_all"; // Печать экспортного сертификата

export const import_cert_view = "import_cert_view"; // Импортные сертификаты.Просмотр сертификатов
export const import_log_view = "import_log_view"; // Импортные сертификаты.Просмотр операций

export const record_create = "record_create"; // Учтённые сертификаты.Создание
export const record_edit_org = "record_edit_org"; // Учтённые сертификаты.Редактирование в рамках организации
export const record_view_all = "record_view_all"; // Учтённые сертификаты.Просмотр без ограничений
export const record_view_org = "record_view_org"; // Учтённые сертификаты.Просмотр в рамках организации
export const record_withdrawn_org = "record_withdrawn_org"; // Учтённые сертификаты.Аннулирование в рамках организации

export const user_custom_roles_create = "user_custom_roles_create"; // Управление пользователями.Создание ролей
export const user_list_view = "user_list_view"; // Управление пользователями.Просмотр списка пользователей
export const user_rights_assignment = "user_rights_assignment"; // Управление пользователями.Назначение прав пользователей

export const application_pets_view = "application_pets_view"; // Просмотр старых заявок
export const application_view_all = "application_view_all"; // Просмотр списка заявок на экспорт
export const application_view_org = "application_view_org"; // Просмотр списка заявок на экспорт
export const application_actions = "application_actions"; // Выполнение действий над заявкой
export const application_link = "application_link"; // Привязать к заявке сертификаты
export const application_link_all = "application_link_all"; // Привязать к заявке сертификаты

export const dictionary_view = "dictionary_view";
export const dictionary_add = "dictionary_add";
export const dictionary_edit = "dictionary_edit";
export const dictionary_delete = "dictionary_delete";
export const dictionary_show_service_info = "dictionary_show_service_info";

export const departments_view = "departments_view"; // Просмотр списка мест оформления своего ТУ.
export const departments_modify = "departments_modify"; // Изменение (редактирование, синхронизация, удаление) сведений о месте оформления своево ТУ.
export const departments_sync_all = "departments_sync_all"; // Синхронизация всех мест оформления с Цербером.
export const departments_view_all = "departments_view_all"; // Просмотр списка всех подразделений.

export const veterinarian_view = "veterinarian_view"; // Просмотр реестра ветеринаров
export const veterinarian_modify = "veterinarian_modify"; // Изменение (добавление, редактирование, удаление) записи в реестр ветеринара.

export const business_participant_view = "business_participant_view"; // Просмотр реестра контрагентов
export const business_participant_modify = "business_participant_modify"; // Изменение (добавление, редактирование, удаление) записи в реестр контрагентов.

export const document_type_view = "document_type_view"; // Согласованные формы сертификатов. Просмотр без ограничений
export const document_type_modify = "document_type_modify"; // Согласованные формы сертификатов. Изменение (добавление, редактирование, удаление)

export const wbr_conclusion_record_view = "wbr_conclusion_record_view"; // Просмотр учтенных заключения ВБР, без учета организации
export const wbr_conclusion_record_modify = "wbr_conclusion_record_modify"; // Создание и Редактирование учтенных заключения ВБР, с учетом организации
export const wbr_conclusion_record_withdrawn_org = "wbr_conclusion_record_withdrawn_org"; // Аннулирование учтенных заключения ВБР, с учетом организации

export const wbr_application_view_all = "wbr_application_view_all"; // Заявка на ВБР. Просмотр
export const wbr_application_view_org = "wbr_application_view_org"; // Заявка на ВБР. Просмотр в рамках организации
export const wbr_application_documents_actions = "wbr_application_documents_actions"; // Заявка на ВБР. Действия с договорами и счетами в рамках организации
export const wbr_application_actions = "wbr_application_actions"; // Заявка на ВБР. Работа с заявкой в рамках организации
export const wbr_application_reject = "wbr_application_reject"; // Заявка на ВБР. Отклонение в рамках организации

export const rabies_protocol_view_all = "rabies_protocol_view_all"; // Просмотр учетных экспортных сертификатов, без учета организации.
export const rabies_protocol_view_org = "rabies_protocol_view_org"; // Просмотр учётных экспортных сертификатов, с учетом организации.
export const rabies_protocol_actions = "rabies_protocol_actions"; // Создание учетного экспортного сертификата.

export const schedulers_view = "schedulers_view"; // Просмотр задач планировщика
