import { Fragment, createElement, useEffect, useState } from "react";

export const logLevel = {
    DEBUG: "DEBUG",
    ERROR: "ERROR",
};

let messageListener = null;

export const LogManager = ({ listeners, producers, maxStackSize = 32 }) => {
    const [messageStack, setMessageStack] = useState([]);

    const onMessageHandler = (capturedMessage) => {
        setMessageStack((currentMessageStack) => {
            capturedMessage.timestamp = Date.now();
            const newMessageStack = [...currentMessageStack, capturedMessage];
            if (newMessageStack.length > maxStackSize) {
                return newMessageStack.slice(1, newMessageStack.length);
            }
            return newMessageStack;
        });
    };

    useEffect(() => {
        messageListener = onMessageHandler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {listeners.map((listener, index) => (
                <Fragment key={index}>{listener.component(messageStack)}</Fragment>
            ))}
            {producers.map((producer, index) => (
                <Fragment key={index}>{createElement(producer)}</Fragment>
            ))}
        </>
    );
};

function handleMessage(level, value, data) {
    messageListener?.({ level, value, data });
}

const logger = {
    debug: (message, data = null) => handleMessage(logLevel.DEBUG, message, data),
    error: (message, data = null) => handleMessage(logLevel.ERROR, message, data),
};

export default logger;
