import { useTranslation } from "react-i18next";

import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useCreateMutation, useCustomMutation, useCustomQuery, useListQuery, useOneItemQuery, useUpdateMutation } from "../../utils/commonQueryBase";

export const API_URL = "/rest-api/dictionary";
export const DICTIONARY_STALE_TIME = Infinity;

export function useDictionaryOneQuery(baseUrl, id, options = {}) {
    const { t } = useTranslation();
    return useOneItemQuery(API_URL + baseUrl, id, {
        ...options,
        staleTime: DICTIONARY_STALE_TIME,
        onError: (error) => {
            options.onError?.(error);
            NotificationManager.error(
                error,
                options.dictionaryItemName
                    ? t("Произошла ошибка при загрузке {{dictionaryItemName}}.", { dictionaryItemName: options.dictionaryItemName })
                    : t("Произошла ошибка при загрузке позиции справочника.")
            );
        },
    });
}

export function useDictionaryListQuery(baseUrl, options = {}) {
    const { t } = useTranslation();
    return useCustomQuery(
        API_URL + baseUrl,
        { method: options.method ?? "GET", params: options.params, body: options.body },
        {
            placeholderData: [],
            ...options,
            staleTime: DICTIONARY_STALE_TIME,
            onError: (error) => {
                options.onError?.(error);
                NotificationManager.error(
                    error,
                    options.dictionaryName
                        ? t("Произошла ошибка при загрузке справочника {{dictionaryName}}.", { dictionaryName: options.dictionaryName })
                        : t("Произошла ошибка при загрузке справочника.")
                );
            },
        }
    );
}

export function useDictionaryPageQuery(baseUrl, searchParams, options = {}) {
    const { t } = useTranslation();
    return useListQuery(API_URL + baseUrl, searchParams, {
        placeholderData: [],
        enabled: !!searchParams,
        ...options,
        staleTime: DICTIONARY_STALE_TIME,
        onError: (error) => {
            options.onError?.(error);
            NotificationManager.error(
                error,
                options.dictionaryName
                    ? t("Произошла ошибка при загрузке страницы справочника {{dictionaryName}}.", { dictionaryName: options.dictionaryName })
                    : t("Произошла ошибка при загрузке страницы справочника")
            );
        },
    });
}

export function useDictionaryCreateMutation(baseUrl, options = {}) {
    const { t } = useTranslation();
    return useCreateMutation(API_URL + baseUrl, {
        ...options,
        onError: (error) => {
            options.onError?.(error);
            NotificationManager.error(
                error,
                options.dictionaryItemName
                    ? t("Произошла ошибка при сохранении {{dictionaryItemName}}.", { dictionaryItemName: options.dictionaryItemName })
                    : t("Произошла ошибка при сохранении позиции справочника.")
            );
        },
    });
}

export function useDictionaryUpdateMutation(baseUrl, options = {}) {
    const { t } = useTranslation();
    return useUpdateMutation(API_URL + baseUrl, {
        ...options,
        onError: (error) => {
            options.onError?.(error);
            NotificationManager.error(
                error,
                options.dictionaryItemName
                    ? t("Произошла ошибка при обновлении {{dictionaryItemName}}.", { dictionaryItemName: options.dictionaryItemName })
                    : t("Произошла ошибка при обновлении позиции справочника.")
            );
        },
    });
}

export function useDictionaryDeleteMutation(baseUrl, options = {}) {
    const { t } = useTranslation();

    const deleteRequest = (data) => ({
        type: "DELETE",
        url: `/${data}`,
    });

    return useCustomMutation(API_URL + baseUrl, deleteRequest, {
        ...options,
        onError: (error) => {
            options.onError?.(error);
            NotificationManager.error(
                error,
                options.dictionaryItemName
                    ? t("Произошла ошибка при удалении {{dictionaryItemName}}.", { dictionaryItemName: options.dictionaryItemName })
                    : t("Произошла ошибка при удалении позиции справочника.")
            );
        },
    });
}
