export const researchResultStatus = {
    STATUS_UNACCEPTABLE_AB_LEVEL: "STATUS_UNACCEPTABLE_AB_LEVEL", // Недопустимый уровень поствакцинальных антител
    STATUS_POSITIVE_V: "STATUS_POSITIVE_V", // Положительный поствакцинальный
    STATUS_POSITIVE_I: "STATUS_POSITIVE_I", // Положительный постинфекционный
    STATUS_FREE_FROM_POINTS: "STATUS_FREE_FROM_POINTS", // Не нормируется
    STATUS_POSITIVE: "STATUS_POSITIVE", // Положительный
    STATUS_NEGATIVE: "STATUS_NEGATIVE", // Отрицательный
    STATUS_DUBIOUS: "STATUS_DUBIOUS", // Сомнительный
    STATUS_NONE: "STATUS_NONE", // Не указано
};
