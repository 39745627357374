import React from "react";

const MenuToggler = ({ menuToggleHandler }) => {
    return (
        <button
            type="button"
            className="navbar-toggle menu-toggler pull-left btn-success"
            id="menu-toggler"
            data-target="#sidebar"
            onClick={menuToggleHandler}
        >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
        </button>
    );
};

export default MenuToggler;
