import { useTranslation } from "react-i18next";
import React from "react";

import { useWbrApplicationPage } from "../../api/WbrApplicationQueries";
import { WBR_APP_ROUTE } from "../../WbrApplicationRoutes";
import BlinkingFetchingContainer from "../../../../components/fetching/BlinkingFetchingContainer";
import PageContent from "../../../../components/page/PageContent";
import usePageParams from "../../../../utils/usePageParams";
import WbrApplicationPageableTable from "./components/WbrApplicationPageableTable";
import WbrApplicationSearchWrapper from "./components/WbrApplicationSearchWrapper";
import WideWrapper from "../../../../components/page/WideWrapper";

const WbrApplicationListPage = () => {
    const { t } = useTranslation();

    const title = WBR_APP_ROUTE.LIST.getTitle(t);
    const { pageParams, setPage, setSize, searchParams, setSearchParams } = usePageParams("WbrApplication");

    const wbrConclusionsQuery = useWbrApplicationPage(pageParams, searchParams);

    const changePageSize = (size) => {
        setSize(size);
        setPage(1);
    };

    return (
        <WbrApplicationSearchWrapper searchParams={searchParams} setSearchParams={setSearchParams}>
            {(searchForm, budgeList, searchToggleButton) => (
                <PageContent title={title} toolbar={searchToggleButton} secondLineInfo={budgeList}>
                    <WideWrapper>
                        {searchForm}
                        <BlinkingFetchingContainer isFetching={wbrConclusionsQuery.isFetching}>
                            <WbrApplicationPageableTable
                                page={wbrConclusionsQuery.data}
                                onChangePage={setPage}
                                onChangeSizePerPage={changePageSize}
                            />
                        </BlinkingFetchingContainer>
                    </WideWrapper>
                </PageContent>
            )}
        </WbrApplicationSearchWrapper>
    );
};

export default WbrApplicationListPage;
