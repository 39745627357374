import { useState } from "react";
import { useTranslation } from "react-i18next";

import { statusList } from "../../../../../../utils/constants/constants";
import { useCountriesListQuery } from "../../../../../../dictionary/api/countriesQueries";
import SearchForm, { getChangedAdvancedFieldsCount, searchSchemeFieldType } from "../../../../../../components/list/SearchForm";
import SearchFormBudgeList from "../../../../../../components/list/SearchFormBudgeList";
import SearchToggleButton from "../../../../../../components/list/SearchToggleButton";

const ECertImportListSearchWrapper = ({ searchParams, setSearchParams, children }) => {
    const { t } = useTranslation();
    const [isShowSearchPanel, setIsShowSearchPanel] = useState(false);

    const { data: countries } = useCountriesListQuery();

    const searchSchema = {
        fields: {
            shoulderNumber: { title: t("Номер сертификата") },
            countryList: {
                title: t("Страна"),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: countries,
                labelKey: "view",
                valueKey: "code",
                isObjectSelect: false,
            },
            approvedDateInterval: {
                title: t("Оформлен в период"),
                type: searchSchemeFieldType.dateRange,
            },
            statusList: {
                title: t("Статус"),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: statusList,
                labelKey: "name",
                valueKey: "type",
                isObjectSelect: false,
            },
        },
        quickSearch: [],
        advancedSearch: ["shoulderNumber", "countryList", "approvedDateInterval", "statusList"],
    };

    const budgeList = <SearchFormBudgeList schema={searchSchema} searchParams={searchParams} setSearchParams={setSearchParams} />;

    const searchToggleButton = (
        <SearchToggleButton
            active={isShowSearchPanel}
            onClick={() => setIsShowSearchPanel(!isShowSearchPanel)}
            advancedParamsCount={getChangedAdvancedFieldsCount(searchParams, searchSchema)}
        />
    );

    const searchForm = (
        <SearchForm
            schema={searchSchema}
            isExtendedPanelShow={isShowSearchPanel}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
        />
    );

    return children(searchForm, budgeList, searchToggleButton);
};

export default ECertImportListSearchWrapper;
