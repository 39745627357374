import { combineReducers } from "redux";

import notificationStore from "../store/notification/reducer";
import loadingStore from "../store/loading/reducer";
import infoStore from "../store/info/reducer";

export default combineReducers({
    notificationStore,
    loadingStore,
    infoStore,
});
