import React, { Fragment } from "react";

import { deleteIn, generatingId, InputFormGroup, SelectFormGroup, setIn } from "vetrf-ui";
import { useTranslation } from "react-i18next";

const AddressFormComponent = ({
    countries,
    address,
    addressPath,
    onChangeHandler,
    requestRegionsByCountry,
    errorHandler,
    showError,
    regions,
    fixCountry,
    isNotRequireAddress,
    isNotRequireEnglishAddress,
    fixLocation,
    addressHelp,
    additionalBlock,
}) => {
    const { t } = useTranslation("translation");

    const onChange = (value, path) => {
        let updatedAddress = setIn(address, path, value);

        if (path === "country") {
            value && value.guid && requestRegionsByCountry(value.guid);
            if (value === undefined || (value && address?.country && value.guid !== address.country.guid)) {
                updatedAddress = deleteIn(updatedAddress, "region");
            }
        }

        if (path !== "addressView" && path !== "enAddressView") {
            let view = [];
            let enView = [];
            if (updatedAddress?.country) {
                if (!updatedAddress.country.name && updatedAddress.country.guid) {
                    updatedAddress.country = countries.find((country) => country.guid === updatedAddress.country.guid);
                }
                view.push(updatedAddress.country.name);
                enView.push(updatedAddress.country.englishName);
            }
            if (updatedAddress?.region) {
                view.push(updatedAddress.region.view);
                enView.push(updatedAddress.region.englishName);
            }
            updatedAddress.addressView = view.join(", ");
            updatedAddress.enAddressView = enView.join(", ");
        }
        onChangeHandler(updatedAddress, addressPath);
    };

    const errorCustomHandler = (hasError, field) => {
        errorHandler(hasError, `${addressPath}.${field}`);
    };

    return (
        <Fragment>
            <SelectFormGroup
                labelText={t("Страна")}
                require
                clearable
                disabled={fixCountry || fixLocation}
                options={countries}
                valueKey="guid"
                labelKey="view"
                value={address?.country}
                onChange={onChange}
                field="country"
                placeholder={t("Выберите страну...")}
                showError={showError}
                errorHandler={errorCustomHandler}
                help=""
                prefixId={generatingId(addressPath)}
            />
            <SelectFormGroup
                labelText={t("Регион")}
                disabled={fixLocation}
                clearable
                options={regions && regions.filter((r) => address?.country && r.countryGuid === address.country.guid)}
                valueKey="guid"
                labelKey="selectView"
                value={address?.region}
                onChange={onChange}
                field="region"
                placeholder={t("Выберите регион...")}
                showError={showError}
                errorHandler={errorCustomHandler}
                prefixId={generatingId(addressPath)}
            />

            <InputFormGroup
                labelText={t("Адрес")}
                disabled={fixLocation}
                require={!isNotRequireAddress}
                onChange={onChange}
                field="addressView"
                value={address?.addressView}
                showError={showError}
                maxLength={200}
                errorHandler={errorCustomHandler}
                help={addressHelp ? addressHelp : ""}
                additionalBlock={additionalBlock && additionalBlock}
                prefixId={generatingId(addressPath)}
            />
            <InputFormGroup
                labelText={t("Адрес на английском языке")}
                disabled={fixLocation}
                require={!isNotRequireEnglishAddress}
                validateLatin
                onChange={onChange}
                field="enAddressView"
                value={address?.enAddressView}
                showError={showError}
                maxLength={200}
                errorHandler={errorCustomHandler}
                help={addressHelp ? addressHelp : ""}
                additionalBlock={additionalBlock && additionalBlock}
                prefixId={generatingId(addressPath)}
            />
        </Fragment>
    );
};

export default AddressFormComponent;
