import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../common/utils/constants";

export const { session } = createActions({
    SESSION: loadActions,
});
export const { userSettings } = createActions({
    USER_SETTINGS: {
        SAVING: asyncActions,
    },
});
