import { Fragment } from "react";

import WbrApplicationItemInfo from "./WbrApplicationItemInfo";

const WbrApplicationItems = ({ application, applicationForCompare }) => {
    return (
        <Fragment>
            {application.consignment.items.map((item, index) => (
                <WbrApplicationItemInfo key={index} index={index} application={application} compareToApplication={applicationForCompare} />
            ))}
        </Fragment>
    );
};

export default WbrApplicationItems;
