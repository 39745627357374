import React from "react";
import PropTypes from "prop-types";
import { filter, uniqueId } from "lodash";

import SubMenuItem from "./SubMenuItem";
import NavButton from "./NavButton";

const MenuItem = ({ userAuthorities, hasSomeAuthority, navListItem, isActive }) => {
    let submenuList = navListItem.submenuItems;
    if (hasSomeAuthority) {
        submenuList = filter(submenuList, (subMenuItem) => hasSomeAuthority(subMenuItem.authorities, userAuthorities));
    }
    return (
        <li className={isActive && isActive(navListItem)}>
            <NavButton id={navListItem.id} label={navListItem.label} icon={navListItem.icon} url={navListItem.url} />
            {submenuList.length > 0 && (
                <ul className="submenu can-scroll">
                    {submenuList.map((subMenuItem, i) => (
                        <SubMenuItem key={uniqueId()} subMenuItem={subMenuItem} />
                    ))}
                </ul>
            )}
        </li>
    );
};

MenuItem.propTypes = {
    navListItem: PropTypes.shape({
        authorities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        url: PropTypes.string,
        submenuItems: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                authorities: PropTypes.arrayOf(PropTypes.string),
                url: PropTypes.string,
                label: PropTypes.string,
            })
        ),
    }),
    userAuthorities: PropTypes.array,
    isActive: PropTypes.func,
    hasSomeAuthority: PropTypes.func,
};

export default MenuItem;
