import React, { Fragment } from "react";
import { Button, FormContainer, InputField, SelectField, SubHeader } from "vetrf-ui";
import { MAX_TEXT_LENGTH } from "../../../utils/constants/constants";
import { useTranslation } from "react-i18next";
import { useAuthorityDepartmentsListByOrgQuery } from "../../../dictionary/api/authorityDepartmentsQueries";
import { useSaveEnglishInfoMutation } from "../../api/userSettingsQuery";
import { useUserContext } from "../../../session/UserContextProvider";

const labelClassName = "col-sm-2 control-label no-padding-right";
const fieldClassName = "col-sm-10";

const EnglishUserInfoForm = ({ user, onClose }) => {
    const { t } = useTranslation("translation");
    const { data: departments } = useAuthorityDepartmentsListByOrgQuery();
    const useSaveEnglishInfo = useSaveEnglishInfoMutation();
    const { requestSession } = useUserContext();

    const onSubmitHandler = (data) => {
        useSaveEnglishInfo.mutate(data, {
            onSuccess: () => {
                onClose();
                requestSession();
            },
        });
    };

    return (
        <div className="row">
            <div className="col-xs-12">
                <SubHeader
                    header={t("Изменение информации о пользователе")}
                    underline={true}
                    description={
                        <Fragment>
                            {t(`Внимание! Изменение информации о пользователе приведёт к изменению информации`)}
                            <a href={"/veterinarians"}>&nbsp;{t("в реестре Ветеринарных врачей.")}</a>
                        </Fragment>
                    }
                />
                <FormContainer onSubmit={onSubmitHandler} defaultValues={user}>
                    <InputField
                        name="position"
                        required
                        maxLength={MAX_TEXT_LENGTH}
                        fieldClassName={fieldClassName}
                        labelClassName={labelClassName}
                        labelText={t("Должность на русском")}
                    />
                    <InputField
                        name="fioEnglish"
                        required
                        validateLatin
                        maxLength={MAX_TEXT_LENGTH}
                        fieldClassName={fieldClassName}
                        labelClassName={labelClassName}
                        labelText={t("Ф.И.О на английском")}
                    />
                    <InputField
                        name="positionEnglish"
                        required
                        validateLatin
                        maxLength={MAX_TEXT_LENGTH}
                        fieldClassName={fieldClassName}
                        labelClassName={labelClassName}
                        labelText={t("Должность на английском")}
                    />
                    <InputField
                        name="institution.englishName"
                        required
                        disabled
                        maxLength={MAX_TEXT_LENGTH}
                        fieldClassName={fieldClassName}
                        labelClassName={labelClassName}
                        labelText={t("Учреждение")}
                    />
                    <SelectField
                        name="department"
                        options={departments}
                        labelKey="view"
                        labelText={t("Место оформления")}
                        fieldClassName={fieldClassName}
                        labelClassName={labelClassName}
                    />
                    <div className="clearfix form-actions no-margin-bottom col-xs-12">
                        <Button id="cancel_btn" size="sm" onClick={onClose} text={t("Отмена")} />
                        <div className="pull-right">
                            <Button type="submit" id="save_btn" size="sm" color="success" text={t("Сохранить")} />
                        </div>
                    </div>
                </FormContainer>
            </div>
        </div>
    );
};

export default EnglishUserInfoForm;
