import { transformL10nECertStatus } from "../utils/transformers/transformECertStatus";

export function fillNotificationEvent(event, t) {
    fillApplicationStatus(event, t);
    return event;
}

function fillApplicationStatus(event, t) {
    if (Object.prototype.hasOwnProperty.call(event, "applicationStatus")) {
        event.applicationStatus = transformL10nECertStatus(event.applicationStatus, t);
    }
}
