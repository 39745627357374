export const transformEventType = (type, t) => {
    switch (type) {
        case "ALL":
            return t("Все");
        case "APPLICATION_STATUS_CHANGED":
            return t("Изменение статуса заявки");
        default:
            return t(type);
    }
};

export const SettingsAllow = {
    Disabled: "DISABLED",
    All: "ALL",
    Some: "SOME",
};

export const transformSettingsAllow = (allow, t) => {
    switch (allow) {
        case SettingsAllow.Disabled:
            return t("Отключены");
        case SettingsAllow.All:
            return t("Все");
        case SettingsAllow.Some:
            return t("Некоторые");
        default:
            return t(allow);
    }
};
