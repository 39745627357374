import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Button, Page,  deleteIn, setIn } from "vetrf-ui";
import { connect } from "react-redux";

import LogListSearchPanel from "./components/search/LogListSearchPanel";
import LogsListTable from "./components/LogsListTable";
import getLogFilterBadgeList from "../../common/search/ProccessingFilterBadgeList";
import { clearFilter, setFilter } from "../../../actions/filterLogAction";
import { requestCountries } from "../../../../dictionary/actions";
import { requestOperationLogList } from "../../../actions/listLogsAction";
import { useUserContext } from "../../../../session/UserContextProvider";

const title = "История операций";

const breadcrumbs = [
    {
        home: true,
        link: "/",
        text: "Главная",
    },
    {
        link: "/import/cert",
        text: "Импортные сертификаты",
    },
    {
        active: true,
        text: title,
    },
];

const getDefaultPageable = () => ({
    number: 0,
    page: 0,
    size: 10,
});

const initialState = {
    pageable: getDefaultPageable(),
    showSearchPanel: false,
    isFirstRender: true,
};

const LogsListPage = ({
    requestOperationLogList,
    list = [],
    filterObj,
    searchFormValue,
    setFilter,
    clearFilter,
    countries,
    requestCountries,
}) => {
    const [state, setState] = useState(initialState);
    const { user } = useUserContext();

    useEffect(() => {
        if (isEmpty(countries)) {
            requestCountries();
        }
        requestOperationLogList(searchFormValue, state.pageable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sizeChange = (value) => {
        const pageable = { ...state.pageable, size: value, page: 0 };
        requestOperationLogList(searchFormValue, pageable);
    };

    const pageChange = (value) => {
        const pageable = { ...state.pageable, page: value };
        requestOperationLogList(searchFormValue, pageable);
    };

    const toggleSearchPanel = () => {
        setState({
            ...state,
            showSearchPanel: !state.showSearchPanel,
        });
    };

    const resetSearchForm = () => {
        let newSearchFormValue = searchFormValue;
        Object.keys(filterObj).forEach((field) => {
            newSearchFormValue = deleteIn(newSearchFormValue, field);
            setFilter(newSearchFormValue);
        });
        clearFilter();
        requestOperationLogList({}, getDefaultPageable());
    };

    const handleChange = (value, field) => {
        let val;
        if (typeof value === "string" || value instanceof String) {
            // Игнорируем кириллицу
            val = value.replace(/[\u0250-\ue007]/g, "");
        } else {
            val = value;
        }

        const newSearchFormValue = setIn(searchFormValue, field, val);
        setFilter(newSearchFormValue);
        return newSearchFormValue;
    };

    const searchHandler = () => {
        requestOperationLogList(searchFormValue, getDefaultPageable());
    };

    const resetSearchFormFieldValue = (field) => {
        const newSearchFormValue = deleteIn(searchFormValue, field);
        setFilter(newSearchFormValue);
        return newSearchFormValue;
    };

    const resetSearchFormAndSearch = (field) => {
        return () => {
            const newSearchFormValue = resetSearchFormFieldValue(field);
            requestOperationLogList(newSearchFormValue, getDefaultPageable());
        };
    };

    const { showSearchPanel } = state;

    const filter = {
        icon: "chevron-up",
        text: "Свернуть",
    };
    const search = {
        icon: "search",
        text: "Расширенный поиск",
    };

    const searchBtn = showSearchPanel ? filter : search;

    const toolbar = user.id && (
        <div className="actions pull-right">
            <Button onClick={toggleSearchPanel} color="purple" size="sm" text={searchBtn.text} icon={searchBtn.icon} />
        </div>
    );

    return (
        <Page
            title={title}
            header={title}
            toolbar={toolbar}
            breadcrumbs={breadcrumbs}
            headerSecondLine={getLogFilterBadgeList(filterObj, resetSearchFormAndSearch)}
        >
            <div className="row">
                {showSearchPanel && (
                    <div className="col-xs-12">
                        <LogListSearchPanel
                            handleChange={handleChange}
                            search={searchHandler}
                            searchFormValue={searchFormValue}
                            resetSearchForm={resetSearchForm}
                        />
                    </div>
                )}
                <div className="col-xs-12 wraptablebig">
                    <LogsListTable
                        itemCount={list.totalElements}
                        list={list.content}
                        page={list.number}
                        sizePerPage={list.size}
                        handleChangePage={pageChange}
                        handleChangeSizePerPage={sizeChange}
                    />
                </div>
            </div>
        </Page>
    );
};

function mapStateToProps(state) {
    return {
        list: state.processing.logStore.list.logs,
        searchFormValue: state.processing.logStore.searchFormValue,
        filterObj: state.processing.logStore.filterObj,
        countries: state.dictionary.countries.countries,
    };
}

export default connect(mapStateToProps, {
    setFilter,
    clearFilter,
    requestCountries,
    requestOperationLogList,
})(LogsListPage);
