import React from "react";
import { Page } from "vetrf-ui";

import ECertImportListTable from "./components/ECertImportListTable";
import usePageParams from "../../../../utils/usePageParams";
import { useECertImportListPage } from "../../../api/ECertImportQueries";
import ECertImportListSearchWrapper from "./components/search/ECertImportListSearchWrapper";

const title = "Импортные сертификаты";

const breadcrumbs = [
    {
        home: true,
        link: "/",
        text: "Главная",
    },
    {
        active: true,
        text: title,
    },
];

const ECertImportListPage = () => {
    const { pageParams, setPage, setSize, searchParams, setSearchParams } = usePageParams("ECertImport");
    const { data } = useECertImportListPage(pageParams, searchParams, !!pageParams);
    const ecertImportList = data?.certs;

    /*const handleChange = (value, field) => {
        let val;
        if (typeof value === "string" || value instanceof String) {
            // Игнорируем кириллицу
            val = value.replace(/[\u0250-\ue007]/g, "");
        } else {
            val = value;
        }
        const newSearchFormValue = setIn(searchFormValue, field, val);
        setFilter(newSearchFormValue);
        return newSearchFormValue;
    };*/

    return (
        <ECertImportListSearchWrapper searchParams={searchParams} setSearchParams={setSearchParams}>
            {(searchForm, budgeList, searchToggleButton) => (
                <Page
                    title={title}
                    header={title}
                    breadcrumbs={breadcrumbs}
                    toolbar={<div className="pull-right">{searchToggleButton}</div>}
                    headerSecondLine={budgeList}
                >
                    <div className="row">
                        {searchForm}
                        <div className="col-xs-12 wraptablebig">
                            <ECertImportListTable
                                itemCount={ecertImportList?.totalElements}
                                list={ecertImportList?.content}
                                page={ecertImportList?.number}
                                sizePerPage={ecertImportList?.size}
                                handleChangePage={setPage}
                                handleChangeSizePerPage={setSize}
                            />
                        </div>
                    </div>
                </Page>
            )}
        </ECertImportListSearchWrapper>
    );
};

export default ECertImportListPage;
