import { Fragment } from "react";
import { Table } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import CountryFlag from "../../../../../../components/countryFlag/CountryFlag";

const WbrApplicationItemRawInfo = ({ item, index }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const tableRows = [
        {
            key: "name",
            title: t("Наименование сырья"),
            dataFormatter: (item) => item.name ?? unknown,
        },
        {
            key: "originCountry",
            title: t("Страна происхождения"),
            dataFormatter: (item) =>
                item.originCountry ? (
                    <Fragment>
                        <CountryFlag country={item.originCountry} nameField={"view"} /> ({item.originCountry.code})
                    </Fragment>
                ) : (
                    unknown
                ),
        },
        {
            key: "fishingLocation",
            title: t("Место происхождения"),
            dataFormatter: (item) => (
                <Fragment>
                    {item.fishingLocation?.name || item.fishingLocation?.faoCode ? (
                        <Fragment>
                            {t("Район промысла")}: {item.fishingLocation.faoCode}, {item.fishingLocation.name}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {t("Регион происхождения")}: {item.fishingLocation.originRegion}
                        </Fragment>
                    )}
                </Fragment>
            ),
        },
        {
            key: "enterpriseNumber",
            title: t("Регистрационный номер КЕС/CH/RK"),
            dataFormatter: (item) => item.producer.enterpriseNumber ?? unknown,
        },
        {
            key: "producerLocation",
            title: t("Наименование и адрес производителя"),
            dataFormatter: (item) => (
                <span>
                    {item.producer?.address?.addressView || unknown}
                    <br />
                    {item.producer?.address?.enAddressView && (
                        <Fragment>
                            <br />
                            {item.producer.address.enAddressView}
                        </Fragment>
                    )}
                </span>
            ),
        },
    ];

    return (
        <Fragment>
            <BlockHeader level={4}>
                {t("Происхождение сырья позиции")} №{index + 1}
            </BlockHeader>
            <div className="wraptablebig">
                <Table dataList={item.rawOriginInfos.sort((a, b) => a.name - b.name)} columns={tableRows} />
            </div>
        </Fragment>
    );
};

export default WbrApplicationItemRawInfo;
