import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";

const initialState = { dropdownOpen: false };

//TODO: Сделать универсальный компонент Dropdown
class LanguageDropdown extends Component {
    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = initialState;
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    handleClickOutside(e) {
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState(initialState);
        }
    }

    render() {
        const { chosenLanguage = "", languages = [], changeLanguage } = this.props;
        const getLangNameCss = (lang) => {
            return lang === "en" ? "us" : getLangName(lang);
        };
        const getLangName = (lang) => {
            return lang.split("-")[0].toLowerCase();
        };
        return (
            <li className={this.state.dropdownOpen ? "open" : ""}>
                <a id="language_dropdown" onClick={this.toggleDropdown} className={`dropdown-toggle`}>
                    <span className={`fi fi-${getLangNameCss(chosenLanguage)}`} />
                    &nbsp;
                    {getLangName(chosenLanguage).toUpperCase()}
                </a>
                <ul className="user-menu pull-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">
                    {languages.map((item, index) => (
                        <li key={`lang_${index}`}>
                            <a
                                id={`language_dropdown__btn_${index}`}
                                onClick={() => {
                                    if (changeLanguage && getLangName(chosenLanguage) !== item.code) {
                                        changeLanguage(item.code);
                                    }
                                    this.setState(initialState);
                                }}
                                className="decoration-none"
                            >
                                {item.name}&nbsp;
                                <span className={`fi fi-${getLangNameCss(item.code)} float-right`} />
                            </a>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }
}

LanguageDropdown.propTypes = {
    chosenLanguage: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.object),
    changeLanguage: PropTypes.func,
};

export default LanguageDropdown;
