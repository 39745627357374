import React from "react";
import PropTypes from "prop-types";

import Shortcuts from "./shortcuts/Shortcuts";
import NavList from "./nav-list/NavList";

const Sidebar = ({ navList, shortcuts, showMenu }) => (
    <div
        id="sidebar"
        className={"sidebar clearfix h-sidebar irena-sidebar navbar-collapse ace-save-state " + (showMenu ? "" : "collapse")}
        data-sidebar="true"
        data-sidebar-scroll="true"
        data-sidebar-hover="true"
    >
        <Shortcuts btnSuccess={shortcuts.btnSuccess} btnInfo={shortcuts.btnInfo} btnWarning={shortcuts.btnWarning} btnDanger={shortcuts.btnDanger} />
        <NavList
            userAuthorities={navList.userAuthorities}
            hasSomeAuthority={navList.hasSomeAuthority}
            navListItems={navList.navListItems}
            isActive={navList.isActive}
        />
    </div>
);

Sidebar.propTypes = {
    navList: PropTypes.shape({
        navListItem: PropTypes.shape({
            authorities: PropTypes.arrayOf(PropTypes.string),
            id: PropTypes.string,
            icon: PropTypes.string,
            label: PropTypes.string,
            submenuItems: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    authorities: PropTypes.arrayOf(PropTypes.string),
                    url: PropTypes.string,
                    label: PropTypes.string,
                })
            ),
        }),
        userAuthorities: PropTypes.array,
        isActive: PropTypes.func,
        hasSomeAuthority: PropTypes.func,
    }),
    shortcuts: PropTypes.shape({
        btnSuccess: PropTypes.shape({
            id: PropTypes.string,
            href: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
        }),
        btnInfo: PropTypes.shape({
            id: PropTypes.string,
            href: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
        }),
        btnWarning: PropTypes.shape({
            id: PropTypes.string,
            href: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
        }),
        btnDanger: PropTypes.shape({
            id: PropTypes.string,
            href: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
        }),
    }),
};

Sidebar.defaultProps = { navList: {}, shortcuts: {} };

export default Sidebar;
