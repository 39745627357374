import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";
import AlertWithIcon from "../../../../../components/page/AlertWithIcon";

const WbrApplicationStatusAlert = ({ application }) => {
    const { t } = useTranslation();

    const content = useMemo(() => {
        if (!application) return null;
        if (application.invoicePaymentInfo && application.invoicePaymentInfo.invoicePayment?.id && !application.invoicePaymentInfo.isConfirmed) {
            return (
                <Fragment>
                    {t("Экспортер передал сведения об оплате, подтвердить факт получения средств?")}{" "}
                    <Link to={WBR_APP_ROUTE.PAYMENT_VIEW.getFullPath(application.id)}>
                        <u>{t("Перейти к просмотру сведений об оплате и подтвердить")}</u>
                    </Link>
                </Fragment>
            );
        }
    }, [application, t]);

    return (
        <Fragment>
            {content && (
                <div className="row">
                    <AlertWithIcon messageType="info" icon="info">{content}</AlertWithIcon>
                </div>
            )}
        </Fragment>
    );
};

export default WbrApplicationStatusAlert;
