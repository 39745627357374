export const wbrApplicationActStatuses = {
    NOT_SENT: "NOT_SENT",
    SENT: "SENT",
    SIGNED_BY_EXPORTER: "SIGNED_BY_EXPORTER",
    REFUSAL_TO_SIGN: "REFUSAL_TO_SIGN",
    NOT_SIGNED_DUE_TIME: "NOT_SIGNED_DUE_TIME",
};

export const getWbrApplicationActStatuses = (t) => {
    return Object.keys(wbrApplicationActStatuses).map((key) => ({
        id: wbrApplicationActStatuses[key],
        view: getWbrApplicationActStatusView(wbrApplicationActStatuses[key], t),
    }));
};

export const getWbrApplicationActStatusView = (type, t) => {
    if (!type) return null;
    switch (type) {
        case wbrApplicationActStatuses.NOT_SENT:
            return t("Не отправлен");
        case wbrApplicationActStatuses.SENT:
            return t("Отправлен");
        case wbrApplicationActStatuses.SIGNED_BY_EXPORTER:
            return t("Подписан экспортером");
        case wbrApplicationActStatuses.REFUSAL_TO_SIGN:
            return t("Отказ в подписании, экспортер должен лично направить претензии в лабораторию)");
        case wbrApplicationActStatuses.NOT_SIGNED_DUE_TIME:
            return t("Не подписан в установленный срок (считается подписанным со стороны экспортера)");
        default:
            return null;
    }
};
