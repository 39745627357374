import { EditableTable, deleteIn, setIn } from "vetrf-ui";
import { Controller, useFormContext } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ERROR_TYPES = {
    DIRTY: "dirty",
    EMPTY: "empty",
    MAX: "max",
};

const FormEditableTable = ({ name, required, max = 100, columns, rowForm, defaultValue, onChange, ...props }) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    const { control, formState, clearErrors, resetField, getValues, setValue } = methods;
    const { errors } = formState;
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        resetField(name, []);
    }, [defaultValue, name, resetField]);

    const onChangeRowHandler = (row, index) => {
        const rows = getValues()?.[name] ?? [];
        if (index == null) {
            setValue(name, rows.concat(row));
        } else {
            const itemPath = `[${index}]`;
            setValue(name, setIn(rows, itemPath, row));
        }
        setIsDirty(false);
        clearErrors(name);
    };

    const onRemoveRowHandler = (index) => {
        const rows = getValues()?.[name] ?? [];
        const itemPath = `[${index}]`;
        setValue(name, deleteIn(rows, itemPath));
    };

    const onFormChangeHandler = (isDirty) => {
        setIsDirty(isDirty);
    };

    const onCloseHandler = () => {
        setIsDirty(false);
    };

    const rowFormField = <RowFormFieldWrapper onAnyChange={onFormChangeHandler}>{rowForm}</RowFormFieldWrapper>;
    const editableColumns = [
        ...columns.map((column, index) => ({ ...column, key: column.key ?? `col-${index}` })),
        {
            key: "actions",
            title: "",
            width: "90px",
            buttonFormatter: {
                deleteBtn: {
                    action: onRemoveRowHandler,
                    confirmHeaderText: t("Удаление записи"),
                    confirmBodyText: t("Вы действительно хотите удалить запись из таблицы?"),
                },
            },
        },
    ];

    return (
        <Fragment>
            <Controller
                control={control}
                name={name}
                rules={{
                    validate: {
                        [ERROR_TYPES.DIRTY]: () => !isDirty,
                        [ERROR_TYPES.EMPTY]: (values) => (!required && !values) || (!!values && !!values.length),
                        [ERROR_TYPES.MAX]: (values) => (!required && !values) || (!!values && values.length <= max),
                    },
                }}
                render={({ field }) => (
                    <span ref={field.ref}>
                        <EditableTable
                            {...props}
                            columns={editableColumns}
                            dataList={field.value ?? []}
                            rowFormField={() => rowFormField}
                            handleChangeRow={onChangeRowHandler}
                            customActionClose={onCloseHandler}
                            className={`table table-striped-edit table-bordered table-hover${errors[name] ? " outline-danger" : ""}`}
                        />
                    </span>
                )}
            />
            {errors[name]?.type === ERROR_TYPES.DIRTY && (
                <p className="help-block text-danger">
                    {t(
                        'В форме остались не добавленные в таблицу данные. Для продолжения нажмите на кнопку "Добавить" или кнопку "Отмена" для очистки формы.'
                    )}
                </p>
            )}
            {errors[name]?.type === ERROR_TYPES.EMPTY && (
                <p className="help-block text-danger">{t("В таблицу не добавлены данные. Для продолжения добавьте минимум одну запись.")}</p>
            )}
            {errors[name]?.type === ERROR_TYPES.MAX && (
                <p className="help-block text-danger">{t("Превышено максимальное количество записей")}</p>
            )}
        </Fragment>
    );
};

const RowFormFieldWrapper = ({ onAnyChange, children }) => {
    const methods = useFormContext();
    const { formState } = methods;
    const { isDirty } = formState;

    useEffect(() => {
        onAnyChange?.(isDirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    return children;
};

export default FormEditableTable;
