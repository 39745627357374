import React from "react";
import { Alert, Page } from "vetrf-ui";
import UserInfo from "./components/UserInfo";
import NotificationSettings from "./components/NotificationSettings";
import { useUserContext } from "../../session/UserContextProvider";
import EnglishUserInfo from "./components/EnglishUserInfo";

const UserSettingsPage = () => {
    const { user } = useUserContext();

    const title = "Настройки пользователя";
    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            link: "/",
            text: "Главная",
        },
        {
            text: title,
        },
    ];

    return (
        <Page title={title} breadcrumbs={breadcrumbs} header={title}>
            <div className="col-lg-offset-2 col-lg-8 col-md-12">
                {(!user.fioEnglish || !user.positionEnglish) && (
                    <Alert messageType="warning">
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;&nbsp;
                        <strong>Внимание!</strong> Следующая информация необходима для оформления экспортных ветеринарных сертификатов:
                        <ul>
                            {!user.fioEnglish && <li>Ф.И.О. пользователя на английском языке</li>}
                            {!user.positionEnglish && <li>должность пользователя на английском языке</li>}
                        </ul>
                    </Alert>
                )}
                <UserInfo user={user} />
                <EnglishUserInfo user={user} />
                <NotificationSettings user={user} />
            </div>
        </Page>
    );
};

export default UserSettingsPage;
