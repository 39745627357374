import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import { getWbrApplicationActStatusView, wbrApplicationActStatuses } from "../../../../../../utils/constants/wbr/wbr-application-act-statuses";
import { transformL10nECertStatus } from "../../../../../../utils/transformers/transformECertStatus";
import { viewRoute } from "../../../../../WbrConclusionRoutes";
import { WBR_APP_ROUTE } from "../../../../WbrApplicationRoutes";
import BlockHeader from "../../../../../../components/page/BlockHeader";
import BlockTable from "../../../../../../components/table/BlockTable";
import IconedLink from "../../../../../../components/page/IconedLink";
import {
    getWbrApplicationStatusView,
    isWbrApplicationRejected,
    wbrApplicationStatuses,
} from "../../../../../../utils/constants/wbr/wbr-application-statuses";

const otherReasonsCode = "10000";

const WbrApplicationRegistrationInfo = ({ application }) => {
    const { t } = useTranslation();
    const { statusChangeId, anotherStatusChangeId } = useParams();
    const unknown = getUnknownNormalText(t);

    const getApplicantView = (applicant) => {
        if (applicant) {
            return (
                <span>
                    {applicant.view} {applicant.inn ? `(${t("ИНН")}: ${applicant.inn})` : ""}
                    {applicant.phone && (
                        <Fragment>
                            <br />
                            {t("Телефон")}: {applicant.phone}{" "}
                        </Fragment>
                    )}
                    {applicant.email && (
                        <Fragment>
                            <br />
                            {t("E-mail")}: <IconedLink.Email href={applicant.email} />
                        </Fragment>
                    )}
                </span>
            );
        }
        return null;
    };

    const getConclusionView = (conclusion) => {
        if (conclusion) {
            return (
                <span>
                    {conclusion.referenceNumber} ({transformL10nECertStatus(conclusion.documentStatus, t)})&nbsp;
                    <Link to={viewRoute.replace(":id", conclusion.id)}>{t("Просмотр")}</Link>
                </span>
            );
        }
        return null;
    };

    const tableRows = [
        {
            header: {
                key: "referenceNumber",
                title: t("Номер заявки"),
            },
            dataFormatter: (item) => item.referenceNumber ?? unknown,
        },
        {
            header: {
                key: "statusHistory",
                title: t("История состояний заявки"),
            },
            dataFormatter: (item) => (
                <div className="status-history">
                    <div className="back-path" />
                    {[...item.statusHistory].reverse().map((statusChange, index, history) => (
                        <div key={index} className="status-history-item">
                            <i className="fa fa-circle" />{" "}
                            <span>
                                {getWbrApplicationStatusView(statusChange.status, t)} {t("от")} {statusChange.changeDate}
                            </span>{" "}
                            {statusChange.status === wbrApplicationStatuses.REPEAT_NEW &&
                                statusChange.id !== statusChangeId &&
                                history[index + 1]?.id !== anotherStatusChangeId && (
                                    <Link to={WBR_APP_ROUTE.HISTORY_VIEW.getFullPath(application.id, statusChange.id, history[index + 1]?.id)}>
                                        {t("Посмотреть изменения")}
                                    </Link>
                                )}
                            {statusChange.status === wbrApplicationStatuses.SENT_FOR_REVISION && statusChange.id !== statusChangeId && (
                                <Link to={WBR_APP_ROUTE.HISTORY_VIEW.getFullPath(application.id, statusChange.id)}>
                                    {t("Причины отправки на доработку")}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            ),
        },
    ];

    if (isWbrApplicationRejected(application)) {
        const rejectionChange = application.statusHistory[application.statusHistory.length - 1];
        tableRows.push({
            header: {
                key: "rejectionReasons",
                title: t("Причина отклонения заявки"),
            },
            dataFormatter: () => (
                <Fragment>
                    {rejectionChange.reasons?.length > 0 && (
                        <Fragment>
                            <span>{t("Причина отклонения заявки")}:</span>
                            <ul className="dot-list">
                                {[...rejectionChange.reasons]
                                    .sort((a, b) => (a.code > b.code ? -1 : b.code > a.code ? 1 : 0))
                                    .map((reason, index) => (
                                        <li key={index}>
                                            {reason.message}
                                            {reason.code === otherReasonsCode ? `: ${rejectionChange.customReason ?? unknown}` : ""}
                                        </li>
                                    ))}
                            </ul>
                        </Fragment>
                    )}
                </Fragment>
            ),
        });
    }

    tableRows.push(
        {
            header: {
                key: "receivedDateTime",
                title: t("Дата и время подачи заявки"),
            },
            dataFormatter: (item) => item.receiveDateTime ?? unknown,
        },
        {
            header: {
                key: "issuePlace",
                title: t("Место оформления"),
            },
            dataFormatter: (item) => item.laboratory?.name ?? unknown,
        },
        {
            header: {
                key: "applicant",
                title: t("Заявитель"),
            },
            dataFormatter: (item) => getApplicantView(item.applicant) ?? unknown,
        },
        {
            header: {
                key: "conclusion",
                title: t("Заключение"),
            },
            dataFormatter: (item) => getConclusionView(item.conclusion) ?? unknown,
        },
        {
            header: {
                key: "accesptableCertificateStatus",
                title: t("Акт выполненных работ"),
            },
            dataFormatter: (item) => getWbrApplicationActStatusView(item.acceptanceCertificateInfo?.status ?? wbrApplicationActStatuses.NOT_SENT, t),
        }
    );

    return (
        <Fragment>
            <BlockHeader>{t("Реквизиты заявки")}</BlockHeader>
            <BlockTable data={application} rows={tableRows} />
        </Fragment>
    );
};

export default WbrApplicationRegistrationInfo;
