import { notification } from "./actions";
import i18next from "i18next";
import { Button } from "vetrf-ui";
import { useUserContext } from "../../../session/UserContextProvider";
import { useTranslation } from "react-i18next";
import openLoginWindow from "../../../session/LoginTab";

export const notifyError =
    (error = {}, title, message) =>
    (dispatch) => {
        if (error.status === 403) {
            return dispatch(
                notification.add(
                    "Ошибка авторизации",
                    "Произошла ошибка при выполнении операции. Возможно завершилась сессия, попробуйте перезайти в систему.",
                    "error",
                    <EnterButton />
                )
            );
        } else {
            if (error.errorList) {
                const businessMessages = error.errorList
                    .map((item) => {
                        return item.code && i18next.t(item.code);
                    })
                    .join("\n");
                dispatch(notification.add(title, businessMessages ? businessMessages : message, "error"));
            } else {
                return dispatch(notification.add(title, message, "error"));
            }
        }
    };

export const notifySuccess = (title, message) => notification.add(title, message, "success");

export const clearAllNotifications = () => notification.clearAll();

export const sendCustomNotification = (customNotification) => (dispatch) => {
    dispatch(notification.custom(customNotification));
};

const EnterButton = () => {
    const { t } = useTranslation();
    const { requestSession } = useUserContext();

    const onEnterClick = () => {
        const msg = openLoginWindow();
        msg.then((user) => {
            requestSession();
        });
    };

    return (
        <Button
            color="danger"
            icon="sign-in"
            iconColor="white"
            onClick={onEnterClick}
            size="sm"
            text={t("Войти в систему")}
            className="margin-top-5"
        />
    );
};
