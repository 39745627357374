import { combineReducers } from "redux";

import catalogStore from "../store/catalog/reducer";
import animalSpeciesStore from "../store/animalSpecies/reducer";
import authorityDepartmentsStore from "../store/authorityDepartments/reducer";
import breedsStore from "../store/breeds/reducer";
import clausesStore from "../store/clauses/reducer";
import coloursStore from "../store/colours/reducer";
import countriesStore from "../store/countries/reducer";
import dateTimeFormatsStore from "../store/dateTimeFormats/reducer";
import diseasesStore from "../store/diseases/reducer";
import gendersStore from "../store/genders/reducer";
import identificationSystemsStore from "../store/identificationSystems/reducer";
import parasiteTreatmentsStore from "../store/parasiteTreatments/reducer";
import pharmaceuticalsStore from "../store/pharmaceuticals/reducer";
import processTypesStore from "../store/processTypes/reducer";
import productTypesStore from "../store/productTypes/reducer";
import productsStore from "../store/products/reducer";
import subProductsStore from "../store/subProducts/reducer";
import producerRegistryStore from "../store/producerRegistry/reducer";
import russianRegionsStore from "./regions/reducer";
import transportEquipmentTypesStore from "../store/transportEquipmentTypes/reducer";
import transportTypesStore from "./transportTypes/reducer";
import treatmentOperationsStore from "./treatmentOperations/reducer";
import unitMeasuresStore from "./unitMeasures/reducer";
import organizationListStore from "./organizationList/reducer";
import packageTypesStore from "./packageTypes/reducer";
import usersStore from "./users/reducer";
import officialVeterinariansStore from "./officialVeterinarians/reducer";
import businessParticipantsStore from "./businessParticipants/reducer";
import violationStore from "./violations/reducer";
import preconditionsActionStore from "./preconditionsAction/reducer";
import appStatusReasonStore from "./appStatusReason/reducer";
import appResearchResultStore from "./appResearchResults/reducer";
import appResearchDocumentStore from "./appResearchDocuments/reducer";
import documentTemplatesStore from "./documentTemplates/reducer";

export default combineReducers({
    catalogStore,
    animalSpeciesStore,
    authorityDepartmentsStore,
    breedsStore,
    clausesStore,
    coloursStore,
    countriesStore,
    dateTimeFormatsStore,
    diseasesStore,
    gendersStore,
    identificationSystemsStore,
    parasiteTreatmentsStore,
    pharmaceuticalsStore,
    productTypesStore,
    productsStore,
    subProductsStore,
    processTypesStore,
    producerRegistryStore,
    russianRegionsStore,
    transportEquipmentTypesStore,
    transportTypesStore,
    treatmentOperationsStore,
    unitMeasuresStore,
    organizationListStore,
    packageTypesStore,
    usersStore,
    officialVeterinariansStore,
    businessParticipantsStore,
    violationStore,
    preconditionsActionStore,
    appStatusReasonStore,
    appResearchResultStore,
    appResearchDocumentStore,
    documentTemplatesStore,
});
