import React from "react";
import "moment/locale/ru";
import moment from "moment";
import { Button, PaginationRow, Table } from "vetrf-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSystemNotifications } from "./SystemNotificationProvider";

const tableId = "document_type";

const SystemNotificationPageableTable = ({ page, onChangePage, onChangeSizePerPage }) => {
    const { i18n, t } = useTranslation("translation");
    moment.locale(i18n.language);

    const { markAsRead } = useSystemNotifications();

    const onNotificationClick = (item) => {
        markAsRead(item);
    };

    const onNotificationMarkAsRead = (item) => {
        markAsRead(item);
    };

    const columns = [
        {
            key: "qualifier",
            title: "",
            className: "padding-0",
            dataFormatter: (item, tableId, index) => (
                <div key={item.id} className={"notify-item" + (item.isRead ? "" : " unread")}>
                    <Link
                        to={`/${t(item.event.eventType.systemMessageTemplate.onClickLink, item.event)}`}
                        onClick={() => onNotificationClick(item)}
                        id={`notifications_table__item_${index}`}
                    >
                        <p>{t(item.event.eventType.systemMessageTemplate.body, item.event)}</p>
                        <div>
                            <span className="notify-time">{moment(item.event.appearTime).fromNow()}</span>
                        </div>
                    </Link>
                    {!item.isRead && (
                        <div className="float-right">
                            <Button
                                onClick={() => onNotificationMarkAsRead(item)}
                                id={`notify_item_mark_${index}`}
                                onlyIcon
                                icon="close"
                                iconSize={90}
                                tooltip={t("Пометить как прочитанное")}
                            />
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const pagination = (
        <PaginationRow
            id={tableId}
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            id={tableId}
            className="table table-striped table-bordered table-hover"
            hideHeader
            dataList={page?.content}
            paginationRow={pagination}
            columns={columns}
        />
    );
};
export default SystemNotificationPageableTable;
