import React from "react";
import { Button, Page } from "vetrf-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const crateBreadcrumbs = (centerBreadcrumbs) => {
    let breadcrumbs = [
        {
            link: "/",
            text: "Главная",
        },
    ];

    if (centerBreadcrumbs) breadcrumbs.push(...centerBreadcrumbs);
    breadcrumbs.push({
        text: "404",
    });

    return breadcrumbs;
};

const PageNotFound = ({ breadcrumbs = [] }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Page title="404" header="Страница не найдена" breadcrumbs={crateBreadcrumbs(breadcrumbs)}>
            <div className="center">
                <div className="whole404">
                    <section className="main">
                        <div className="four left"></div>
                        <div className="search"></div>
                        <div className="four right"></div>
                    </section>
                    <section className="steps">
                        <div className="step-left step-left-1"></div>
                        <div className="step-right step-right-1"></div>
                        <div className="step-left step-left-2"></div>
                        <div className="step-right step-right-2"></div>
                    </section>
                    <section className="not-found-text">
                        <h1>{t("Страница не найдена")}</h1>
                        <p>{t("Вероятно, указан несуществующий адрес, страница была удалена, перемещена или временно недоступна")}</p>
                        <Button text={t("На главную")} onClick={() => navigate("/")} className="margin-top-1" />
                    </section>
                </div>
            </div>
        </Page>
    );
};

export default PageNotFound;
