import { handleActions } from "redux-actions";
import { regions } from "./actions";
import { RUSSIA } from "../../../utils/constants/constants";

const initialState = {
    russianRegions: [],
    regions: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [regions.russian.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [regions.russian.load.success]: (state, { payload = {} }) => ({
            ...state,
            russianRegions: payload,
            regions: [...state.regions.filter((r) => r.countryGuid !== RUSSIA.guid), ...payload],
            loading: false,
        }),
        [regions.russian.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [regions.byCountry.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [regions.byCountry.load.success]: (state, { payload = {} }) => ({
            ...state,
            regions: [
                ...state.regions.filter((r) => !payload[0] || r.countryGuid !== payload[0].countryGuid),
                ...payload.filter((r) => r.englishName),
            ],
            loading: false,
        }),
        [regions.byCountry.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
    },
    initialState
);
