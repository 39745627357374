import { SubHeader } from "vetrf-ui";
import React from "react";

const BlockHeader = ({ level = 3, toolbar, children, onClick, ...props }) => {
    return (
        <div onClick={onClick} className={onClick ? "cursor-pointer" : ""}>
            <SubHeader
                underline
                level={level}
                header={children}
                className="smaller lighter blue inline"
                toolbar={toolbar}
                {...props}
            />
        </div>
    );
};
export default BlockHeader;
