import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Button, deleteIn, Form, InputFormGroup, SelectFormGroup, setIn } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { useCountriesListQuery } from "../../../api/countriesQueries";
import { useRegionsList } from "../../../api/regionsQueries";

const replaceInAddress = (item, field, searchString, replaceString) => {
    const resultString = (item[field] ? item[field] : "").replace(searchString, replaceString);
    return setIn(item, field, resultString);
};

const ForeignForm = ({ item, saving, onSave, onToggleModal }) => {
    const { t } = useTranslation();
    const [participant, setParticipant] = useState({});
    const [showError, setShowError] = useState(false);
    const invalidFields = useRef({});

    const { data: countries } = useCountriesListQuery();
    const { data: regions } = useRegionsList(participant.country?.guid);

    useEffect(() => {
        if (item) {
            setParticipant(item);
        }
    }, [item]);

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const onChangeHandler = (value, field) => {
        let newParticipant = setIn(participant, field, value);
        if (field === "country") {
            if (value?.guid) {
                newParticipant = replaceInAddress(newParticipant, "address", participant?.country ? participant.country.name : "", value.name);
                newParticipant = replaceInAddress(
                    newParticipant,
                    "englishAddress",
                    participant?.country ? participant.country.englishName : "",
                    value.englishName
                );
            }
        }
        setParticipant(newParticipant);
    };

    const onSaveHandler = () => {
        setShowError(true);
        setTimeout(() => {
            if (isEmpty(invalidFields.current)) {
                onSave(participant);
            }
        }, 300);
    };

    const isDisabledSave = isEmpty(participant);
    return (
        <div className="col-xs-12 no-padding">
            <Form name="russianForm">
                <InputFormGroup
                    labelText={t("Наименование")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.name}
                    field="name"
                    onChange={onChangeHandler}
                    help={"Название на русском языке, если указывается в сертификате"}
                />
                <SelectFormGroup
                    labelText={t("Страна")}
                    require
                    options={countries}
                    valueKey="guid"
                    labelKey="view"
                    value={participant.country}
                    onChange={onChangeHandler}
                    field="country"
                    placeholder={t("Выберите страну...")}
                    showError={showError}
                    errorHandler={errorHandler}
                    help=""
                />
                <SelectFormGroup
                    labelText={t("Регион")}
                    options={regions && regions.filter((r) => participant.country && r.countryGuid === participant.country.guid)}
                    valueKey="guid"
                    labelKey="selectView"
                    value={participant.region}
                    onChange={onChangeHandler}
                    field="region"
                    placeholder={t("Выберите регион...")}
                    showError={showError}
                    errorHandler={errorHandler}
                />
                <InputFormGroup
                    labelText={t("Адрес")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.address}
                    field="address"
                    onChange={onChangeHandler}
                />
                <InputFormGroup
                    labelText={t("Наименование на английском")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.englishName}
                    field="englishName"
                    onChange={onChangeHandler}
                    require
                    validateLatin
                    help={t("Укажите наименование на английском языке, так как оно будет печататься на сертификате")}
                />
                <InputFormGroup
                    labelText="Адрес на английском"
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.englishAddress}
                    field="englishAddress"
                    onChange={onChangeHandler}
                    require
                    validateLatin
                    help={t("Укажите адрес на английском языке, так как он будет печататься на сертификате")}
                />
            </Form>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="cancel_btn" size="sm" onClick={onToggleModal} text={t("Отмена")} />
                <div className="pull-right">
                    <Button
                        id="save_btn"
                        size="sm"
                        color="success"
                        onClick={onSaveHandler}
                        text={t("Сохранить")}
                        disabled={isDisabledSave || saving}
                    />
                </div>
            </div>
        </div>
    );
};

export default ForeignForm;
