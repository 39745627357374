import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";
import { packageTypes } from "./actions";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка упаковок";

export const requestPackageTypes = () =>
    createLoadAsyncAction(
        packageTypes,
        request,
        {
            type: "GET",
            url: `package-types/all`,
        },
        errorMessage
    );
