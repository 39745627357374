import { ORGANIZATION_LIST_ERROR, ORGANIZATION_LIST_LOAD, ORGANIZATION_LIST_RECEIVE } from "../constants/ActionTypes";

const initialState = {
    organizationList: [],
    error: null,
    loading: false,
};

const getOrganizationList = (action) => (action.organizationList && action.organizationList.length > 0 ? action.organizationList : []);

const organizationListLoad = (state, action) => ({ ...state, loading: true });

const organizationListReceive = (state, action) => ({
    ...state,
    error: null,
    organizationList: getOrganizationList(action),
    loading: false,
});

const organizationListLoadError = (state, action) => ({
    ...state,
    error: action.error,
    organizationList: [],
    loading: false,
});

export default function organizationList(state = initialState, action) {
    switch (action.type) {
        case ORGANIZATION_LIST_LOAD:
            return organizationListLoad(state, action);
        case ORGANIZATION_LIST_RECEIVE:
            return organizationListReceive(state, action);
        case ORGANIZATION_LIST_ERROR:
            return organizationListLoadError(state, action);
        default:
            return state;
    }
}
