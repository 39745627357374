import React from "react";
import { Icon, SubHeader } from "vetrf-ui";
import { Trans, useTranslation } from "react-i18next";

const CurrentVersionPanel = () => {
    const { t } = useTranslation("translation");

    return (
        <div>
            <SubHeader header={t("Последние обновления")} underline={true} />
            <p>
                <b>{t("Версия")} 1.27 </b>
            </p>
            <p>{t("Дата обновления:")} 22.07.2024</p>
            <div>
                <ul>
                    <li>{t("Реализован механизм регистрации протокола исследований на антитела к вирусу бешенства, оформленного на бумажном носителе.")}</li>
                    <li>
                        <Trans
                            defaults="Реализована возможность поиска и проверки подлинности зарегистрированного протокола для владельцев животных-компаньонов на публичной форме проверки ECert.Питомцы в разделе <0>«Протоколы исследований»</0>."
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            components={[<a key="0" href="/pets/protocol/search" />]}
                        />
                    </li>
                </ul>
            </div>
            <br />
            <a href={`https://help.vetrf.ru/wiki/%D0%9E%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%B0_eCert_%D0%B7%D0%B0_${new Date().getFullYear()}_%D0%B3%D0%BE%D0%B4`}>
                <Icon icon="refresh" /> {t("Список обновлений системы")}
            </a>
        </div>
    );
};

export default CurrentVersionPanel;
