import isString from "lodash/isString";
import moment from "moment";

/* eslint-disable */
export const startsWith = (srcString = "", searchString = "") => srcString.substr(0, searchString.length) === searchString;

export const isDateStringNowOrAfter = (date) => !date || moment(date, "DD.MM.YYYY").isAfter(moment()) || date === moment().format("DD.MM.YYYY");
export const isDateStringNowOrBefore = (date) => !date || moment(date, "DD.MM.YYYY").isBefore(moment());

export const isValidUrl = (string) => {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi;
    return urlRegex.test(string);
};

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

export const isExtendEmpty = (value) => value == null || Number.isNaN(value) || value === "" || value.trim?.() === "" || value?.length === 0;
export const isEmptyString = (value) => !value || !value.trim?.();

export const combineValidators =
    (...validators) =>
    (value) => {
        for (let i = 0; i < validators.length; i++) {
            const validator = validators[i];
            const result = validator(value);
            if (result !== true && isString(result)) {
                return result;
            }
        }
        return true;
    };
