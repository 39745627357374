import { regions } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";
const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка регионов";

export const requestRussianRegions = () =>
    createLoadAsyncAction(
        regions.russian,
        request,
        {
            type: "POST",
            url: "russianRegions",
        },
        errorMessage
    );

export const requestRegions = (guid) =>
    createLoadAsyncAction(
        regions.byCountry,
        request,
        {
            type: "POST",
            url: `regions?countryGuid=${guid}`,
        },
        errorMessage
    );
