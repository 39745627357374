import React from "react";
import PropTypes from "prop-types";

import LoginDropdown from "./LoginDropdown";
import LogoutDropdown from "./LogoutDropdown";

const AuthorizationDropdown = ({ user, avatarSrc, menu }) => {
    if (user && user.login) {
        return (
            <LogoutDropdown user={user} avatarSrc={avatarSrc}>
                {menu}
            </LogoutDropdown>
        );
    } else {
        return <LoginDropdown />;
    }
};

AuthorizationDropdown.propTypes = {
    user: PropTypes.shape({
        fio: PropTypes.string,
        login: PropTypes.string,
        institution: PropTypes.shape({
            shortName: PropTypes.string,
        }),
    }),
    avatarSrc: PropTypes.string,
    menu: PropTypes.node,
};

export default AuthorizationDropdown;
