import { handleActions } from "redux-actions";
import { loading } from "./actions";

const initialState = {
    show: false,
    loadingCount: 0,
};

export default handleActions(
    {
        [loading.start]: (state) => ({
            ...state,
            show: state.loadingCount >= 0,
            loadingCount: state.loadingCount + 1,
        }),
        [loading.complete]: (state) => ({
            ...state,
            show: state.loadingCount > 1,
            loadingCount: state.loadingCount - 1,
        }),
    },
    initialState
);
