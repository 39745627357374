import { Alert, Button, Icon } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React from "react";

import { useWbrApplicationCompleteRegistrationMutation } from "../../../api/WbrApplicationStatusQueries";
import { WBR_APP_PAYMENT_ACTIONS, WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";
import { WBR_CONCLUSION_ROUTE } from "../../../../WbrConclusionRoutes";
import TextButton from "../../../../../components/button/TextButton";
import WbrApplicationSendActConfirmationButton from "./toolbar/WbrApplicationSendActConfirmationButton";
import { isWbrAppInStatus, wbrApplicationStatuses } from "../../../../../utils/constants/wbr/wbr-application-statuses";

export const canCompleteRegistrationWbrApplicationDocuments = (application) => {
    if (!application) return false;
    if (!isWbrAppInStatus(application, [wbrApplicationStatuses.RESEARCH_FINISHED])) return false;
    const invoiceCheck = application?.invoiceInfo?.invoice.id;
    const actCheck = application?.acceptanceCertificateInfo?.acceptanceCertificate.id;
    const conclusionCheck = application?.conclusion;
    return invoiceCheck && actCheck && conclusionCheck;
};

const WbrApplicationClosePanel = ({ application }) => {
    const { t } = useTranslation();
    const completeRegistration = useWbrApplicationCompleteRegistrationMutation(application?.id);

    const onCompleteRegistrationhandler = () => {
        completeRegistration.mutate();
    };

    const getCheckIcon = (isChecked) => {
        return <Icon icon={isChecked ? "check-circle" : "times-circle"} color={isChecked ? "green" : "red"} />;
    };

    const invoiceCheck = application?.invoiceInfo?.invoice.id;
    const actCheck = application?.acceptanceCertificateInfo?.acceptanceCertificate.id;
    const conclusionCheck = application?.conclusion;
    const completeEnabled = canCompleteRegistrationWbrApplicationDocuments(application);
    if (!application) return null;
    return (
        <div className="row">
            <Alert>
                <h4>{t("Регистрация закрывающих документов:")}</h4>
                <div>
                    {getCheckIcon(invoiceCheck)}{" "}
                    <TextButton
                        id="registration_panel__request_payment_btn"
                        href={!invoiceCheck && WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(application.id, WBR_APP_PAYMENT_ACTIONS.EDIT)}
                    >
                        {t("Приложить счет")}
                    </TextButton>
                </div>
                <div>
                    {getCheckIcon(actCheck)}{" "}
                    <WbrApplicationSendActConfirmationButton
                        applicationId={application.id}
                        button={(onClick) => (
                            <TextButton id="registration_panel__send_act_btn" onClick={!actCheck && onClick}>
                                {t("Приложить акт выполненных работ")}
                            </TextButton>
                        )}
                    />
                </div>
                <div>
                    {getCheckIcon(conclusionCheck)} {t("Оформить")}{" "}
                    <TextButton
                        id="registration_panel__issue_conclusion_of_conformity_btn"
                        href={!conclusionCheck && WBR_CONCLUSION_ROUTE.CREATE_FROM_APPLICATION.getFullPath(application.id)}
                    >
                        {t("заключение о соответствии")}
                    </TextButton>
                    {` ${t("или")} `}
                    <TextButton
                        id="registration_panel__issue_conclusion_of_nonconformity_btn"
                        href={!conclusionCheck && WBR_CONCLUSION_ROUTE.CREATE_FROM_APPLICATION.getFullPath(application.id)}
                        state={{ complianceConclusion: { decision: "NOT_MEET_REQUIREMENTS" } }}
                    >
                        {t("заключение о НЕсоответствии")}
                    </TextButton>
                </div>
                <center>
                    <Button
                        id="registration_panel__finish_registration_btn"
                        text={t("Завершить")}
                        size="sm"
                        color="info"
                        disabled={!completeEnabled}
                        tooltip={!completeEnabled && t("Не выполнены все пункты")}
                        onClick={onCompleteRegistrationhandler}
                    />
                </center>
            </Alert>
        </div>
    );
};

export default WbrApplicationClosePanel;
