import React, { Fragment, useEffect, useState } from "react";
import { Button, CheckboxFormGroup, SubHeader, Table } from "vetrf-ui";
import { withTranslation } from "react-i18next";

import { useEventTypesQuery } from "../../../system-notification/systemNotificationQuery";
import { useUserNotificationSettingsMutation } from "../../api/userSettingsQuery";
import { SettingsAllow, transformEventType } from "../../../utils/transformers/transformEventType";

const ALL_EVENTS = "ALL";

const NotificationSettingsForm = ({ userNotificationSettings, onClose, t }) => {
    const [notificationSettings, setNotificationSettings] = useState(userNotificationSettings);
    const [eventTypes, setEventTypes] = useState([]);
    const notificationSettingsMutation = useUserNotificationSettingsMutation();
    const { data: loadedEventTypes } = useEventTypesQuery();

    useEffect(() => {
        if (!loadedEventTypes) return;
        setEventTypes(loadedEventTypes);
        const missingRestrictions = loadedEventTypes
            .filter((x) => !notificationSettings.userEventTypeRestrictions.map((r) => r.eventTypeEnum).includes(x))
            .map((x) => ({
                eventTypeEnum: x,
                allowSystem: true,
                allowEmail: true,
            }));
        setNotificationSettings((notificationSettings) => ({
            ...notificationSettings,
            userEventTypeRestrictions: [...notificationSettings.userEventTypeRestrictions, ...missingRestrictions].map((restriction) => ({
                ...restriction,
                allowSystem: notificationSettings.allowSystem !== "DISABLED" ? restriction.allowSystem : false,
                allowEmail: notificationSettings.allowEmail !== "DISABLED" ? restriction.allowEmail : false,
            })),
        }));
    }, [loadedEventTypes]);

    const isAllowEvent = (event, type) => {
        if (event === ALL_EVENTS) return notificationSettings[type] === SettingsAllow.All;
        else return notificationSettings.userEventTypeRestrictions.find((x) => x.eventTypeEnum === event)?.[type];
    };

    const onAllowToggle = (event, type) => {
        let settings = {
            ...notificationSettings,
            userEventTypeRestrictions: [...notificationSettings.userEventTypeRestrictions],
        };
        if (event === ALL_EVENTS) {
            if (settings[type] === SettingsAllow.All) settings[type] = SettingsAllow.Disabled;
            else settings[type] = SettingsAllow.All;
            settings.userEventTypeRestrictions.forEach((x) => (x[type] = settings[type] === SettingsAllow.All));
        } else {
            let restriction = settings.userEventTypeRestrictions.find((x) => x.eventTypeEnum === event);
            if (restriction) restriction[type] = !restriction[type];
            settings[type] = settings.userEventTypeRestrictions.every((x) => x[type] === true) ? SettingsAllow.All : SettingsAllow.Some;
        }
        setNotificationSettings(settings);
    };

    const onSaveSettings = () => {
        notificationSettingsMutation.mutate(notificationSettings, {
            onSuccess: () => onClose(),
        });
    };

    const columns = [
        {
            key: "name",
            title: t("Уведомление"),
            dataFormatter: (item) => transformEventType(item, t),
        },
        {
            key: "system",
            title: t("В интерфейсе"),
            width: "20%",
            className: "align-center",
            // TODO: возможность отключения текста у CheckboxFormGroup (чтобы чек-бокс был по центру своей группы)
            dataFormatter: (item) => (
                <CheckboxFormGroup value={isAllowEvent(item, "allowSystem")} onChange={() => onAllowToggle(item, "allowSystem")} />
            ),
        },
        {
            key: "email",
            title: t("По электронной почте"),
            width: "20%",
            className: "align-center",
            dataFormatter: (item) => (
                <CheckboxFormGroup value={isAllowEvent(item, "allowEmail")} onChange={() => onAllowToggle(item, "allowEmail")} />
            ),
        },
    ];

    const notificationsRows = eventTypes.length > 1 ? [ALL_EVENTS, ...eventTypes] : eventTypes;
    return (
        <Fragment>
            <SubHeader header={t("Изменение уведомлений")} underline={true} />
            <Table className="table table-striped table-bordered table-hover" dataList={notificationsRows} columns={columns} />
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="cancel_btn" size="sm" onClick={onClose} text={t("Отмена")} />
                <div className="pull-right">
                    <Button id="save_btn" size="sm" color="success" onClick={onSaveSettings} text={t("Сохранить")} disabled={false} />
                </div>
            </div>
        </Fragment>
    );
};

export default withTranslation("translations")(NotificationSettingsForm);
