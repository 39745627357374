import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../../components/page/BlockHeader";
import CompareTable from "../../../../../../../components/table/CompareTable";

const AdditionalContractDocumentsTable = ({ documents, documentsForCompare }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const tableRows = [
        {
            key: "issueNumber",
            title: t("Номер и дата документа"),
            dataFormatter: (item) => (
                <Fragment>
                    {item.issueNumber ?? unknown}
                    {item.issueDate ? ` ${t("от")} ${item.issueDate}` : ""}
                </Fragment>
            ),
            compareData: (item) => [item.issueNumber, item.issueDate].join("-"),
        },
        {
            key: "type",
            title: t("Тип документа"),
            dataFormatter: (item) => (
                <Fragment>
                    {t("Тип документа")}: {item.name ?? unknown}
                </Fragment>
            ),
            compareData: (item) => item.name,
        },
        {
            key: "parties",
            title: t("Наименования сторон по документу"),
            dataFormatter: (item) => item.attributes?.parties ?? unknown,
        },
        {
            key: "subject",
            title: t("Предмет документа"),
            dataFormatter: (item) => item.attributes?.subject ?? unknown,
        },
    ];

    return (
        <Fragment>
            <BlockHeader level={4}>{t("Дополнительная документация к контракту")}</BlockHeader>
            <div className="wraptablebig">
                <CompareTable dataList={documents ?? []} dataListToCompare={documentsForCompare} columns={tableRows} />
            </div>
        </Fragment>
    );
};

export default AdditionalContractDocumentsTable;
