import { useEffect, useRef, useState } from "react";

export default function useIntersectionObserver(observerOptions, freezeOnceVisible) {
    const elementRef = useRef();
    const [entry, setEntry] = useState({});

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    useEffect(() => {
        const node = elementRef.current;
        const hasIOSupport = !!window.IntersectionObserver;
        if (!hasIOSupport || frozen || !node) return;

        const observer = new IntersectionObserver(([entry]) => setEntry(entry), observerOptions);

        observer.observe(elementRef.current);

        return () => observer.disconnect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef, observerOptions, freezeOnceVisible]);

    return [elementRef, entry];
}
