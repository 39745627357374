import React from "react";
import PropTypes from "prop-types";

const Footer = ({ children }) => (
    <div className="footer">
        <div className="footer-inner">
            <div className="footer-content">{children}</div>
        </div>
    </div>
);

Footer.propTypes = {
    children: PropTypes.node,
};

export default Footer;
