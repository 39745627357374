import React from "react";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { getUnknownCursiveText } from "../../../../utils/constants/constants";

const WbrConclusionTransportView = ({ conclusion }) => {
    const { t } = useTranslation("translation");
    const unknown = getUnknownCursiveText(t);
    const rows = [];
    const transportMovements = conclusion?.consignment?.transportMovements;
    transportMovements &&
        transportMovements
            .slice()
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .forEach((transportMov) =>
                rows.push({
                    header: {
                        key: `transportMovement[${transportMov.orderNumber}]`,
                        title:
                            transportMovements.length > 1
                                ? `${t("Транспортное средство")} №${transportMov.orderNumber + 1}`
                                : t("Транспортное средство"),
                    },
                    dataFormatter: () => `${transportMov?.transportType?.view || unknown}: ${transportMov?.transport?.number || unknown}`,
                })
            );

    rows.push({
        header: {
            key: "transportEquipments",
            title: t("Оборудование для транспортировки"),
        },
        dataFormatter: (item) => {
            return item?.transportEquipments?.length > 0
                ? item.transportEquipments
                      .map(
                          (c) =>
                              `${c.type && c.type.name}${c.number ? `: ${c.number}` : ""}${c.affixedSeal ? `, ${t("пломба")}: ${c.affixedSeal}` : ""}`
                      )
                      .join(", ")
                : unknown;
        },
    });

    return (
        <div className={"row"}>
            <SubHeader header={t("Сведения о транспорте")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__transport"
                    itemList={conclusion?.consignment ? [{ item: conclusion?.consignment }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={rows}
                />
            </div>
        </div>
    );
};

export default WbrConclusionTransportView;
