import React, { Fragment } from "react";
import { ConfirmActionButton } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { useDeleteDepartmentMutation } from "../../api/departmentQuery";

const DeleteConfirmButton = ({ department, buttonId, onlyIcon, onDeleted }) => {
    const { t } = useTranslation();
    const deleteMutation = useDeleteDepartmentMutation();
    return (
        <ConfirmActionButton
            onConfirm={() => {
                deleteMutation.mutate(department, {
                    onSuccess: (data) => onDeleted?.(data),
                });
            }}
            id={buttonId}
            isOnlyIconButton={onlyIcon}
            icon="trash"
            iconColor={onlyIcon ? "red" : ""}
            iconSize={onlyIcon ? 150 : 100}
            buttonSize="sm"
            buttonColor="danger"
            tooltip={onlyIcon && t("Удалить")}
            buttonText={t("Удалить")}
            confirmBodyContent={
                <Fragment>
                    <span>{t("Вы уверены, что хотите удалить сведения о месте оформления?")}</span>
                </Fragment>
            }
            confirmHeaderText={t("Удалить сведения о месте оформления")}
            confirmBtnText={t("Удалить")}
            confirmBtnColor="danger"
        />
    );
};

export default DeleteConfirmButton;
