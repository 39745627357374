import { ModalDialog } from "vetrf-ui";
import { Trans, useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { useIntervalAppInfo } from "./api/api-info";

const AppUpdateChecker = ({ delay = 5 * 60 }) => {
    const { t } = useTranslation();
    const [showWarning, setShowWarning] = useState(false);
    const { data: info } = useIntervalAppInfo(delay, !showWarning);
    const currentVersionRef = useRef();

    useEffect(() => {
        if (info) {
            if (currentVersionRef.current && currentVersionRef.current !== info.version) {
                setShowWarning(true);
            }
            currentVersionRef.current = info.version;
        }
    }, [info]);

    if (!showWarning) return null;
    return (
        <ModalDialog
            header={t("Необходима перезагрузка")}
            children={
                <Fragment>
                    <Trans
                        defaults="Необходимо перезагрузить страницу браузера в связи с обновлением системы до <0>версии {{version}}</0>."
                        values={{ version: currentVersionRef.current }}
                        components={[
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                                key={0}
                                target="_blank"
                                rel="noreferrer"
                                href={`https://help.vetrf.ru/wiki/%D0%9E%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%B0_eCert_%D0%B7%D0%B0_${new Date().getFullYear()}_%D0%B3%D0%BE%D0%B4`}
                            />,
                        ]}
                    />
                    <br />
                    <br />
                    {t("Обращаем внимание, что несохраненные данные будут потеряны.")}
                    <br />
                    {t("Продолжение работы без перезагрузки может привести к непредвиденным ошибкам.")}
                </Fragment>
            }
            confirmBtn={{
                action: () => window.location.reload(),
                text: "Перезагрузить",
                color: "success",
                icon: "refresh",
            }}
            cancelBtn={{
                text: "Позже",
            }}
        />
    );
};

export default AppUpdateChecker;
