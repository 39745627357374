import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ProfileTable, SubHeader } from "vetrf-ui";

const PvkpView = (props) => {
    const commonRows = [
        {
            header: {
                key: "guid",
                title: "Идентификатор",
                width: "12%",
            },
            dataFormatter: (item) => (item.guid ? item.guid : ""),
        },
        {
            header: {
                key: "name",
                title: "Наименование",
                width: "12%",
            },
            dataFormatter: (item) => (item.name ? item.name : ""),
        },
    ];

    const { pvkp } = props;

    return (
        <div>
            <SubHeader header={"Пункт пропуска"} />
            <ProfileTable
                itemList={pvkp ? [{ item: pvkp }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={commonRows}
            />
        </div>
    );
};

PvkpView.propTypes = {
    pvkp: PropTypes.object,
    headerName: PropTypes.string,
};

export default withTranslation("translations")(PvkpView);
