import React, { Fragment, useState } from "react";
import { Button, ProfileTable, SubHeader } from "vetrf-ui";

import { useTranslation } from "react-i18next";
import { unknownNormalText } from "../../../utils/constants/constants";
import EnglishUserInfoForm from "./EnglishUserInfoForm";

const EnglishUserInfo = ({ user }) => {
    const { t } = useTranslation();
    const [showFullSettings, setShowFullSettings] = useState(false);

    const rows = [
        {
            header: {
                key: "position",
                title: t("Должность на русском"),
            },
            dataFormatter: (item) => item.position || unknownNormalText,
            getCellClass: (item) => !item.position && "warning",
        },
        {
            header: {
                key: "fioEnglish",
                title: t("Ф.И.О на английском"),
            },
            dataFormatter: (item) => item.fioEnglish || unknownNormalText,
            getCellClass: (item) => !item.fioEnglish && "warning",
        },
        {
            header: {
                key: "positionEnglish",
                title: t("Должность на английском"),
            },
            dataFormatter: (item) => item.positionEnglish || unknownNormalText,
            getCellClass: (item) => !item.positionEnglish && "warning",
        },
        {
            header: {
                key: "institutionEnglishName",
                title: t("Учреждение на английском"),
            },
            dataFormatter: (item) => (item.institution && item.institution.englishName) || unknownNormalText,
            getCellClass: (item) => !(item.institution && item.institution.englishName) && "warning",
        },
        {
            header: {
                key: "department",
                title: t("Место оформления"),
            },
            dataFormatter: (item) => (item.department && item.department.view) || unknownNormalText,
            getCellClass: (item) => !(item.department && item.department.address?.enAddressView) && "warning",
        },
    ];

    const toolbar = !showFullSettings && (
        <Button id="edit_btn" text={t("Редактировать")} onClick={() => setShowFullSettings(true)} size="minier" color="info" icon="pencil" />
    );

    return (
        <Fragment>
            <SubHeader header={t("Информация о пользователе")} toolbar={toolbar} />
            <ProfileTable itemList={user ? [{ item: user }] : [{}]} tableClassName="table color-table-border font-weight-normal" rows={rows} />
            {showFullSettings && (
                <EnglishUserInfoForm user={user} onClose={() => setShowFullSettings(false)} />
            )}
        </Fragment>
    );
};

export default EnglishUserInfo;
