import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SubMenuItem = ({ subMenuItem }) => (
    <li className="hover">
        <Link id={subMenuItem.id} to={subMenuItem.url}>
            <i className="menu-icon fa fa-angle-down" />
            {subMenuItem.label}
        </Link>
    </li>
);

SubMenuItem.propTypes = {
    subMenuItem: PropTypes.shape({
        authorities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        url: PropTypes.string,
        label: PropTypes.string,
    }),
};

export default SubMenuItem;
