import React, { Fragment } from "react";
import { Button, SubHeader, Page, Icon } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import CurrentVersionPanel from "../../version/containers/CurrentVersionPanel";
import ECertSearchPanel from "./inner/ECertSearchPanel";
import openLoginWindow from "../../session/LoginTab";
import RecordSearchPanel from "./inner/RecordSearchPanel";
import { useUserContext } from "../../session/UserContextProvider";

const PubCheckAuthenticityPub = () => {
    const { t } = useTranslation("translation");
    const { user, requestSession } = useUserContext();

    const handleLogIn = () => {
        const msg = openLoginWindow();
        msg.then(() => {
            requestSession();
        });
    };

    const title = t("Проверка подлинности сертификата");
    const titleMainInput = t("Система экспортной ветеринарной сертификации ECert");

    const anonymous = (
        <Fragment>
            <p>{t("Если Вы зарегистрированы в системе ECert и у Вас есть реквизиты доступа, Вы можете начать работу с системой прямо сейчас.")}</p>
            <div className={"text-align-center margin-top-2"}>
                <Button id="home_page__btn_sign_in" text={t("Войти в систему")} onClick={handleLogIn} className="btn btn-success btn-lg btn-center" />
            </div>
        </Fragment>
    );

    const all_users = (
        <Fragment>
            <SubHeader header={t("Справочная информация")} underline />
            <p>{t("Информация для владельцев животных-компаньонов")}</p>
            <a href="/pets">
                <Icon icon="paw" /> {t("Подать заявку на сертификат онлайн")}
            </a>
            <br />
            <a href="/pets/protocol/search">
                <Icon icon="flask" /> {t("Проверить подлинность протокола исследований на титры антител к вирусу бешенства")}
            </a>

            <p className="margin-top-3">
                {t("Подробные инструкции по работе в системе Вы можете найти в")}{" "}
                <a href={"http://help.vetrf.ru/wiki/Автоматизированная_система_ECert"}>{t("Справочной системе")}</a>.{" "}
            </p>

            <p>
                {t("По техническим вопросам, связанным с работой системы ECert, обращаться по электронной почте на адрес")}
                &nbsp;
                <a href="mailto:#">ecert@fsvps.ru</a>, {t("а также по телефону горячей линии")} <a href="tel:+7(4922)52-99-29">+7 (4922) 52-99-29</a>.
            </p>
        </Fragment>
    );

    return (
        <div className="margin-top-1">
            <Page title={title}>
                <div className="col-lg-7 col-md-12 col-xs-12">
                    <SubHeader header={titleMainInput} underline={true} className={"sub-header_big"} />
                    <div className="main-font">
                        <div className={"col-lg-12 text-align-justify margin-top-2"}>
                            {!user.id && anonymous}
                            {all_users}
                        </div>
                        <div className={"col-lg-12 text-align-justify margin-top-2"}>
                            <CurrentVersionPanel />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-xs-12">
                    <SubHeader header={title} underline={true} className={"sub-header_big"} />
                    <div className="col-lg-12 col-md-5 col-xs-12 col-lg-offset-0 col-xs-offset-0 col-md-offset-1 margin-top-2">
                        <RecordSearchPanel />
                    </div>
                    <div className="col-lg-12  col-md-5 col-xs-12  col-lg-offset-0 col-xs-offset-0 col-md-offset-1 margin-top-2">
                        <ECertSearchPanel />
                    </div>
                </div>
            </Page>
        </div>
    );
};

export default PubCheckAuthenticityPub;
