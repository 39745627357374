import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../../utils/constants/constants";
import { getWbrApplicationFileDownloadUrl } from "../../../../../api/WbrApplicationQueries";
import BlockHeader from "../../../../../../../components/page/BlockHeader";
import CompareTable from "../../../../../../../components/table/CompareTable";
import IconedLink from "../../../../../../../components/page/IconedLink";
import ItemsList from "../../../../../../../components/list/ItemsList";

const JournalTable = ({ applicationId, documents, documentsForCompare }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const tableRows = [
        {
            key: "issueNumber",
            title: t("Номер журнала"),
            dataFormatter: (item) => item.issueNumber ?? unknown,
        },
        {
            key: "name",
            title: t("Наименование журнала"),
            dataFormatter: (item) => item.name ?? unknown,
        },
        {
            key: "files",
            title: t("Файлы"),
            dataFormatter: (item) => (
                <ItemsList
                    items={item.files?.map((file) => (
                        <IconedLink.Download file={file} href={getWbrApplicationFileDownloadUrl(applicationId, file.id)} />
                    ))}
                    empty={unknown}
                />
            ),
        },
    ];

    return (
        <Fragment>
            <BlockHeader level={4}>{t("Журналы продукции")}</BlockHeader>
            <div className="wraptablebig">
                <CompareTable dataList={documents ?? []} dataListToCompare={documentsForCompare} columns={tableRows} />
            </div>
        </Fragment>
    );
};

export default JournalTable;
