import React, { Fragment, useState } from "react";
import { Button, ProfileTable, SubHeader } from "vetrf-ui";
import { withTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../utils/constants/constants";
import NotificationSettingsForm from "./NotificationSettingsForm";
import { useUserNotificationSettings } from "../../api/userSettingsQuery";
import { SettingsAllow, transformSettingsAllow } from "../../../utils/transformers/transformEventType";


const NotificationSettings = ({ user, t }) => {
    const [showFullSettings, setShowFullSettings] = useState(false);
    const userNotificationSettingsQuery = useUserNotificationSettings();
    const unknown = getUnknownNormalText(t);
    const userNotification = {
        userEmail: user.workEmail,
        settingsEmail: userNotificationSettingsQuery.data?.allowEmail,
        settingsSystem: userNotificationSettingsQuery.data?.allowSystem
    };
    const rows = [
        {
            header: {
                key: "allowSystem",
                title: t("В интерфейсе"),
            },
            dataFormatter: (item) => transformSettingsAllow(item.settingsSystem, t),
            getCellClass: (item) => item.settingsSystem === SettingsAllow.Disabled && "warning",
        },
        {
            header: {
                key: "allowEmail",
                title: t("По электронной почте"),
            },
            dataFormatter: (item) => transformSettingsAllow(item.settingsEmail, t),
            getCellClass: (item) => item.settingsEmail === SettingsAllow.Disabled && "warning",
        },
        {
            header: {
                key: "workEmail",
                title: t("Электронная почта для уведомлений"),
            },
            dataFormatter: (item) => item.userEmail || unknown,
        },
    ];

    const toolbar = !showFullSettings && (
        <Button id="edit_btn" text={t("Редактировать")} onClick={() => setShowFullSettings(true)} size="minier" color="info" icon="pencil" />
    );

    return (
        <Fragment>
            <SubHeader header={t("Уведомления")} toolbar={toolbar} />
            <ProfileTable
                itemList={userNotification ? [{ item: userNotification }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={rows}
            />
            {showFullSettings && userNotificationSettingsQuery.data && (
                <NotificationSettingsForm userNotificationSettings={userNotificationSettingsQuery.data} onClose={() => setShowFullSettings(false)} />
            )}
        </Fragment>
    );
};

export default withTranslation("translations")(NotificationSettings);
