import React from "react";
import PropTypes from "prop-types";
import { Badge, Button } from "vetrf-ui";
import isEmpty from "lodash/isEmpty";

const FilterBadge = ({ id, text, handleClick }) => {
    if (isEmpty(text)) {
        return null;
    }

    return (
        <Badge className="filter-status-badge filter-status-badge_dark-cyan margin-left-10 margin-bottom-5">
            {text}
            <Button id={id} onClick={handleClick} icon="times" iconSize={150} className="margin-left-5" tooltip="Сбросить" onlyIcon={true} />
        </Badge>
    );
};

FilterBadge.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    handleClick: PropTypes.func,
};

export default FilterBadge;
