import { Button, FileField } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { combineValidators } from "../../../../../../utils/utils";
import { useWbrApplicationSendActMutation } from "../../../../api/WbrApplicationStatusQueries";
import { validateFileExtension, validateFilesCount } from "./WbrApplicationSendContractConfirmationButton";
import ButtonWithConfirmation from "../../../../../../components/modal/ButtonWithConfirmation";
import ModalForm from "../../../../../../components/modal/ModalForm";
import ServerErrorAlert from "../../../../../../errors/ServerErrorAlert";

const WbrApplicationSendActConfirmationButton = ({ applicationId, button }) => {
    const { t } = useTranslation();
    const sendContract = useWbrApplicationSendActMutation(applicationId);

    const onSendContractHandler = (onClose) => (data) => {
        sendContract.mutate(data, { onSuccess: () => onClose?.() });
    };

    const defaultButton = (onClick) => (
        <Button id="send_act_btn" text={t("Отправить акт")} color="success" size="sm" icon="share" onClick={onClick} />
    );

    const actionButton = button ?? defaultButton;
    return (
        <ButtonWithConfirmation
            button={actionButton}
            modal={(onClose) => (
                <ModalForm header={t("Отправить акт")} onSubmit={onSendContractHandler(onClose)} onClose={onClose}>
                    <ServerErrorAlert
                        error={sendContract.error}
                        message={(error) =>
                            `${t("Загрузка документа не удалась. Причина:")} ${
                                error.message ?? error.errorList?.map((error) => error.message).join("; ")
                            }. ${t("Попробуйте позднее или обратитесь в техническую поддержку")}`
                        }
                    />
                    <p>{t("Приложите файл акта выполненных работ и файл подписи (УКЭП)")}</p>
                    <FileField
                        name="certificateFile"
                        labelText={t("Подписываемый файл акта (.pdf)")}
                        required
                        acceptedFileTypes={["application/pdf"]}
                        maxFileSize={{ value: "5MB", message: "Максимально допустимый размер файла 5 мегабайт" }}
                        validate={validateFilesCount(1)}
                    />
                    <FileField
                        name="signatures"
                        labelText={t("Файл подписи (.sig)")}
                        required
                        maxFileSize={{ value: "1MB", message: "Максимально допустимый размер файла 1 мегабайт" }}
                        validate={combineValidators(validateFileExtension(["sig"]), validateFilesCount(1))}
                    />
                </ModalForm>
            )}
        />
    );
};

export default WbrApplicationSendActConfirmationButton;
