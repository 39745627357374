import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ProfileTable, SubHeader } from "vetrf-ui";

const OrganizationView = (props) => {
    const { org, headerName } = props;

    const commonRows = [
        {
            header: {
                key: "id",
                title: "Идентификатор",
                width: "12%",
            },
            dataFormatter: (item) => (item.id ? item.id : ""),
        },
        {
            header: {
                key: "guid",
                title: "GUID",
                width: "12%",
            },
            dataFormatter: (item) => (item.guid ? item.guid : ""),
        },
        {
            header: {
                key: "name",
                title: "Наименование",
                width: "12%",
            },
            dataFormatter: (item) => (item.name ? item.name : ""),
        },
        {
            header: {
                key: "address",
                title: "Адрес",
                width: "12%",
            },
            dataFormatter: (item) => (item.address ? item.address.lineOne : ""),
        },
    ];

    const rows = commonRows.filter((item) => {
        return !(org[item.header.key] === undefined);
    });

    return (
        <div>
            <SubHeader header={headerName ? headerName : ""} />
            <ProfileTable
                itemList={org ? [{ item: org }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={rows}
            />
        </div>
    );
};

OrganizationView.propTypes = {
    eCert: PropTypes.object,
};

export default withTranslation("translations")(OrganizationView);
