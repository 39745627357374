import { treatmentOperations } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка пород";

export const requestTreatmentOperations = (speciesId) =>
    createLoadAsyncAction(
        treatmentOperations,
        request,
        {
            type: "GET",
            url: `treatment-operations/by-species/${speciesId}`,
        },
        errorMessage
    );
