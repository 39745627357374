import React from "react";
import PropTypes from "prop-types";
import { filter, uniqueId } from "lodash";

import MenuItem from "./MenuItem";

const NavList = ({ navListItems, isActive, userAuthorities, hasSomeAuthority }) => {
    let menuList = navListItems;
    if (hasSomeAuthority) {
        menuList = filter(menuList, (navListItem) => hasSomeAuthority(navListItem.authorities, userAuthorities));
    }
    return (
        <ul className="nav nav-list">
            {menuList.map((navListItem, index) => (
                <MenuItem
                    key={uniqueId()}
                    userAuthorities={userAuthorities}
                    hasSomeAuthority={hasSomeAuthority}
                    navListItem={navListItem}
                    isActive={isActive}
                />
            ))}
        </ul>
    );
};

NavList.propTypes = {
    navListItems: PropTypes.arrayOf(
        PropTypes.shape({
            authorities: PropTypes.arrayOf(PropTypes.string),
            id: PropTypes.string,
            icon: PropTypes.string,
            label: PropTypes.string,
            submenuItems: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    authorities: PropTypes.arrayOf(PropTypes.string),
                    url: PropTypes.string,
                    label: PropTypes.string,
                })
            ),
        })
    ),
    userAuthorities: PropTypes.array,
    isActive: PropTypes.func,
    hasSomeAuthority: PropTypes.func,
};

export default NavList;
