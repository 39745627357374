import React from "react";
import { Alert, Page } from "vetrf-ui";

const breadcrumbs = [
    {
        link: "/",
        text: "Главная",
    },
    {
        text: "401",
    },
];

const PermissionDenied = () => (
    <Page title="401" header="Ошибка авторизации" breadcrumbs={breadcrumbs}>
        <div className="col-xs-4 col-xs-offset-4">
            <Alert messageType="danger">
                <h3 className="no-margin-top">
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp; Доступ запрещен!
                </h3>
                <p>У Вас нет прав для доступа к системе ECert.</p>
                <p>
                    Обратитесь к администратору <a href={"https://accounts.vetrf.ru/"}>ВетИС.Паспорт</a> для предоставления доступа.
                </p>
            </Alert>
        </div>
    </Page>
);

export default PermissionDenied;
