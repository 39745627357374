import {
    application_pets_view,
    food_cert_create,
    food_cert_view_all,
    food_cert_view_org,
    import_cert_view,
    import_log_view,
    record_create,
    record_view_all,
    record_view_org,
    dictionary_view,
    departments_view,
    departments_modify,
    veterinarian_view,
    business_participant_view,
    application_view_all,
    application_view_org,
    wbr_conclusion_record_view,
    wbr_conclusion_record_modify,
    wbr_application_view_all,
    wbr_application_view_org,
    rabies_protocol_view_all,
    rabies_protocol_view_org,
    rabies_protocol_actions,
} from "../common/constants/permissions";
import { WBR_APP_ROUTE } from "../wbr/application/WbrApplicationRoutes";

const listItems = (t) => {
    return [
        {
            authorities: [application_pets_view],
            id: "nav_list__application",
            value: "application",
            label: t("Заявки на сертификаты"),
            icon: "fa fa-file-o",
            submenuItems: [
                {
                    authorities: [application_view_all, application_view_org],
                    url: "/application",
                    value: "applicationList",
                    label: t("Список заявок"),
                    id: "nav_list__application__list",
                },
            ],
        },
        {
            authorities: [food_cert_view_all, food_cert_view_org, food_cert_create],
            id: "nav_list__e_cert",
            value: "cert",
            label: t("Электронные сертификаты"),
            icon: "fa fa-file-text-o",
            submenuItems: [
                {
                    authorities: [food_cert_create],
                    url: "/cert/create",
                    value: "certCreate",
                    label: t("Создать сертификат"),
                    id: "nav_list__nav_list__e_cert__create",
                },
                {
                    authorities: [food_cert_view_all, food_cert_view_org],
                    url: "/cert",
                    value: "certList",
                    label: t("Список сертификатов"),
                    id: "nav_list__nav_list__e_cert__list",
                },
            ],
        },
        {
            authorities: [record_view_all, record_view_org, record_create],
            id: "nav_list__record",
            value: "record",
            label: t("Учёт сертификатов"),
            icon: "fa fa-file-pdf-o",
            submenuItems: [
                {
                    authorities: [record_create],
                    url: "/record/create",
                    value: "recordCreate",
                    label: t("Зарегистрировать сертификат"),
                    id: "nav_list__record__create",
                },
                {
                    authorities: [record_view_all, record_view_org],
                    url: "/record",
                    value: "recordList",
                    label: t("Список сертификатов"),
                    id: "nav_list__record__list",
                },
            ],
        },
        {
            authorities: [wbr_conclusion_record_view, wbr_conclusion_record_modify],
            id: "nav_list__record",
            value: "wbrConclusion",
            label: t("Заключения ВБР"),
            icon: "fa fa-ship",
            submenuItems: [
                {
                    authorities: [wbr_application_view_all, wbr_application_view_org],
                    url: WBR_APP_ROUTE.LIST.getFullPath(),
                    value: "wbrConclusionList",
                    label: t("Cписок заявок"),
                    id: "nav_list__wbr_conclusion__list",
                },
                {
                    authorities: [wbr_conclusion_record_modify],
                    url: "/wbr-conclusion/record/create",
                    value: "wbrConclusionRecordCreate",
                    label: t("Зарегистрировать заключение"),
                    id: "nav_list__wbr_conclusion__record_create",
                },
                {
                    authorities: [wbr_conclusion_record_view],
                    url: "/wbr-conclusion/record",
                    value: "wbrConclusionList",
                    label: t("Список заключений"),
                    id: "nav_list__wbr_conclusion__list",
                },
            ],
        },
        {
            authorities: [rabies_protocol_actions, rabies_protocol_view_all, rabies_protocol_view_org],
            id: "nav_list__protocol",
            value: "protocol",
            label: t("Протоколы исследования"),
            icon: "fa fa-flask",
            submenuItems: [
                {
                    authorities: [rabies_protocol_actions],
                    url: "/protocol/create",
                    value: "protocolCreate",
                    label: t("Зарегистрировать протокол"),
                    id: "nav_list__protocol_create",
                },
                {
                    authorities: [rabies_protocol_view_all, rabies_protocol_view_org],
                    url:"/protocol",
                    value: "protocolList",
                    label: t("Cписок протоколов"),
                    id: "nav_list__protocol_list",
                },
            ]
        },
        {
            authorities: [import_cert_view, import_log_view],
            id: "nav_list__import_cert",
            value: "cert",
            label: t("Импортные сертификаты"),
            icon: "fa fa-gears",
            submenuItems: [
                {
                    authorities: [import_cert_view],
                    url: "/import/cert",
                    value: "certImportList",
                    label: t("Список сертификатов"),
                    id: "nav_list__import_cert__list",
                },
                {
                    authorities: [import_log_view],
                    url: "/import/cert/logs",
                    value: "logs",
                    label: t("История операций"),
                    id: "nav_list__import_cert__logs",
                },
            ],
        },
        {
            authorities: [],
            id: "nav_list__info",
            value: "info",
            label: t("Справочная информация"),
            icon: "fa fa-life-ring",
            submenuItems: [
                {
                    authorities: [departments_view, departments_modify],
                    url: "/departments",
                    value: "departments",
                    label: t("Места оформления"),
                    id: "nav_list__info__departments",
                },
                {
                    authorities: [business_participant_view],
                    url: "/participants",
                    value: "participants",
                    label: t("Хозяйствующие субъекты"),
                    id: "nav_list__info__participants",
                },
                {
                    authorities: [veterinarian_view],
                    url: "/veterinarians",
                    value: "veterinarians",
                    label: t("Ветеринарные врачи"),
                    id: "nav_list__info__veterinarians",
                },
                {
                    authorities: [dictionary_view],
                    url: "/catalogs",
                    value: "catalogs",
                    label: t("Справочники и реестры"),
                    id: "nav_list__info__catalogs",
                },
                {
                    url: "/info/forms",
                    label: t("Согласованные формы сертификатов"),
                    id: "nav_list__info__forms",
                },
            ],
        },
    ];
};

export default listItems;
