const FormActionsPanel = ({ children }) => {
    return <div className="clearfix form-actions no-margin-bottom col-xs-12">{children}</div>;
};

const RightSide = ({ children }) => {
    return <div className="pull-right inline actions">{children}</div>;
};

FormActionsPanel.RightSide = RightSide;
export default FormActionsPanel;
