import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useUserContext } from "../../../session/UserContextProvider";

import { useMarkAllNotificationMutation, useMarkNotificationMutation, useNewNotificationsPage } from "../../systemNotificationQuery";

const SystemNotificationContext = React.createContext();

export const systemNotificationEvents = {
    NewNotificationFetched: "NewNotificationFetched",
    AllNotificationsMarked: "AllNotificationsMarked",
    AllNotificationsMarkedSuccessfully: "AllNotificationsMarkedSuccessfully",
    NotificationMarked: "NotificationMarked",
    NotificationMarkedSuccessfully: "NotificationMarkedSuccessfully",
};

function SystemNotificationProvider({ children }) {
    const { user } = useUserContext();
    const isAuthorized = !!user?.id;

    const [afterDate, setAfterDate] = useState();
    const observersRef = useRef({});

    useNewNotificationsPage(isAuthorized && !!afterDate, afterDate, onNewNotificationsFetch);
    const markAsReadMutation = useMarkNotificationMutation();
    const markAllAsReadMutation = useMarkAllNotificationMutation();

    function attachToEvent(event, callback) {
        if (!observersRef.current[event]) {
            observersRef.current[event] = [callback];
        } else {
            observersRef.current[event] = [...observersRef.current[event], callback];
        }
    }

    function detachFromEvent(event, callback) {
        let callbacks = observersRef.current[event];
        const index = callbacks.findIndex((x) => x === callback);
        if (index >= 0) {
            callbacks.splice(index, 1);
        }
    }

    function notifyObservers(event, data) {
        observersRef.current[event]?.forEach((func) => func?.(data));
    }

    function onNewNotificationsFetch(data) {
        if (data?.content.length > 0) {
            setAfterDate(Date.parse(data.content[0].event.appearTime));
            notifyObservers(systemNotificationEvents.NewNotificationFetched, data);
        }
    }

    function markAsRead(item) {
        notifyObservers(systemNotificationEvents.NotificationMarked, item);
        markAsReadMutation.mutate(
            { id: item.id },
            {
                onSuccess: () => {
                    notifyObservers(systemNotificationEvents.NotificationMarkedSuccessfully, item);
                },
            }
        );
    }

    function markAllAsRead() {
        notifyObservers(systemNotificationEvents.AllNotificationsMarked);
        markAllAsReadMutation.mutate(
            {},
            {
                onSuccess: () => {
                    notifyObservers(systemNotificationEvents.AllNotificationsMarkedSuccessfully);
                },
            }
        );
    }

    return (
        <SystemNotificationContext.Provider
            value={{
                attachToEvent,
                detachFromEvent,
                afterDate,
                setAfterDate,
                markAsRead,
                markAllAsRead,
            }}
        >
            {children}
        </SystemNotificationContext.Provider>
    );
}

export default SystemNotificationProvider;

/**
 * @param {object} eventHandlers - события с уведомлениями
 * @param {callback} [eventHandlers.onNewNotificationFetched] - появились новые уведомления (параметры: объект страницы уведомлений)
 * @param {callback} [eventHandlers.onAllNotificationsMarked] - все уведомления помечены как прочитанные (нажатие кнопки, отправка запроса)
 * @param {callback} [eventHandlers.onAllNotificationsMarkedSuccessfully] - все уведомления успешно помечены как прочитанные
 * @param {callback} [eventHandlers.onNotificationMarked] - уведомление помечено как прочитанное (параметры: объект уведомления) (нажатие кнопки, отправка запроса)
 * @param {callback} [eventHandlers.onNotificationMarkedSuccessfully] - уведомление успешно помечено как прочитанное (параметры: объект уведомления)
 */
export function useSystemNotifications(eventHandlers) {
    const { attachToEvent, detachFromEvent, afterDate, setAfterDate, markAsRead, markAllAsRead } = useContext(SystemNotificationContext);

    useEffect(() => {
        if (eventHandlers?.onNewNotificationFetched)
            attachToEvent(systemNotificationEvents.NewNotificationFetched, eventHandlers.onNewNotificationFetched);
        if (eventHandlers?.onNotificationMarked) attachToEvent(systemNotificationEvents.NotificationMarked, eventHandlers.onNotificationMarked);
        if (eventHandlers?.onNotificationMarkedSuccessfully)
            attachToEvent(systemNotificationEvents.NotificationMarkedSuccessfully, eventHandlers.onNotificationMarkedSuccessfully);
        if (eventHandlers?.onAllNotificationsMarked)
            attachToEvent(systemNotificationEvents.AllNotificationsMarked, eventHandlers.onAllNotificationsMarked);
        if (eventHandlers?.onAllNotificationsMarkedSuccessfully)
            attachToEvent(systemNotificationEvents.AllNotificationsMarkedSuccessfully, eventHandlers.onAllNotificationsMarkedSuccessfully);

        return () => {
            if (eventHandlers?.onNewNotificationFetched)
                detachFromEvent(systemNotificationEvents.NewNotificationFetched, eventHandlers.onNewNotificationFetched);
            if (eventHandlers?.onNotificationMarked) detachFromEvent(systemNotificationEvents.NotificationMarked, eventHandlers.onNotificationMarked);
            if (eventHandlers?.onNotificationMarkedSuccessfully)
                detachFromEvent(systemNotificationEvents.NotificationMarkedSuccessfully, eventHandlers.onNotificationMarkedSuccessfully);
            if (eventHandlers?.onAllNotificationsMarked)
                detachFromEvent(systemNotificationEvents.AllNotificationsMarked, eventHandlers.onAllNotificationsMarked);
            if (eventHandlers?.onAllNotificationsMarkedSuccessfully)
                detachFromEvent(systemNotificationEvents.AllNotificationsMarkedSuccessfully, eventHandlers.onAllNotificationsMarkedSuccessfully);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventHandlers]);

    return {
        afterDate,
        setAfterDate,
        markAsRead,
        markAllAsRead,
    };
}
