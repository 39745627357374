import { authorityDepartments } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction, createPageableRequestParams } from "../../../common/utils/service-utils";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError, notifySuccess } from "../../../common/store/notification/service";

const request = ajax("/rest-api/dictionary/");
const loadListErrorMessage = "Произошла ошибка при загрузке списка мест оформления";
const loadItemErrorMessage = "Произошла ошибка при загрузке сведений о месте оформления";

export const requestDepartments = (pageable) =>
    createLoadAsyncAction(
        authorityDepartments.page,
        request,
        createPageableRequestParams(pageable, "authority-departments/by-org"),
        loadListErrorMessage
    );

export const requestDepartmentsForIssue = () =>
    createLoadAsyncAction(
        authorityDepartments,
        request,
        {
            type: "GET",
            url: "authority-departments/by-org-list",
        },
        loadListErrorMessage
    );

export const requestItem = (id) =>
    createLoadAsyncAction(authorityDepartments.item, request, { type: "GET", url: `authority-departments/${id}` }, loadItemErrorMessage);

export const requestSaveItem = (item) => (dispatch) => {
    dispatch(authorityDepartments.save.request());
    dispatch(startLoading());

    request({
        url: `authority-departments/`,
        data: item,
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно сохранена в справочник"));
            dispatch(completeLoading());
            dispatch(authorityDepartments.save.success());
        })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при сохранении записи в справочник"));
            dispatch(completeLoading());
            dispatch(authorityDepartments.save.error(error));
        });
};
