import React, { useEffect, useState } from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";

const getNotificationContent = (notification) => notification.title + notification.message;

const NotificationContainer = ({ notification }) => {
    const notificationSystem = React.createRef();
    const [displayedNotifications, setDisplayedNotifications] = useState([]);

    const onAddHandler = (notification) => {
        setDisplayedNotifications([...displayedNotifications, getNotificationContent(notification)]);
    };

    const onRemoveHandler = (notification) => {
        const index = displayedNotifications.findIndex((x) => x === getNotificationContent(notification));
        if (index >= 0) displayedNotifications.splice(index, 1);
        setDisplayedNotifications(displayedNotifications);
    };

    useEffect(() => {
        if (notification.requestClearAll) {
            notificationSystem.current.clearNotifications();
        } else {
            const newNotification = { ...notification };
            if (
                (newNotification?.title || newNotification?.message) &&
                newNotification?.level &&
                !displayedNotifications.includes(getNotificationContent(newNotification))
            ) {
                newNotification.onRemove = onRemoveHandler;
                newNotification.onAdd = onAddHandler;
                notificationSystem.current.addNotification(newNotification);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return <NotificationSystem ref={notificationSystem} />;
};

const mapStateToProps = (state) => ({
    notification: state.common.notificationStore,
});

export default connect(mapStateToProps)(NotificationContainer);
