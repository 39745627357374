export const transformECertStatusColor = (status) => {
    switch (status) {
        case "NEW":
            return "dark-blue light-blue-background";
        case "IN_PROCESS":
            return "dark-blue light-blue-background";
        case "ISSUED":
            return "dark-blue light-green-background";
        case "WITHDRAWN":
            return "dark-blue light-red-background";
        default:
            return "";
    }
};

export const getStatusClassName = (status) => {
    switch (status) {
        case "ISSUED":
            return "label label-lg label-success arrowed arrowed-right";
        case "WITHDRAWN":
            return "label label-lg label-grey arrowed arrowed-right";
        default:
            return "label label-lg label-info arrowed arrowed-right";
    }
};
