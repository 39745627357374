import React from "react";
import PropTypes from "prop-types";

import ShortcutButton from "./ShortcutButton";

const Shortcuts = ({ btnSuccess, btnInfo, btnWarning, btnDanger }) => (
    <div className="sidebar-shortcuts" id="sidebar_shortcuts">
        <div className="sidebar-shortcuts-large" id="sidebar_shortcuts_large" style={{ zIndex: 1 }}>
            {btnSuccess && (
                <ShortcutButton
                    id={btnSuccess.id}
                    className="btn btn-success"
                    title={btnSuccess.title}
                    icon={btnSuccess.icon}
                    href={btnSuccess.href}
                />
            )}
            {btnInfo && <ShortcutButton id={btnInfo.id} className="btn btn-info" title={btnInfo.title} icon={btnInfo.icon} href={btnInfo.href} />}
            {btnWarning && (
                <ShortcutButton
                    id={btnWarning.id}
                    className="btn btn-warning"
                    title={btnWarning.title}
                    icon={btnWarning.icon}
                    href={btnWarning.href}
                />
            )}
            {btnDanger && (
                <ShortcutButton id={btnDanger.id} className="btn btn-danger" title={btnDanger.title} icon={btnDanger.icon} href={btnDanger.href} />
            )}
        </div>
        <div className="sidebar-shortcuts-mini" id="sidebar-shortcuts-mini">
            <span className="btn btn-success" />
            <span className="btn btn-info" />
            <span className="btn btn-warning" />
            <span className="btn btn-danger" />
        </div>
    </div>
);

Shortcuts.propTypes = {
    btnSuccess: PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
    }),
    btnInfo: PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
    }),
    btnWarning: PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
    }),
    btnDanger: PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
    }),
};

export default Shortcuts;
