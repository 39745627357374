import { Button } from "vetrf-ui";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isWbrAppInStatus, wbrApplicationStatuses } from "../../../../../../utils/constants/wbr/wbr-application-statuses";
import { useWbrApplicationProcessMutation } from "../../../../api/WbrApplicationStatusQueries";
import { WBR_APP_PAYMENT_ACTIONS, WBR_APP_ROUTE } from "../../../../WbrApplicationRoutes";
import ButtonWithConfirmation from "../../../../../../components/modal/ButtonWithConfirmation";
import ModalBox from "../../../../../../components/modal/ModalBox";

const WbrApplicationProcessConfirmationButton = ({ application }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toProcess = useWbrApplicationProcessMutation(application.id);

    const canPrepayment = isWbrAppInStatus(application, [
        wbrApplicationStatuses.NEW,
        wbrApplicationStatuses.DATA_FOR_CONTRACT_RECEIVED,
        wbrApplicationStatuses.CONTRACT_PREPARING,
        wbrApplicationStatuses.CONTRACT_RECEIVED,
    ]);

    const onToProcessHandler = () => {
        toProcess.mutate();
    };

    const onRequestPrepaymentHandler = () => {
        navigate(WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(application.id, WBR_APP_PAYMENT_ACTIONS.EDIT));
    };

    const shortModal = (onClose) => {
        const content = t('Статус заявки будет изменен на "На рассмотрении". Вы уверены что хотите взять заявку в работу?');
        return <ModalBox header={t("Взять в работу")} onConfirm={onToProcessHandler} onClose={onClose} children={content} />;
    };

    const extendedModal = (onClose) => {
        const content = t(
            "Нужно ли запросить предоплату перед началом рассмотрения заявки? (Заявку будет невозможно взять в работу до поступления сведений об оплате)"
        );
        const footer = (handleClose) => (
            <Fragment>
                <Button id="modal__prepayment_ok_btn" text={t("Да, запросить предоплату")} color="success" onClick={() => handleClose(onRequestPrepaymentHandler)} />
                <Button id="modal__prepayment_cancel_btn" text={t("Нет, взять в работу")} color="default" onClick={() => handleClose(onToProcessHandler)} />
                <Button id="modal__cancel_btn" text={t("Отмена")} color="default" onClick={handleClose} />
            </Fragment>
        );
        return <ModalBox header={t("Взять в работу")} onClose={onClose} footer={footer} children={content} />;
    };

    return (
        <ButtonWithConfirmation
            button={(onClick) => <Button id="process_btn" text={t("Взять в работу")} color="success" size="sm" icon="briefcase" onClick={onClick} />}
            modal={(onClose) => (canPrepayment ? extendedModal : shortModal)(onClose)}
        />
    );
};

export default WbrApplicationProcessConfirmationButton;
