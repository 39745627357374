import React from "react";

export default function FetchingPageIndicator() {
    return (
        <div className="insp-logo-frame">
            <div className="mosaic-loader">
                <div className="ml-cell d-0"></div>
                <div className="ml-cell d-1"></div>
                <div className="ml-cell d-2"></div>
                <div className="ml-cell d-1"></div>
                <div className="ml-cell d-2"></div>
                <div className="ml-cell d-3"></div>
                <div className="ml-cell d-2"></div>
                <div className="ml-cell d-3"></div>
                <div className="ml-cell d-4"></div>
            </div>
        </div>
    );
}
