import { ConfirmActionButton } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { useBusinessParticipantDeleteMutation } from "../../../api/businessParticipantsQueries";

const BusinessParticipantDeleteButton = ({ id, onlyIcon, participant }) => {
    const { t } = useTranslation();
    const participantDeleteMutation = useBusinessParticipantDeleteMutation();

    const deleteParticipant = (item) => {
        participantDeleteMutation.mutate(item.id);
    };

    return (
        <ConfirmActionButton
            id={id}
            closeAfterConfirm
            isOnlyIconButton={onlyIcon}
            icon="trash-o"
            iconColor={onlyIcon ? "red" : "white"}
            iconSize={onlyIcon && 150}
            tooltip={onlyIcon && t("Удалить")}
            buttonText={t("Удалить")}
            buttonColor="danger"
            buttonSize="sm"
            confirmBodyContent={t("Вы уверены, что хотите удалить запись?")}
            confirmHeaderText={t("Удаление записи")}
            onConfirm={() => deleteParticipant(participant)}
        />
    );
};

export default BusinessParticipantDeleteButton;
