import { Icon } from "vetrf-ui";

import { getFileMetaView } from "../../utils/viewUtils";

export const DownloadLink = ({ title, file, href, ...props }) => {
    return (
        <a {...props} href={href} rel="noopener noreferrer" download>
            {title ?? (file ? getFileMetaView(file) : href)} <Icon icon="download" size={80} />
        </a>
    );
};

export const ExternalLink = ({ title, href, ...props }) => {
    return (
        <a {...props} href={href} rel="noopener noreferrer" target="_blank">
            {title ?? href} <Icon icon="external-link" size={80} />
        </a>
    );
};

export const EmailLink = ({ title, href, ...props }) => {
    return (
        <a {...props} href={`mailto:${href}`}>
            {title ?? href} <Icon icon="envelope-o" size={80} />
        </a>
    );
};

const IconedLink = ({ title, href, ...props }) => {
    return (
        <a {...props} href={href} rel="noopener noreferrer" target="_blank">
            {title ?? href} <Icon icon="link" size={80} />
        </a>
    );
};

export default IconedLink;
IconedLink.Download = DownloadLink;
IconedLink.External = ExternalLink;
IconedLink.Email = EmailLink;
