import { Fragment } from "react";
import { Table } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { getUnknownNormalText } from "../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../components/page/BlockHeader";

const WbrApplicationProcessesInfo = ({ application }) => {
    const { t, i18n } = useTranslation();
    const unknown = getUnknownNormalText(t);
    const consignment = application.consignment;
    const nameField = i18n.resolvedLanguage === "ru" ? "name" : "englishName";

    const tableRows = [
        {
            key: "processType",
            title: t("Тип операции"),
            dataFormatter: (item) => item.type?.[nameField] ?? unknown,
        },
        {
            key: "producerNumber",
            title: t("Регистрационный номер предприятия"),
            dataFormatter: (item) => item.producer?.approvedNumber ?? unknown,
        },
        {
            key: "producerName",
            title: t("Наименование предприятия"),
            dataFormatter: (item) =>
                item.producer ? (
                    <span>
                        {item.producer.view ?? unknown}
                        {item.producer.enView && (
                            <Fragment>
                                <br />
                                {item.producer.enView}
                            </Fragment>
                        )}
                    </span>
                ) : (
                    unknown
                ),
        },
        {
            key: "producerLocation",
            title: t("Адрес предприятия"),
            dataFormatter: (item) =>
                item.producer ? (
                    <span>
                        {item.producer.address?.addressView || unknown}
                        {item.producer.address?.enAddressView && (
                            <Fragment>
                                <br />
                                {item.producer.address?.enAddressView}
                            </Fragment>
                        )}
                    </span>
                ) : (
                    unknown
                ),
        },
    ];

    return (
        <Fragment>
            <BlockHeader>{t("Сведения о грузе")}</BlockHeader>
            <div className="wraptablebig">
                <Table dataList={consignment.processes.sort((a, b) => a.orderNumber - b.orderNumber)} columns={tableRows} />
            </div>
        </Fragment>
    );
};

export default WbrApplicationProcessesInfo;
