import ajax from "../../utils/ajax";

import {
    COUNTRY_LIST_LOAD,
    COUNTRY_LIST_RECEIVE,
    COUNTRY_LIST_ERROR,
    SUPPORTED_COUNTRY_LIST_LOAD,
    SUPPORTED_COUNTRY_LIST_RECEIVE,
    SUPPORTED_COUNTRY_LIST_ERROR,
} from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const countriesLoad = () => ({
    type: COUNTRY_LIST_LOAD,
});

export const countriesReceive = (countries) => ({
    type: COUNTRY_LIST_RECEIVE,
    countries,
});

export const countriesLoadError = (error) => ({
    type: COUNTRY_LIST_ERROR,
    error,
});
export const supportedCountriesLoad = () => ({
    type: SUPPORTED_COUNTRY_LIST_LOAD,
});

export const supportedCountriesReceive = (countries) => ({
    type: SUPPORTED_COUNTRY_LIST_RECEIVE,
    countries,
});

export const supportedCountriesLoadError = (error) => ({
    type: SUPPORTED_COUNTRY_LIST_ERROR,
    error,
});

export const requestCountries = () => (dispatch) => {
    dispatch(countriesLoad());
    request({
        type: "POST",
        url: "countries",
    })
        .then((response) => {
            const result = response.map((c) => ({
                ...c,
                view: c.name + " (" + c.englishName + ")",
            }));
            dispatch(countriesReceive(result));
        })
        .catch((error) => {
            dispatch(countriesLoadError(error));
        });
};

export const requestSupportedCountries = () => (dispatch) => {
    dispatch(supportedCountriesLoad());
    request({
        type: "POST",
        url: "supportedCountries",
    })
        .then((response) => {
            dispatch(supportedCountriesReceive(response));
        })
        .catch((error) => {
            dispatch(supportedCountriesLoadError(error));
        });
};
