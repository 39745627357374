import ajax from "../../utils/ajax";

import { PROCESS_TYPE_LIST_LOAD, PROCESS_TYPE_LIST_RECEIVE, PROCESS_TYPE_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const processTypesLoad = () => ({
    type: PROCESS_TYPE_LIST_LOAD,
});

export const processTypesReceive = (processTypes) => ({
    type: PROCESS_TYPE_LIST_RECEIVE,
    processTypes,
});

export const processTypesLoadError = (error) => ({
    type: PROCESS_TYPE_LIST_ERROR,
    error,
});

export const requestProcessTypes = () => (dispatch) => {
    dispatch(processTypesLoad());
    request({
        type: "POST",
        url: "processTypes",
    })
        .then((response) => {
            dispatch(processTypesReceive(response));
        })
        .catch((error) => {
            dispatch(processTypesLoadError(error));
        });
};
