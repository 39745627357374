import { VALID } from "../components/sidePanel/constants/FormStatus";
import { find, findIndex } from "lodash";
import { uniqueId } from "lodash/util";

export const generateKey = (item) => (item.k = uniqueId());

export const getDefaultParentColumns = (oldItems) => {
    return [
        {
            id: "common",
            url: "",
            text: "1. Общие сведения",
            status: oldItems && oldItems[0] ? oldItems[0].status : null,
        },
        {
            id: "consignor",
            url: "consignor",
            text: "2. Сведения о грузоотправителе",
            status: oldItems && oldItems[1] ? oldItems[1].status : null,
        },
        {
            id: "consignee",
            url: "consignee",
            text: "3. Сведения о грузополучателе",
            status: oldItems && oldItems[2] ? oldItems[2].status : null,
        },
        {
            id: "route",
            url: "route",
            text: "4. Сведения о маршруте",
            status: oldItems && oldItems[3] ? oldItems[3].status : null,
        },
        {
            id: "transport",
            url: "transport",
            text: "5. Сведения о транспортном средстве",
            status: oldItems && oldItems[4] ? oldItems[4].status : null,
        },
        {
            id: "consignment",
            url: "consignment",
            text: "6. Сведения о грузе",
            items: [],
            status: oldItems && oldItems[5] ? oldItems[5].status : null,
        },
        {
            id: "summary",
            url: "summary",
            valid: true,
            text: "Предпросмотр сертификата",
        },
    ];
};

export const getECertRoadColumns = (documentType, oldItems) => {
    if (!documentType) return getDefaultParentColumns(oldItems);
    if (documentType.isDocumentTypeVisible || documentType.isDocumentReferenceNumberVisible) {
        let columns = getDefaultParentColumns(oldItems);
        columns.splice(6, 0, {
            id: `documents`,
            url: `documents`,
            text: "7. Сведения о документах",
            status: oldItems && oldItems[6] ? oldItems[6].status : null,
        });
        return columns;
    }

    return getDefaultParentColumns(oldItems);
};

export const getRecordRoadColumns = () => {
    return [
        {
            id: "common",
            url: "",
            text: "1. Общие сведения",
        },
        {
            id: "consignor",
            url: "consignor",
            text: "2. Сведения о грузоотправителе",
        },
        {
            id: "consignee",
            url: "consignee",
            text: "3. Сведения о грузополучателе",
        },
        {
            id: "transport",
            url: "transport",
            text: "4. Сведения о транспортном средстве",
        },
        {
            id: "consignment",
            url: "consignment",
            text: "5. Сертифицируемая продукция",
        },
        {
            id: "summary",
            url: "summary",
            text: "Предпросмотр",
            valid: true,
        },
    ];
};
export const getWbrConclusionRoadColumns = () => {
    return [
        {
            id: "common",
            url: "",
            text: "1. Общие сведения",
        },
        {
            id: "consignor",
            url: "consignor",
            text: "2. Сведения о грузоотправителе",
        },
        {
            id: "consignee",
            url: "consignee",
            text: "3. Сведения о грузополучателе",
        },
        {
            id: "transport",
            url: "transport",
            text: "4. Сведения о транспортном средстве",
        },
        {
            id: "consignment",
            url: "consignment",
            text: "5. Сертифицируемая продукция",
        },
        {
            id: "summary",
            url: "summary",
            text: "Предпросмотр",
            valid: true,
        },
    ];
};

export const generateParentItem = (id, childList, item, qualifier) => {
    if (qualifier && qualifier === "PetAnimalECert") {
        return {
            id: `${id}`,
            uniqueGlobalId: `pet_${id}`,
            url: `${id}/pet`,
            text: item ? `Позиция №${id + 1} ${item.name ? `(${item.name})` : ""}` : `Новая позиция №${id + 1}`,
            items: childList,
            parentId: "consignment",
        };
    }
    return {
        id: `${id}`,
        uniqueGlobalId: `item_${id}`,
        url: `${id}/item`,
        text: item
            ? `Позиция №${id + 1} ${item.productEnglishName || item.productName ? `(${item.productEnglishName ?? item.productName})` : ""}`
            : `Новая позиция №${id + 1}`,
        items: childList,
        parentId: "consignment",
    };
};

export const generateParentItemDefault = (id, childs, view) => {
    return {
        id: `${id}`,
        uniqueGlobalId: `item_${id}`,
        url: `${id}/item`,
        text: view ? `Позиция №${id + 1} (${view})` : `Новая позиция №${id + 1}`,
        items: childs,
        parentId: "consignment",
    };
};

export const generateChildItems = (id, qualifier) => {
    if (qualifier && qualifier === "PetAnimalECert") {
        return [
            {
                id: `${id}/treatment`,
                uniqueGlobalId: `pet_${id}__treatment`,
                url: `${id}/pet/treatment`,
                text: "Вакцинация и обработки",
                parentId: `${id}`,
            },
        ];
    } else
        return [
            {
                id: `${id}/trades`,
                uniqueGlobalId: `item_${id}__trades`,
                url: `${id}/item/trades`,
                text: "Сведения о партиях",
                parentId: `${id}`,
            },
        ];
};

export const updateStates = (panel, state) => {
    panel.items.forEach((item) => {
        if (item.status && item.status !== state) {
            item.status = state;
        }
        if (item.items) {
            updateStates(item, state);
        }
    });
};

export const findItem = (panel, id) => {
    let item;
    if (panel.items) {
        item = find(panel.items, { id: id });
        if (!item) {
            for (let i of panel.items) {
                let childItem = findItem(i, id);
                if (childItem) {
                    return childItem;
                }
            }
        }
    }
    return item;
};

export const findFirstNotValid = (panel) => {
    let notValid = null;
    panel.items.forEach((item) => {
        if (!notValid) {
            if (item.status !== VALID && !item.valid) {
                notValid = item;
            } else {
                if (item.items) {
                    notValid = findFirstNotValid(item);
                }
            }
        }
    });
    return notValid;
};

export const findNextSibling = (panel, item) => {
    const parent = item.parentId ? findItem(panel, item.parentId) : panel;
    const index = findIndex(parent.items, item);
    if (parent.items[index + 1]) {
        if (parent.items[index + 1].disabled) {
            return findNextSibling(parent.items[index + 1], parent.items[index + 1]);
        }
        return parent.items[index + 1];
    } else {
        return findNextSibling(panel, parent);
    }
};

export const findPreviousSibling = (panel, item) => {
    let parent = item.parentId ? findItem(panel, item.parentId) : panel;
    const index = findIndex(parent.items, item);
    if (parent.items[index - 1]) {
        parent = parent.items[index - 1];
        while (parent.items) {
            parent = parent.items[parent.items.length - 1];
        }
        return parent;
    } else {
        if (parent.disabled) {
            return findPreviousSibling(panel, parent);
        }
        return parent;
    }
};
