import { useMutation, useQueryClient } from "react-query";

import { getWbrApplicationQueryKey } from "./application/api/WbrApplicationQueries";
import { queryClient } from "../index";
import { useOneItemQuery, usePageQuery } from "../utils/commonQueryBase";
import ajax from "../utils/ajax";
import NotificationManager from "../common/utils/notification/NotificationManager";

const baseUrl = "/rest-api/wbr/conclusion/record/";

export function useGetWbrConclusionsPage(pageParams, searchParams, enabled) {
    return usePageQuery(baseUrl, pageParams, searchParams, {
        enabled: enabled,
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка заключений ВБР."),
    });
}

export function useGetWbrConclusion(id) {
    return useOneItemQuery(baseUrl, id, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке заключения ВБР."),
    });
}

export function useSaveWbrConclusionMutation() {
    const request = ajax(baseUrl);
    const createRequest = (data) => {
        return request({ type: "POST", data, uploadFile: true, headers: {} });
    };

    return useMutation({
        mutationFn: createRequest,
        onSuccess: (data) => {
            NotificationManager.success("Заключение ВБР успешно сохранено.");
            queryClient.invalidateQueries([baseUrl, data.id]);
            queryClient.setQueryData([baseUrl, data.id], data);

            if (data.application?.id) {
                queryClient.invalidateQueries(getWbrApplicationQueryKey(data.application.id));
            }
        },
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при создании заключения ВБР."),
    });
}
export function useWithdrawWbrConclusionMutation() {
    const request = ajax(baseUrl);
    const createRequest = (data) => {
        return request({ type: "POST", data, url: "withdraw" });
    };

    return useMutation({
        mutationFn: createRequest,
        onSuccess: (data) => {
            NotificationManager.success("Заключение ВБР успешно аннулировано.");
            queryClient.invalidateQueries([baseUrl, data.id]);
            queryClient.setQueryData([baseUrl, data.id], data);
        },
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при аннулировании заключения ВБР."),
    });
}

export function useIsWbrConclusionMutating() {
    const queryClient = useQueryClient();
    return queryClient.isMutating(baseUrl);
}
