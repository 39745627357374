import React from "react";
import { InputFormGroup, SelectFormGroup } from "vetrf-ui";
import { t } from "i18next";

import BooleanSelectFormGroup from "../BooleanSelectFormGroup";
import ValueSelectFormGroup from "../ValueSelectFormGroup";
import isArray from "lodash/isArray";

export function getQueryContent(query) {
    const data = query?.().data;
    if (data) {
        return isArray(data) ? data : data.content ?? [];
    }
    return [];
}

export const getFormField = (fieldMeta, key, catalogData, onChange, showError, errorHandler) => {
    const form = fieldMeta.form;
    const name = fieldMeta.name;
    const label = fieldMeta.title;
    const options = fieldMeta.options ?? {};
    const disabled = options.disabled;
    const require = options.require;
    const customValidate = options.customValidate;

    if (name === "id" || name === "guid") return null;
    switch (fieldMeta?.type) {
        case "checkbox":
            return (
                <BooleanSelectFormGroup
                    clearable={options?.clearable ?? false}
                    labelText={label}
                    placeholder={form?.placeholder}
                    field={name}
                    value={fieldMeta.dataFormatter(catalogData)}
                    onChange={onChange}
                    key={key}
                />
            );
        case "select":
            return (
                <SelectFormGroup
                    labelText={label}
                    placeholder={form?.placeholder ?? `Выберите ${label.toLowerCase()}...`}
                    field={name}
                    disabled={disabled}
                    require={require}
                    customValidate={customValidate}
                    maxOptions={options.max}
                    clearable={options.clearable}
                    multiple={options.multiple}
                    value={fieldMeta.dataFormatter(catalogData)}
                    labelKey={form.labelKey}
                    valueKey={form.valueKey}
                    options={form.options ?? getQueryContent(form.useOptionsListQuery)}
                    showError={showError}
                    errorHandler={errorHandler}
                    onChange={onChange}
                    key={key}
                />
            );
        case "valueSelect":
            return (
                <ValueSelectFormGroup
                    labelText={label}
                    placeholder={form?.placeholder ?? `Выберите ${label.toLowerCase()}...`}
                    field={name}
                    disabled={disabled}
                    require={require}
                    customValidate={customValidate}
                    maxOptions={options.max}
                    clearable={options.clearable}
                    multiple={options.multiple}
                    value={fieldMeta.dataFormatter(catalogData)}
                    labelKey={form.labelKey}
                    valueKey={form.valueKey}
                    options={form.options ?? getQueryContent(form.useOptionsListQuery)}
                    showError={showError}
                    errorHandler={errorHandler}
                    onChange={onChange}
                    key={key}
                />
            );
        case "text":
        default:
            return (
                <InputFormGroup
                    labelText={label}
                    placeholder={form?.placeholder ?? `${label}...`}
                    field={name}
                    disabled={disabled}
                    require={require}
                    customValidate={customValidate}
                    validateLatin={options.isLatin}
                    maxLength={options.max}
                    validateRegExp={{ regExp: options.matchRegExp }}
                    value={fieldMeta.dataFormatter(catalogData)}
                    className="width-max"
                    showError={showError}
                    errorHandler={errorHandler}
                    onChange={onChange}
                    key={key}
                />
            );
    }
};

export const getSearchField = (field, catalogMetaData, searchParamsTemp, onChange) => {
    const fieldMeta = catalogMetaData.fields.find((x) => x.name === field.fieldName);
    const form = field.form ?? fieldMeta?.form ?? {};
    const name = field.paramName ?? field.fieldName ?? fieldMeta?.name;
    const label = field?.title ?? fieldMeta?.title ?? t("Поиск");

    switch (field.type ?? fieldMeta?.type) {
        case "checkbox":
            return (
                <BooleanSelectFormGroup
                    clearable
                    labelText={label}
                    placeholder={form.placeholder}
                    field={name}
                    value={searchParamsTemp[name]}
                    onChange={(value, path) => onChange(value, path, true)}
                />
            );
        case "select":
            return (
                <ValueSelectFormGroup
                    clearable
                    labelText={label}
                    placeholder={form?.placeholder ?? `Выберите ${label.toLowerCase()}...`}
                    field={name}
                    value={searchParamsTemp[name]}
                    labelKey={form.labelKey}
                    valueKey={form.valueKey}
                    options={form.options ?? getQueryContent(form.useOptionsListQuery)}
                    onChange={(value, path) => onChange(value, path, true)}
                />
            );
        default:
            return (
                <InputFormGroup
                    labelText={label}
                    placeholder={form.placeholder ?? `${label}...`}
                    field={name}
                    value={searchParamsTemp[name]}
                    className="width-max"
                    onChange={onChange}
                />
            );
    }
};
