import { InputField } from "vetrf-ui";

import withOnChange from "./hoc/withOnChange";

/**
 * @param {callback} onChange - обратный вызов при изменении значения
 */
const EInputField = ({ ...props }) => {
    return <InputField maxLength={255} {...props} />;
};

export default withOnChange(EInputField);
