import React from "react";
import { ConfirmActionButton } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import WbrConclusionDocumentView from "../../view/components/WbrConclusionDocumentView";
import WbrConclusionConsignorView from "../../view/components/WbrConclusionConsignorView";
import WbrConclusionConsigneeView from "../../view/components/WbrConclusionConsigneeView";
import WbrConclusionTransportView from "../../view/components/WbrConclusionTransportView";
import WbrConclusionConsignmentItemsView from "../../view/components/WbrConclusionConsignmentItemsView";

const WbrConclusionSummary = ({ conclusion, cancelButton, saveRecord, countries, formValid, files, metadata, saving }) => {
    const { t } = useTranslation("translation");

    return (
        <form className="form-horizontal css-form">
            <h3 className="header smaller lighter blue">{t("Предпросмотр заключения")}</h3>
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0">
                <WbrConclusionDocumentView
                    metadata={metadata}
                    conclusion={conclusion}
                    countries={countries}
                    file={files && files.length > 0 ? files[0] : undefined}
                />
                <WbrConclusionConsignorView conclusion={conclusion} />
                <WbrConclusionConsigneeView conclusion={conclusion} />
                <WbrConclusionTransportView conclusion={conclusion} />
                <WbrConclusionConsignmentItemsView conclusion={conclusion} />
            </div>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <div className="pull-left">{cancelButton}</div>
                <div className="pull-right">
                    <div className="pull-right">
                        <ConfirmActionButton
                            onConfirm={() => {
                                saveRecord && saveRecord();
                            }}
                            id="save_conclusion_btn"
                            icon="check"
                            buttonSize="sm"
                            buttonColor="success"
                            buttonText={t("Сохранить заключение")}
                            confirmBodyContent={
                                <span>
                                    <span>{t("Вы уверены, что хотите сохранить заключение?")}</span>
                                </span>
                            }
                            confirmHeaderText={t("Регистрация заключения")}
                            confirmBtnText={t("Продолжить")}
                            confirmBtnColor="success"
                            confirmBtnDisabled={saving}
                            disabled={!formValid}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default WbrConclusionSummary;
