import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

const withOnChange = (WrappedComponent) => {
    return (props) => {
        const { watch, getFieldState, formState } = useFormContext();
        const fieldValue = watch(props.name);
        const { isDirty } = getFieldState(props.name, formState);
        const isChanged = useRef(false);

        useEffect(() => {
            if (isDirty) {
                props.onChange?.(fieldValue);
                isChanged.current = true;
            }
            if (isChanged.current) {
                props.onChange?.(fieldValue);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [fieldValue]);

        return <WrappedComponent {...props} />;
    };
};

export default withOnChange;
