import { handleActions } from "redux-actions";
import { templateList } from "./actions";

const initialState = {
    documentTypeId: null,
    list: [],
};

export default handleActions(
    {
        [templateList.load.request]: (state, action) => ({
            ...state,
            documentTypeId: action.payload,
            list: [],
        }),
        [templateList.load.success]: (state, action) => ({
            ...state,
            documentTypeId: action.payload.documentTypeId,
            list: action.payload.list
                ? action.payload.list.sort((a, b) => {
                      return a.priority - b.priority;
                  })
                : action.payload.list,
        }),
        [templateList.load.error]: (state, action) => ({
            ...state,
            list: [],
        }),
    },
    initialState
);
