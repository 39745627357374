import React, { Fragment, useEffect, useState } from "react";

import { SelectFormGroup } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { useWbrProducts } from "../../dictionary/api/productQuery";
import { useGetSubProductList } from "../../dictionary/api/subProductQuery";

const ProductFormComponent = ({ item, itemPath, onChangeHandler, errorHandler, showError, prefixId }) => {
    const { t } = useTranslation("translation");
    const [productGuid, setProductGuid] = useState(null);
    const { data: products } = useWbrProducts();
    const { data: subProducts } = useGetSubProductList({ productGuid: productGuid }, productGuid);

    useEffect(() => {
        setProductGuid(item?.product?.guid);
    }, [item?.product?.guid]);

    const onChange = (value, path) => {
        if (path === "product") {
            setProductGuid(value?.guid);
            onChangeHandler(value, path);
        } else {
            onChangeHandler(value, path);
        }
    };

    const errorCustomHandler = (hasError, field) => {
        errorHandler(hasError, `${itemPath}.${field}`);
    };

    return (
        <Fragment>
            <SelectFormGroup
                labelText={t("Продукция")}
                require
                clearable
                options={products}
                valueKey="guid"
                onChange={onChange}
                field={"product"}
                value={item && item.product}
                showError={showError}
                errorHandler={errorCustomHandler}
                placeholder={t("Продукция...")}
                prefixId={prefixId}
            />
            <SelectFormGroup
                labelText={t("Вид продукции")}
                require
                clearable
                options={productGuid ? subProducts : []}
                valueKey="guid"
                onChange={onChange}
                field={"subProduct"}
                value={item && item.subProduct}
                showError={showError}
                errorHandler={errorCustomHandler}
                placeholder={t("Вид продукции...")}
                prefixId={prefixId}
            />
        </Fragment>
    );
};

export default ProductFormComponent;
