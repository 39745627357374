export const wbrApplicationPaymentTypes = {
    PREPAYMENT: "PREPAYMENT",
    BEFORE_RESULTS: "BEFORE_RESULTS",
    FREE_PAYMENT: "FREE_PAYMENT",
};

export const getWbrApplicationPaymentTypes = (t) => {
    return Object.keys(wbrApplicationPaymentTypes).map((key) => ({
        id: wbrApplicationPaymentTypes[key],
        view: getWbrApplicationPaymentTypeView(wbrApplicationPaymentTypes[key], t),
    }));
};

export const getWbrApplicationPaymentTypeView = (type, t) => {
    if (!type) return null;
    switch (type) {
        case wbrApplicationPaymentTypes.PREPAYMENT:
            return t("Предоплата (заявку можно взять в работу только после получения сведений об оплате)");
        case wbrApplicationPaymentTypes.BEFORE_RESULTS:
            return t("Оплата до выдачи результата (ожидание оплаты блокирует отправку результата экспортеру)");
        case wbrApplicationPaymentTypes.FREE_PAYMENT:
            return t("Свободная оплата (ожидание оплаты не влияет на рассмотрение заявки и выдачу результата)");
        default:
            return t("Неизвстно");
    }
};