import { Alert, Icon } from "vetrf-ui";

const AlertWithIcon = ({ icon = "info-circle", children, ...props }) => {
    return (
        <Alert messageType="info" {...props}>
            <Icon icon={icon} />
            &nbsp;&nbsp;
            {children}
        </Alert>
    );
};

export default AlertWithIcon;
