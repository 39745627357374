import React, { Fragment } from "react";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { getUnknownCursiveText } from "../../../../utils/constants/constants";

const WbrConclusionConsigneeView = ({ conclusion }) => {
    const { t } = useTranslation("translation");
    const unknown = getUnknownCursiveText(t);
    const consigneeRows = [
        {
            header: {
                key: "consignee.name",
                title: t("Имя"),
                width: "100px",
            },
            dataFormatter: (item) => {
                if (item?.consignee) {
                    return (
                        <Fragment>
                            <span>{item.consignee?.view || unknown}</span>
                            <br />
                            <span>{item.consignee?.enView || unknown}</span>
                            <br />
                        </Fragment>
                    );
                }
                return unknown;
            },
        },
        {
            header: {
                key: "consignee.address",
                title: t("Адрес"),
                width: "100px",
            },
            dataFormatter: (item) => {
                if (item?.consignee) {
                    return (
                        <Fragment>
                            <span>{item.consignee?.address?.addressView || unknown}</span>
                            <br />
                            <span>{item.consignee?.address?.enAddressView || unknown}</span>
                            <br />
                        </Fragment>
                    );
                }
                return unknown;
            },
        },
        {
            header: {
                key: "consignee.phone",
                title: t("Контактный телефон"),
            },
            dataFormatter: (item) => item?.consignee?.phone || unknown,
        },
    ];
    return (
        <div className={"row"}>
            <SubHeader header={t("Грузополучатель")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__consignee"
                    itemList={conclusion?.consignment ? [{ item: conclusion.consignment }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={consigneeRows}
                />
            </div>
        </div>
    );
};

export default WbrConclusionConsigneeView;
