import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { NEW, UPDATE, VALID, ERROR, VOID } from "./constants/FormStatus";

const statusIcon = (status) => {
    switch (status) {
        case VOID:
        case NEW:
            return "fa-question-circle";
        case UPDATE:
            return "fa-circle";
        case VALID:
            return "fa-check-circle green";
        case ERROR:
            return "fa-exclamation-circle red";

        default:
            return "fa-question-circle";
    }
};

const PanelItemLink = ({ text, status, children, url, id }) => (
    <li className="dd-item">
        <NavLink id={id} className={({ isActive }) => "dd-handle panel-item" + (isActive ? " btn-info" : "")} end to={url}>
            {text} {status && <i className={`fa pull-right bigger-130 ${statusIcon(status)}`} />}
        </NavLink>
        {children}
    </li>
);

// const DisabledPanelItem = ({text, status, children}) => (
//     <li className="dd-item">
//         <div className="dd-handle panel-item">
//             {text} {status && <i className={classNames("fa", "pull-right", "bigger-130", statusIcon(status))}/>}
//         </div>
//         {children}
//     </li>
// );

const PanelItem = ({ items, prefixId }) => (
    <ol className="dd-list">
        {items.map((item) => {
            const formingId = prefixId + `__${item.uniqueGlobalId}`;
            return (
                <PanelItemLink text={item.text} url={`${item.url}`} status={item.status} key={item.id} id={formingId}>
                    {item.items && <PanelItem items={item.items} prefixId={prefixId} />}
                </PanelItemLink>
            );
        })}
    </ol>
);

const FormSidePanelItem = ({ items, name, children }) => (
    <div>
        <div className="col-lg-3 col-md-5 col-xs-12">
            <h3 className="header smaller lighter blue">{name}</h3>
            <div className="dd">
                <ol className="dd-list">
                    {items.map((item) => {
                        const formingId = "side_panel" + `__${item.id}`;
                        return (
                            <PanelItemLink text={item.text} url={`${item.url}`} status={item.status} key={item.id} id={formingId}>
                                {item.items && <PanelItem items={item.items} prefixId={formingId} />}
                            </PanelItemLink>
                        );
                    })}
                </ol>
            </div>
        </div>
        <div className="col-lg-9 col-md-7 col-xs-12">{children}</div>
    </div>
);

FormSidePanelItem.propTypes = {
    items: PropTypes.array,
};

export default FormSidePanelItem;
