export const getApplicationList = (state) => state.applicationStore.applicationList.result;
export const getApplicationShowFilter = (state) => state.applicationStore.applicationList.search.showFilter;
export const getApplicationSearchParams = (state) => state.applicationStore.applicationList.search.searchParams;
export const getApplicationBadgeSearchParams = (state) => state.applicationStore.applicationList.search.badgeSearchParams;
export const getApplicationSearchIsValidForm = (state) => state.applicationStore.applicationList.search.isValidForm;
export const getApplicationPageParams = (state) => state.applicationStore.applicationList.pageParams;

export const getApplication = (state) => state.applicationStore.application.result;
export const getEcertListForApplication = (state) => state.applicationStore.application.ecertList;
export const getApplicationClosing = (state) => state.applicationStore.application.closing;
