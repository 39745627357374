import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../../common/utils/constants";

export const { recordList } = createActions({
    RECORD_LIST: {
        ...loadActions,
        FILTER: {
            SET: null,
            CLEAR: null,
        },
    },
});
export const { record } = createActions({
    RECORD: {
        ...loadActions,
        SAVING: asyncActions,
    },
});

export const { files } = createActions({
    FILES: {
        ...loadActions,
    },
});
