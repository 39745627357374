import { Button, ModalDialog } from "vetrf-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";

const ModalBox = ({ size, closeAfterConfirm, confirmBtn, cancelBtn, onConfirm, children = (handlerClose) => {}, footer, ...props }) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.onClose?.();
    };

    const onConfirmHandler = () => {
        if (closeAfterConfirm) {
            onClose();
        }
        onConfirm?.();
    };

    const handleClose = (action) => {
        if (action && isFunction(action)) {
            action();
        }
        onClose();
    };

    const defaultFooter = (
        <Fragment>
            <Button id="modal__confirm_btn" text={t("Да")} color="success" style={{ width: "90px" }} onClick={onConfirmHandler} />
            <Button id="modal__cancel_btn" text={t("Отмена")} color="default" onClick={handleClose} />
        </Fragment>
    );

    const sizeToWidth = (size) => {
        switch (size) {
            case "lg":
                return "min(800px, 100vw - 20px)";
            case "sm":
            default:
                return null;
        }
    };

    return (
        <ModalDialog
            {...props}
            width={sizeToWidth(size)}
            onClose={onClose}
            footer={footer?.(handleClose) ?? defaultFooter}
            children={typeof children === "function" ? children?.(handleClose) : children}
        />
    );
};

export default ModalBox;
