import React, { useEffect } from "react";
import { Alert, Page } from "vetrf-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useShortUrl } from "../api/pubQueries";
import FetchingPageIndicator from "../../components/fetching/FetchingPageIndicator";

const PubViewShortUrl = () => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const { shortUrl } = useParams();
    const pubECertQuery = useShortUrl(shortUrl);

    useEffect(() => {
        const shortUrlData = pubECertQuery.data;
        if (pubECertQuery.isSuccess && pubECertQuery.data) {
            navigate(`/verify/${shortUrlData.id}/${shortUrlData.referenceNumber}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pubECertQuery.isSuccess]);

    const title = t("Просмотр экспортного сертификата");
    const breadcrumbs = [
        {
            link: "/",
            text: t("Главная"),
        },
        {
            link: "/verify/",
            text: t("Проверка сертификата"),
        },
        {
            text: title,
        },
    ];

    return (
        <Page title={title} breadcrumbs={breadcrumbs} header={title}>
            {pubECertQuery.isLoading && <FetchingPageIndicator />}
            {pubECertQuery.isError && (
                <div className="row">
                    <div className="col-xs-6 col-xs-offset-3">
                        <Alert messageType="warning">
                            <h4>{t("Сертификат не найден")}</h4>
                            {t("По короткой ссылке сертификат не найден в реестре")}.
                        </Alert>
                    </div>
                </div>
            )}
        </Page>
    );
};

export default PubViewShortUrl;
