import { PROCESS_TYPE_LIST_LOAD, PROCESS_TYPE_LIST_RECEIVE, PROCESS_TYPE_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    processTypes: [],
    error: null,
    loading: false,
};

const processTypesLoad = (state, action) => ({ ...state, loading: true });

const getProcessTypes = (action) => (action.processTypes && action.processTypes.length > 0 ? action.processTypes : []);

const processTypesReceive = (state, action) => ({
    ...state,
    error: null,
    processTypes: getProcessTypes(action),
    loading: false,
});

const processTypesError = (state, action) => ({
    ...state,
    error: action.error,
    processTypes: [],
    loading: false,
});

export default function processTypes(state = initialState, action) {
    switch (action.type) {
        case PROCESS_TYPE_LIST_LOAD:
            return processTypesLoad(state, action);
        case PROCESS_TYPE_LIST_RECEIVE:
            return processTypesReceive(state, action);
        case PROCESS_TYPE_LIST_ERROR:
            return processTypesError(state, action);

        default:
            return state;
    }
}
