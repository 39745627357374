import { handleActions } from "redux-actions";
import { record, recordList, files } from "./actions";

const initialState = {
    record: {},
    list: {},
    filterObj: {},
    searchFormValue: {},
    error: null,
    loading: false,
    saving: false,
    downloading: false,
};

export default handleActions(
    {
        [recordList.load.request]: (state, action) => ({
            ...state,
            loading: true,
        }),
        [recordList.load.success]: (state, action) => ({
            ...state,
            list: action.payload,
        }),
        [recordList.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
        [recordList.filter.set]: (state, action) => ({
            ...state,
            searchFormValue: action.payload,
            filterObj: action.payload,
        }),
        [recordList.filter.clear]: (state) => ({
            ...state,
            searchFormValue: {},
            filterObj: {},
        }),
        [record.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [record.load.success]: (state, action) => ({
            ...initialState,
            record: action.payload,
        }),
        [record.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
        [record.saving.request]: (state) => ({
            ...state,
            saving: true,
        }),
        [record.saving.success]: (state, action) => ({
            ...state,
            record: action.payload,
            error: null,
            saving: false,
        }),
        [record.saving.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),

        [files.load.request]: (state) => ({
            ...state,
            downloading: true,
        }),
        [files.load.success]: (state, action) => ({
            ...state,
        }),
        [files.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
    },
    initialState
);
