import { Button } from "vetrf-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { isWbrAppInStatus } from "../../../../utils/constants/wbr/wbr-application-statuses";
import { useUserContext } from "../../../../session/UserContextProvider";
import { useWbrApplication } from "../../api/WbrApplicationQueries";
import { WBR_APP_BREADCRUMBS, WBR_APP_ROUTE } from "../../WbrApplicationRoutes";
import { wbrApplicationStatuses } from "../../../../utils/constants/wbr/wbr-application-statuses";
import Container from "../../../../components/page/Container";
import FormActionsPanel from "../../../../components/page/FormActionsPanel";
import PageContent from "../../../../components/page/PageContent";
import WbrApplicationClosePanel from "./components/WbrApplicationClosePanel";
import WbrApplicationNotFound from "./components/WbrApplicationNotFound";
import WbrApplicationStatusAlert from "./components/WbrApplicationStatusAlert";
import WbrApplicationToolbar from "./components/toolbar/WbrApplicationToolbar";
import WbrApplicationViewTable from "./components/WbrApplicationViewTable";

const WbrApplicationViewPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { user } = useUserContext();
    const { data: application, isLoading, isError } = useWbrApplication(id);

    const title = WBR_APP_ROUTE.VIEW.getTitle(t);
    const isUserLaboratory = application?.laboratory?.guid === user?.institution?.guid;
    const isShowRegistrationPanel = isWbrAppInStatus(application, wbrApplicationStatuses.RESEARCH_FINISHED);

    const toolbar = application && isUserLaboratory && <WbrApplicationToolbar application={application} />;
    return (
        <PageContent title={title} middleBreadcrumbs={WBR_APP_BREADCRUMBS.getRootBreadcrumbs()} toolbar={toolbar} isLoading={isLoading}>
            {isError && <WbrApplicationNotFound />}
            <Container>
                {isUserLaboratory && <WbrApplicationStatusAlert application={application} />}
                {isShowRegistrationPanel && isUserLaboratory && <WbrApplicationClosePanel application={application} />}
                {application && <WbrApplicationViewTable application={application} />}
            </Container>
            <FormActionsPanel>
                <Button id="back_btn" text={t("К списку")} size="sm" icon="list" href={WBR_APP_ROUTE.LIST.getFullPath()} />
                <FormActionsPanel.RightSide>{toolbar}</FormActionsPanel.RightSide>
            </FormActionsPanel>
        </PageContent>
    );
};

export default WbrApplicationViewPage;
