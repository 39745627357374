import React from "react";
import PropTypes from "prop-types";

import { DateRangeFormGroup, Form, InputFormGroup, SelectFormGroup } from "vetrf-ui";
import { connect } from "react-redux";
import { operationTypes } from "../../../../../../utils/constants/constants";

const LogListSearchForm = (props) => {
    const { searchFormValue = {}, handleChange, countries } = props;

    return (
        <Form name="logSearchForm">
            <div className={"col-xs-12 col-md-12 col-lg-6 col-lg-offset-3"}>
                <InputFormGroup
                    labelText="Номер сертификата"
                    name="shoulderNumber"
                    field="shoulderNumber"
                    fieldClassName="col-sm-9 col-md-8"
                    value={searchFormValue.shoulderNumber}
                    onChange={handleChange}
                />
                <SelectFormGroup
                    multiple
                    labelText="Страна"
                    name="countryList"
                    field="countryList"
                    labelKey="view"
                    valueKey="code"
                    fieldClassName="col-sm-9 col-md-8"
                    options={countries}
                    value={searchFormValue.countryList}
                    onChange={(country) => {
                        handleChange(country, "countryList");
                    }}
                />
                <SelectFormGroup
                    multiple
                    labelText="Тип операции"
                    // name="operationTypeList"
                    field="operationTypeList"
                    valueKey="type"
                    labelKey="name"
                    fieldClassName="col-sm-9 col-md-8"
                    options={operationTypes}
                    onChange={(logType) => {
                        handleChange(logType, "operationTypeList");
                    }}
                    value={searchFormValue.operationTypeList}
                />
                <DateRangeFormGroup
                    labelText="Операция выполнена в период"
                    beginDatePath="insertDateInterval.viewStartDate"
                    endDatePath="insertDateInterval.viewEndDate"
                    onChange={handleChange}
                    beginDate={searchFormValue.insertDateInterval && searchFormValue.insertDateInterval.viewStartDate}
                    height="30px"
                    endDate={searchFormValue.insertDateInterval && searchFormValue.insertDateInterval.viewEndDate}
                    fieldClassName="col-sm-9 col-md-8"
                    placeholder=""
                />
            </div>
        </Form>
    );
};

LogListSearchForm.propTypes = {
    searchFormValue: PropTypes.object,
    handleChange: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        countries: state.dictionary.countries.countries,
    };
}

export default connect(mapStateToProps)(LogListSearchForm);
