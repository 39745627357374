import { Button } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { useWbrApplicationResearchFinishedMutation } from "../../../../api/WbrApplicationStatusQueries";
import ButtonWithConfirmation from "../../../../../../components/modal/ButtonWithConfirmation";
import ModalBox from "../../../../../../components/modal/ModalBox";

const WbrApplicationResearchFinishedConfirmationButton = ({ application }) => {
    const { t } = useTranslation();
    const researchFinishedMutation = useWbrApplicationResearchFinishedMutation(application.id);

    const notifyCantChange = application.invoiceInfo?.invoice.id && !application.invoicePaymentInfo?.invoicePayment.id;

    const researchFinished = () => {
        researchFinishedMutation.mutate();
    };

    const header = t("Завершить рассмотрение");
    const shortModal = (onClose) => {
        const content = t('Перевести заявку в статус "Исследование завершено"?');
        return <ModalBox header={header} onConfirm={researchFinished} onClose={onClose} children={content} />;
    };

    const extendedModal = (onClose) => {
        const content = t(
            'Перевести заявку в статус "Исследование завершено"? После нажатия на кнопку "Да" изменение условий оплаты будет невозможно.'
        );
        return <ModalBox header={header} onConfirm={researchFinished} onClose={onClose} children={content} />;
    };

    return (
        <ButtonWithConfirmation
            button={(onClick) => (
                <Button
                    id="research_finish_btn"
                    text={t("Завершить рассмотрение")}
                    color="success"
                    size="sm"
                    icon="check-square-o"
                    onClick={onClick}
                />
            )}
            modal={(onClose) => (notifyCantChange ? extendedModal : shortModal)(onClose)}
        />
    );
};

export default WbrApplicationResearchFinishedConfirmationButton;
