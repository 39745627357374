import React, { Fragment } from "react";

import { ConfirmActionButton } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { useSyncDepartmentMutation } from "../../api/departmentQuery";

const SyncConfirmButton = ({ department, buttonId, onlyIcon, onSynced }) => {
    const { t } = useTranslation();
    const syncDepartmentMutation = useSyncDepartmentMutation();
    return (
        <ConfirmActionButton
            onConfirm={() => {
                syncDepartmentMutation.mutate(department, { onSuccess: (data) => onSynced?.(data)});
            }}
            id={buttonId}
            isOnlyIconButton={onlyIcon}
            icon="refresh"
            iconColor={onlyIcon ? "orange" : ""}
            iconSize={onlyIcon ? 150 : 100}
            buttonSize="sm"
            buttonColor="warning"
            tooltip={onlyIcon && t("Синхронизировать")}
            buttonText={t("Синхронизировать")}
            confirmBodyContent={
                <Fragment>
                    <span>
                        {t("Вы уверены, что хотите выполнить обновление сведений о площадке ")}
                        <b>{`${department.name} (№ ${department.number})`}</b>
                        {t(" из ИС Цербер?")}
                    </span>
                </Fragment>
            }
            confirmHeaderText={t("Синхронизация сведения о месте оформления с ИС Цербер")}
            confirmBtnText={t("Синхронизировать")}
            confirmBtnColor="warning"
            closeAfterConfirm
        />
    );
};

export default SyncConfirmButton;
