import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ajax from "../../../utils/ajax";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError } from "../../../common/store/notification/service";

let request = ajax("/rest-api/document-types");

export const requestDocumentTypeListByCountry = createAsyncThunk("documentType/listByCountry", async (country, { dispatch }) => {
    dispatch(startLoading());
    return request({
        type: "GET",
        url: "?countryGuid=" + country.guid,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при загрузке согласованной формы сертификата."));
        })
        .finally(() => dispatch(completeLoading()));
});

const initialState = {
    resultByCountry: [],
};

const documentTypeListSlice = createSlice({
    name: "documentTypesList",
    initialState,
    reducers: {},
    extraReducers: {
        [requestDocumentTypeListByCountry.pending]: (state, action) => {
            state.resultByCountry = [];
        },
        [requestDocumentTypeListByCountry.fulfilled]: (state, action) => {
            state.resultByCountry = action.payload;
        },
    },
});

export default documentTypeListSlice.reducer;
