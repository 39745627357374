import React from "react";
import { ModalDialog, Form, Tabs } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import RussianForm from "./inner/RussianForm";
import ForeignForm from "./inner/ForeignForm";
import { useBusinessParticipantSaveMutation } from "../../api/businessParticipantsQueries";

const AddBusinessParticipantModal = ({id, selectedTab, toggleModal}) => {
    const { t } = useTranslation();
    const saveParticipant = useBusinessParticipantSaveMutation();

    const onSaveHandler = (participant) => {
        saveParticipant.mutate(participant, { onSuccess: () => toggleModal?.() });
    }

    const tabs = [
        {
            id: id + "__russian_tab",
            name: "russian",
            label: "Отечественные",
            content: <RussianForm prefixId={id} onToggleModal={toggleModal} onSave={onSaveHandler} />,
        },
        {
            id: id + "__foreign_tab",
            name: "foreign",
            label: "Зарубежные",
            content: <ForeignForm prefixId={id} onToggleModal={toggleModal} onSave={onSaveHandler} />,
        },
    ];

    return (
        <div className="inline">
            <ModalDialog id={id} onClose={toggleModal} header={t("Добавление хозяйствующего субъекта")} footer={<div />} footerClass="none">
                <Form name="participantsForm" className={""}>
                    <Tabs tabs={tabs} selectedName={selectedTab} />
                </Form>
            </ModalDialog>
        </div>
    );
}

export default AddBusinessParticipantModal;