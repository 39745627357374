import { Fragment } from "react";
import merge from "lodash/merge";

import { getUnknownNormalText } from "./constants/constants";
import { PRECISION_TYPE, getFloatingDateView } from "../components/form/FloatingDateFormGroup";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const getWeightView = (weightMeasure) => {
    if (!weightMeasure || !weightMeasure.weight) return null;
    return `${weightMeasure.weight} ${weightMeasure.unit?.shortName ?? ""}`;
};

export const getTransportListView = (transportMovements, unknown) => {
    const getTransportView = (transportMovement) => {
        return `${transportMovement?.transportType?.view}: ${transportMovement?.transport?.number || unknown}`;
    };

    if (!transportMovements) return unknown;
    if (transportMovements.length <= 1) return getTransportView(transportMovements[0]);
    return (
        <ul className="list-unstyled">
            {transportMovements
                .slice()
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((it, index) => (
                    <li key={index}>
                        <i className="ace-icon fa fa-caret-right blue" />
                        {getTransportView(it)}
                    </li>
                ))}
        </ul>
    );
};

export const getFileMetaView = (file) => {
    if (!file) return;
    const fileName = file.nativeName ?? file.name ?? "unknown";
    const size = Math.floor(file.size / 1024);
    const sizeView = file.size ? ", " + (size || 1) + " KB" : "";
    return `${fileName}${sizeView} `;
};

export const forceDownloadFile = (url) => {
    const aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = true;
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
};

export const getDateIntervalView = (interval, unknown) => {
    if (interval) {
        if (interval.endDateTime) {
            return (
                <Fragment>
                    {interval.startDateTime ?? unknown} - {interval.endDateTime ?? unknown}
                </Fragment>
            );
        }
        return <Fragment>{interval.startDateTime ?? unknown}</Fragment>;
    }
    return null;
};

export const getFloatingDateIntervalView = (date, options = {}) => {
    if (!date || (!date.startDateTime && !date.endDateTime)) return null;
    const mergedOptions = merge({ view: { [PRECISION_TYPE.NOT_LIMITED]: i18n.t("не ограничен") } }, options);
    if (date.startDateTime?.value === date.endDateTime?.value) {
        return getFloatingDateView(date.startDateTime, mergedOptions);
    }
    const startDate = getFloatingDateView(date.startDateTime, mergedOptions) ?? mergedOptions.unknown ?? "-";
    const endDate = getFloatingDateView(date.endDateTime, mergedOptions) ?? mergedOptions.unknown ?? "-";
    return `${startDate} - ${endDate}`;
};

export const getProductionDateView = (date) => {
    return getFloatingDateIntervalView(date, { unknown: getUnknownNormalText(i18n.t) });
};

export const getExpireDateView = (date) => {
    return getFloatingDateIntervalView(date, {
        unknown: getUnknownNormalText(i18n.t),
        view: { [PRECISION_TYPE.NOT_LIMITED]: i18n.t("срок годности не ограничен") },
    });
};

export function getBooleanAsText(bool, t) {
    const yes = t("Да");
    const no = t("Нет");
    return bool ? yes : no;
}

export const getMultiLanguageView = (text, enText) => {
    if (!text && !enText) return null;
    if (!text) return enText;
    if (!enText) return text;
    return `${text} (${enText})`;
};

export function useMultiLangView() {
    const { i18n } = useTranslation();
    const isLatinText = i18n.resolvedLanguage !== "ru";

    return (text, latinText) => {
        if (isLatinText) {
            return latinText || text;
        }
        return text || latinText;
    };
}
