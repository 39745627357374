import React from "react";
import { Page } from "vetrf-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ForeignForm from "../../containers/businessParticipants/inner/ForeignForm";
import RussianForm from "../../containers/businessParticipants/inner/RussianForm";
import { RUSSIA } from "../../../utils/constants/constants";
import { useBusinessParticipantQuery, useBusinessParticipantSaveMutation } from "../../api/businessParticipantsQueries";

const EditFormPage = () => {
    const { id } = useParams();
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const { data: participant } = useBusinessParticipantQuery(id);
    const saveParticipant = useBusinessParticipantSaveMutation();

    const onSave = (participant) => {
        saveParticipant.mutate(participant, { onSuccess: () => navigate("..") });
    };

    const onCancel = () => {
        navigate("..")
    }

    const title = t("Редактирование информации о хозяйствующем субъекте");

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "participant_list_page",
            link: "/participants/",
            text: t("Список хозяйствующих субъектов"),
        },
        {
            active: true,
            text: title,
        },
    ];

    return (
        <Page header={title} breadcrumbs={breadcrumbs} title={title}>
            <div className="col-lg-offset-2 col-lg-8 col-md-12">
                {participant?.country?.guid === RUSSIA.guid && <RussianForm item={participant} onSave={onSave} onToggleModal={onCancel} />}
                {participant?.country?.guid !== RUSSIA.guid && <ForeignForm item={participant} onSave={onSave} onToggleModal={onCancel} />}
            </div>
        </Page>
    );
};

export default EditFormPage;
