import { businessParticipants } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction, createPageableRequestParams } from "../../../common/utils/service-utils";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { notifyError, notifySuccess } from "../../../common/store/notification/service";

const request = ajax("/rest-api/dictionary/");
const loadListErrorMessage = "Произошла ошибка при загрузке списка контрагентов";
const loadItemErrorMessage = "Произошла ошибка при загрузке сведений о хозяйствующем субъекте";

export const requestParticipants = (pageable) =>
    createLoadAsyncAction(
        businessParticipants.page,
        request,
        createPageableRequestParams(pageable, "business-participants/by-org"),
        loadListErrorMessage
    );

export const requestParticipantsForAutocomplete = (name) => (dispatch) => {
    dispatch(businessParticipants.load.request());
    dispatch(startLoading());

    request({
        type: "GET",
        url: `business-participants/autocomplete?name=${name}`,
    })
        .then((data) => {
            dispatch(completeLoading());
            dispatch(businessParticipants.load.success(data));
        })
        .catch((error) => {
            dispatch(notifyError(error, loadListErrorMessage));
            dispatch(completeLoading());
            dispatch(businessParticipants.load.error(error));
        });
};

export const requestItem = (id) =>
    createLoadAsyncAction(businessParticipants.item, request, { type: "GET", url: `business-participants/${id}` }, loadItemErrorMessage);

export const requestSaveItem = (item) => (dispatch) => {
    dispatch(businessParticipants.save.request());
    dispatch(startLoading());

    request({
        url: `business-participants/`,
        data: item,
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно сохранена в справочник"));
            dispatch(completeLoading());
            dispatch(businessParticipants.save.success());
        })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при сохранении записи в справочник"));
            dispatch(completeLoading());
            dispatch(businessParticipants.save.error(error));
        });
};

export const deleteItem = (item) => (dispatch) => {
    dispatch(businessParticipants.delete.request());
    dispatch(startLoading());

    request({
        url: `business-participants/${item.id}`,
        type: "DELETE",
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно удалена из справочника"));
            dispatch(completeLoading());
            dispatch(businessParticipants.delete.success());
        })
        .catch((error) => {
            dispatch(notifyError(error.status, "Произошла ошибка при удалении записи"));
            dispatch(completeLoading());
            dispatch(businessParticipants.delete.error(error));
        });
};

export const requestBusinessEntitiesAutocomplete = (name) =>
    createLoadAsyncAction(
        businessParticipants.entityAutocomplete,
        request,
        {
            type: "GET",
            url: `business-entity?inn=${name}`,
        },
        loadListErrorMessage
    );

export const requestBusinessEntitiesAutocompleteReset = () => (dispatch) => dispatch(businessParticipants.entityAutocompleteReset());

export const requestAutocompleteReset = () => (dispatch) => dispatch(businessParticipants.autocompleteReset());
