import React from "react";
import isEmpty from "lodash/isEmpty";
import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import { getUnknownCursiveText } from "../../../../utils/constants/constants";
import { workingDaysView } from "../../../../utils/filterDepartments";

const ContactInfoView = ({ department }) => {
    const { t } = useTranslation();
    const unknown = getUnknownCursiveText(t);
    const commonRows = [
        {
            header: {
                key: "contactEmails",
                title: t("Адреса электронной почты"),
            },
            dataFormatter: (item) => (item?.contactEmailList && !isEmpty(item.contactEmailList) ? item.contactEmailList.join(", ") : unknown),
        },
        {
            header: {
                key: "contactPhoneNumbers",
                title: t("Контактные телефоны"),
            },
            dataFormatter: (item) =>
                item?.contactPhoneNumberList && !isEmpty(item.contactPhoneNumberList) ? item.contactPhoneNumberList.join(", ") : unknown,
        },
        {
            header: {
                key: "responsibleVeterinarians",
                title: t("Сотрудники"),
            },
            dataFormatter: (item) =>
                item?.responsibleVeterinarianList && !isEmpty(item.responsibleVeterinarianList)
                    ? item.responsibleVeterinarianList.map((vet) => vet.fio).join(", ")
                    : unknown,
        },
        {
            header: {
                key: "country",
                title: t("Расписание работы"),
            },
            getCellClass: () => "white-space_origin",
            dataFormatter: (item) => (item?.officeHours && !isEmpty(item.officeHours) ? workingDaysView(item.officeHours) : unknown),
        },
    ];

    return (
        <div className={"row"}>
            <SubHeader
                header={t("Контактная информация")}
                description={t("Контактная информация доступная для просмотра при подаче заявки в ECert.Питомцы")}
            />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__common"
                    itemList={department ? [{ item: department }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={commonRows}
                />
            </div>
        </div>
    );
};

export default ContactInfoView;
