import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class CountryFlag extends Component {
    render() {
        const { country, nameField } = this.props;
        const field = nameField ? nameField : "name";
        return (
            <Fragment>
                <span className={`fi fi-${country && country.code && country.code.toLowerCase()}`} />
                &nbsp;{country && country[field]}
            </Fragment>
        );
    }
}

CountryFlag.propTypes = {
    nameField: PropTypes.string,
    country: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
    }),
};
export default CountryFlag;
