import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TimeLineListContainer } from "vetrf-ui";
import { transformDateByFormat } from "../../../../../utils/transformers/transformDateByFormat";

const OperationLogTimeLine = (props) => {
    const createTimeLine = (logs) => {
        if (!logs || !logs.content) return [];
        const items = logs.content.map((item) => {
            return {
                content: (
                    <span>
                        {item.error && (
                            <details>
                                <summary>Подробнее</summary>
                                {item.error}
                            </details>
                        )}
                    </span>
                ),
                indicator: {
                    icon: item.result ? "ace-icon fa fa-thumbs-up btn btn-yellow no-hover" : "ace-icon fa fa-thumbs-down btn btn-grey no-hover",
                },
                date: {
                    className: "label label-info label-sm",
                    content: transformDateByFormat(item.insertDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
                },
                widgetMetaData: {
                    className: "transparent",
                    header: {
                        title: {
                            content: <span>{item.operationType}</span>,
                        },
                    },
                },
            };
        });
        return [{ items: items }];
    };

    return <TimeLineListContainer timeLineList={createTimeLine(props.logs)} timeLineContainerClassName={"block-scroll"} />;
};

OperationLogTimeLine.propTypes = {
    logs: PropTypes.object,
};

export default withTranslation("translations")(OperationLogTimeLine);
