import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../errors/PageNotFound";
import SystemNotificationsPage from "./containers/SystemNotificationsListPage";

const SystemNotificationsRoutes = () => {
    return (
        <Routes>
            <Route exact path="" element={<SystemNotificationsPage />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default SystemNotificationsRoutes;
