import { availableAppStatusReasons, appStatusReasons } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";
import { defaultGetRequestParams } from "../../../common/utils/constants";

const request = ajax("/rest-api/dictionary/applicationStatusReasons/");
const errorMessage = "Произошла ошибка при загрузке причин отклонения заявки";

export const requestAppStatusReasons = () =>
    createLoadAsyncAction(
        appStatusReasons,
        request,
        {
            ...defaultGetRequestParams,
            url: "all",
        },
        errorMessage
    );

export const requestAvailableAppStatusReasons = () =>
    createLoadAsyncAction(
        availableAppStatusReasons,
        request,
        {
            ...defaultGetRequestParams,
            url: "available",
        },
        errorMessage
    );
