import { handleActions } from "redux-actions";
import { businessParticipants } from "./actions";
import { isArray } from "lodash";

const initialState = {
    businessParticipants: [],
    entityAutocompleteList: [],
    page: {},
    item: {},
    error: null,
    loading: false,
};

export default handleActions(
    {
        [businessParticipants.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [businessParticipants.load.success]: (state, { payload = {} }) => ({
            ...state,
            businessParticipants: isArray(payload) ? payload : state.businessParticipants,
            loading: false,
        }),
        [businessParticipants.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [businessParticipants.page.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [businessParticipants.page.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            page: payload,
            loading: false,
        }),
        [businessParticipants.page.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [businessParticipants.save.request]: (state) => ({
            ...state,
            error: null,
            saving: true,
        }),
        [businessParticipants.save.success]: (state) => ({
            ...state,
            businessParticipants: [],
            page: {},
            item: null,
            error: null,
            saving: false,
        }),
        [businessParticipants.save.error]: (state, action) => ({
            ...state,
            error: action.payload,
            saving: false,
        }),
        [businessParticipants.delete.request]: (state) => ({
            ...state,
            error: null,
            deleting: true,
        }),
        [businessParticipants.delete.success]: (state) => ({
            ...state,
            businessParticipants: [],
            error: null,
            deleting: false,
        }),
        [businessParticipants.delete.error]: (state, action) => ({
            ...state,
            error: action.payload,
            deleting: false,
        }),
        [businessParticipants.item.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [businessParticipants.item.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            item: payload,
            loading: false,
        }),
        [businessParticipants.item.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
            loading: false,
        }),
        [businessParticipants.entityAutocomplete.load.request]: (state) => ({
            ...state,
            error: null,
            loading: true,
        }),
        [businessParticipants.entityAutocomplete.load.success]: (state, { payload = {} }) => ({
            ...state,
            error: null,
            entityAutocompleteList: payload,
            loading: false,
        }),
        [businessParticipants.entityAutocomplete.load.error]: (state, action) => ({
            ...state,
            error: action.payload,
        }),
        [businessParticipants.entityAutocompleteReset]: (state) => ({
            ...state,
            entityAutocompleteList: null,
        }),
        [businessParticipants.autocompleteReset]: (state) => ({
            ...state,
            businessParticipants: null,
        }),
    },
    initialState
);
