import React from "react";
import WbrConclusionDocumentView from "./WbrConclusionDocumentView";
import WbrConclusionConsignorView from "./WbrConclusionConsignorView";
import WbrConclusionConsigneeView from "./WbrConclusionConsigneeView";
import WbrConclusionTransportView from "./WbrConclusionTransportView";
import WbrConclusionConsignmentItemsView from "./WbrConclusionConsignmentItemsView";

const WbrConclusionView = ({ conclusion }) => {
    return (
        <div className={"row"}>
            <WbrConclusionDocumentView conclusion={conclusion} file={conclusion?.file} />
            <WbrConclusionConsignorView conclusion={conclusion} />
            <WbrConclusionConsigneeView conclusion={conclusion} />
            <WbrConclusionTransportView conclusion={conclusion} />
            <WbrConclusionConsignmentItemsView conclusion={conclusion} />
        </div>
    );
};

export default WbrConclusionView;
