import { Table } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import React from "react";

import { getUnknownNormalText } from "../../../../../utils/constants/constants";

const WbrApplicationRevisionReasonsTable = ({ reasons }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const columns = [
        {
            key: "places",
            title: "Место доработки",
            dataFormatter: (item) => item.places.map(place => place.view).join(", ") ?? unknown,
        },
        {
            key: "description",
            title: "Необходимые доработки",
            dataFormatter: (item) => item.description ?? unknown,
        },
    ];

    return (
        <div className="row">
            <Table dataList={reasons} columns={columns} />
        </div>
    );
};

export default WbrApplicationRevisionReasonsTable;
