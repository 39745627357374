import { configureStore } from "@reduxjs/toolkit";

import applicationStore from "./application/store/reducers";
import documentTypeStore from "./document-type/store/reducers";
import ecertStore from "./ecert/store/reducers";
import processing from "./processing/reducers/";
import record from "./record/store/reducers";
import dictionary from "./dictionary/reducers/";
import catalogStore from "./dictionary/store/reducers";
import session from "./session/store/reducer";
import common from "./common/store/reducers";

const store = configureStore({
    reducer: {
        applicationStore,
        documentTypeStore,
        ecertStore,
        record,
        processing,
        dictionary,
        session,
        common,
        catalogStore,
    },
});

export default store;
