import { useQueryClient } from "react-query";
import {
    API_URL,
    useDictionaryCreateMutation,
    useDictionaryDeleteMutation,
    useDictionaryListQuery,
    useDictionaryOneQuery,
    useDictionaryPageQuery,
} from "./dictionaryQueries";

const baseUrl = "/official-veterinarians";
const byOrgUrlPart = "/by-org";
const deleteUrlPart = "/delete";

const itemName = "ветеринарного врача";
const dictionaryName = "ветеринарных врачей";

export function useOfficialVeterinarianQuery(id) {
    return useDictionaryOneQuery(baseUrl, id, { dictionaryItemName: itemName });
}

export function useOfficialVeterinariansListQuery() {
    return useDictionaryListQuery(baseUrl, { dictionaryName: dictionaryName });
}

export function useOfficialVeterinariansPageByOrgQuery(pageParams, searchParams) {
    return useDictionaryPageQuery(baseUrl + byOrgUrlPart, {...pageParams, ...searchParams}, { dictionaryName: dictionaryName });
}

export function useOfficialVeterinarianSaveMutation() {
    const queryClient = useQueryClient();
    return useDictionaryCreateMutation(baseUrl, {
        dictionaryItemName: itemName,
        onSuccess: () => {
            queryClient.invalidateQueries([API_URL + baseUrl + byOrgUrlPart]);
        },
    });
}

export function useOfficialVeterinarianDeleteMutation() {
    const queryClient = useQueryClient();
    return useDictionaryDeleteMutation(baseUrl + deleteUrlPart, {
        dictionaryItemName: itemName,
        onSuccess: () => {
            queryClient.invalidateQueries([API_URL + baseUrl + byOrgUrlPart]);
        },
    });
}
