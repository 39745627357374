import { useQueryClient } from "react-query";
import { queryClient } from "../..";

import NotificationManager from "../../common/utils/notification/NotificationManager";
import { useOneItemQuery, useCreateMutation, useDeleteMutation, useCustomMutation } from "../../utils/commonQueryBase";
import { useDictionaryPageQuery } from "../../dictionary/api/dictionaryQueries";

const baseUrl = "/rest-api/dictionary/authority-departments/";
const dictionaryBaseUrl = "/authority-departments/page";

export function useGetDepartmentPage(pageParams, searchParams) {
    return useDictionaryPageQuery(
        dictionaryBaseUrl,
        { ...pageParams, ...searchParams },
        {
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка мест оформления."),
        }
    );
}

export function useGetDepartment(id) {
    return useOneItemQuery(baseUrl, id, {
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке сведений о месте оформления."),
    });
}

export function useSaveDepartmentMutation() {
    return useCreateMutation(baseUrl, {
        onSuccess: () => NotificationManager.success("Сведения о месте оформления успешно сохранены."),
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при сохранении сведений о месте оформления."),
    });
}
export function useDeleteDepartmentMutation() {
    return useDeleteMutation(baseUrl, {
        onSuccess: () => NotificationManager.success("Сведения о месте оформления успешно удалены."),
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при удалении сведений о месте оформления."),
    });
}
export function useSyncDepartmentMutation() {
    return useCustomMutation(
        baseUrl,
        (data) => {
            return { type: "GET", url: "sync/" + data.id };
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([baseUrl, data.id]);
                queryClient.setQueryData([baseUrl, data.id], data);
                NotificationManager.success("Сведения о месте оформления успешно синхронизированы с ИС Цербер.");
            },
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при синхронизации сведений о месте оформления."),
        }
    );
}
export function useSyncAllDepartmentsMutation() {
    return useCustomMutation(
        baseUrl,
        () => {
            return { type: "GET", url: "sync/" };
        },
        {
            onSuccess: () => NotificationManager.success("Сведения о местах оформления успешно синхронизированы с ИС Цербер."),
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при синхронизации сведений о местах оформления."),
        }
    );
}

export function useIsDepartmentMutating() {
    const queryClient = useQueryClient();
    return queryClient.isMutating(baseUrl);
}
