import { Alert, Button, deleteIn, Form, InputFormGroup, Page, SelectFormGroup, setIn } from "vetrf-ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { isEmptyValidationError } from "../../../utils/isEmptyAfterOmitEmpty";
import { useOfficialVeterinarianQuery, useOfficialVeterinarianSaveMutation } from "../../api/officialVeterinarianQueries";
import * as dictionaryService from "../../store/service";
import * as officialVeterinariansService from "../../store/officialVeterinarians/service";
import FetchingPageIndicator from "../../../components/fetching/FetchingPageIndicator";

const getTitle = (isEdit) => (isEdit ? "Редактирование " : "Добавление ") + "информации о ветеринарном враче";

const OfficialVeterinarianFormPage = ({ users, organizations, usersLoading, organizationsLoading, dictionaryService }) => {
    const { t } = useTranslation("translation");
    const { id } = useParams();
    let navigate = useNavigate();
    const isEdit = !isEmpty(id);

    const veterinarianQuery = useOfficialVeterinarianQuery(id);
    const [veterinarian, setVeterinarian] = useState({});
    const [usedVeterinarian, setUsedVeterinarian] = useState();

    useEffect(() => {
        const veterinarian = veterinarianQuery.data;
        if (veterinarian) {
            setVeterinarian(veterinarian);
            if (veterinarian.user) {
                setShowOrganization(false);
                setUsedVeterinarian(veterinarian.user);
            }
        }
    }, [veterinarianQuery.data]);

    const invalidFields = useRef({});
    const saveMutation = useOfficialVeterinarianSaveMutation();
    const [showError, setShowError] = useState(false);
    const [showOrganization, setShowOrganization] = useState(true);

    useEffect(() => {
        if (!isEmpty(id)) {
            const { requestItem } = officialVeterinariansService;
            requestItem(id);
        }
        const { requestUsersWhereVeterinarianIsNull, requestVDOrganizationList } = dictionaryService;
        requestUsersWhereVeterinarianIsNull();
        if (isEmpty(organizations)) {
            requestVDOrganizationList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const onSave = () => {
        if (!showError) setShowError(true);

        setTimeout(() => {
            if (isEmptyValidationError(invalidFields)) {
                saveMutation.mutate(veterinarian, {
                    onSuccess: () => {
                        navigate("..");
                    },
                });
            }
        }, 300);
    };

    const onChangeHandler = (value, field) => {
        let newItem = setIn(veterinarian, field, value);
        if (field === "user") {
            newItem = setIn(newItem, "fio", value && value.fio);
            newItem = setIn(newItem, "position", value && value.position);
            newItem = setIn(newItem, "englishFio", value && value.fioEnglish);
            newItem = setIn(newItem, "englishPosition", value && value.positionEnglish);
        }
        setVeterinarian(newItem);
    };

    const toggle = () => {
        let newItem = setIn(veterinarian, "organization", undefined);
        newItem = setIn(newItem, "user", undefined);
        setVeterinarian(newItem);
        setShowOrganization(!showOrganization);
    };

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "breadcrumb__home_page",
            link: "/veterinarians/",
            text: t("Список ветеринарных врачей"),
        },
        {
            active: true,
            text: t(getTitle(isEdit)),
        },
    ];

    const isDisabledSave = isEmpty(veterinarian);
    const withCurrentUserSelect = usedVeterinarian && !users.includes(usedVeterinarian) && isEdit;
    const availableUsers = withCurrentUserSelect ? [usedVeterinarian, ...users] : users

    return (
        <Page header={getTitle(isEdit)} breadcrumbs={breadcrumbs} title={getTitle(isEdit)}>
            {(usersLoading || organizationsLoading) && <FetchingPageIndicator />}
            {!(usersLoading || organizationsLoading) && (
                <Fragment>
                    <div className="col-lg-offset-2 col-lg-8 col-md-12">
                        {veterinarianQuery.error && (
                            <Alert messageType="danger">
                                <p>
                                    <i className="fa fa-exclamation-triangle" />
                                    &nbsp;&nbsp;
                                    <strong>{t("Произошла ошибка при сохранении.")}</strong>
                                </p>
                                <p>{veterinarianQuery.error.statusText}</p>
                            </Alert>
                        )}
                        <Form name="modifyVeterinarianForm">
                            {!showOrganization && (
                                <SelectFormGroup
                                    labelText={t("Пользователь системы")}
                                    disabled={!usedVeterinarian && users && users.length === 0}
                                    options={availableUsers}
                                    valueKey="id"
                                    labelKey="fio"
                                    value={veterinarian?.user}
                                    onChange={onChangeHandler}
                                    field={`user`}
                                    placeholder={t("Выберите пользователя...")}
                                    showError={showError}
                                    errorHandler={errorHandler}
                                    help={t(
                                        "Выберите пользователя системы для связи с записью ветеринара. В списке показываются только не прикрепленные к ветеринару пользователи"
                                    )}
                                    additionalBlock={
                                        <div>
                                            <Button
                                                id="another_org_add_user_btn"
                                                className="btn-link btn-xs"
                                                text={t("Перейти к добавлению пользователя не из моей организации")}
                                                onClick={() => {
                                                    toggle();
                                                }}
                                            />
                                        </div>
                                    }
                                />
                            )}{" "}
                            {showOrganization && (
                                <SelectFormGroup
                                    labelText={t("Организация")}
                                    require
                                    options={organizations ? organizations : []}
                                    valueKey="guid"
                                    labelKey="shortName"
                                    value={veterinarian?.organization}
                                    onChange={onChangeHandler}
                                    field={`organization`}
                                    placeholder={t("Выберите организацию...")}
                                    showError={showError}
                                    errorHandler={errorHandler}
                                    additionalBlock={
                                        <div>
                                            <Button
                                                id="your_org_add_user_btn"
                                                className="btn-link btn-xs"
                                                text={t("Перейти к добавлению пользователя из своей организации")}
                                                onClick={() => {
                                                    toggle();
                                                }}
                                            />
                                        </div>
                                    }
                                />
                            )}
                            <h3 className="header smaller lighter blue">{t("Сведения на русском языке")}</h3>
                            <InputFormGroup
                                disabled={!isEmpty(veterinarian.user)}
                                labelText={t("ФИО")}
                                showError={showError}
                                errorHandler={errorHandler}
                                value={veterinarian?.fio}
                                field="fio"
                                onChange={onChangeHandler}
                                require={true}
                            />
                            <InputFormGroup
                                labelText={t("Должность")}
                                showError={showError}
                                errorHandler={errorHandler}
                                value={veterinarian.position}
                                field="position"
                                onChange={onChangeHandler}
                                require={true}
                            />
                            <h3 className="header smaller lighter blue">{t("Сведения на английском языке")}</h3>
                            <InputFormGroup
                                labelText={t("ФИО")}
                                showError={showError}
                                errorHandler={errorHandler}
                                value={veterinarian.englishFio}
                                field="englishFio"
                                onChange={onChangeHandler}
                                validateLatin={{
                                    errorText: t("Поле допускает ввод только латинских символов."),
                                    errorTextBeforeDetailing: t("Разбор ошибок - "),
                                }}
                                help={t("Укажите ФИО на английском языке, так как оно будет печататься на сертификате")}
                            />
                            <InputFormGroup
                                labelText={t("Должность")}
                                showError={showError}
                                errorHandler={errorHandler}
                                value={veterinarian.englishPosition}
                                field="englishPosition"
                                onChange={onChangeHandler}
                                validateLatin={{
                                    errorText: t("Поле допускает ввод только латинских символов."),
                                    errorTextBeforeDetailing: t("Разбор ошибок - "),
                                }}
                                help={t("Укажите должность на английском языке, так как она будет печататься на сертификате")}
                            />
                        </Form>
                        <div className="clearfix form-actions no-margin-bottom col-xs-12">
                            <Button id="cancel_btn" size="sm" href={"/veterinarians/"} text={t("Отмена")} />
                            <div className="pull-right">
                                <Button id="save_btn" size="sm" color="success" onClick={onSave} text={t("Сохранить")} disabled={isDisabledSave} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Page>
    );
};

const mapStateToProps = (state) => ({
    users: state.catalogStore.usersStore.usersWithoutVeterinarian,
    usersLoading: state.catalogStore.usersStore.loading,
    organizations: state.catalogStore.organizationListStore.organizationList,
    organizationsLoading: state.catalogStore.organizationListStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
    dictionaryService: bindActionCreators(dictionaryService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficialVeterinarianFormPage);
