import { Button, Icon, PaginationRow, Table } from "vetrf-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { getUnknownNormalText } from "../../../../utils/constants/constants";
import { transformL10nECertStatus } from "../../../../utils/transformers/transformECertStatus";
import { viewRoute } from "../../../WbrConclusionRoutes";
import { wbrComplianceDecision } from "../../../../utils/constants/wbr/wbr-constants";
import CountryFlag from "../../../../components/countryFlag/CountryFlag";

const tableId = "document_type";
const unknown = "не указано";

const getStatusLabel = (status, t) => {
    return <span>{transformL10nECertStatus(status, t)}</span>;
};

const getProductView = (item) => {
    return item ? `${item.subProduct?.name} (код ТНВЭД ${item.cnCode})` : unknown;
};

const getProductList = (items) => {
    if (items && items.length <= 1) return getProductView(items[0]);
    else {
        return (
            items && (
                <ul className="list-unstyled">
                    {items.slice().map((it, index) => (
                        <li key={index}>
                            <i className="ace-icon fa fa-caret-right blue" />
                            {getProductView(it)}
                        </li>
                    ))}
                </ul>
            )
        );
    }
};

const getDecisionView = (decision) => {
    if (!decision) return null;
    switch (decision) {
        case wbrComplianceDecision.MEET_REQUIREMENTS:
            return <Icon icon="check-circle" color="green" />;
        case wbrComplianceDecision.NOT_MEET_REQUIREMENTS:
            return <Icon icon="times-circle" color="red" />;
        default:
            return null;
    }
};

const getTransportList = (transportMovements) => {
    if (transportMovements && transportMovements.length <= 1) return getTransportView(transportMovements[0]);
    else {
        return (
            transportMovements && (
                <ul className="list-unstyled">
                    {transportMovements
                        .slice()
                        .sort((a, b) => a.orderNumber - b.orderNumber)
                        .map((it, index) => (
                            <li key={index}>
                                <i className="ace-icon fa fa-caret-right blue" />
                                {getTransportView(it)}
                            </li>
                        ))}
                </ul>
            )
        );
    }
};

const getTransportView = (transportMovement) => {
    return `${transportMovement?.transportType?.view}: ${transportMovement?.transport?.number || unknown}`;
};

const getUserView = (user, institution, date) => {
    const fio = user && user.fio ? user.fio : unknown;
    return `${fio} (${date}, ${institution.shortName})`;
};

const WbrConclusionPageableTable = ({ page, onChangePage, onChangeSizePerPage, sortedId }) => {
    const { t, i18n } = useTranslation("translations");
    const nameField = i18n.resolvedLanguage === "ru" ? "name" : "englishName";
    const unknown = getUnknownNormalText(t);

    const columns = [
        {
            key: "referenceNumber",
            title: t("Номер заключения"),
            className: "align-center",
            dataFormatter: (item) => <Link to={viewRoute.replace(":id", item.id)}>{item?.referenceNumber || unknown}</Link>,
        },
        {
            key: "issueDateTime",
            title: t("Дата оформления"),
            className: "align-center",
            dataFormatter: (item) => item?.issueDateTime || unknown,
        },
        {
            key: "country",
            title: t("Страна назначения"),
            dataFormatter: (item) => <CountryFlag country={item.consignment.importCountry} nameField={nameField} />,
        },
        {
            key: "documentStatus",
            title: t("Статус"),
            dataFormatter: (item) => getStatusLabel(item.documentStatus, t),
        },
        {
            key: "decision",
            title: t("Решение"),
            className: "align-center",
            dataFormatter: (item) => getDecisionView(item.complianceConclusion?.decision) ?? unknown,
        },
        {
            key: "productionTypeName",
            title: t("Продукция"),
            dataFormatter: (item) => getProductList(item?.consignment?.consignmentItems),
        },
        {
            key: "transportMovement",
            title: t("Транспортное средство"),
            dataFormatter: (item) => getTransportList(item?.consignment?.transportMovements),
        },
        {
            key: "user",
            title: t("Зарегистрирован"),
            className: "align-center",
            dataFormatter: (item) => getUserView(item.createdByUser, item.createdByInstitution, item.createDateTime),
        },
        {
            key: "actions",
            title: "",
            width: "40px",
            className: "align-center",
            buttonFormatter: (item, tableId, index) => {
                const viewButtonId = `${tableId}__view_${index}`;
                return (
                    <span>
                        <Button
                            href={viewRoute.replace(":id", item.id)}
                            id={viewButtonId}
                            icon="bars"
                            iconSize={130}
                            onlyIcon={true}
                            tooltip={t("Просмотр")}
                        />
                    </span>
                );
            },
        },
    ];

    const pagination = (
        <PaginationRow
            id={tableId}
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            id={tableId}
            className="table table-striped table-bordered table-hover"
            dataList={page?.content}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};
export default WbrConclusionPageableTable;
