import React from "react";
import PropTypes from "prop-types";
import { transformECertStatus } from "../../../../../utils/transformers/transformECertStatus";
import { Button, PaginationRow, Table } from "vetrf-ui";
import { transformDateByFormat } from "../../../../../utils/transformers/transformDateByFormat";

const unknown = "не указано";

const ECertImportListTable = (props) => {
    const { page, handleChangePage, handleChangeSizePerPage, sizePerPage, itemCount = 0, list = [], sortedId } = props;

    const columns = [
        {
            key: "shoulderNumber",
            title: "Номер сертификата",
            className: "align-left",
            width: "16%",
            dataFormatter: (item) => item.shoulderNumber || unknown,
        },
        {
            key: "countryCode",
            title: "Страна",
            className: "align-center",
            width: "5%",
            dataFormatter: (item) => item.country.code || unknown,
        },
        {
            key: "status",
            title: "Статус",
            width: "5%",
            className: "align-center",
            dataFormatter: (item) => transformECertStatus(item.status),
        },
        {
            key: "approvedDate",
            title: "Дата оформления",
            width: "12%",
            className: "align-center",
            dataFormatter: (item) => transformDateByFormat(item.approvedDate, "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
        },
        {
            key: "consignor",
            title: "Грузоотправитель  (ХС)",
            width: "15%",
            className: "align-center",
            dataFormatter: (item) => (item.consignor ? item.consignor.name : unknown),
        },
        {
            key: "consignee",
            title: "Грузополучатель (ХС)",
            width: "15%",
            className: "align-center",
            dataFormatter: (item) => (item.consignee ? item.consignee.name : unknown),
        },
        {
            key: "despatch",
            title: "Грузоотправитель (Предприятие)",
            width: "15%",
            className: "align-center",
            dataFormatter: (item) => (item.despatch ? item.despatch.name : unknown),
        },
        {
            key: "delivery",
            title: "Грузополучатель (Предприятие)",
            width: "15%",
            className: "align-center",
            dataFormatter: (item) => (item.delivery ? item.delivery.name : unknown),
        },
        // {
        //     key: "operations",
        //     title: "История операций",
        //     className: "align-left",
        //     dataFormatter: (item) => <span>
        //         {
        //             <span className={transformDateToStatus(item.insertDate)}>
        //                 &nbsp;Загружен в ECert {transformDateByFormat(item.insertDate, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'YYYY-MM-DD HH:mm:ss')}
        //             </span>
        //         }
        //         {item.insertDate && <br/>}
        //
        //         {
        //             item.reproduceResult !== 'NOT_SENT' &&
        //             <span className={transformOperationStatusImportECert(item.reproduceResult)}>
        //             &nbsp;Отправлен в Меркурий {transformDateByFormat(item.reproduceDate, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'YYYY-MM-DD HH:mm:ss')} <br/>
        //             </span>
        //         }
        //         {item.reproduceResult !== 'NOT_SENT' && <br/>}
        //
        //         {
        //             item.acknowledgeResult !== 'NOT_SENT' &&
        //             <span className={transformOperationStatusImportECert(item.acknowledgeResult)}>
        //              &nbsp;Подтвержден {transformDateByFormat(item.acknowledgeDate, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'YYYY-MM-DD HH:mm:ss')}<br/>
        //             </span>
        //
        //         }
        //         {item.acknowledgeResult !== 'NOT_SENT' && <br/>}
        //
        //         {
        //             item.rejectDate &&
        //             <span className={transformDateToStatus(item.rejectDate)}>
        //              &nbsp;Отклонён {transformDateByFormat(item.rejectDate, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'YYYY-MM-DD HH:mm:ss')} <br/>
        //             </span>
        //         }
        //         {item.rejectDate && <br/>}
        //         {
        //             item.acceptDate &&
        //             <span className={transformDateToStatus(item.acceptDate)}>
        //              &nbsp;Принят на ППУ {transformDateByFormat(item.acceptDate, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'YYYY-MM-DD HH:mm:ss')} <br/>
        //             </span>
        //         }
        //         {item.acceptDate && <br/>}
        //
        //     </span>
        // },
        {
            key: "views",
            title: "",
            width: "40px",
            className: "align-center",
            dataFormatter: (item) => (
                <span>
                    <Button href={`/import/cert/${item.id}`} onlyIcon={true} icon="bars" iconSize={130} tooltip={"Просмотр"} />
                </span>
            ),
        },
    ];

    const pagination = (
        <PaginationRow
            page={page + 1}
            onChangePage={handleChangePage}
            onChangeSizePerPage={handleChangeSizePerPage}
            sizePerPage={sizePerPage}
            itemCount={itemCount}
        />
    );

    return (
        <Table
            className="table table-striped table-bordered table-hover"
            dataList={list}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

ECertImportListTable.propTypes = {
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleChangePage: PropTypes.func,
    handleChangeSizePerPage: PropTypes.func,
    sort: PropTypes.func,
    sizePerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemCount: PropTypes.number,
    sortedId: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
};

export default ECertImportListTable;
