import { Link } from "react-router-dom";
import React from "react";

const TextButton = ({ text, href, state, onClick, children, ...props }) => {
    if (!href && !onClick) {
        return <span {...props}>{children ?? text}</span>;
    }
    if (href) {
        return (
            <Link to={href} state={state} onClick={onClick} {...props}>
                {children ?? text}
            </Link>
        );
    }
    return (
        <a onClick={onClick} {...props}>
            {children ?? text}
        </a>
    );
};

export default TextButton;
