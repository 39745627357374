import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
const NavButton = ({ id, icon, label, url }) => {
    let link;
    if (url) {
        link = (
            <Link id={id} className="dropdown-toggle" to={url}>
                <i className={`menu-icon ${icon}`} />
                <span className="menu-text">{label}</span>
            </Link>
        );
    } else {
        link = (
            <a id={id} className="dropdown-toggle">
                <i className={`menu-icon ${icon}`} />
                <span className="menu-text">{label}</span>
            </a>
        );
    }

    return link;
};

NavButton.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
};

export default NavButton;
