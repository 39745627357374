import { t } from "i18next";
import uniqueId from "lodash/uniqueId";
import isString from "lodash/isString";

// символы из Unicode блока Cyrillic и Cyrillic Supplement + спецсимволы
export const CYRILLIC_REGEXP_FOR_VALIDATE =
    // eslint-disable-next-line no-control-regex
    /[\u0000-\u001F\u0041-\u005A\u0061-\u007A\u007F-\u009F\u00A1-\u00AA\u00AC-\u00BA\u00BC-\u00BF\u00D7\u00F7\u0180-\u01FF\u021C-\u0299\u0234-\u03FF\u0530-\u2008\u200B-\u200F\u2016-\u2115\u2117-\uFFFF]+/g;

export const validateCyrillic = () => (value) => {
    if (!value) return true;
    let hasInvalidChars = false;
    const highlightedValue = value.replaceAll(CYRILLIC_REGEXP_FOR_VALIDATE, (part) => {
        hasInvalidChars = true;
        return (
            <span className="error__font-weight" key={`error__${uniqueId()}`}>
                {part}
            </span>
        );
    });
    if (hasInvalidChars) {
        return (
            <span>
                {t("Поле допускает ввод только кириллицы.")}
                <br />
                {t("Разбор ошибок – ")}
                {highlightedValue}
            </span>
        );
    }
    return true;
};

/**
 * @deprecated use only with old vetrf components
 */
export const CYRILLIC_REG_EXP_FOR_VALIDATE =
    // eslint-disable-next-line no-control-regex
    /^[^\u0000-\u001F\u0041-\u005A\u0061-\u007A\u007F-\u009F\u00A1-\u00AA\u00AC-\u00BA\u00BC-\u00BF\u00D7\u00F7\u0180-\u01FF\u021C-\u0299\u0234-\u03FF\u0530-\u2008\u200B-\u200F\u2016-\u2115\u2117-\uFFFF]+$/;

/**
 * @deprecated use only with old vetrf components
 */
export const CYRILLIC_REG_EXP_FOR_MESSAGE =
    // eslint-disable-next-line no-control-regex
    /[\u0000-\u001F\u0041-\u005A\u0061-\u007A\u007F-\u009F\u00A1-\u00AA\u00AC-\u00BA\u00BC-\u00BF\u00D7\u00F7\u0180-\u01FF\u021C-\u0299\u0234-\u03FF\u0530-\u2008\u200B-\u200F\u2016-\u2115\u2117-\uFFFF]/g;

/**
 * @deprecated use only with old vetrf components
 */
export const validateRegExpCyrillic = {
    regExp: CYRILLIC_REG_EXP_FOR_VALIDATE,
    messageRegExp: CYRILLIC_REG_EXP_FOR_MESSAGE,
    errorText: t("Поле допускает ввод только кириллицы."),
};

const onlyNumberRegexp = /^[0-9]+$/;

export const validateBik = () => (value) => {
    if (!value) return true;
    if (value.length !== 9 || !onlyNumberRegexp.test(value)) {
        return t("БИК должен состоять из 9 цифр");
    }
    return true;
};

export const validatePaymentAccountNumber =
    (bik, isAllowTreasuryAccounts = true) =>
    (value) => {
        if (!value || !bik) return true;
        if (value.length !== 20 || !onlyNumberRegexp.test(value)) {
            return t("Расчетный счет должен состоять из 20 цифр");
        }
        if (isAllowTreasuryAccounts && value[0] === "0") {
            return true;
        }

        const bikAndPa = bik.slice(-3) + value;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
        let checksum = 0;
        for (const i in coefficients) {
            checksum += coefficients[i] * (bikAndPa[i] % 10);
        }
        if (checksum % 10 !== 0) {
            return t("Расчетный счет не прошел проверку по контрольной цифре");
        }
        return true;
    };

export const validateCorrespondentAccountNumber = (bik) => (value) => {
    if (!value || !bik) return true;
    if (value.length !== 20 || !onlyNumberRegexp.test(value)) {
        return t("Расчетный счет должен состоять из 20 цифр");
    }

    const bikAndCa = "0" + bik.slice(4, 6) + value;
    const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
    let checksum = 0;
    for (const i in coefficients) {
        checksum += coefficients[i] * (bikAndCa[i] % 10);
    }
    if (checksum % 10 !== 0) {
        return t("Корреспондентский счет не прошел проверку по контрольной цифре");
    }
    return true;
};

export const validateInn = () => (value) => {
    if (!value) return true;
    if ((value.length !== 10 && value.length !== 12) || !onlyNumberRegexp.test(value)) {
        return t("ИНН должен состоять из 10 или 12 цифр");
    }
    const getCheckDigit = (inn, coefficients) => {
        let checksum = 0;
        for (const i in coefficients) {
            checksum += coefficients[i] * inn[i];
        }
        return (checksum % 11) % 10;
    };

    if (value.length === 10) {
        const checkDigit = getCheckDigit(value, [2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
        if (checkDigit !== parseInt(value[9])) {
            return t("ИНН не прошел проверку по контрольной цифре");
        }
    } else if (value.length === 12) {
        const checkDigit1 = getCheckDigit(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
        const checkDigit2 = getCheckDigit(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
        if (checkDigit1 !== parseInt(value[10]) || checkDigit2 !== parseInt(value[11])) {
            return t("ИНН не прошел проверку по контрольной цифре");
        }
    }

    return true;
};

const DOT_AND_MINUS_REGEXP = /^(?:(?!\.-|-\.|\.\.).)*$/;
const UNICODE_EMAIL_REGEXP =
    /^(?!\.)[\u00A0-\uD7FF\uE000-\uFFFFa-z0-9.!#$%&'"*+\-/=?^_`{|}~]{0,63}[\u00A0-\uD7FF\uE000-\uFFFFa-z0-9!#$%&'"*+\-/=?^_`{|}~]@(?![-.])[\u00A0-\uD7FF\uE000-\uFFFFa-z0-9.!#$%&'"*+\-/=?^_`{|}~]{1,63}\.(?![-])[\u00A0-\uD7FF\uE000-\uFFFFa-z0-9!#$%&'"*+\-/=?^_`{|}~]{0,62}[\u00A0-\uD7FF\uE000-\uFFFFa-z0-9!#$%&'"*+/=?^_`{|}~]$/i;

export const validateEmail = () => (value) => {
    if (!value) return true;
    return (DOT_AND_MINUS_REGEXP.test(value) && UNICODE_EMAIL_REGEXP.test(value)) || t("Поле содержит некорректный адрес электронной почты");
};

export const combineValidators =
    (...validators) =>
    (value) => {
        for (const validator of validators) {
            const result = validator(value);
            if (result && isString(result)) {
                return result;
            }
        }
        return true;
    };
