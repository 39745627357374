import { Alert, Button, Page } from "vetrf-ui";
import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";

import logger from "../utils/logging/base/LogManager";
import QRCode from "react-qr-code";

const message = (
    <div>
        <Trans>
            <p>
                Возникла непредвиденная ситуация, попробуйте перезагрузить страницу и повторить текущую операцию заново. Если ошибка повторится,
                обратитесь в техническую поддержку по адресу <a href="mailto:ecert@fsvps.ru">ecert@fsvps.ru</a>
                <br />
                Приносим свои извинения за временные неудобства.
            </p>
            <small>Время возникновения: {new Date().toLocaleString("ru-ru")}</small>
        </Trans>
    </div>
);

const reloadPage = () => {
    window.location.reload();
};

const InternalErrorPage = ({ error }) => {
    const { t } = useTranslation();
    const title = t("Непредвиденная ошибка");
    const compressedError = error && compressError(error.message, error.stack);
    const breadcrumbs = [
        {
            link: "/",
            text: t("Главная"),
        },
        {
            text: title,
        },
    ];

    const openMainWithReload = () => {
        window.open("/", "_self");
    };

    useEffect(() => {
        if (error) {
            const formattedError = `${error.name}: ${error.message}\n${error?.stack?.split(/\r?\n/).slice(0, 10).join("\n").replace(/^/gm, "\t")}\n`;
            logger.error(formattedError);
        }
    }, [error]);

    return (
        <Page title={title} header={title} breadcrumbs={breadcrumbs}>
            <div className="col-xs-6 col-xs-offset-3">
                <Alert messageType="danger">
                    <div className="row">
                        <div className="col-xs-2">
                            <QRCode value={compressedError ?? "ecert@fsvps.ru"} size={180} style={{ padding: "10px", background: "white", border: "1px solid #cecece" }} />
                        </div>
                        <div className="col-xs-8">
                            <h3 className="no-margin-top">
                                <i className="fa fa-wrench icon-animated-wrench bigger-100" />
                                &nbsp; {t("Произошла непредвиденная ошибка")}
                            </h3>
                            {message}
                            {compressedError && (
                                <p style={{ wordBreak: "break-all" }}>
                                    {t("Код ошибки")}: {compressedError}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="margin-top-2 justify-center">
                        <Button icon="refresh" text={t("Перезагрузить страницу")} color="danger" onClick={reloadPage} />
                        &nbsp;&nbsp;
                        <Button text={t("На главную")} color="danger" onClick={openMainWithReload} />
                    </div>
                </Alert>
            </div>
        </Page>
    );
};

export const InternalErrorModal = ({ errorMessage }) => {
    return (
        <div className="danger-modal-backdrop">
            <div className="danger-modal">
                {message}
                <br/>
                <p style={{ wordBreak: "break-all", width: "min(500px, 90vw)", fontSize: "20px" }}>Код ошибки: {errorMessage}</p>
                <div className="margin-top-2 justify-center">
                    <Button icon="refresh" text="Перезагрузить страницу" color="danger" onClick={reloadPage} />
                </div>
            </div>
        </div>
    );
};

export const compressError = (errorMessage = "", stack = "") => {
    const filesDictionary = [];
    const compressedStack = [];
    const shortStack = stack.split(/\r?\n/).slice(0, 8).join("\n");
    for (const match of shortStack.matchAll(/\/static\/js\/(.*).chunk.js:\d+:(\d+)/g)) {
        const filename = match[1];
        const pos = match[2];
        const fileIndex = filesDictionary.indexOf(filename);
        if (fileIndex === -1) {
            filesDictionary.push(filename);
            compressedStack.push(filename + ":" + pos);
        } else {
            compressedStack.push(fileIndex + ":" + pos);
        }
    }
    return `${btoa(errorMessage)}.${compressedStack.join("/")}`;
};

export default InternalErrorPage;
