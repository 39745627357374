import { filterRetry, useCustomMutation, useCustomQuery } from "../../utils/commonQueryBase";
import { saveAs } from "file-saver";

const BASE_URL = "/rest-api/record";

export function usePubRecord(importCountryGuid, referenceNumber, issueDateTime) {
    const enabled = referenceNumber && issueDateTime && importCountryGuid;
    const url = enabled ? "/" + btoa(unescape(encodeURIComponent(referenceNumber))) + "/" + issueDateTime + "/" + importCountryGuid : "";
    return useCustomQuery(
        BASE_URL + url,
        {},
        {
            retry: filterRetry(3, { notRetryCode: "ECERT10011" }),
            refetchOnWindowFocus: false,
            enabled: !!enabled,
        }
    );
}

export function useDownloadRecordMutation() {
    const createRequest = (data) => ({
        type: "GET",
        url: `/${data.id}/cert.pdf`,
    });

    return useCustomMutation(BASE_URL, createRequest, {
        onSuccess: (data, variables) => {
            data.blob().then((blob) => {
                saveAs(blob, `${variables.id}.pdf`);
            });
        },
    });
}
