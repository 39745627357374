import { DATE_TIME_FORMAT_LIST_LOAD, DATE_TIME_FORMAT_LIST_RECEIVE, DATE_TIME_FORMAT_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    dateTimeFormats: [],
    error: null,
    loading: false,
};

const dateTimeFormatsLoad = (state, action) => ({ ...state, loading: true });

const dateTimeFormatsReceive = (state, action) => ({
    ...state,
    error: null,
    dateTimeFormats: action.dateTimeFormats || [],
    loading: false,
});

const dateTimeFormatsError = (state, action) => ({
    ...state,
    error: action.error,
    dateTimeFormats: [],
    loading: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case DATE_TIME_FORMAT_LIST_LOAD:
            return dateTimeFormatsLoad(state, action);
        case DATE_TIME_FORMAT_LIST_RECEIVE:
            return dateTimeFormatsReceive(state, action);
        case DATE_TIME_FORMAT_LIST_ERROR:
            return dateTimeFormatsError(state, action);

        default:
            return state;
    }
};
