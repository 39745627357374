class NotificationManager {
    constructor() {
        this.counter = 0;
        this.notify = null;
        this.clearAll = null;
    }

    success(title, message) {
        this.notify(null, title, message);
    }

    error(error = {}, title = "", message = "") {
        this.notify(error, title, message);
    }

    clearAll() {
        this.clearAll();
    }

    register({notify, clearAll}) {
        this.notify = notify;
        this.clearAll = clearAll;
    }
}

export default new NotificationManager();
