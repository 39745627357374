import { createActions } from "redux-actions";
import { loadActions } from "../../../common/utils/constants";

export const { availableAppStatusReasons } = createActions({
    AVAILABLE_APP_STATUS_REASONS: loadActions,
});

export const { appStatusReasons } = createActions({
    APP_STATUS_REASONS: loadActions,
});
