import {
    TRANSPORT_EQUIPMENT_TYPE_LIST_LOAD,
    TRANSPORT_EQUIPMENT_TYPE_LIST_RECEIVE,
    TRANSPORT_EQUIPMENT_TYPE_LIST_ERROR,
} from "../constants/ActionTypes";

const initialState = {
    transportEquipmentTypes: [],
    error: null,
    loading: false,
};

const transportEquipmentTypesLoad = (state, action) => ({
    ...state,
    loading: true,
});

const getTransportEquipmentTypes = (action) =>
    action.transportEquipmentTypes && action.transportEquipmentTypes.length > 0 ? action.transportEquipmentTypes : [];

const transportEquipmentTypesReceive = (state, action) => ({
    ...state,
    error: null,
    transportEquipmentTypes: getTransportEquipmentTypes(action),
    loading: false,
});

const transportEquipmentTypesError = (state, action) => ({
    ...state,
    error: action.error,
    transportEquipmentTypes: [],
    loading: false,
});

export default function transportEquipmentTypes(state = initialState, action) {
    switch (action.type) {
        case TRANSPORT_EQUIPMENT_TYPE_LIST_LOAD:
            return transportEquipmentTypesLoad(state, action);
        case TRANSPORT_EQUIPMENT_TYPE_LIST_RECEIVE:
            return transportEquipmentTypesReceive(state, action);
        case TRANSPORT_EQUIPMENT_TYPE_LIST_ERROR:
            return transportEquipmentTypesError(state, action);

        default:
            return state;
    }
}
