import ajax from "../../utils/ajax";
import { LOG_ERROR, LOG_LOAD, LOG_RECEIVE, LOGS_LIST_ERROR, LOGS_LIST_LOAD, LOGS_LIST_RECEIVE } from "../constants/ActionTypes";
import moment from "moment";

let request = ajax("/rest-api/import/ecert/logs");

export const logLoad = (id) => ({
    type: LOG_LOAD,
    id,
});

export const logReceive = (log) => ({
    type: LOG_RECEIVE,
    log,
});

export const logLoadError = ({ responseJSON: error }) => ({
    type: LOG_ERROR,
    error,
});

export const logsListLoad = (filterObj, pageable) => ({
    type: LOGS_LIST_LOAD,
    filterObj,
    pageable,
});

export const logsListReceive = (logs) => ({
    type: LOGS_LIST_RECEIVE,
    logs,
});

export const logsListLoadError = ({ responseJSON: error }) => ({
    type: LOGS_LIST_ERROR,
    error,
});

export const requestOperationLogList = (filterObj, pageable) => (dispatch) => {
    dispatch(logsListLoad(filterObj, pageable));
    let url = "";
    if (pageable) {
        url = `?page=${pageable.page}&size=${pageable.size}`;
        if (pageable.sort && pageable.sort.property && pageable.sort.direction) {
            url += `&sort=${pageable.sort.property},${pageable.sort.direction}`;
        }
    }
    if (filterObj && filterObj.operationTypeList) {
        filterObj.operationTypeList = filterObj.operationTypeList.filter((op) => op).map((operationType) => operationType && operationType.type);
    }
    if (filterObj && filterObj.countryList) {
        filterObj.countryList = filterObj.countryList.filter((c) => c).map((country) => country && country.code);
    }
    if (filterObj && filterObj.insertDateInterval) {
        const viewStartDate = filterObj.insertDateInterval.viewStartDate;
        const viewEndDate = filterObj.insertDateInterval.viewEndDate;
        filterObj.insertDateInterval.startDate = viewStartDate ? moment(viewStartDate, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss") : "";
        filterObj.insertDateInterval.endDate = viewEndDate ? moment(viewEndDate, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss") : "";
    }
    request({
        url: url,
        data: filterObj,
    })
        .then((response) => {
            dispatch(logsListReceive(response));
        })
        .catch((error) => {
            dispatch(logsListLoadError(error));
        });
};

export const requestOperationLogsForECert = (id) => (dispatch) => {
    dispatch(logLoad(id));
    let url = "";
    request({
        url: url,
        data: { eCertID: id },
    })
        .then((response) => {
            dispatch(logReceive(response));
        })
        .catch((error) => {
            dispatch(logLoadError(error));
        });
};
