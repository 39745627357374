import React from "react";
import { useTranslation } from "react-i18next";

import openLoginWindow from "../../../../session/LoginTab";
import { useUserContext } from "../../../../session/UserContextProvider";

const LoginDropdown = () => {
    const { t } = useTranslation();
    const { requestSession } = useUserContext();

    const handleLogIn = () => {
        const msg = openLoginWindow();
        msg.then((user) => {
            requestSession();
        });
        return false;
    };

    return (
        <li className="light-blue">
            <a id="btn_sign_in" onClick={handleLogIn}>
                {t("Войти")}
            </a>
        </li>
    );
};

export default LoginDropdown;
