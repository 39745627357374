import { USER_LIST_BY_CURRENT_USER_ERROR, USER_LIST_BY_CURRENT_USER_LOAD, USER_LIST_BY_CURRENT_USER_RECEIVE } from "../constants/ActionTypes";

const initialState = {
    users: [],
    error: null,
    loading: false,
};

const userListLoad = (state, action) => ({ ...state, loading: true });

const getUsers = (action) => (action.users && action.users.length > 0 ? action.users : []);

const userListReceive = (state, action) => ({
    ...state,
    error: null,
    users: getUsers(action),
    loading: false,
});

const userListError = (state, action) => ({
    ...state,
    error: action.error,
    users: [],
    loading: false,
});

export default function users(state = initialState, action) {
    switch (action.type) {
        case USER_LIST_BY_CURRENT_USER_LOAD:
            return userListLoad(state, action);
        case USER_LIST_BY_CURRENT_USER_RECEIVE:
            return userListReceive(state, action);
        case USER_LIST_BY_CURRENT_USER_ERROR:
            return userListError(state, action);

        default:
            return state;
    }
}
