import { TRANSPORT_TYPE_LIST_LOAD, TRANSPORT_TYPE_LIST_RECEIVE, TRANSPORT_TYPE_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    transportTypes: [],
    error: null,
    loading: false,
};

const transportTypesLoad = (state, action) => ({ ...state, loading: true });

const getTransportTypes = (action) => (action.transportTypes && action.transportTypes.length > 0 ? action.transportTypes : []);

const transportTypesReceive = (state, action) => ({
    ...state,
    error: null,
    transportTypes: getTransportTypes(action),
    loading: false,
});

const transportTypesError = (state, action) => ({
    ...state,
    error: action.error,
    transportTypes: [],
    loading: false,
});

export default function transportTypes(state = initialState, action) {
    switch (action.type) {
        case TRANSPORT_TYPE_LIST_LOAD:
            return transportTypesLoad(state, action);
        case TRANSPORT_TYPE_LIST_RECEIVE:
            return transportTypesReceive(state, action);
        case TRANSPORT_TYPE_LIST_ERROR:
            return transportTypesError(state, action);

        default:
            return state;
    }
}
