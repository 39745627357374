import React, { createElement } from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return createElement(this.props.fallback, { error: this.state.error });
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
