import { combineReducers } from "redux";

import countries from "./countryReducer";
import eCertDocumentTypes from "./documentTypeReducer";
import transportTypes from "./transportTypeReducer";
import transportEquipmentTypes from "./transportEquipmentTypeReducer";
import processTypes from "./processTypeReducer";
import russianRegionsStore from "./russianRegionsReducer";
import dateTimeFormatsStore from "./dateTimeFormatsReducer";
import unitMeasures from "./unitMeasureReducer";
import users from "./usersSelectReducer";
import organizationList from "./organizationReducer";
import animalSpecies from "./animalSpeciesReducer";
import diseases from "./diseasesReducer";

export default combineReducers({
    countries,
    eCertDocumentTypes,
    transportTypes,
    transportEquipmentTypes,
    processTypes,
    russianRegionsStore,
    dateTimeFormatsStore,
    unitMeasures,
    users,
    organizationList,
    animalSpecies,
    diseases,
});
