import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCountriesListQuery } from "../../../../dictionary/api/countriesQueries";
import { useGetOrganisationListByWbrConclusions } from "../../../../dictionary/api/organizationQuery";
import { useGetProductList } from "../../../../dictionary/api/productQuery";
import { useTransportTypesListQuery } from "../../../../dictionary/api/transportTypesQueries";
import { wbrComplianceDecision } from "../../../../utils/constants/wbr/wbr-constants";
import SearchForm, { getChangedAdvancedFieldsCount, searchSchemeFieldType } from "../../../../components/list/SearchForm";
import SearchFormBudgeList from "../../../../components/list/SearchFormBudgeList";
import SearchToggleButton from "../../../../components/list/SearchToggleButton";

const getDecisions = (t) => [
    { id: wbrComplianceDecision.MEET_REQUIREMENTS, view: t("Соответствует требованиям страны-импортера") },
    { id: wbrComplianceDecision.NOT_MEET_REQUIREMENTS, view: t("Не соответствует требованиям страны-импортера") },
];

const WbrConclusionSearchWrapper = ({ searchParams, setSearchParams, children }) => {
    const { t } = useTranslation();
    const [isShowSearchPanel, setIsShowSearchPanel] = useState(false);

    const { data: countries } = useCountriesListQuery();
    const { data: products } = useGetProductList({ type: "7" });
    const { data: organizations } = useGetOrganisationListByWbrConclusions();
    const { data: transportTypes } = useTransportTypesListQuery();

    const searchSchema = {
        fields: {
            referenceNumber: { title: t("Номер заключения") },
            importCountries: {
                title: t("Страна назначения"),
                placeholder: t("Выберите страну..."),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: countries,
                labelKey: "view",
                valueKey: "guid",
                isObjectSelect: false,
            },
            products: {
                title: t("Продукция (ВетИС)"),
                placeholder: t("Выберите продукцию..."),
                help: t("Поиск осуществляется по наличию хотя бы одной позиции продукции"),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: products,
                labelKey: "name",
                valueKey: "guid",
                isObjectSelect: false,
            },
            transportType: {
                title: t("Тип транспортного средства"),
                type: searchSchemeFieldType.select,
                options: transportTypes,
                labelKey: "view",
                valueKey: "id",
                isObjectSelect: false,
            },
            transportNumber: { title: t("Номер транспортного средства") },
            createdByInstitution: {
                title: t("Организация"),
                placeholder: t("Выберите организацию..."),
                type: searchSchemeFieldType.select,
                options: organizations,
                labelKey: "shortName",
                valueKey: "guid",
                isObjectSelect: false,
            },
            issueDateInterval: { title: t("Оформлен в период"), type: searchSchemeFieldType.dateRange },
            decision: {
                title: t("Решение"),
                type: searchSchemeFieldType.select,
                options: getDecisions(t),
                labelKey: "view",
                valueKey: "id",
                isObjectSelect: false,
            },
            application: { title: t("Номер заявки") },
        },
        quickSearch: ["createdByInstitution", "importCountries"],
        advancedSearch: [
            "referenceNumber",
            "importCountries",
            "products",
            "transportType",
            "transportNumber",
            "createdByInstitution",
            "issueDateInterval",
            "decision",
            "application",
        ],
    };

    const budgeList = <SearchFormBudgeList schema={searchSchema} searchParams={searchParams} setSearchParams={setSearchParams} />;

    const searchToggleButton = (
        <SearchToggleButton
            active={isShowSearchPanel}
            onClick={() => setIsShowSearchPanel(!isShowSearchPanel)}
            advancedParamsCount={getChangedAdvancedFieldsCount(searchParams, searchSchema)}
        />
    );

    const searchForm = (
        <SearchForm schema={searchSchema} isExtendedPanelShow={isShowSearchPanel} searchParams={searchParams} setSearchParams={setSearchParams} />
    );

    return children(searchForm, budgeList, searchToggleButton);
};

export default WbrConclusionSearchWrapper;
