import { useDictionaryListQuery, useDictionaryOneQuery, useDictionaryPageQuery } from "./dictionaryQueries";

const baseUrl = "/countries";
const allBaseUrl = "/all-countries";
const itemName = "страны";
const dictionaryName = "стран";

export function useAllCountryQuery(id) {
    return useDictionaryOneQuery(allBaseUrl, id, { dictionaryItemName: itemName });
}

export function useAllCountriesPageQuery(searchParams) {
    return useDictionaryPageQuery(allBaseUrl, searchParams, { dictionaryName: dictionaryName });
}

export function useCountriesListQuery() {
    return useDictionaryListQuery(baseUrl, { method: "POST", dictionaryName: dictionaryName });
}
