import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../errors/PageNotFound";
import UserSettingsPage from "./containers/UserSettingsPage";

const UserSettingsRoutes = () => {
    return (
        <Routes>
            <Route exact path="" element={<UserSettingsPage />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default UserSettingsRoutes;
