import { Fragment } from "react";

const SeparatedList = ({ items, separator = () => <br />, empty }) => {
    const filteredItems = items.filter(Boolean);
    if (filteredItems.length === 0) {
        return empty;
    } else if (filteredItems.length === 1) {
        return filteredItems[0];
    }
    return filteredItems.map((item, index) => (
        <Fragment key={index}>
            {item}
            {index !== filteredItems.length - 1 && separator(item, index)}
        </Fragment>
    ));
};

export default SeparatedList;
