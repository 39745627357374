import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "vetrf-ui";
import isEmpty from "lodash/isEmpty";
import { bindActionCreators } from "redux";
import * as infoService from "../../store/info/service";

class InfoAlert extends Component {
    constructor(props, context) {
        super(props, context);
        this.isVisible = this.isVisible.bind(this);
        this.getText = this.getText.bind(this);
    }

    componentDidMount() {
        const { buildInfo, infoService } = this.props;
        if (isEmpty(buildInfo)) {
            infoService.requestBuildInfo();
        }
    }

    isVisible() {
        const { buildInfo } = this.props;
        return buildInfo && buildInfo.profile === "demo";
    }

    getText() {
        const { buildInfo } = this.props;
        if (buildInfo && buildInfo.profile === "demo") {
            return "Вы работаете в УЧЕБНОЙ ВЕРСИИ системы!";
        }
        return "";
    }

    render() {
        return (
            <React.Fragment>
                {this.isVisible() && (
                    <Alert messageType="success" className={"margin-0"} closable={true}>
                        {this.getText()}
                    </Alert>
                )}
            </React.Fragment>
        );
    }
}

InfoAlert.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
    infoService: bindActionCreators(infoService, dispatch),
});

const mapStateToProps = (state) => ({
    buildInfo: state.common.infoStore.buildInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoAlert);
