import React from "react";
import { Page, Tabs } from "vetrf-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ForeignForm from "./inner/ForeignForm";
import RussianForm from "./inner/RussianForm";
import { useBusinessParticipantSaveMutation } from "../../api/businessParticipantsQueries";

const CreateFormPage = () => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const saveParticipant = useBusinessParticipantSaveMutation();

    const onSave = (participant) => {
        saveParticipant.mutate(participant, { onSuccess: () => navigate("..") });
    };

    const onCancel = () => {
        navigate("..")
    }

    const title = t("Добавление информации о хозяйствующем субъекте");
    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "participant_list_page",
            link: "/participants/",
            text: t("Список хозяйствующих субъектов"),
        },
        {
            active: true,
            text: title,
        },
    ];

    const tabs = [
        {
            name: "russian",
            label: "Отечественные",
            content: <RussianForm onSave={onSave} onToggleModal={onCancel} />,
        },
        {
            name: "foreign",
            label: "Зарубежные",
            content: <ForeignForm onSave={onSave} onToggleModal={onCancel} />,
        },
    ];

    return (
        <Page header={title} breadcrumbs={breadcrumbs} title={title}>
            <div className="col-lg-offset-2 col-lg-8 col-md-12">
                <Tabs tabs={tabs} />
            </div>
        </Page>
    );
};

export default CreateFormPage;
