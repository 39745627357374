import { combineReducers } from "redux";

import eCertStore from "../store/ecert/reducer";
import eCertList from "./slices/ecertListSlice";
import canToIssueStore from "../store/canToIssue/reducer";
import withdrawnReasonStore from "../store/withdrawn/reducer";
import templatesStore from "../store/templates/reducer";

export default combineReducers({
    eCertStore,
    eCertList,
    canToIssueStore,
    withdrawnReasonStore,
    templatesStore,
});
