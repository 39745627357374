import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, InputField, SubHeader } from "vetrf-ui";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { usePubECert } from "../../../ecert/api/ecertQueries";

const initialState = {
    id: "",
    referenceNumber: "",
};

const ECertSearchPanel = () => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [cert, setCert] = useState(initialState);
    const pubECertQuery = usePubECert(cert.id, cert.referenceNumber);

    const methods = useForm({ defaultValues: cert });
    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (pubECertQuery.isSuccess) {
            const id = encodeURIComponent(cert.id);
            const referenceNumber = encodeURIComponent(cert.referenceNumber);
            const params = `${id}/${referenceNumber}`;
            navigate(`/verify/${params}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pubECertQuery.isSuccess]);

    useEffect(() => {
        setShowError(pubECertQuery.isError);
    }, [pubECertQuery.isError]);

    const onECertSubmit = (data) => {
        data.id = data.id.trim();
        data.referenceNumber = data.referenceNumber.trim();
        setCert(data);
    };

    const onECertReset = () => {
        setShowError(false);
        reset();
    };

    return (
        <Fragment>
            <SubHeader header={t("Для электронных сертификатов")} />
            {showError && <Alert messageType="danger">{t("По данным параметрам поиска сертификат не найден в реестре")}</Alert>}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onECertSubmit)} className="form-horizontal css-form">
                    <InputField name="referenceNumber" required maxLength={255} labelText={t("Номер сертификата")} />
                    <InputField name="id" required maxLength={40} labelText={t("Код сертификата")} />
                    <div className="clearfix form-actions no-margin-bottom ">
                        <div className="pull-right">
                            <Button id="el_cert__clear_btn" text={t("Очистить")} size="sm" icon="eraser" onClick={onECertReset} />
                            &nbsp;&nbsp;
                            <Button
                                type="submit"
                                id="el_cert_search_btn"
                                color="info"
                                size="sm"
                                icon={pubECertQuery.isLoading ? "circle-o-notch fa-spin" : "search"}
                                disabled={pubECertQuery.isLoading}
                                text={t("Поиск")}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Fragment>
    );
};

export default ECertSearchPanel;
