const countriesForFilter = [
    {
        guid: "74a3cbb1-56fa-94f3-ab3f-e8db4940d96b",
        name: "Российская Федерация",
    },
];

export const getFilterCountryList = (countries) => {
    return countries.filter(
        (country) =>
            !countriesForFilter.some(function (filterCountry) {
                return filterCountry.guid === country.guid;
            })
    );
};
