import { useDictionaryListQuery } from "./dictionaryQueries";
import { useListQuery } from "../../utils/commonQueryBase";
import NotificationManager from "../../common/utils/notification/NotificationManager";
import { ORGANIZATION_TYPES } from "../../utils/constants/constants";

const baseUrl = "/rest-api/dictionary/organizations";
const organizationsUrlPart = "/organizations";

// TODO: use dictionaryzed queries
/**
 * @deprecated use hook useOrganizations
 */
export function useGetOrganisationList(searchParams) {
    return useListQuery(baseUrl, searchParams, {
        staleTime: 5 * 60 * 1000,
        onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка организаций."),
    });
}

/**
 * @deprecated use hook useLaboratories
 */
export function useLabOrganizationsListQuery() {
    return useListQuery(
        baseUrl,
        {
            type: ["LB", "RI", "RC", "AH", "PL"].join(","),
        },
        {
            staleTime: Infinity,
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка организаций."),
        }
    );
}

export function useGetOrganisationListByWbrConclusions() {
    return useListQuery(
        baseUrl + "/by-wbr",
        {},
        {
            staleTime: 5 * 60 * 1000,
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка организаций."),
        }
    );
}

export function useGetOrganisationListByProtocols() {
    return useListQuery(
        baseUrl + "/by-protocol",
        {},
        {
            staleTime: 5 * 60 * 1000,
            onError: (error) => NotificationManager.error(error, "Произошла ошибка при загрузке списка организаций."),
        }
    );
}

export function useOrganizationList(types) {
    return useDictionaryListQuery(organizationsUrlPart, { params: { type: types.join(",") } });
}

export function useLaboratories(activities = []) {
    return useDictionaryListQuery(organizationsUrlPart, {
        params: {
            type: [
                ORGANIZATION_TYPES.LABORATORY,
                ORGANIZATION_TYPES.RESEARCH_INSTITUTE,
                ORGANIZATION_TYPES.REFERENCE_CENTER,
                ORGANIZATION_TYPES.ANIMAL_HEALTH_CENTER,
                ORGANIZATION_TYPES.PRIVATE_LABORATORY,
            ].join(","),
            activity: activities.join(","),
        },
        dictionaryName: "Список лабораторий",
    });
}

export function useOrganizations(types = [], activities = []) {
    return useDictionaryListQuery(organizationsUrlPart, { params: { type: types.join(","), activity: activities.join(",") } });
}
