import NotificationManager from "../../common/utils/notification/NotificationManager";
import { filterRetry, useCustomQuery } from "../../utils/commonQueryBase";

const BASE_URL = "/rest-api/ecert";

export function usePubECert(id, referenceNumber) {
    const enabled = id && referenceNumber;
    const url = enabled ? `/${id}/${referenceNumber}` : "";
    return useCustomQuery(
        BASE_URL + url,
        {},
        {
            retry: filterRetry(3, { notRetryCode: "ECERT10017" }),
            refetchOnWindowFocus: false,
            enabled: !!enabled,
        }
    );
}

export function useECertClause(ecertId) {
    return useCustomQuery(
        `${BASE_URL}/${ecertId}/clause`,
        {},
        {
            onError: (error) => {
                NotificationManager.error(error, "Произошла ошибка при загрузке списка кляуз");
            },
            enabled: !!ecertId,
        }
    );
}

export function useECertListForLinkToApplication(applicationId, referenceNumber) {
    return useCustomQuery(
        `/rest-api/ecert/for-link`,
        {
            params: { applicationId, referenceNumber },
        },
        {
            onError: (error) => {
                NotificationManager.error(error, "Произошла ошибка при получении списка сертификатов для привязки");
            },
            enabled: !!applicationId,
        }
    );
}
