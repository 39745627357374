import { countries, supportedCountries, euCountries } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка стран";

export const requestCountries = () => createLoadAsyncAction(countries, request, { type: "POST", url: "countries" }, errorMessage);
export const requestSupportedCountries = () =>
    createLoadAsyncAction(supportedCountries, request, { type: "POST", url: "supportedCountries" }, errorMessage);
export const requestEUCountries = () => createLoadAsyncAction(euCountries, request, { type: "POST", url: "euCountries" }, errorMessage);
