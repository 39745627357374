import { Button, InputField, SelectField, TextareaField } from "vetrf-ui";
import { Fragment } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { getUnknownNormalText } from "../../../../utils/constants/constants";
import { isWbrAppInStatus, wbrApplicationStatuses } from "../../../../utils/constants/wbr/wbr-application-statuses";
import { useUserContext } from "../../../../session/UserContextProvider";
import { useWbrApplication, useWbrApplicationRevisionPlaces } from "../../api/WbrApplicationQueries";
import { useWbrApplicationRevisionMutation } from "../../api/WbrApplicationStatusQueries";
import { WBR_APP_BREADCRUMBS, WBR_APP_ROUTE } from "../../WbrApplicationRoutes";
import AlertWithIcon from "../../../../components/page/AlertWithIcon";
import BlockHeader from "../../../../components/page/BlockHeader";
import Container from "../../../../components/page/Container";
import FormActionsPanel from "../../../../components/page/FormActionsPanel";
import FormEditableTable from "../../../../components/table/FormEditableTable";
import PageContent from "../../../../components/page/PageContent";
import ShortFormProvider from "../../../../components/form/ShortFormProvider";
import WbrApplicationRegistrationInfo from "./components/blocks/WbrApplicationRegistrationInfo";

export const wbrInvoiceRevisionUnavailableByStatusAlert = (
    <AlertWithIcon messageType="danger">
        <Translation>{(t) => t("Отправка на доработку недоступно для данной заявки на ВБР, так как она имеет неверный статус")}</Translation>
    </AlertWithIcon>
);

const WbrApplicationRevisionPage = ({ revisionState, setRevisionState }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { user } = useUserContext();
    const navigate = useNavigate();

    const { data: application, isLoading } = useWbrApplication(id);
    const revisionApplicationQuery = useWbrApplicationRevisionMutation(id);
    const methods = useForm({ defaultValues: revisionState });
    const { handleSubmit, getValues } = methods;

    const title = WBR_APP_ROUTE.REVISION.getTitle(t);
    const isUserLaboratory = application?.laboratory.guid === user?.institution.guid;
    const canRevisonApplication = isWbrAppInStatus(application, [wbrApplicationStatuses.IN_PROCESS]);

    const onSubmitHandler = (data) => {
        revisionApplicationQuery.mutate(data, { onSuccess: () => navigate(WBR_APP_ROUTE.VIEW.getFullPath(id)) });
    };

    const onBackHandler = () => {
        setRevisionState(getValues());
    };

    const getPageAlert = () => {
        if (!application) return;
        if (!canRevisonApplication) {
            return wbrInvoiceRevisionUnavailableByStatusAlert;
        }
    };

    const pageToolbar = (
        <Fragment>
            <Button id="back_btn" text={t("Назад")} size="sm" icon="arrow-left" href={WBR_APP_ROUTE.VIEW.getFullPath(id)} onClick={onBackHandler} />
        </Fragment>
    );

    return (
        <PageContent
            title={title}
            chapter={WBR_APP_ROUTE.PAYMENT_VIEW.getChapter(t)}
            middleBreadcrumbs={WBR_APP_BREADCRUMBS.getViewBreadcrumbs(id)}
            toolbar={pageToolbar}
            isLoading={isLoading}
            canAccess={isUserLaboratory}
        >
            <Container>{getPageAlert()}</Container>
            {canRevisonApplication && (
                <Fragment>
                    <Container>
                        <WbrApplicationRegistrationInfo application={application} />
                        <BlockHeader>{t("Отправка заявки на доработку")}</BlockHeader>
                        <p>{t("Введите сведения о необходимых доработках")}</p>
                        <ShortFormProvider methods={methods}>
                            <ImprovementsEditableTable name="reportConclusions" required applicationId={id} />
                            <BlockHeader level={4}>{t("Контактная информация")}</BlockHeader>
                            <InputField
                                name="changer.phone"
                                labelText={t("Контактный телефон")}
                                maxLength={255}
                            />
                        </ShortFormProvider>
                    </Container>
                    <FormActionsPanel>
                        <Button id="return_btn" text={t("Назад")} size="sm" href={WBR_APP_ROUTE.VIEW.getFullPath(id)} onClick={onBackHandler} />
                        <FormActionsPanel.RightSide>
                            <Button
                                id="submit_btn"
                                text={t("Отправить на доработку")}
                                type="submit"
                                size="sm"
                                color="success"
                                onClick={handleSubmit(onSubmitHandler)}
                            />
                        </FormActionsPanel.RightSide>
                    </FormActionsPanel>
                </Fragment>
            )}
        </PageContent>
    );
};

const ImprovementsEditableTable = ({ name, required, applicationId }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);
    const { data: revisionPlaces } = useWbrApplicationRevisionPlaces(applicationId);

    const improvementsColumns = [
        {
            title: "Место доработки",
            dataFormatter: (item) => item.places?.map((place) => place.view).join(", "),
        },
        {
            title: "Необходимые доработки",
            dataFormatter: (item) => item.description ?? unknown,
        },
    ];

    const improvementForm = (
        <Fragment>
            <SelectField
                name="places"
                labelText={t("Место доработки")}
                placeholder={t("Выберите место доработки...")}
                help={t("Выберите место заявки, нуждающееся в доработке")}
                required
                clearable
                multiple
                valueKey="view"
                labelKey="view"
                options={revisionPlaces}
            />
            <TextareaField
                name="description"
                labelText={t("Необходимые доработки")}
                help={t("Введите сведения о выявленных недочетах в предоставленной информации и необходимых доработках")}
                required
                maxLength={4096}
            />
        </Fragment>
    );

    return <FormEditableTable name={name} required={required} columns={improvementsColumns} rowForm={improvementForm} />;
};

export default WbrApplicationRevisionPage;
