import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getDateIntervalView } from "../../../../../../../utils/viewUtils";
import { getUnknownNormalText } from "../../../../../../../utils/constants/constants";
import BlockHeader from "../../../../../../../components/page/BlockHeader";
import CompareBlockTable from "../../../../../../../components/table/CompareBlockTable";

const ContractDocumentTable = ({ documents, documentsForCompare }) => {
    const { t } = useTranslation();
    const unknown = getUnknownNormalText(t);

    const getEnityView = (entity) => {
        if (!entity) return null;
        return (
            <Fragment>
                {entity.enName ?? unknown}
                <br />
                {entity.address?.enAddressView && (
                    <Fragment>
                        {entity.address?.enAddressView}
                        <br />
                    </Fragment>
                )}
                {(entity.name || entity.address?.addressView) && (
                    <Fragment>
                        <br />
                        {entity.name ?? unknown}
                        <br />
                        {entity.address?.addressView}
                    </Fragment>
                )}
            </Fragment>
        );
    };

    const tableRows = [
        {
            header: {
                key: "common",
                title: t("Общие сведения"),
            },
            dataFormatter: (item) => (
                <Fragment>
                    {t("Номер")}: {item.issueNumber ?? unknown}
                    {item.issueDate && ` ${t("от")} ${item.issueDate}`}
                    <br />
                    {document.validityDateInterval && (
                        <Fragment>
                            {t("Срок действия")}: {getDateIntervalView(document.validityDateInterval) ?? unknown}
                            <br />
                        </Fragment>
                    )}
                </Fragment>
            ),
            compareData: (item) =>
                [item.issueNumber, item.issueDate, document.validityDateInterval?.startDateTime, document.validityDateInterval?.endDateTime]
                    .join("-"),
        },
        {
            header: {
                key: "seller",
                title: t("Продавец"),
            },
            dataFormatter: (item) => getEnityView(item.attributes?.seller) ?? unknown,
            compareData: (item) => JSON.stringify(item.attributes?.seller),
        },
        {
            header: {
                key: "buyer",
                title: t("Покупатель"),
            },
            dataFormatter: (item) => getEnityView(item.attributes?.buyer) ?? unknown,
            compareData: (item) => JSON.stringify(item.attributes?.buyer),
        },
        {
            header: {
                key: "regulations",
                title: t("Товар поставляется согласно"),
            },
            dataFormatter: (item) => item.attributes?.regulations ?? unknown,
        },
        {
            header: {
                key: "additionalInfo",
                title: t("Дополнительные условия к контракту"),
            },
            dataFormatter: (item) => item.attributes?.additionalInfo ?? unknown,
        },
    ];

    return (
        <Fragment>
            <BlockHeader level={4}>{t("Контракт на поставку продукции")}</BlockHeader>
            <CompareBlockTable data={documents[0] ?? []} comparedData={documentsForCompare?.[0]} rows={tableRows} />
        </Fragment>
    );
};

export default ContractDocumentTable;
