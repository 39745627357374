import { handleActions } from "redux-actions";
import { treatmentOperations } from "./actions";

const initialState = {
    treatmentOperations: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [treatmentOperations.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [treatmentOperations.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            treatmentOperations: payload,
        }),
        [treatmentOperations.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
    },
    initialState
);
