import {
    LOGS_LIST_RECEIVE,
    LOGS_LIST_LOAD,
    LOGS_LIST_ERROR,
    LOG_RECEIVE,
    LOG_ERROR,
    LOG_LOAD,
    LOGS_IMPORT_LIST_FILTER_CLEAR,
    LOGS_IMPORT_LIST_FILTER_SET,
} from "../constants/ActionTypes";

const initialState = {
    log: {},
    list: {},
    filterObj: {},
    searchFormValue: {},
    error: null,
    loading: false,
    saving: false,
};

const logsListLoad = (state, action) => ({
    ...state,
    loading: true,
    filterObj: action.filterObj,
});

const logsListReceive = (state, action) => ({
    ...state,
    error: null,
    list: action.logs,
    loading: false,
});

const logsListLoadError = (state, action) => ({
    ...state,
    error: action.error,
    list: {},
    loading: false,
});

const logLoad = (state, action) => ({ ...state, loading: true });

const logReceive = (state, action) => ({
    ...state,
    error: null,
    log: action.log,
    loading: false,
});

const logLoadError = (state, action) => ({
    ...state,
    error: action.error,
    log: {},
    loading: false,
});

const setLogsListFilter = (state, action) => ({
    ...state,
    searchFormValue: action.filter,
});

const clearLogsListFilter = (state, action) => ({
    ...state,
    searchFormValue: {},
});

export default function report(state = initialState, action) {
    switch (action.type) {
        case LOGS_LIST_LOAD:
            return logsListLoad(state, action);
        case LOGS_LIST_RECEIVE:
            return logsListReceive(state, action);
        case LOGS_LIST_ERROR:
            return logsListLoadError(state, action);
        case LOG_LOAD:
            return logLoad(state, action);
        case LOG_RECEIVE:
            return logReceive(state, action);
        case LOG_ERROR:
            return logLoadError(state, action);
        case LOGS_IMPORT_LIST_FILTER_CLEAR:
            return clearLogsListFilter(state, action);
        case LOGS_IMPORT_LIST_FILTER_SET:
            return setLogsListFilter(state, action);
        default:
            return state;
    }
}
