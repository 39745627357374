import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../../common/utils/constants";

export const { catalog } = createActions({
    CATALOG: {
        ...loadActions,
        SAVE_ITEM: asyncActions,
        DELETE_ITEM: asyncActions,
        ITEM: loadActions,
    },
});
