import { filterRetry, useOneItemQuery } from "../../utils/commonQueryBase";

const BASE_SHORT_URL = "/rest-api/c/";

export function useShortUrl(shortUrl) {
    return useOneItemQuery(BASE_SHORT_URL, shortUrl, {
        retry: filterRetry(3, { notRetryCode: "ECERT10019" }),
        refetchOnWindowFocus: false,
    });
}
