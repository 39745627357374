import { ANIMAL_SPECIES_LIST_LOAD, ANIMAL_SPECIES_LIST_RECEIVE, ANIMAL_SPECIES_LIST_ERROR } from "../constants/ActionTypes";

const initialState = {
    animalSpecies: [],
    error: null,
    loading: false,
};

const getList = (action) => (action.animalSpecies && action.animalSpecies.length > 0 ? action.animalSpecies : []);

const animalSpeciesLoad = (state, action) => ({ ...state, loading: true });

const animalSpeciesReceive = (state, action) => ({
    ...state,
    error: null,
    animalSpecies: getList(action),
    loading: false,
});

const animalSpeciesError = (state, action) => ({
    ...state,
    error: action.error,
    animalSpecies: [],
    loading: false,
});

export default function animalSpecies(state = initialState, action) {
    switch (action.type) {
        case ANIMAL_SPECIES_LIST_LOAD:
            return animalSpeciesLoad(state, action);
        case ANIMAL_SPECIES_LIST_RECEIVE:
            return animalSpeciesReceive(state, action);
        case ANIMAL_SPECIES_LIST_ERROR:
            return animalSpeciesError(state, action);
        default:
            return state;
    }
}
