import { Table } from "vetrf-ui";
import isObject from "lodash/isObject";
import React from "react";

const CompareTable = ({ dataList, dataListToCompare, columns }) => {
    const differentRows = dataListToCompare ? getDifferentRows(dataList, dataListToCompare, columns) : [];
    const customColumns = dataListToCompare
        ? columns.map((column) => ({
              ...column,
              getClassName: (row) => (differentRows.indexOf(row) !== -1 ? "dark-blue light-orange-background strike" : column.getClassName),
          }))
        : columns;
    return <Table dataList={dataListToCompare ? [...differentRows, ...dataList] : dataList} columns={customColumns} />;
};

const getDifferentRows = (rows, rowsForCompare, columns) => {
    const stringifyRows = (rows) =>
        rows.map((row) =>
            columns
                .map((column) => {
                    const cellData = column.dataFormatter(row);
                    if (!isObject(cellData)) {
                        return cellData;
                    }
                    if (column.compareData) {
                        return column.compareData(row);
                    }
                    return false;
                })
                .join("-")
        );

    const strikedRows = [];
    const rowsStrings = stringifyRows(rows);
    const rowsForCompareStrings = stringifyRows(rowsForCompare);
    for (let i = 0; i < rowsForCompareStrings.length; i++) {
        const row = rowsForCompareStrings[i];
        if (!rowsStrings.includes(row)) {
            strikedRows.push(rowsForCompare[i]);
        }
    }
    return strikedRows;
};

export default CompareTable;
