import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ProfileTable, SubHeader } from "vetrf-ui";
import { unknownNormalText } from "../../../../../utils/constants/constants";

const TransportView = (props) => {
    const { transport } = props;
    const commonRows = [
        {
            header: {
                key: "transportType",
                title: "Тип",
                width: "12%",
            },
            dataFormatter: (item) => getTitleOfType(item.transportType),
        },
        {
            header: {
                key: "trailerNumber",
                title: "Номер прицепа (полуприцепа)",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.trailerNumber : unknownNormalText),
        },
        {
            header: {
                key: "vehicleNumber",
                title: "Номер автомобиля",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.vehicleNumber : unknownNormalText),
        },
        {
            header: {
                key: "wagonNumber",
                title: "Номер вагона",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.wagonNumber : unknownNormalText),
        },
        {
            header: {
                key: "containerNumber",
                title: "Номер контейнера",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.containerNumber : unknownNormalText),
        },
        {
            header: {
                key: "shipName",
                title: "Наименование судна",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.shipName : unknownNormalText),
        },
        {
            header: {
                key: "flightNumber",
                title: "Номер авиарейса",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.flightNumber : unknownNormalText),
        },
        {
            header: {
                key: "affixedSeal",
                title: "Номер пломбы",
                width: "12%",
            },
            dataFormatter: (item) => (item.transportNumber ? item.transportNumber.affixedSeal : unknownNormalText),
        },
    ];

    const getTitleOfType = (type) => {
        switch (type) {
            case 0:
                return "Неизвестный тип";
            case 1:
                return "Автомобильный";
            case 2:
                return "Железнодорожный";
            case 3:
                return "Авиатранспортный";
            case 4:
                return "Морской (контейнер)";
            case 5:
                return "Морской (трюм)";
            case 6:
                return "Речной";
            case 7:
                return "Перегон";
            default:
                return "Неизвестный тип";
        }
    };

    const rows = commonRows.filter((item) => {
        return !(transport[item.header.key] === undefined && transport.transportNumber[item.header.key] === undefined);
    });

    return (
        <div>
            <SubHeader header={"Транспортное средство"} />
            <ProfileTable
                itemList={transport ? [{ item: transport }] : [{ item: {} }]}
                tableClassName="table color-table-border font-weight-normal"
                rows={rows}
            />
        </div>
    );
};

TransportView.propTypes = {
    transport: PropTypes.object,
};

export default withTranslation("translations")(TransportView);
