import React, { useCallback, useState } from "react";
import { Button, Page } from "vetrf-ui";
import { useTranslation } from "react-i18next";

import SystemNotificationPageableTable from "./components/SystemNotificationPageableTable";
import usePageParams from "../../utils/usePageParams";
import { fillNotificationEvent } from "../notification-utils";
import { useNotificationsPage } from "../systemNotificationQuery";
import { useSystemNotifications } from "./components/SystemNotificationProvider";

const SystemNotificationsPage = () => {
    const { t } = useTranslation("translation");
    const title = t("Все уведомления");
    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            link: "/",
            text: "Главная",
        },
        {
            text: title,
        },
    ];

    const { pageParams, setPage, setSize, searchParams } = usePageParams("notifications");
    const [notifications, setNotifications] = useState();
    const [allMarkedAsRead, setAllMarkedAsRead] = useState(false);
    useNotificationsPage(pageParams, searchParams, onSuccess);

    function onSuccess(data) {
        data.content.forEach((x) => fillNotificationEvent(x.event, t));
        setNotifications(data);
    }

    const onNewNotificationFetched = useCallback(
        (data) => {
            if (!notifications) return;
            if (pageParams.page === 1) {
                let targetNotifications = {
                    ...notifications,
                    content: [...data.content, ...notifications.content],
                };
                targetNotifications.totalElements += data.totalElements;
                targetNotifications.totalPages = Math.ceil(targetNotifications.totalElements / notifications.size);
                targetNotifications.content.length = targetNotifications.size;
                setNotifications(targetNotifications);
            }
            setAllMarkedAsRead(false);
        },
        [notifications, pageParams]
    );

    const onNotificationMarked = useCallback(
        (notification) => {
            if (notifications) {
                let targetNotifications = {
                    ...notifications,
                    content: [...notifications.content],
                };
                let marked = targetNotifications.content.find((x) => x.id === notification.id);
                if (marked) {
                    marked.isRead = true;
                    setNotifications(targetNotifications);
                }
            }
        },
        [notifications]
    );

    const onAllNotificationsMarked = useCallback(() => {
        if (!notifications?.content) return;
        let targetNotifications = {
            ...notifications,
            content: [...notifications.content],
        };
        targetNotifications.content.forEach((x) => (x.isRead = true));
        setNotifications(targetNotifications);
        setAllMarkedAsRead(true);
    }, [notifications]);

    const { markAllAsRead } = useSystemNotifications({
        onNewNotificationFetched: onNewNotificationFetched,
        onNotificationMarked: onNotificationMarked,
        onAllNotificationsMarked: onAllNotificationsMarked,
    });

    const toolbar = (
        <div className="pull-right actions">
            <Button
                disabled={!notifications?.content?.length || allMarkedAsRead}
                id={"notifications_page__mark_all_btn"}
                icon="check"
                color="purple"
                size="sm"
                text={t("Прочитать все")}
                onClick={markAllAsRead}
            />
            <Button id={"notifications_page__settings_btn"} icon="cog" color="primary" size="sm" text={t("Настройки")} href={"/user-settings"} />
        </div>
    );

    const changePageSize = (size) => {
        setSize(size);
        setPage(1);
    };

    return (
        <Page title={title} breadcrumbs={breadcrumbs} header={title} toolbar={toolbar}>
            <div className="col-lg-offset-3 col-lg-6 col-md-12">
                <SystemNotificationPageableTable page={notifications} onChangePage={setPage} onChangeSizePerPage={changePageSize} />
            </div>
        </Page>
    );
};

export default SystemNotificationsPage;
