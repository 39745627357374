import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";

import UserShortInfo from "./UserShortInfo";

const initialState = { dropdownOpen: false };

class LogoutDropdown extends Component {
    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = initialState;
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    handleClickOutside(e) {
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState(initialState);
        }
    }

    render() {
        const { user, avatarSrc, children } = this.props;
        const url = process.env.NODE_ENV === "production" ? "/logout" : "http://localhost:8080/logout";
        return (
            <li className={`light-blue ${this.state.dropdownOpen ? "open" : ""}`}>
                <UserShortInfo avatarSrc={avatarSrc} user={user} handleClick={this.toggleDropdown} />
                <ul className="user-menu pull-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">
                    {children}
                    <li>
                        <a id="user_info__dropdown_btn__logout" href={url}>
                            <i className="ace-icon fa fa-power-off" />
                            Выйти
                        </a>
                    </li>
                </ul>
            </li>
        );
    }
}

LogoutDropdown.propTypes = {
    user: PropTypes.shape({
        fio: PropTypes.string,
        login: PropTypes.string,
        institution: PropTypes.shape({
            shortName: PropTypes.string,
        }),
    }),
    avatarSrc: PropTypes.string,
    children: PropTypes.node,
};

export default LogoutDropdown;
