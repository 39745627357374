import React from "react";
import PropTypes from "prop-types";
import { FormAction, SubHeader } from "vetrf-ui";
import LogListSearchForm from "./LogListSearchForm";

const LogListSearchPanel = (props) => {
    const { search, handleChange, resetSearchForm, searchFormValue } = props;

    const searchBtn = {
        text: "Поиск",
        icon: "search",
        className: "btn btn-info btn-sm col-xs-2",
        action: () => search && search(searchFormValue),
    };

    const resetBtn = {
        className: "btn btn-sm",
        text: "Очистить",
        action: resetSearchForm,
    };

    return (
        <div className="search-panel col-xs-12 no-padding">
            <SubHeader header="Поиск" />
            <LogListSearchForm searchFormValue={searchFormValue} handleChange={handleChange} />
            <FormAction confirmBtn={searchBtn} resetBtn={resetBtn} />
        </div>
    );
};

LogListSearchPanel.propTypes = {
    search: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    resetSearchForm: PropTypes.func,
    searchFormValue: PropTypes.object,
};

export default LogListSearchPanel;
