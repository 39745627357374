import React from "react";
import { Route, Routes } from "react-router-dom";

import ECertImportListPage from "./containers/ecert/list/ECertImportListPage";
import ECertImportViewPage from "./containers/ecert/view/ECertImportViewPage";
import LogsListPage from "./containers/logs/list/LogsListPage";
import PageNotFound from "../errors/PageNotFound";

const ProcessingRoutes = () => (
    <Routes>
        <Route exact path="" element={<ECertImportListPage />} />
        <Route exact path="logs" element={<LogsListPage />} />
        <Route exact path=":id" element={<ECertImportViewPage />} />
        <Route path="*" element={<PageNotFound />} />
    </Routes>
);

export default ProcessingRoutes;
