import ajax from "../../utils/ajax";

import { RUSSIAN_REGION_LIST_LOAD, RUSSIAN_REGION_LIST_RECEIVE, RUSSIAN_REGION_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const russianRegionsLoad = () => ({
    type: RUSSIAN_REGION_LIST_LOAD,
});

export const russianRegionsReceive = (russianRegions) => ({
    type: RUSSIAN_REGION_LIST_RECEIVE,
    russianRegions,
});

export const russianRegionsLoadError = (error) => ({
    type: RUSSIAN_REGION_LIST_ERROR,
    error,
});

export const requestRussianRegions = () => (dispatch) => {
    dispatch(russianRegionsLoad());
    request({
        type: "POST",
        url: "russianRegions",
    })
        .then((response) => {
            const result = response.map((c) => ({
                ...c,
                view: c.view + (c.englishName ? ` (${c.englishName})` : ""),
            }));
            dispatch(russianRegionsReceive(result));
        })
        .catch((error) => {
            dispatch(russianRegionsLoadError(error));
        });
};
