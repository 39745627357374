import { bindActionCreators } from "redux";
import { cloneDeep, isEmpty } from "lodash";
import { ConfirmActionButton, Page, setIn } from "vetrf-ui";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { findFirstNotValid, findItem, findNextSibling, findPreviousSibling, getWbrConclusionRoadColumns } from "../../../utils/panelItemGenerate";
import { NEW, VALID } from "../../../components/sidePanel/constants/FormStatus";
import { path } from "../../WbrConclusionRoutes";
import { RUSSIA } from "../../../utils/constants/constants";
import { useIsWbrConclusionMutating, useGetWbrConclusion, useSaveWbrConclusionMutation } from "../../wbrConclusionQuery";
import { useUserContext } from "../../../session/UserContextProvider";
import { useWbrApplicationToConclusion } from "../../application/api/WbrApplicationQueries";
import { WBR_APP_ROUTE } from "../../application/WbrApplicationRoutes";
import * as businessParticipantsService from "../../../dictionary/store/businessParticipants/service";
import * as dictionaryService from "../../../dictionary/store/service";
import FormSidePanelItem from "../../../components/sidePanel/FormSidePanel";
import WbrConclusionCommonForm from "./components/WbrConclusionCommonForm";
import WbrConclusionConsigneeForm from "./components/WbrConclusionConsigneeForm";
import WbrConclusionConsignmentForm from "./components/WbrConclusionConsignmentForm";
import WbrConclusionConsignorForm from "./components/WbrConclusionConsignorForm";
import WbrConclusionSummary from "./components/WbrConclusionSummary";
import WbrConclusionTransportForm from "./components/WbrConclusionTransportForm";

const getInitialState = (user, t) => ({
    title: t("Регистрация заключения"),
    panel: {
        items: getWbrConclusionRoadColumns(),
        formStatus: false,
    },
    conclusion: {
        issueDateTime: moment().format("DD.MM.YYYY"),
        createdByUser: user,
        documentStatus: "ISSUED",
        complianceConclusion: {
            decision: "MEET_REQUIREMENTS"
        },
        consignment: {
            exportCountry: RUSSIA,
            consignmentItems: [],
        },
    },
    files: [],
});

const WbrConclusionFormPage = ({
    saving,
    error,
    countries,
    businessParticipantsService,
    businessParticipants,
    businessParticipantsLoading,
    unitMeasures,
    transportTypes,
    transportEquipmentTypes,
    regions,
    dictionaryService,
}) => {
    const { t } = useTranslation("translation");
    const { id, action, applicationId } = useParams();
    const { state: locationConclusionState } = useLocation();
    const navigate = useNavigate();
    const { user } = useUserContext();
    const [conclusionState, setConclusionState] = useState(getInitialState(user, t));
    const [conclusionSaving, setConclusionSaving] = useState(false);

    const conclusionStateRef = useRef();
    conclusionStateRef.current = conclusionState;

    const wbrConclusionQuery = useGetWbrConclusion(id);
    const wbrConclusionMutation = useSaveWbrConclusionMutation();
    const isSaving = useIsWbrConclusionMutating();
    const { data: conclusionFromApplication } = useWbrApplicationToConclusion(applicationId);

    useEffect(() => {
        const { requestCountries, requestTransportTypes, requestTransportEquipmentTypes, requestUnitMeasures, requestRegions } = dictionaryService;
        if (isEmpty(countries)) {
            requestCountries();
        }

        if (isEmpty(unitMeasures)) {
            requestUnitMeasures();
        }
        if (isEmpty(transportTypes)) {
            requestTransportTypes();
        }
        if (isEmpty(transportEquipmentTypes)) {
            requestTransportEquipmentTypes();
        }
        if (isEmpty(regions)) {
            requestRegions(RUSSIA.guid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && !isEmpty(wbrConclusionQuery.data)) updateStateOnEdit(wbrConclusionQuery.data, action);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wbrConclusionQuery?.data]);

    useEffect(() => {
        if (conclusionSaving && !saving && error === null) navigate(id ? `../${id}` : "..");
        setConclusionSaving(saving);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saving]);

    useEffect(() => {
        if (isEmpty(id)) setConclusionState(getInitialState(user, t));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (conclusionFromApplication) {
            const initialState = getInitialState(user, t);
            setConclusionState({ ...initialState, conclusion: { ...initialState.conclusion, ...conclusionFromApplication, ...locationConclusionState } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conclusionFromApplication]);

    const onChangeHandler = (value, path) => {
        const val = value;
        let state = conclusionStateRef.current;
        if (path === "conclusion.consignment.importCountry") {
            if (!state?.conclusion?.consignment?.importCountry) {
                state = setIn(state, "conclusion.consignment.consignee.address.country", val);
                state = setIn(state, "conclusion.consignment.consignor.address.country", RUSSIA);
            } else {
                if (!state?.conclusion?.consignment?.consignee?.name) {
                    state = setIn(state, "conclusion.consignment.consignee.address.country", val);
                }
            }
            if (val && val.guid) {
                dictionaryService.requestRegions(val.guid);
            }
        }
        setConclusionState(setIn(state, path, val));
    };

    const onChangeHandlerPDF = (files) => {
        setConclusionState({
            ...conclusionStateRef.current,
            files: files,
        });
    };

    // panel
    const statusHandler = (id) => {
        return (status) => {
            let { panel } = conclusionStateRef.current;
            const item = findItem(panel, id);
            if (item) {
                findItem(panel, id).status = status;
            }
            setConclusionState({
                ...conclusionStateRef.current,
                panel: panel,
            });
        };
    };

    //panel
    const submitHandler = (id) => {
        return (status, callback) => {
            let { panel } = conclusionStateRef.current;
            let item = findItem(panel, id);
            if (item) {
                item.status = status;

                setConclusionState({
                    ...conclusionStateRef.current,
                    panel: panel,
                });

                if (status === VALID) {
                    callback && callback();

                    let url;
                    if (item.items) {
                        url = item.items[0].url;
                    } else {
                        url = findNextSibling(panel, item).url;
                    }
                    navigate(url);
                }
            }
        };
    };

    //panel
    const previousButtonHandler = (id) => {
        return () => {
            let { panel } = conclusionState;
            let item = findItem(panel, id);
            let url = findPreviousSibling(panel, item).url;
            navigate(url);
        };
    };

    const updateStateOnEdit = (oldVersionRecord, action) => {
        const defaultStatus = action === "copy" ? NEW : VALID;
        let copyRecord = cloneDeep(oldVersionRecord);
        const editBlobMock = new Blob([""], { type: "application/pdf" });
        const attachFile = copyRecord.file;
        editBlobMock["name"] = attachFile ? attachFile.nativeName + ".pdf" : t("Неизвестное имя файла");
        editBlobMock["isMock"] = true;
        if (action === "copy") {
            copyRecord.referenceNumber = undefined;
            copyRecord.documentStatus = "ISSUED";
            copyRecord.id = undefined;
            copyRecord.consignment.id = undefined;
            copyRecord.consignment?.consignmentItems?.forEach((item) => {
                item.id = undefined;
                item.ordered = undefined;
            });
        }

        const editBlobMockArr = action !== "copy" ? [editBlobMock] : undefined;

        let panel = { ...conclusionStateRef.current.panel };
        panel.items.forEach((item) => {
            if (item.id === "common" && action === "copy") {
                item.status = defaultStatus;
            } else {
                item.status = defaultStatus;
            }
            if (item.id === "summary") {
                item.status = null;
            }
        });

        copyRecord.consignment?.consignmentItems?.map((item, index) => {
            item.index = index;
            return item;
        });

        const header = t("Редактирование заключения");
        setConclusionState({
            ...conclusionStateRef.current,
            conclusion: copyRecord,
            originCert: oldVersionRecord,
            panel: panel,
            title: header,
            files: editBlobMockArr,
        });
    };

    const saveConclusionHandler = () => {
        const { panel, files, conclusion } = conclusionState;
        if (!isSaving) {
            const notValidItem = findFirstNotValid(panel);
            if (!notValidItem) {
                let formData = new FormData();
                const file = files[0];
                const requestConclusion = cloneDeep(conclusion);

                formData.append("file", new Blob([file], { type: file.type }), file ? file.name : "unknown");
                formData.append(
                    "conclusion",
                    new Blob([JSON.stringify(requestConclusion)], {
                        type: "application/json",
                    })
                );
                wbrConclusionMutation.mutate(formData, {
                    onSuccess: (conlusion) => {
                        navigate(`${path}/${conlusion.id}`);
                    },
                });
                setConclusionSaving(true);
            } else {
                const url = notValidItem.url;
                navigate(url);
            }
        }
    };

    const { title } = conclusionState;

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            link: "/",
            text: t("Главная"),
        },
        {
            id: "breadcrumb__wbr_list_page",
            link: path,
            text: t("Заключения ВБР"),
        },
        {
            text: title,
        },
    ];

    const onBackClick = () => {
        if (applicationId) {
            navigate(WBR_APP_ROUTE.VIEW.getFullPath(applicationId));
        } else {
            navigate(`/wbr-conclusion/conclusion/${id || ""}`);
        }
    };

    const CancelButton = (
        <ConfirmActionButton
            onConfirm={onBackClick}
            id="cancel_btn"
            buttonSize="sm"
            buttonText={t("Отмена")}
            confirmBodyContent={t("Вы уверены, что хотите отменить регистрацию заключения? Все введенные данные будут потеряны.")}
            confirmHeaderText={t("Отмена регистрации заключения ВБР")}
            confirmBtnText={t("Да")}
            confirmBtnColor="danger"
            cancelBtnText={t("Нет")}
        />
    );

    return (
        <Page title={title} breadcrumbs={breadcrumbs} header={title}>
            <div className="row">
                <FormSidePanelItem items={conclusionState?.panel?.items} name={t("Карта документа")}>
                    <Routes>
                        <Route
                            path={``}
                            element={
                                <WbrConclusionCommonForm
                                    countries={countries}
                                    onChangeHandler={onChangeHandler}
                                    submitHandler={submitHandler("common")}
                                    statusHandler={statusHandler("common")}
                                    conclusion={conclusionState.conclusion}
                                    files={conclusionState.files}
                                    user={user}
                                    onChangeHandlerPDF={onChangeHandlerPDF}
                                    cancelButton={CancelButton}
                                />
                            }
                        />
                        <Route
                            path={`consignor`}
                            element={
                                <WbrConclusionConsignorForm
                                    conclusion={conclusionState.conclusion}
                                    onChangeHandler={onChangeHandler}
                                    submitHandler={submitHandler("consignor")}
                                    statusHandler={statusHandler("consignor")}
                                    previousButtonHandler={previousButtonHandler("consignor")}
                                    countries={countries}
                                    regions={regions}
                                    requestRegionsByCountry={dictionaryService.requestRegions}
                                    businessParticipantsService={businessParticipantsService}
                                    businessParticipants={businessParticipants}
                                    businessParticipantsLoading={businessParticipantsLoading}
                                />
                            }
                        />
                        <Route
                            path={`consignee`}
                            element={
                                <WbrConclusionConsigneeForm
                                    conclusion={conclusionState.conclusion}
                                    onChangeHandler={onChangeHandler}
                                    submitHandler={submitHandler("consignee")}
                                    statusHandler={statusHandler("consignee")}
                                    previousButtonHandler={previousButtonHandler("consignee")}
                                    countries={countries}
                                    regions={regions}
                                    requestRegionsByCountry={dictionaryService.requestRegions}
                                    businessParticipantsService={businessParticipantsService}
                                    businessParticipants={businessParticipants}
                                    businessParticipantsLoading={businessParticipantsLoading}
                                />
                            }
                        />
                        <Route
                            path={`transport`}
                            element={
                                <WbrConclusionTransportForm
                                    conclusion={conclusionState.conclusion}
                                    onChangeHandler={onChangeHandler}
                                    submitHandler={submitHandler("transport")}
                                    statusHandler={statusHandler("transport")}
                                    previousButtonHandler={previousButtonHandler("transport")}
                                    transportTypes={transportTypes}
                                    transportEquipmentTypes={transportEquipmentTypes}
                                />
                            }
                        />
                        <Route
                            path={`consignment`}
                            element={
                                <WbrConclusionConsignmentForm
                                    conclusion={conclusionState.conclusion}
                                    consignment={conclusionState.conclusion?.consignment}
                                    unitMeasures={unitMeasures}
                                    onChangeHandler={onChangeHandler}
                                    statusHandler={statusHandler("consignment")}
                                    submitHandler={submitHandler("consignment")}
                                    previousButtonHandler={previousButtonHandler("consignment")}
                                    countries={countries}
                                    cancelButton={CancelButton}
                                />
                            }
                        />
                        <Route
                            path={`summary`}
                            element={
                                <WbrConclusionSummary
                                    conclusion={conclusionState.conclusion}
                                    cancelButton={CancelButton}
                                    countries={countries}
                                    formValid={true}
                                    action={action}
                                    files={conclusionState.files}
                                    saving={saving}
                                    saveRecord={saveConclusionHandler}
                                />
                            }
                        />
                    </Routes>
                </FormSidePanelItem>
            </div>
        </Page>
    );
};

function mapStateToProps(state) {
    return {
        countries: state.catalogStore.countriesStore.countries,
        regions: state.catalogStore.russianRegionsStore.regions,
        unitMeasures: state.catalogStore.unitMeasuresStore.unitMeasures,
        transportTypes: state.catalogStore.transportTypesStore.transportTypes,
        transportEquipmentTypes: state.catalogStore.transportEquipmentTypesStore.transportEquipmentTypes,
        savingVeterinarian: state.catalogStore.officialVeterinariansStore.saving,
        businessParticipants: state.catalogStore.businessParticipantsStore.businessParticipants,
        businessParticipantsLoading: state.catalogStore.businessParticipantsStore.loading,
    };
}

const mapDispatchToProps = (dispatch) => ({
    dictionaryService: bindActionCreators(dictionaryService, dispatch),
    businessParticipantsService: bindActionCreators(businessParticipantsService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WbrConclusionFormPage);
