import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Button, deleteIn, Form, InputFormGroup, AutocompleteFormGroup, setIn } from "vetrf-ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import * as businessParticipantsService from "../../../store/businessParticipants/service";

const RussianForm = ({ item, saving, onSave, onToggleModal, entityAutocompleteList, businessParticipantsService }) => {
    const { t } = useTranslation();
    const [participant, setParticipant] = useState({});
    const [showError, setShowError] = useState(false);
    const invalidFields = useRef({});
    const [autocompleteInn, setAutocompleteInn] = useState();

    useEffect(() => {
        if (item) {
            setParticipant(item);
        }
    }, [item]);

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const onChangeHandler = (value, field) => {
        let newParticipant = setIn(participant, field, value);
        setParticipant(newParticipant);
    };

    const onAutocompleteResetHandler = () => {
        const { requestBusinessEntitiesAutocompleteReset } = businessParticipantsService;
        requestBusinessEntitiesAutocompleteReset();
    };

    const selectAutocompleteHandler = (value) => {
        const item = {
            name: value.view,
            address: value.juridicalAddress.addressView,
            country: value.juridicalAddress.country,
            region: value.juridicalAddress.region,
            englishName: value.latinName,
            businessEntityGuid: value.guid,
        };
        setAutocompleteInn(value.inn);
        setParticipant(item);
        onAutocompleteHandler(value);
    };

    const onAutocompleteHandler = (value) => {
        const { requestBusinessEntitiesAutocomplete } = businessParticipantsService;
        requestBusinessEntitiesAutocomplete(value.inn);
    };

    const onSaveHandler = () => {
        setShowError(true);
        setTimeout(() => {
            if (isEmpty(invalidFields.current)) {
                onSave(participant);
            }
        }, 300);
    };

    const isDisabledSave = isEmpty(participant);
    return (
        <div className="col-xs-12 no-padding">
            <Form name="russianForm">
                {
                    <AutocompleteFormGroup
                        labelText="ИНН"
                        name="inn"
                        searchField="inn"
                        selectField="inn"
                        viewKey="autocompleteView"
                        id="inn_auto"
                        value={autocompleteInn}
                        items={entityAutocompleteList}
                        onSelect={selectAutocompleteHandler}
                        onAutocomplete={onAutocompleteHandler}
                        resetAutocompleteList={onAutocompleteResetHandler}
                        onChange={setAutocompleteInn}
                        searchLabel={true}
                        help="Укажите ИНН для поиска в реестре хозяйствующих субъектов в АИС Цербер"
                    />
                }
                <InputFormGroup
                    labelText={t("Наименование")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.name}
                    field="name"
                    onChange={onChangeHandler}
                    disabled={true}
                    require={true}
                />
                <InputFormGroup
                    labelText={t("Страна")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.country?.view}
                    field="country"
                    disabled={true}
                    onChange={onChangeHandler}
                    require={true}
                />
                <InputFormGroup
                    labelText={t("Регион")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.region?.view}
                    field="region"
                    disabled={true}
                    onChange={onChangeHandler}
                    require={true}
                />
                <InputFormGroup
                    labelText={t("Адрес")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.address}
                    field="address"
                    onChange={onChangeHandler}
                    disabled={true}
                    require={true}
                />
                <InputFormGroup
                    labelText={t("Наименование на английском")}
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.englishName}
                    field="englishName"
                    onChange={onChangeHandler}
                    require
                    validateLatin
                    help={t("Укажите наименование на английском языке, так как оно будет печататься на сертификате")}
                />
                <InputFormGroup
                    labelText="Адрес на английском"
                    showError={showError}
                    errorHandler={errorHandler}
                    value={participant.englishAddress}
                    field="englishAddress"
                    onChange={onChangeHandler}
                    require
                    validateLatin
                    help={t("Укажите адрес на английском языке, так как он будет печататься на сертификате")}
                />
            </Form>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="cancel_btn" size="sm" onClick={onToggleModal} text={t("Отмена")} />
                <div className="pull-right">
                    <Button
                        id="save_btn"
                        size="sm"
                        color="success"
                        onClick={onSaveHandler}
                        text={t("Сохранить")}
                        disabled={isDisabledSave || saving}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    entityAutocompleteList: state.catalogStore.businessParticipantsStore.entityAutocompleteList,
});

const mapDispatchToProps = (dispatch) => ({
    businessParticipantsService: bindActionCreators(businessParticipantsService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RussianForm);
