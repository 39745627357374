import { useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";

import SearchForm, { getChangedAdvancedFieldsCount } from "../../../../components/list/SearchForm";
import SearchFormBudgeList from "../../../../components/list/SearchFormBudgeList";
import SearchToggleButton from "../../../../components/list/SearchToggleButton";

const BusinessParticipantSearchWrapper = ({ searchParams, setSearchParams, additionalToolbar, children }) => {
    const { t } = useTranslation();
    const [isShowSearchPanel, setIsShowSearchPanel] = useState(false);

    const searchSchema = {
        fields: {
            name: { title: t("Наименование") },
            address: { title: t("Адрес") },
        },
        quickSearch: ["address", "name"],
    };

    const budgeList = <SearchFormBudgeList schema={searchSchema} searchParams={searchParams} setSearchParams={setSearchParams} />;

    const searchToggleButton = (
        <div className="pull-right actions">
            {additionalToolbar}
            <SearchToggleButton
                active={isShowSearchPanel}
                onClick={() => setIsShowSearchPanel(!isShowSearchPanel)}
                advancedParamsCount={getChangedAdvancedFieldsCount(searchParams, searchSchema)}
            />
        </div>
    );

    const searchForm = (
        <SearchForm schema={searchSchema} searchParams={searchParams} isExtendedPanelShow={isShowSearchPanel} setSearchParams={setSearchParams} />
    );

    return children(searchForm, budgeList, searchToggleButton);
};

export default BusinessParticipantSearchWrapper;
