import { useEffect, useRef } from "react";

function useClickElementEffect(callback) {
    const clickStackRef = useRef([]);
    const lastClickRef = useRef();

    useEffect(() => {
        document.addEventListener("click", clickHandler, false);
        return () => {
            document.removeEventListener("click", clickHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickHandler = (e) => {
        e = e || window.event;
        const element = generateSelector(e.target);
        if (element && element !== lastClickRef.current) {
            callback?.(element, e.target);
            lastClickRef.current = element;
        }
    };

    return clickStackRef.current;
}

// CSS Selector
function generateSelector(element) {
    const elementId = element.id;
    if (elementId) return `#${element.id}`;
    const className = element.className ? `.${[...element.classList].join(".")}` : "";
    return `${element.localName}${className}`;
}

export default useClickElementEffect;
