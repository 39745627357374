import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, deleteIn, RowEditTable, SelectFormGroup, ValidateNumberFormGroup } from "vetrf-ui";
import { ERROR, VALID } from "../../../../components/sidePanel/constants/FormStatus";
import { useTranslation } from "react-i18next";
import { indexOf, isNull } from "lodash";
import { getUnknownCursiveText, RUSSIA } from "../../../../utils/constants/constants";
import ProductFormComponent from "../../../../components/form/ProductFormComponent";

export const getConsignmentItems = (conclusion) => {
    return conclusion?.consignment?.consignmentItems ? conclusion.consignment.consignmentItems : [];
};

const WbrConclusionConsignmentForm = ({
    conclusion,
    consignment,
    unitMeasures,
    onChangeHandler,
    statusHandler,
    submitHandler,
    previousButtonHandler,
    countries,
}) => {
    const { t } = useTranslation("translation");
    const [showEmptyError, setShowEmptyError] = useState(false);

    const onChange = (value, path) => {
        statusHandler();
        onChangeHandler && onChangeHandler(value, path);
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keydownHandler = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            nextOnClickHandler();
        }
    };

    // Идёт позиция на вход, так как удалять можно любую позицию с вкладки Сертифицируемая продукция
    const removeConsignmentItem = (item) => {
        const index = indexOf(getConsignmentItems(conclusion), item);
        const items = deleteIn(getConsignmentItems(conclusion), `[${index}]`);
        onChange(items, `conclusion.consignment.consignmentItems`);
    };

    const rowFormField = (item, onChangeField, showError, errorHandler, formEditRowId) => {
        return (
            <Fragment>
                <SelectFormGroup
                    labelText={t("Страна происхождения")}
                    require
                    options={countries}
                    valueKey="guid"
                    labelKey="name"
                    value={item.originCountry ? item.originCountry : RUSSIA}
                    field="originCountry"
                    placeholder={t("Выберите страну...")}
                    onChange={onChangeField}
                    showError={showError}
                    errorHandler={errorHandler}
                    help=""
                />
                <ProductFormComponent
                    item={item}
                    itemPath={""}
                    onChangeHandler={(value, path) => {
                        if (path === "subProduct") {
                            onChangeField({ ...item, cnCode: value?.code, subProduct: value }, "");
                        } else {
                            onChangeField(value, path);
                        }
                    }}
                    errorHandler={errorHandler}
                    showError={showError}
                    prefixId={formEditRowId}
                />
                <ValidateNumberFormGroup
                    labelText={t("ТН ВЭД код")}
                    require
                    onChange={onChangeField}
                    field={`cnCode`}
                    value={item && item.cnCode}
                    maxLength={10}
                    showError={showError}
                    customValidate={(value) => value?.length >= 4}
                    errorHandler={errorHandler}
                    help={t("Минимальная длина кода составляет 4 цифры")}
                    prefixId={formEditRowId}
                />
                <ValidateNumberFormGroup
                    labelText={t("Вес нетто")}
                    require
                    format={{
                        errorText: "Поле допускает только ввод целых или дробных чисел",
                        float: true,
                    }}
                    onChange={onChangeField}
                    customValidate={(value) => value > 0}
                    field={`netWeight`}
                    value={item && item.netWeight}
                    showError={showError}
                    errorHandler={errorHandler}
                    maxLength={16}
                    prefixId={formEditRowId}
                />
                <SelectFormGroup
                    labelText={t("Единицы измерения")}
                    require
                    options={unitMeasures}
                    valueKey="code"
                    labelKey="name"
                    value={item.unitMeasure}
                    onChange={onChangeField}
                    field={`unitMeasure`}
                    placeholder={t("Выберите ед.измерения...")}
                    showError={showError}
                    errorHandler={errorHandler}
                    prefixId={formEditRowId}
                    help=""
                />
            </Fragment>
        );
    };

    const handleChangeRow = (item, index) => {
        if (!item.originCountry) {
            item.originCountry = RUSSIA;
        }
        if (isNull(index)) {
            const items = getConsignmentItems(conclusion);
            onChange(items.concat(item), `conclusion.consignment.consignmentItems`);
        } else {
            const itemPath = `conclusion.consignment.consignmentItems[${index}]`;
            onChange(item, itemPath);
        }
    };

    const unknown = getUnknownCursiveText(t);

    const nextOnClickHandler = () => {
        setShowEmptyError(true);
        setTimeout(() => {
            const status = consignment?.consignmentItems?.length > 0 ? VALID : ERROR;
            submitHandler(status);
        }, 300);
    };
    const maxConsignmentItems = 100;

    const columns = [
        {
            key: "originCountry",
            title: t("Страна происхождения"),
            className: "align-center",
            dataFormatter: (item) => (item.originCountry ? item.originCountry?.view : unknown),
        },
        {
            key: "subProduct",
            title: t("Вид продукции"),
            className: "align-center",
            dataFormatter: (item) => (item.subProduct ? item.subProduct?.name : unknown),
        },
        {
            key: "cnCode",
            title: t("ТН ВЭД код"),
            className: "align-center",
            dataFormatter: (item) => (item.cnCode ? item.cnCode : unknown),
        },
        {
            key: "netWeight",
            title: t("Объем"),
            className: "align-center",
            dataFormatter: (item) => (item.netWeight ? <span>{item.netWeight + " " + item.unitMeasure?.shortName}</span> : unknown),
        },
        {
            key: "actions",
            title: "",
            width: "90px",
            buttonFormatter: {
                deleteBtn: {
                    action: removeConsignmentItem,
                    confirmHeaderText: t("Удаление сведений о партии"),
                    confirmBodyText: t("Вы действительно хотите удалить сведения о партии?"),
                },
            },
        },
    ];

    return (
        <Fragment>
            <h3 className="header smaller lighter blue">{t("Сертифицируемая продукция")}</h3>
            {showEmptyError && !consignment?.consignmentItems?.length && (
                <Alert messageType="danger">
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp;&nbsp; <strong>Необходимо добавить хотя бы одну позицию продукции.</strong>
                </Alert>
            )}
            <RowEditTable
                id="consignment_items"
                columns={columns}
                dataList={consignment.consignmentItems}
                rowFormField={rowFormField}
                handleChangeRow={(item, index) => handleChangeRow(item, index)}
                buttonAddRow={getConsignmentItems(conclusion).length < maxConsignmentItems}
                formStyles={{
                    height: "350px",
                }}
            />
            <div className="clearfix form-actions no-margin-bottom col-xs-12 actions">
                <div className="pull-left">
                    <Button id="back_btn" text={t("Назад")} size="sm" icon="arrow-left" onClick={previousButtonHandler} />
                </div>
                <div className="pull-right">
                    <Button id="next_btn" text={t("Применить")} color="info" size="sm" icon="check" onClick={nextOnClickHandler} />
                </div>
            </div>
        </Fragment>
    );
};

export default WbrConclusionConsignmentForm;
