import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { getWbrApplicationStatuses, wbrApplicationStatuses } from "../../../../../utils/constants/wbr/wbr-application-statuses";
import { hasAuthority } from "../../../../../utils/authority-utils";
import { ORGANIZATION_ACTIVITIES } from "../../../../../utils/constants/constants";
import { useCountriesListQuery } from "../../../../../dictionary/api/countriesQueries";
import { useLaboratories } from "../../../../../dictionary/api/organizationQuery";
import { useRussianRegionsListQuery } from "../../../../../dictionary/api/regionsQueries";
import { useUserContext } from "../../../../../session/UserContextProvider";
import { wbr_application_view_all } from "../../../../../common/constants/permissions";
import SearchForm, { getChangedAdvancedFieldsCount, searchSchemeFieldType } from "../../../../../components/list/SearchForm";
import SearchFormBudgeList from "../../../../../components/list/SearchFormBudgeList";
import SearchToggleButton from "../../../../../components/list/SearchToggleButton";

const inProgressStatuses = [
    wbrApplicationStatuses.NEW,
    wbrApplicationStatuses.DATA_FOR_CONTRACT_RECEIVED,
    wbrApplicationStatuses.CONTRACT_PREPARING,
    wbrApplicationStatuses.CONTRACT_RECEIVED,
    wbrApplicationStatuses.PREPAYMENT_INFO_RECEIVED,
    wbrApplicationStatuses.IN_PROCESS,
    wbrApplicationStatuses.REPEAT_NEW,
    wbrApplicationStatuses.RESEARCH_FINISHED,
    wbrApplicationStatuses.PAYMENT_INFO_RECEIVED,
    wbrApplicationStatuses.ACT_RECEIVED,
];

const waitingStatuses = [
    wbrApplicationStatuses.WAITING_DATA_FOR_CONTRACT,
    wbrApplicationStatuses.WAITING_SIGN_CONTRACT,
    wbrApplicationStatuses.WAITING_PREPAYMENT,
    wbrApplicationStatuses.WAITING_PAYMENT,
    wbrApplicationStatuses.WAITING_CLOSING_ACT,
    wbrApplicationStatuses.SENT_FOR_REVISION,
];

const closedStatuses = [wbrApplicationStatuses.REJECTED, wbrApplicationStatuses.COMPLETED, wbrApplicationStatuses.AUTOMATICALLY_CLOSED];

const WbrApplicationSearchWrapper = ({ searchParams, setSearchParams, children }) => {
    const { t } = useTranslation();
    const [isShowSearchPanel, setIsShowSearchPanel] = useState(false);

    const { authorities: userAuthorities } = useUserContext();
    const { data: laboratories } = useLaboratories([ORGANIZATION_ACTIVITIES.WBR_ISSUE]);
    const { data: countries } = useCountriesListQuery();
    const { data: regions } = useRussianRegionsListQuery();

    const canSelectLaboratory = hasAuthority(wbr_application_view_all, userAuthorities);

    const setStatuses = (statuses) => {
        setSearchParams({
            ...searchParams,
            statuses: statuses,
        });
    };

    const searchSchema = {
        fields: {
            magicField: { title: t("Поиск"), placeholder: t("Номер заявки, информация о заявителе или инициаторе...") },
            laboratory: {
                title: t("Место оформления"),
                type: searchSchemeFieldType.select,
                options: laboratories,
                valueKey: "guid",
                labelKey: "shortName",
                isObjectSelect: false,
            },
            importCountries: {
                title: t("Страна"),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: countries,
                labelKey: "view",
                valueKey: "guid",
                isObjectSelect: false,
                placeholder: t("Выберите страну..."),
            },
            referenceNumber: { title: t("Номер заявки") },
            statuses: {
                title: t("Статус"),
                type: searchSchemeFieldType.select,
                multiple: true,
                options: getWbrApplicationStatuses(t),
                labelKey: "view",
                valueKey: "id",
                isObjectSelect: false,
            },
            receiveDateInterval: { title: t("Создан в период"), type: searchSchemeFieldType.dateRange },
            exitDateInterval: { title: t("Вывоз в период"), type: searchSchemeFieldType.dateRange },
            consignorView: { title: t("Грузоотправитель") },
            consigneeView: { title: t("Грузополучатель") },
            departureLocationRegionGuid: {
                title: t("Регион отправки"),
                type: searchSchemeFieldType.select,
                options: regions,
                labelKey: "view",
                valueKey: "guid",
                isObjectSelect: false,
            },
        },
        quickSearch: ["departureLocationRegionGuid", canSelectLaboratory && "laboratory", "magicField"].filter(Boolean),
        advancedSearch: [
            "importCountries",
            "referenceNumber",
            "statuses",
            "receiveDateInterval",
            "exitDateInterval",
            "consignorView",
            "consigneeView",
            "departureLocationRegionGuid",
            canSelectLaboratory && "laboratory",
        ].filter(Boolean),
    };

    const quickToolbar = (
        <div className="col-xs-12 col-md-12 margin-bottom-15">
            <div className="col-xs-4 col-sm-1 mt-2">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a id={"in_progress_btn"} onClick={() => setStatuses(inProgressStatuses)} className="editable editable-click">
                    {t("В работе")}
                </a>
            </div>
            <div className="col-xs-4 col-sm-1 mt-2">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a id={"waiting_btn"} onClick={() => setStatuses(waitingStatuses)} className="editable editable-click">
                    {t("Ожидание")}
                </a>
            </div>
            <div className="col-xs-4 col-sm-1 mt-2">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a id={"closed_btn"} onClick={() => setStatuses(closedStatuses)} className="editable editable-click">
                    {t("Закрытые")}
                </a>
            </div>
        </div>
    );

    const budgeList = <SearchFormBudgeList schema={searchSchema} searchParams={searchParams} setSearchParams={setSearchParams} />;

    const searchToggleButton = (
        <SearchToggleButton
            active={isShowSearchPanel}
            onClick={() => setIsShowSearchPanel(!isShowSearchPanel)}
            advancedParamsCount={getChangedAdvancedFieldsCount(searchParams, searchSchema)}
        />
    );

    const searchForm = (
        <SearchForm
            schema={searchSchema}
            isExtendedPanelShow={isShowSearchPanel}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            quickToolbar={quickToolbar}
        />
    );

    return children(searchForm, budgeList, searchToggleButton);
};

export default WbrApplicationSearchWrapper;
