import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button, InputFormGroup, SelectFormGroup } from "vetrf-ui";
import { getTransportNumberHelp } from "../../../../utils/help/TransportNumberHelp";

const WbrConclusionMultipleTransportField = ({ conclusion, transportTypes, onChange, showError, errorHandler }) => {
    const { t } = useTranslation("translation");

    const removeTransport = (index) => {
        const transportMovements = conclusion?.consignment?.transportMovements || [{ orderNumber: 0 }];
        const newTransportMovements = transportMovements.slice();
        newTransportMovements.splice(index, 1);
        newTransportMovements.forEach((transport, i) => (transport.orderNumber = i));
        onChange(newTransportMovements, "conclusion.consignment.transportMovements");
    };

    const transportMovements = conclusion?.consignment?.transportMovements?.sort((a, b) => a.orderNumber - b.orderNumber) || [{ orderNumber: 0 }];
    const len = transportMovements.length;
    let rows = [];
    for (let id = 0; id < transportMovements.length; id++) {
        rows.push(
            <Fragment key={"transport_" + id}>
                <h3 className="header smaller lighter blue">
                    {`${t("Сведения о транспортном средстве")} ${len > 1 ? `№${id + 1}` : ""}`}
                    {len > 1 && (
                        <span className={"pull-right inline"}>
                            <Button
                                id={`delete_btn_${id}`}
                                icon={"trash"}
                                iconColor={"white"}
                                size={"sm"}
                                color={"danger"}
                                text={t("Удалить")}
                                tooltip={t("Удалить")}
                                className={"btn-transparent"}
                                onClick={() => removeTransport(id)}
                            />
                        </span>
                    )}
                </h3>
                <SelectFormGroup
                    labelText={t("Тип транспортного средства")}
                    clearable
                    require
                    options={transportTypes}
                    valueKey="id"
                    onChange={onChange}
                    field={`conclusion.consignment.transportMovements[${id}].transportType`}
                    value={conclusion.consignment?.transportMovements && conclusion.consignment?.transportMovements[id]?.transportType}
                    showError={showError}
                    errorHandler={errorHandler}
                    placeholder={t("Тип транспортного средства...")}
                />
                <InputFormGroup
                    labelText={t("Номер транспортного средства")}
                    require
                    onChange={onChange}
                    field={`conclusion.consignment.transportMovements[${id}].transport.number`}
                    value={conclusion.consignment?.transportMovements && conclusion.consignment?.transportMovements[id]?.transport?.number}
                    showError={showError}
                    errorHandler={errorHandler}
                    maxLength={255}
                    help={t(
                        getTransportNumberHelp(
                            conclusion.consignment?.transportMovements ? conclusion.consignment?.transportMovements[id] : undefined
                        )
                    )}
                />
            </Fragment>
        );
    }
    return <div>{rows}</div>;
};

export default WbrConclusionMultipleTransportField;
