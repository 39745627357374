import React from "react";
import { SelectFormGroup } from "vetrf-ui";

export default function ValueSelectFormGroup({ onChange, options, value, ...props }) {
    const onChangeHandler = (value, field) => {
        if (!value) {
            onChange(null, field);
        } else {
            onChange(props.multiple ? value.map((x) => x[props.valueKey]) : value[props.valueKey], field);
        }
    };

    return (
        <SelectFormGroup
            onChange={onChangeHandler}
            value={
                props.multiple
                    ? value?.map((v) => options.find((x) => x[props.valueKey] === v)) ?? []
                    : options?.find((x) => x[props.valueKey] === value)
            }
            options={options}
            {...props}
        />
    );
}
