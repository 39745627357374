import React, { Fragment } from "react";
import { Button, Page } from "vetrf-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ActualObjectView from "./components/ActualObjectView";
import AdditionalInfoView from "./components/AdditionalInfoView";
import ApplicationView from "./components/ApplicationView";
import ContactInfoView from "./components/ContactInfoView";
import DeleteConfirmButton from "../common/DeleteConfirmButton";
import PrivateContent from "../../../components/private-route/PrivateContent";
import SyncConfirmButton from "../common/SyncConfirmButton";
import { departments_modify } from "../../../common/constants/permissions";
import { useGetDepartment } from "../../api/departmentQuery";

const DepartmentViewPage = () => {
    const { t } = useTranslation("translation");
    const { id } = useParams();
    const { data: department } = useGetDepartment(id);
    const navigate = useNavigate();

    const toolbar = department && department.id && (
        <Fragment>
            <div className="actions pull-right">
                <PrivateContent authority={departments_modify}>
                    {department.synced && <SyncConfirmButton department={department} buttonId={"sync_btn"} onlyIcon={false} />}
                    <Button id="edit_btn" text={t("Редактировать")} href={`edit`} color="purple" size="sm" icon="edit" />
                    {!department.synced && (
                        <DeleteConfirmButton department={department} buttonId={"delete_btn"} onlyIcon={false} onDeleted={() => navigate("..")} />
                    )}
                </PrivateContent>
            </div>
        </Fragment>
    );

    const title = t("Место оформления");
    const subTitle = t("в котором возможно оформление экспортных сертификатов");
    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "department_list_page",
            link: "/departments/",
            text: t("Места оформления"),
        },
        {
            active: true,
            text: title,
        },
    ];
    return (
        <Page title={title} subHeader={subTitle} breadcrumbs={breadcrumbs} header={title} toolbar={toolbar}>
            <div className="row">
                <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0">
                    <ActualObjectView department={department} />
                    <AdditionalInfoView department={department} />
                    <ApplicationView department={department} />
                    <ContactInfoView department={department} />
                </div>
            </div>
            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="back_btn" size="sm" icon="list" href="/departments/" text={t(" К списку")} />
                {toolbar}
            </div>
        </Page>
    );
};

export default DepartmentViewPage;
