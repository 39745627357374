import { handleActions } from "redux-actions";
import { countries, supportedCountries, euCountries } from "./actions";

const initialState = {
    countries: [],
    supportedCountries: [],
    euCountries: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [countries.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [countries.load.success]: (state, { payload = {} }) => ({
            ...state,
            loading: false,
            countries: payload,
        }),
        [countries.load.error]: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        [supportedCountries.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [supportedCountries.load.success]: (state, { payload = {} }) => ({
            ...state,
            loading: false,
            supportedCountries: payload,
        }),
        [supportedCountries.load.error]: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        [euCountries.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [euCountries.load.success]: (state, { payload = {} }) => ({
            ...state,
            loading: false,
            euCountries: payload,
        }),
        [euCountries.load.error]: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
    },
    initialState
);
