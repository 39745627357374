import { catalog } from "./actions";
import ajax from "../../../utils/ajax";
import { notifyError, notifySuccess } from "../../../common/store/notification/service";
import { createLoadAsyncAction, createPageableRequestParams } from "../../../common/utils/service-utils";
import { completeLoading, startLoading } from "../../../common/store/loading/service";

const request = ajax("/rest-api/dictionary/");

const loadErrorMessage = "Произошла ошибка при загрузке справочника";

export const requestCatalog = (catalogName, pageable) =>
    createLoadAsyncAction(catalog, request, createPageableRequestParams(pageable, catalogName), loadErrorMessage);
export const requestCatalogItem = (catalogName, id) =>
    createLoadAsyncAction(catalog.item, request, { type: "GET", url: `${catalogName}/${id}` }, loadErrorMessage);

export const requestSaveCatalogItem = (catalogName, catalogItem) => (dispatch) => {
    dispatch(catalog.saveItem.request());
    dispatch(startLoading());

    request({
        url: `${catalogName}`,
        data: catalogItem,
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно сохранена в справочник"));
            dispatch(completeLoading());
            dispatch(catalog.saveItem.success());
        })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при сохранении записи в справочник"));
            dispatch(completeLoading());
            dispatch(catalog.saveItem.error(error));
        });
};

export const requestDeleteCatalogItem = (catalogName, itemId) => (dispatch) => {
    dispatch(catalog.deleteItem.request());
    dispatch(startLoading());

    request({
        url: `${catalogName}/${itemId}`,
        type: "DELETE",
    })
        .then(() => {
            dispatch(notifySuccess("Запись успешно удалена из справочника"));
            dispatch(completeLoading());
            dispatch(catalog.deleteItem.success());
        })
        .catch((error) => {
            dispatch(notifyError(error.status, "Произошла ошибка при удалении записи"));
            dispatch(completeLoading());
            dispatch(catalog.deleteItem.error(error));
        });
};
