import ajax from "../../utils/ajax";

import { ANIMAL_SPECIES_LIST_LOAD, ANIMAL_SPECIES_LIST_RECEIVE, ANIMAL_SPECIES_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const animalSpeciesLoad = () => ({
    type: ANIMAL_SPECIES_LIST_LOAD,
});

export const animalSpeciesReceive = (animalSpecies) => ({
    type: ANIMAL_SPECIES_LIST_RECEIVE,
    animalSpecies,
});

export const animalSpeciesLoadError = (error) => ({
    type: ANIMAL_SPECIES_LIST_ERROR,
    error,
});

export const requestAnimalSpecies = () => (dispatch) => {
    dispatch(animalSpeciesLoad());
    request({
        type: "GET",
        url: "animal-species/all",
    })
        .then((response) => {
            dispatch(animalSpeciesReceive(response));
        })
        .catch((error) => {
            dispatch(animalSpeciesLoadError(error));
        });
};
