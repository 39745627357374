import { useForm } from "react-hook-form";
import React, { useCallback, useEffect, useRef, useMemo } from "react";

import { getFieldForm } from "../form/schema/SearchFormBuilder";
import ShortFormProvider from "../form/ShortFormProvider";

const DEBOUNCE_DELAY = 1500;

const QuickSearchPanel = ({ schema, searchParams, setSearchParams, quickToolbar }) => {
    const methods = useForm();
    const { reset, getValues, handleSubmit, watch } = methods;
    const emptyValues = useMemo(() => fieldsArrayToObject(schema.quickSearch), [schema.quickSearch])

    const debounceHandler = useRef();
    useEffect(() => {
        return () => {
            clearTimeout(debounceHandler.current);
        };
    }, []);

    useEffect(() => {
        reset({...emptyValues, ...searchParams});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const submitSearchParams = () => {
        setSearchParams(getValues());
    };

    const onFieldChange = useCallback(
        (fieldKey, value) => {
            const fieldType = schema.fields[fieldKey]?.type;
            const isTextField = fieldType === undefined || fieldType === "text";
            if (isTextField && value !== "") {
                clearTimeout(debounceHandler.current);
                debounceHandler.current = setTimeout(() => {
                    submitSearchParams();
                }, DEBOUNCE_DELAY);
            } else {
                submitSearchParams();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submitSearchParams]
    );

    useEffect(() => {
        const subscription = watch((value, { name }) => name && onFieldChange(name, value));
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSubmit, watch, onFieldChange]);

    return (
        <div className="search-panel col-xs-12 no-padding">
            {quickToolbar}
            <ShortFormProvider methods={methods}>
                {schema.quickSearch.map((field, index) => (
                    <div className="col-xs-12 col-md-4 pull-right" key={index}>
                        <div className="form-horizontal css-form">{getFieldForm(field, schema.fields?.[field])}</div>
                    </div>
                ))}
            </ShortFormProvider>
        </div>
    );
};

const fieldsArrayToObject = (fields) => {
    let obj = {};
    fields.forEach((item) => {
        obj[item] = null;
    });
    return obj;
}

export default QuickSearchPanel;
