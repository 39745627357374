import ajax from "../../utils/ajax";

import { UNIT_MEASURE_LIST_LOAD, UNIT_MEASURE_LIST_RECEIVE, UNIT_MEASURE_LIST_ERROR } from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const unitMeasuresLoad = () => ({
    type: UNIT_MEASURE_LIST_LOAD,
});

export const unitMeasuresReceive = (unitMeasures) => ({
    type: UNIT_MEASURE_LIST_RECEIVE,
    unitMeasures,
});

export const unitMeasuresLoadError = (error) => ({
    type: UNIT_MEASURE_LIST_ERROR,
    error,
});

export const requestUnitMeasures = () => (dispatch) => {
    dispatch(unitMeasuresLoad());
    request({
        type: "POST",
        url: "unitMeasures",
    })
        .then((response) => {
            dispatch(unitMeasuresReceive(response));
        })
        .catch((error) => {
            dispatch(unitMeasuresLoadError(error));
        });
};
