export const hasAuthority = (authority, userAuthorities = []) => {
    if (!authority || !userAuthorities || userAuthorities.length === 0) {
        return false;
    }
    return userAuthorities.filter((item) => authority === item.name).length > 0;
};

export const hasEveryAuthority = (authorities = [], userAuthorities = []) =>
    authorities.every((authority) => hasAuthority(authority, userAuthorities)) || authorities.length === 0;

export const hasSomeAuthority = (authorities = [], userAuthorities = []) => {
    return authorities.some((authority) => hasAuthority(authority, userAuthorities)) || authorities.length === 0;
};
