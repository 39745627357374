import React from "react";
import { useTranslation } from "react-i18next";
import usePageParams from "../../../utils/usePageParams";
import { useGetDepartmentPage } from "../../api/departmentQuery";
import { getUnknownCursiveText } from "../../../utils/constants/constants";
import { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import { workingDaysView } from "../../../utils/filterDepartments";
import { Button, PaginationRow, Table } from "vetrf-ui";
import PrivateContent from "../../../components/private-route/PrivateContent";
import { departments_modify } from "../../../common/constants/permissions";
import DeleteConfirmButton from "../common/DeleteConfirmButton";
import SyncConfirmButton from "../common/SyncConfirmButton";

const tableId = "department";

const DepartmentPageableTable = ({ page, onChangePage, onChangeSizePerPage, sortedId }) => {
    const { t } = useTranslation();
    const { pageParams, searchParams } = usePageParams("departments");
    const { data: departmentsPage, refetch: refetchDepartmentsPage } = useGetDepartmentPage(pageParams, searchParams);

    const unknown = getUnknownCursiveText(t);
    const getUnknown = (warn) => <i className={warn ? "unknown" : undefined}>{t("не указано")}</i>;

    const refetchDepartments = (department) => {
        if (departmentsPage?.content.findIndex((x) => x.id === department.id)) {
            refetchDepartmentsPage();
        }
    };

    const columns = [
        {
            key: "sync",
            dataFormatter: (item) => (item?.synced ? <i className="fa fa-link green" /> : <i className="fa fa-unlink grey" />),
        },
        {
            key: "name",
            title: t("Наименование и адрес"),
            dataFormatter: (item) => (
                <Fragment>
                    <p>
                        {item?.name} {item?.number ? `(${item?.number})` : ""}
                    </p>
                    <p>{item?.address?.addressView}</p>
                </Fragment>
            ),
        },
        {
            key: "addressView",
            title: t("Наименование и адрес на английском"),
            dataFormatter: (item) => (
                <Fragment>
                    <p>{item?.englishName || t("Наименование не указано")}</p>
                    <p>{item?.address?.enAddressView || t("Адрес не указан")}</p>
                </Fragment>
            ),
        },
        {
            key: "forPets",
            title: t("Источник заявки"),
            dataFormatter: (item) => {
                return (
                    <Fragment>
                        {item?.forPetsIssuer ? (
                            <p>
                                <i className="ace-icon fa fa-paw green" /> ВетИС.Питомцы{" "}
                            </p>
                        ) : (
                            ""
                        )}
                        {item?.synced ? (
                            <p>
                                <i className="fa fa-shopping-basket green" /> ИС "Одно окно"
                            </p>
                        ) : (
                            ""
                        )}
                    </Fragment>
                );
            },
        },
        {
            key: "contacts",
            title: t("Контактная информация"),
            className: "white-space_origin",
            dataFormatter: (item) => {
                return (
                    <Fragment>
                        <p>E-mail: {!isEmpty(item.contactEmailList) ? item.contactEmailList.join(", ") : getUnknown(item?.forPetsIssuer)}</p>
                        <p>
                            Контактные телефоны:{" "}
                            {!isEmpty(item.contactPhoneNumberList) ? item.contactPhoneNumberList.join(", ") : getUnknown(item?.forPetsIssuer)}
                        </p>
                        <p>
                            Сотрудники:{" "}
                            {!isEmpty(item.responsibleVeterinarianList)
                                ? item.responsibleVeterinarianList.map((item) => item.fio).join(", ")
                                : getUnknown(item?.forPetsIssuer)}
                        </p>
                    </Fragment>
                );
            },
        },
        {
            key: "workDays",
            title: t("Расписание"),
            width: "250px",
            className: "white-space_origin",
            dataFormatter: (item) => (!isEmpty(item.officeHours) ? workingDaysView(item.officeHours) : unknown),
        },
        {
            key: "actions",
            title: "",
            width: "110px",
            className: "actions",
            buttonFormatter: (item, tableId, index) => {
                const deleteButtonId = `${tableId}__delete_btn_${index}`;
                const syncButtonId = `${tableId}__sync_btn_${index}`;
                const editButtonId = `${tableId}__edit_btn_${index}`;
                const viewButtonId = `${tableId}__view_${index}`;
                return (
                    <React.Fragment>
                        <Button
                            href={`/departments/${item.id}`}
                            id={viewButtonId}
                            onlyIcon={true}
                            icon="bars"
                            iconSize={150}
                            tooltip={t("Просмотр")}
                        />
                        <PrivateContent authority={departments_modify}>
                            <Button
                                id={editButtonId}
                                href={"/departments/" + item.id + "/edit"}
                                onlyIcon={true}
                                iconColor="purple"
                                icon="pencil"
                                iconSize={150}
                                tooltip={t("Редактировать")}
                            />
                        </PrivateContent>
                        {!item.synced && (
                            <PrivateContent authority={departments_modify}>
                                <DeleteConfirmButton department={item} buttonId={deleteButtonId} onlyIcon={true} onDeleted={refetchDepartments} />
                            </PrivateContent>
                        )}
                        {item.synced && (
                            <PrivateContent authority={departments_modify}>
                                <SyncConfirmButton department={item} buttonId={syncButtonId} onlyIcon={true} onSynced={refetchDepartments} />
                            </PrivateContent>
                        )}
                    </React.Fragment>
                );
            },
        },
    ];

    const pagination = (
        <PaginationRow
            id={tableId}
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            id={tableId}
            className="table table-striped table-bordered table-hover"
            dataList={page?.content}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

export default DepartmentPageableTable;
