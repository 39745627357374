import React from "react";

const WideWrapper = ({ children }) => {
    return (
        <div className="row">
            <div className="col-xs-12 wraptablebig">{children}</div>
        </div>
    );
};

export default WideWrapper;
