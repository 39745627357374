import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOfficialVeterinarianDeleteMutation } from "../../../api/officialVeterinarianQueries";
import { Fragment } from "react";
import PrivateContent from "../../../../components/private-route/PrivateContent";
import { veterinarian_modify } from "../../../../common/constants/permissions";
import { Button, ConfirmActionButton, PaginationRow, Table } from "vetrf-ui";

const tableId = "official_veterinarian";

const OfficialVeterinarianPageableTable = ({ page, onChangePage, onChangeSizePerPage, sortedId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const veterinarianDeleteMutation = useOfficialVeterinarianDeleteMutation();

    const deleteVeterinarian = (item) => {
        veterinarianDeleteMutation.mutate(item.id);
    };

    const columns = [
        {
            key: "fio",
            title: t("ФИО"),
            dataFormatter: (item) => item.fio,
            sortable: true,
        },
        {
            key: "position",
            title: t("Должность"),
            dataFormatter: (item) => item.position || t("Не указано"),
            sortable: true,
        },
        {
            key: "englishFio",
            title: t("ФИО на английском"),
            dataFormatter: (item) => item.englishFio || t("Не указано"),
            sortable: true,
        },
        {
            key: "englishPosition",
            title: t("Должность на английском"),
            dataFormatter: (item) => item.englishPosition || t("Не указано"),
            sortable: true,
        },
        {
            key: "user",
            title: "",
            dataFormatter: (value) => {
                return <span className={value.user ? "fa fa-link green" : "fa fa-link grey"} />;
            },
            width: "10px",
        },
        {
            key: "actions",
            title: "",
            width: "80px",
            className: "actions",
            buttonFormatter: (item, tableId, index) => {
                const editButtonId = `${tableId}__edit_btn${index}`;
                const deleteButtonId = `${tableId}__delete_btn${index}`;
                return (
                    <React.Fragment>
                        {item.isActive && (
                            <Fragment>
                                <PrivateContent authority={veterinarian_modify}>
                                    <Button
                                        id={editButtonId}
                                        onlyIcon={true}
                                        iconColor="blue"
                                        icon="pencil"
                                        iconSize={150}
                                        tooltip={t("Редактировать")}
                                        onClick={() => navigate(`${item.id}/edit`)}
                                    />
                                </PrivateContent>
                                <PrivateContent authority={veterinarian_modify}>
                                    <ConfirmActionButton
                                        id={deleteButtonId}
                                        onConfirm={() => deleteVeterinarian(item)}
                                        isOnlyIconButton={true}
                                        icon="trash-o"
                                        iconColor="red"
                                        iconSize={150}
                                        confirmBodyContent={t("Вы уверены, что хотите удалить запись?")}
                                        confirmHeaderText={t("Удаление записи")}
                                        tooltip={t("Удалить")}
                                    />
                                </PrivateContent>
                            </Fragment>
                        )}
                    </React.Fragment>
                );
            },
        },
    ];

    const pagination = (
        <PaginationRow
            id={tableId}
            page={page?.number + 1}
            onChangePage={onChangePage}
            onChangeSizePerPage={onChangeSizePerPage}
            sizePerPage={page?.size}
            itemCount={page?.totalElements}
        />
    );

    return (
        <Table
            id={tableId}
            className="table table-striped table-bordered table-hover"
            dataList={page?.content}
            paginationRow={pagination}
            sortedId={sortedId}
            columns={columns}
        />
    );
};

export default OfficialVeterinarianPageableTable;
