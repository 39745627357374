import { Button, ModalDialog } from "vetrf-ui";
import { Trans, useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useState } from "react";

import { getUnknownNormalText } from "../../../../utils/constants/constants";
import { useBusinessParticipantGetCerberBP, useBusinessParticipantSynchronize } from "../../../api/businessParticipantsQueries";

const modalId = "sync__modal";

const BusinessParticipantSynchronizationConfirmButton = ({ id, onlyIcon, item }) => {
    const { t } = useTranslation();
    const [isSyncModalShow, setIsSyncModalShow] = useState(false);
    const [isComparisonShow, setIsComparisonShow] = useState(false);
    const [guidToSync, setGuidToSync] = useState();
    const participantSynchronization = useBusinessParticipantSynchronize();
    const { data: participantFromCerber, isFetching: isParticipantFromCerberLoading } = useBusinessParticipantGetCerberBP(guidToSync);
    const unknown = getUnknownNormalText(t);

    const confirmationContent = (
        <Trans
            defaults="Вы уверены, что хотите выполнить обновление сведений о хозяйствующем субъекте <0>{{name}} (ИНН: {{inn}})</0> из ИС Цербер?"
            components={[<strong key={0} />]}
            values={{ name: item.name || unknown, inn: item.inn || unknown }}
        ></Trans>
    );
    const comparisonContent = (
        <Fragment>
            <p>{t("Обратите внимание, данные о хозяйствующем субъекте отличаются:")}</p>
            <p>
                {t("Компонент eCert")}:{" "}
                <strong>
                    {item.englishName || t("(название на английском языке отсутствует)")},{" "}
                    {item.englishAddress || t("(адрес на английском языке отсутствует)")}
                </strong>
                <br />
                {t("Компонент Цербер")}:{" "}
                <strong>
                    {participantFromCerber?.englishName || t("(название на английском языке отсутствует)")},{" "}
                    {participantFromCerber?.englishAddress || t("(адрес на английском языке отсутствует)")}
                </strong>
            </p>
        </Fragment>
    );

    useEffect(() => {
        if (participantFromCerber) {
            if (participantFromCerber.englishName !== item.englishName || participantFromCerber.englishAddress !== item.englishAddress) {
                setIsComparisonShow(true);
            } else {
                onComparisonConfirm();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantFromCerber]);

    const onSyncConfirm = () => {
        setGuidToSync(item.businessEntityGuid);
    };

    const onComparisonConfirm = () => {
        participantSynchronization.mutate(item);
        onSyncModalClose();
    };

    const onSync = () => {
        setIsSyncModalShow(true);
    };

    const onSyncModalClose = () => {
        setIsSyncModalShow(false);
        setIsComparisonShow(false);
        setGuidToSync(null);
    };

    return (
        <div className="inline">
            <Button
                id={id}
                onlyIcon={onlyIcon}
                icon="refresh"
                iconSize={onlyIcon && 150}
                iconColor={onlyIcon ? "green" : "white"}
                tooltip={onlyIcon && t("Синхронизировать")}
                text={t("Синхронизировать")}
                size={!onlyIcon && "sm"}
                color="success"
                onClick={onSync}
            />
            {isSyncModalShow && (
                <ModalDialog
                    id={modalId}
                    onClose={onSyncModalClose}
                    header={t("Синхронизация сведений о хозяйствующем субъекте с ИС Цербер")}
                    children={isComparisonShow ? comparisonContent : confirmationContent}
                    footer={
                        <Fragment>
                            {!isComparisonShow && (
                                <Button
                                    id={`${modalId}__sync_btn`}
                                    text={t("Синхронизировать")}
                                    color="success"
                                    icon={isParticipantFromCerberLoading && "circle-o-notch fa-spin"}
                                    disabled={isParticipantFromCerberLoading}
                                    onClick={onSyncConfirm}
                                />
                            )}
                            {isComparisonShow && (
                                <Button id={`${modalId}__accept_btn`} text={t("Подтвердить")} color="success" onClick={onComparisonConfirm} />
                            )}
                            <Button id={`${modalId}__cancel_btn`} text={t("Отмена")} color="default" onClick={onSyncModalClose} />
                        </Fragment>
                    }
                />
            )}
        </div>
    );
};
export default BusinessParticipantSynchronizationConfirmButton;
