import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../../common/utils/constants";

export const { officialVeterinarians } = createActions({
    OFFICIAL_VETERINARIANS: {
        ...loadActions,
        PAGE: loadActions,
        SAVE: asyncActions,
        DELETE: asyncActions,
        ITEM: loadActions,
    },
});
