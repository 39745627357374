import logger from "./logging/base/LogManager";

const isJSONResponse = (response) =>
    response.headers && response.headers.get("content-type") && response.headers.get("content-type").match(/application\/json/);

const processOkResponse = (response, callback) => (data) => callback(data !== undefined ? data : response);

const processErrorResponse = (response, callback) => (data) => {
    const errorStatus = {
        statusText: response.statusText,
        status: response.status,
        ...(data ?? {})
    };
    callback(errorStatus)
    logger.error(`Request error. Url: ${response.url}`, errorStatus)
};

const handleResponse = (response, callback) => {
    if (isJSONResponse(response)) {
        response.json().then((data) => callback(data));
    } else {
        if (typeof response === "object") {
            switch (response.constructor) {
                case Response: {
                    callback(response);
                    break;
                }
                case TypeError: {
                    callback({ statusText: `${response.name}: ${response.message}`, status: 0 });
                    break;
                }
                default:
                    callback({ statusText: JSON.stringify(response, Object.getOwnPropertyNames(response)), status: 0 });
                    break;
            }
        } else {
            callback({ statusText: response.toString() });
        }
    }
};

const handleOkResponse = (response, callback) => handleResponse(response, processOkResponse(response, callback));
const handleErrorResponse = (response, callback) => handleResponse(response, processErrorResponse(response, callback));

const defaultContentType = {
    "Content-Type": "application/json; charset=utf-8",
};

const request = (baseUrl) => (params) =>
    new Promise((resolve, reject) => {
        fetch(concatUrlParts(baseUrl, params.url), {
            method: params.type || "POST",
            body: params.uploadFile ? params.data : JSON.stringify(params.data),
            // headers: !params.uploadFile && defaultContentType,
            headers: params.headers ? params.headers : defaultContentType,
            credentials: params.credentials || "same-origin",
        })
            .then((response) => {
                if (response.ok) {
                    handleOkResponse(response, resolve);
                } else {
                    handleErrorResponse(response, reject);
                }
            })
            .catch((error) => handleErrorResponse({url: concatUrlParts(baseUrl, params.url), message: `${error.name}: ${error.message}`}, reject));
    });

const concatUrlParts = (base, part) => {
    if (!part) return base;
    if (base.slice(-1) !== "/" && part[0] !== "/") {
        return base + "/" + part;
    }
    if (base.slice(-1) === "/" && part[0] === "/") {
        return base + part.slice(1);
    }
    return base + part;
};

export default request;
