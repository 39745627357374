import { handleActions } from "redux-actions";
import { addWithdrawnReason } from "./actions";

const initialState = {
    withdrawnReason: "",
    withdrawnECertId: "",
};

export default handleActions(
    {
        [addWithdrawnReason]: (state, action) => ({
            ...state,
            withdrawnReason: action.withdrawnReason,
            withdrawnECertId: action.withdrawnECertId,
        }),
    },
    initialState
);
