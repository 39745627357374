import React from "react";
import { Route, Routes } from "react-router-dom";

import DepartmentFormPage from "./containers/form/DepartmentFormPage";
import DepartmentListPage from "./containers/list/DepartmentListPage";
import DepartmentViewPage from "./containers/view/DepartmentViewPage";
import PageNotFound from "../errors/PageNotFound";
import RequireAuth from "../components/private-route/RequireAuth";
import { departments_modify, departments_view } from "../common/constants/permissions";

const DepartmentRoutes = () => {
    return (
        <Routes>
            <Route
                exact
                path=""
                element={
                    <RequireAuth someAuthority={[departments_view, departments_modify]}>
                        <DepartmentListPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path=":id"
                element={
                    <RequireAuth authority={departments_view}>
                        <DepartmentViewPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path=":id/edit"
                element={
                    <RequireAuth authority={departments_modify}>
                        <DepartmentFormPage />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default DepartmentRoutes;
