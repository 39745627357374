import { useMemo } from "react";
import { deleteIn } from "vetrf-ui";

import { getBadge } from "../form/schema/SearchFormBuilder";

const SearchFormBudgeList = ({ schema, searchParams, setSearchParams }) => {
    const onRemove = (field) => {
        setSearchParams(deleteIn(searchParams, field));
    };

    return useMemo(
        () => Object.keys(schema.fields).map((fieldKey) => getBadge(fieldKey, schema, searchParams, onRemove)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchParams, schema]
    );
};

export default SearchFormBudgeList;
