import { handleActions } from "redux-actions";
import { packageTypes } from "./actions";

const initialState = {
    packageTypes: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [packageTypes.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [packageTypes.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            packageTypes: payload,
        }),
        [packageTypes.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
    },
    initialState
);
