import React, { Fragment } from "react";
import { Badge, Button } from "vetrf-ui";
import { useTranslation } from "react-i18next";

const SearchToggleButton = ({
    active = true,
    setActive,
    advancedParamsCount,
    onClick, // deprecated
    prefixId = "",
    ...props
}) => {
    const { t } = useTranslation();

    const filter = {
        id: prefixId + "_advanced_search__toggle_btn",
        icon: "chevron-up",
        text: t("Свернуть"),
    };
    const search = {
        id: prefixId + "_advanced_search__toggle_btn",
        icon: "search",
        text: (
            <Fragment>
                {t("Расширенный поиск")}
                {advancedParamsCount > 0 && (
                    <Fragment>
                        &nbsp;&nbsp;<Badge color="light-purple">{advancedParamsCount}</Badge>
                    </Fragment>
                )}
            </Fragment>
        ),
    };

    const onClickHandler = () => {
        onClick?.();
        setActive?.(!active);
    };

    const searchBtn = active ? filter : search;
    return <Button id={searchBtn.id} onClick={onClickHandler} color="purple" size="sm" text={searchBtn.text} icon={searchBtn.icon} {...props} />;
};

export default SearchToggleButton;
