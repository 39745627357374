import { handleActions } from "redux-actions";
import { users } from "./actions";

const initialState = {
    users: [],
    usersWithoutVeterinarian: [],
    error: null,
    loading: false,
};

export default handleActions(
    {
        [users.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [users.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            users: payload,
        }),
        [users.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
        [users.withoutVeterinarian.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [users.withoutVeterinarian.load.success]: (state, { payload = {} }) => ({
            ...initialState,
            usersWithoutVeterinarian: payload,
        }),
        [users.withoutVeterinarian.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
    },
    initialState
);
