import {
    useDictionaryCreateMutation,
    useDictionaryDeleteMutation,
    useDictionaryListQuery,
    useDictionaryOneQuery,
    useDictionaryPageQuery,
} from "./dictionaryQueries";

const baseUrl = "/transport-types";
const itemName = "вида транспорта";
const dictionaryName = "видов транспортна";

export function useTransportTypeQuery(id) {
    return useDictionaryOneQuery(baseUrl, id, { dictionaryItemName: itemName });
}

export function useTransportTypesListQuery() {
    return useDictionaryListQuery(baseUrl, { dictionaryName: dictionaryName, select: (data) => data.content });
}

export function useTransportTypesPageQuery(searchParams) {
    return useDictionaryPageQuery(baseUrl, searchParams, { dictionaryName: dictionaryName });
}

export function useTransportTypeSaveMutation() {
    return useDictionaryCreateMutation(baseUrl, { dictionaryItemName: itemName });
}

export function useTransportTypeDeleteMutation() {
    return useDictionaryDeleteMutation(baseUrl, { dictionaryItemName: itemName });
}