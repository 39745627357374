import React from "react";

import WbrApplicationContractInfo from "./blocks/WbrApplicationContractInfo";
import WbrApplicationDocuments from "./blocks/WbrApplicationDocuments";
import WbrApplicationGeneralInfo from "./blocks/WbrApplicationGeneralInfo";
import WbrApplicationItems from "./blocks/WbrApplicationItems";
import WbrApplicationProcessesInfo from "./blocks/WbrApplicationProcessesInfo";
import WbrApplicationRegistrationInfo from "./blocks/WbrApplicationRegistrationInfo";
import WbrApplicationRouteInfo from "./blocks/WbrApplicationRouteInfo";

const WbrApplicationViewTable = ({ application, applicationForCompare }) => (
    <div className={"row"}>
        <WbrApplicationRegistrationInfo application={application} />
        {!applicationForCompare && <WbrApplicationContractInfo application={application} />}
        <WbrApplicationGeneralInfo application={application} applicationForCompare={applicationForCompare} />
        <WbrApplicationRouteInfo application={application} applicationForCompare={applicationForCompare} />
        <WbrApplicationProcessesInfo application={application} />
        <WbrApplicationItems application={application} applicationForCompare={applicationForCompare} />
        <WbrApplicationDocuments application={application} applicationForCompare={applicationForCompare} />
    </div>
);

export default WbrApplicationViewTable;
