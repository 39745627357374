import React from "react";
import { Route, Routes } from "react-router-dom";

import OfficialVeterinarianFormPage from "./containers/officialVeterinarians/OfficialVeterinarianFormPage";
import OfficialVeterinarianListPage from "./containers/officialVeterinarians/OfficialVeterinarianListPage";
import PageNotFound from "../errors/PageNotFound";
import RequireAuth from "../components/private-route/RequireAuth";
import { veterinarian_modify, veterinarian_view } from "../common/constants/permissions";

const OfficialVeterinarianRoutes = () => {
    return (
        <Routes>
            <Route
                exact
                path=""
                element={
                    <RequireAuth authority={veterinarian_view}>
                        <OfficialVeterinarianListPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path="create"
                element={
                    <RequireAuth authority={veterinarian_modify}>
                        <OfficialVeterinarianFormPage />
                    </RequireAuth>
                }
            />
            <Route
                exact
                path=":id/edit"
                element={
                    <RequireAuth authority={veterinarian_modify}>
                        <OfficialVeterinarianFormPage />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default OfficialVeterinarianRoutes;
