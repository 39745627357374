import ajax from "../../utils/ajax";

import {
    TRANSPORT_EQUIPMENT_TYPE_LIST_LOAD,
    TRANSPORT_EQUIPMENT_TYPE_LIST_RECEIVE,
    TRANSPORT_EQUIPMENT_TYPE_LIST_ERROR,
} from "../constants/ActionTypes";

let request = ajax("/rest-api/dictionary/");

export const transportEquipmentTypesLoad = () => ({
    type: TRANSPORT_EQUIPMENT_TYPE_LIST_LOAD,
});

export const transportEquipmentTypesReceive = (transportEquipmentTypes) => ({
    type: TRANSPORT_EQUIPMENT_TYPE_LIST_RECEIVE,
    transportEquipmentTypes,
});

export const transportEquipmentTypesLoadError = (error) => ({
    type: TRANSPORT_EQUIPMENT_TYPE_LIST_ERROR,
    error,
});

export const requestTransportEquipmentTypes = () => (dispatch) => {
    dispatch(transportEquipmentTypesLoad());
    request({
        type: "POST",
        url: "transportEquipmentTypes",
    })
        .then((response) => {
            dispatch(transportEquipmentTypesReceive(response));
        })
        .catch((error) => {
            dispatch(transportEquipmentTypesLoadError(error));
        });
};
