import React, { Fragment } from "react";
import get from "lodash/get";

import AdvancedSearch from "./AdvancedSearch";
import QuickSearchPanel from "./QuickSearchPanel";
import { isExtendEmpty } from "../../utils/utils";

/*
    {
        fields: {
            magicField: { 
                title:"Поиск",
                placeholder: "Введите идентификатор...", // подсказка внутри поля (по умолчания значение формируется из title),

                type: "text", // тип поля (по умолчанию: text) text/select/checkbox/dateRange
                valueKey: "id", // ключ значения выпадающего списка (только для select полей)
                labelKey: "name", // ключ подписи выпадающего списка (только для select полей)
                multiple: true, // возможность выбора нескольких значений (только для select полей)
                isObjectSelect: true, // флаг, включающий запись только ключа из options при выборе значения (только для select полей)
                options: [{ id: 1 }, { id: 2 }], // массив опций для выбора (только для select полей)
            },
        },
        advancedSearch: ["magicField"],
        quickSearch: ["magicField"],
    }
*/

export const searchSchemeFieldType = {
    text: "text",
    select: "select",
    checkbox: "checkbox",
    dateRange: "dateRange",
}

const SearchForm = ({ schema, searchParams, defaultSearchParams, setSearchParams, isExtendedPanelShow = false, quickToolbar }) => {
    return (
        <Fragment>
            {isExtendedPanelShow ? (
                <AdvancedSearch
                    schema={schema}
                    searchParams={searchParams}
                    defaultSearchParams={defaultSearchParams}
                    setSearchParams={setSearchParams}
                />
            ) : (
                <QuickSearchPanel schema={schema} searchParams={searchParams} setSearchParams={setSearchParams} quickToolbar={quickToolbar} />
            )}
        </Fragment>
    );
};

export const getChangedAdvancedFieldsCount = (searchParams, schema) => {
    if (!schema.advancedSearch || schema.advancedSearch?.length === 0) return false;
    let count = 0;
    for (const key of schema.advancedSearch) {
        const value = get(searchParams, key);
        if (!isExtendEmpty(value)) {
            count += 1;
        }
    }
    return count;
};

export default SearchForm;
