import React from "react";
import { SelectFormGroup } from "vetrf-ui";
import { withTranslation } from "react-i18next";

const BooleanSelectFormGroup = ({ labelText, placeholder, clearable = true, fieldClassName, field, value, onChange, t, ...props }) => {
    const options = [
        { label: t("Да"), value: true },
        { label: t("Нет"), value: false },
    ];

    const onChangeHandler = (item, field) => {
        onChange?.(item?.value ?? undefined, field);
    };

    return (
        <SelectFormGroup
            valueKey="value"
            labelKey="label"
            labelText={labelText}
            placeholder={placeholder ?? t("Выберите...")}
            fieldClassName={fieldClassName ?? "col-sm-9"}
            value={options.find((x) => x.value === value)}
            clearable={clearable}
            field={field}
            options={options}
            onChange={onChangeHandler}
            {...props}
        />
    );
};

export default withTranslation("translations")(BooleanSelectFormGroup);
