export const getWbrApplicationImprovementsPlaces = (items, t) => [
    { id: "0", view: t("Общая информация") },
    { id: "1", view: t("Маршрут и транспорт") },
    ...items.flatMap((item, index) => [
        { id: `2.${index}`, view: `Позиция №${index + 1} : ${item.name}` },
    ]),
    { id: "3", view: t("Документы заявки") },
];

export const WBR_APPLICATION_IN_PROCESS_REMAINING_DAYS = 5;

export const WBR_APPLICATION_STATUS_OTHER_REASONS_ID = "4b8d9ff3-5185-4b79-a97e-530994aa74f9";

export const wbrApplicationInvoiceVatModeTypes = {
    NOT_INCLUDED: "NOT_INCLUDED",
    INCLUDED: "INCLUDED",
    WITHOUT_VAT: "WITHOUT_VAT",
};

export const wbrComplianceDecision = {
    MEET_REQUIREMENTS: "MEET_REQUIREMENTS",
    NOT_MEET_REQUIREMENTS: "NOT_MEET_REQUIREMENTS",
};
