import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, DateField, InputField, SelectField, SubHeader } from "vetrf-ui";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useCountriesListQuery } from "../../../dictionary/api/countriesQueries";
import { usePubRecord } from "../../../record/api/recordQueries";

const initialState = {
    importCountry: null,
    referenceNumber: null,
    issueDateTime: null,
};

const RecordSearchPanel = () => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [record, setRecord] = useState(initialState);
    const pubRecordQuery = usePubRecord(record.importCountry?.guid, record.referenceNumber, record.issueDateTime);
    const { data: countries } = useCountriesListQuery();

    const methods = useForm({ defaultValues: record });
    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (pubRecordQuery.isSuccess) {
            const guid = encodeURIComponent(record.importCountry.guid);
            const refNumber = encodeURIComponent(record.referenceNumber);
            const issueDate = encodeURIComponent(record.issueDateTime);
            const params = `${guid}/${refNumber}/${issueDate}`;
            navigate(`/verify/${params}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pubRecordQuery.isSuccess]);

    useEffect(() => {
        setShowError(pubRecordQuery.isError);
    }, [pubRecordQuery.isError]);

    const onRecordSubmit = (data) => {
        data.referenceNumber = data.referenceNumber?.trim();
        setRecord(data);
    };

    const onRecordReset = () => {
        setShowError(false);
        reset();
    };

    return (
        <Fragment>
            <SubHeader header={t("Для сертификатов на бланке (учёт)")} />
            {showError && <Alert messageType="danger">{t("По данным параметрам поиска сертификат не найден в реестре")}</Alert>}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onRecordSubmit)} className="form-horizontal css-form">
                    <SelectField
                        name="importCountry"
                        required
                        clearable
                        labelText={t("Страна-импортёр")}
                        placeholder={t("Выберите страну...")}
                        options={countries}
                        valueKey="guid"
                        labelKey="view"
                    />
                    <InputField name="referenceNumber" required maxLength={255} labelText={t("Номер сертификата")} />
                    <DateField
                        name="issueDateTime"
                        required
                        validateDateFormat
                        labelText={t("Дата оформления сертификата")}
                        placeholder={t("дд.мм.гггг")}
                    />
                    <div className="clearfix form-actions no-margin-bottom col-xs-12">
                        <div className="pull-right">
                            <Button type="reset" id="blank__clear_btn" text={t("Очистить")} size="sm" icon="eraser" onClick={onRecordReset} />
                            &nbsp;&nbsp;
                            <Button
                                type="submit"
                                id="blank__search_btn"
                                color="info"
                                size="sm"
                                icon={pubRecordQuery.isLoading ? "circle-o-notch fa-spin" : "search"}
                                disabled={pubRecordQuery.isLoading}
                                text={t("Поиск")}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Fragment>
    );
};

export default RecordSearchPanel;
