import PropTypes from "prop-types";
import React from "react";

const UserShortInfo = ({ user, avatarSrc, handleClick }) => {
    return (
        <a id="user_info__dropdown_btn" onClick={handleClick} className="dropdown-toggle">
            <img className="nav-user-photo" src={avatarSrc} alt="" />
            <span className="user-info">
                {user.fio && <small> {user.fio} </small>}
                {user.login && !user.fio && <small> {user.login} </small>}
                {user.institution && user.institution.shortName && <small>{user.institution.shortName}</small>}
            </span>
            <i className="ace-icon fa fa-caret-down" />
        </a>
    );
};

UserShortInfo.propTypes = {
    user: PropTypes.shape({
        fio: PropTypes.string,
        login: PropTypes.string,
        institution: PropTypes.shape({
            shortName: PropTypes.string,
        }),
    }),
    avatarSrc: PropTypes.string,
    handleClick: PropTypes.func,
};

UserShortInfo.defaultProps = { user: {} };

export default UserShortInfo;
