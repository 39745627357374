import { Alert, Button } from "vetrf-ui";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getBooleanAsText } from "../../../../../utils/viewUtils";
import { getUnknownNormalText } from "../../../../../utils/constants/constants";
import { getWbrApplicationPaymentTypeView } from "../../../../../utils/constants/wbr/wbr-application-payment-type";
import { useUserContext } from "../../../../../session/UserContextProvider";
import { useWbrApplication } from "../../../api/WbrApplicationQueries";
import { useWbrApplicationConfirmPaymentMutation } from "../../../api/WbrApplicationStatusQueries";
import { WBR_APP_BREADCRUMBS, WBR_APP_PAYMENT_ACTIONS, WBR_APP_ROUTE } from "../../../WbrApplicationRoutes";
import BlinkingFetchingContainer from "../../../../../components/fetching/BlinkingFetchingContainer";
import BlockHeader from "../../../../../components/page/BlockHeader";
import BlockTable from "../../../../../components/table/BlockTable";
import ButtonWithConfirmation from "../../../../../components/modal/ButtonWithConfirmation";
import Container from "../../../../../components/page/Container";
import FormActionsPanel from "../../../../../components/page/FormActionsPanel";
import IconedLink from "../../../../../components/page/IconedLink";
import ModalBox from "../../../../../components/modal/ModalBox";
import PageContent from "../../../../../components/page/PageContent";
import {
    getWbrApplicationInvoiceFileUrl,
    getWbrApplicationInvoiceSpecificationFileUrl,
    useWbrApplicationInvoice,
    useWbrApplicationInvoicePayment,
} from "../../../api/WbrApplicationDocumentsQueries";

const WbrApplicationPaymentPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { user } = useUserContext();

    const { data: application, isLoading: isApplicationLoading } = useWbrApplication(id);
    const { data: invoice, isLoading: isInvoiceLoading } = useWbrApplicationInvoice(id, application?.invoiceInfo?.invoice.id ?? false);
    const confirmPayment = useWbrApplicationConfirmPaymentMutation(id);
    const { data: invoicePayment, isLoading: isPaymentLoading } = useWbrApplicationInvoicePayment(
        id,
        application?.invoicePaymentInfo?.invoicePayment.id ?? false
    );

    const title = WBR_APP_ROUTE.PAYMENT_VIEW.getTitle(t);
    const isLoading = isApplicationLoading || isInvoiceLoading;
    const isUserLaboratory = application?.laboratory.guid === user?.institution.guid;
    const invoiceInfo = { ...application?.invoiceInfo, invoice };
    const invoicePaymentInfo = { ...application?.invoicePaymentInfo, invoicePayment };

    const onConfirmPaymentHandler = () => {
        confirmPayment.mutate(null);
    };

    const unknown = getUnknownNormalText(t);

    const toolbar = (
        <Fragment>
            {invoicePayment && !invoicePaymentInfo.isConfirmed && (
                <ButtonWithConfirmation
                    button={(onClick) => (
                        <Button
                            id="confirm_payment_btn"
                            text={t("Подтвердить получение средств")}
                            size="sm"
                            icon="check-square-o"
                            color="success"
                            onClick={onClick}
                        />
                    )}
                    modal={(handleClose) => (
                        <ModalBox
                            header={t("Подтвердить получение средств")}
                            closeAfterConfirm
                            onConfirm={onConfirmPaymentHandler}
                            onClose={handleClose}
                            children={t("Вы дейтсвительно хотите подтвердить факт получения средств?")}
                        />
                    )}
                />
            )}
        </Fragment>
    );

    const pageToolbar = (
        <Fragment>
            {toolbar}
            <Button id="back_btn" text={t("Назад")} size="sm" icon="arrow-left" href={WBR_APP_ROUTE.VIEW.getFullPath(id)} />
        </Fragment>
    );

    const invoicePaymentRows = [
        {
            header: {
                key: "status",
                title: t("Статус"),
            },
            dataFormatter: () =>
                invoicePayment ? (
                    <Fragment>
                        {t("Оплачено")} {invoicePaymentInfo.isConfirmed && `(${t("Потверждено")})`}
                    </Fragment>
                ) : (
                    t("Ожидание оплаты")
                ),
            getCellClass: () => (invoicePayment ? "dark-blue light-green-background" : "dark-blue light-orange-background"),
        },
        {
            header: {
                key: "paymentOrderNumber",
                title: t("Номер платежного поручения"),
            },
            dataFormatter: (item) => item.paymentOrderNumber ?? unknown,
        },
        {
            header: {
                key: "paymentDate",
                title: t("Дата приема поручения к исполнению"),
            },
            dataFormatter: (item) => item.paymentDate ?? unknown,
        },
        {
            header: {
                key: "paymentPurpose",
                title: t("Назначение платежа"),
            },
            dataFormatter: (item) => item.paymentPurpose ?? unknown,
        },
    ];

    const invoiceTypeRows = [
        {
            header: {
                key: "invoiceFile",
                title: t("Файл"),
            },
            dataFormatter: (item) =>
                item.invoice?.invoiceFile ? (
                    <IconedLink.Download href={getWbrApplicationInvoiceFileUrl(application.id)} title={t("Скачать")} />
                ) : (
                    unknown
                ),
        },
        {
            header: {
                key: "paymentType",
                title: t("Вид оплаты"),
            },
            dataFormatter: (item) => getWbrApplicationPaymentTypeView(item.paymentType, t) ?? unknown,
        },
    ];

    const invoiceDataRows = [
        {
            header: {
                key: "totalAmount",
                title: t("Сумма счета (руб.)"),
            },
            dataFormatter: (item) => item.invoice?.totalAmount ?? unknown,
        },
        {
            header: {
                key: "useVat",
                title: t("Использовать НДС"),
            },
            dataFormatter: (item) => getBooleanAsText(item.invoice?.useVat, t) ?? unknown,
        },
        {
            header: {
                key: "includeVat",
                title: t("Включить НДС в стоимость"),
            },
            dataFormatter: (item) => getBooleanAsText(item.invoice?.includeVat, t) ?? unknown,
        },
        {
            header: {
                key: "vatRate",
                title: t("Ставка НДС (%)"),
            },
            dataFormatter: (item) => item.invoice?.vatRate ?? unknown,
        },
        {
            header: {
                key: "vatAmount",
                title: t("Сумма НДС (руб.)"),
            },
            dataFormatter: (item) => item.invoice?.vatAmount ?? unknown,
        },
        {
            header: {
                key: "subTotalAmount",
                title: t("Общая сумма товаров / работ / услуг (руб.)"),
            },
            dataFormatter: (item) => item.invoice?.subTotalAmount ?? unknown,
        },
        {
            header: {
                key: "paymentPurpose",
                title: t("Назначение платежа"),
            },
            dataFormatter: (item) => item.invoice?.paymentPurpose ?? unknown,
        },
        {
            header: {
                key: "bic",
                title: t("БИК банка получателя"),
            },
            dataFormatter: (item) => item.invoice?.paymentRecipient?.bic ?? unknown,
        },
        {
            header: {
                key: "bankName",
                title: t("Наименование банка получателя"),
            },
            dataFormatter: (item) => item.invoice?.paymentRecipient?.bankName ?? unknown,
        },
        {
            header: {
                key: "paymentAccountNumber",
                title: t("Номер расчетного счета получателя"),
            },
            dataFormatter: (item) => item.invoice?.paymentRecipient?.paymentAccountNumber ?? unknown,
        },
        {
            header: {
                key: "correspondentAccount",
                title: t("Корреспондентский номер счета банка получателя"),
            },
            dataFormatter: (item) => item.invoice?.paymentRecipient?.correspondentAccountNumber ?? unknown,
        },
        {
            header: {
                key: "inn",
                title: t("ИНН получателя платежа"),
            },
            dataFormatter: (item) => item.invoice?.paymentRecipient?.inn ?? unknown,
        },
        {
            header: {
                key: "specification",
                title: t("Спецификация"),
            },
            dataFormatter: (item) =>
                item.invoice?.specificationFile?.length > 0 ? (
                    <IconedLink.Download href={getWbrApplicationInvoiceSpecificationFileUrl(application.id)} title={t("Скачать")} />
                ) : (
                    unknown
                ),
        },
    ];

    const getPageAlert = () => {
        if (!application) return;
        if (!invoice && !isLoading) {
            return (
                <Alert messageType="info">
                    <i className="fa fa-info-circle" />
                    &nbsp;&nbsp;
                    {t("Сведения для оплаты еще не добавлены")}{" "}
                    <Link to={WBR_APP_ROUTE.PAYMENT_ACTION.getFullPath(id, WBR_APP_PAYMENT_ACTIONS.EDIT)}>
                        <u>{t("Добавить")}</u>
                    </Link>
                </Alert>
            );
        }
    };

    return (
        <PageContent
            title={title}
            chapter={WBR_APP_ROUTE.PAYMENT_VIEW.getChapter(t)}
            middleBreadcrumbs={WBR_APP_BREADCRUMBS.getViewBreadcrumbs(id)}
            toolbar={pageToolbar}
            isLoading={isLoading}
            canAccess={isUserLaboratory}
        >
            <Container>{getPageAlert()}</Container>
            {invoice && (
                <Fragment>
                    <Container>
                        {invoice.id && (
                            <BlinkingFetchingContainer isFetching={isPaymentLoading}>
                                <BlockHeader>{t("Сведения об оплате выставленного счета экспортером")}</BlockHeader>
                                <BlockTable data={invoicePayment} rows={invoicePaymentRows} />
                            </BlinkingFetchingContainer>
                        )}
                        <BlockHeader>{t("Файл счета и дополнительные сведения")}</BlockHeader>
                        <BlockTable data={invoiceInfo} rows={invoiceTypeRows} />
                        <BlockHeader>{t("Сведения для оплаты")}</BlockHeader>
                        <BlockTable data={invoiceInfo} rows={invoiceDataRows} />
                    </Container>
                    <FormActionsPanel>
                        <Button id="return_btn" text={t("Назад")} size="sm" icon="bars" href={WBR_APP_ROUTE.VIEW.getFullPath(id)} />
                        <FormActionsPanel.RightSide>{toolbar}</FormActionsPanel.RightSide>
                    </FormActionsPanel>
                </Fragment>
            )}
        </PageContent>
    );
};

export default WbrApplicationPaymentPage;
