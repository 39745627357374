import React, { useEffect, useState, useRef, useCallback } from "react";
import "moment/locale/ru";
import moment from "moment";
import { Button } from "vetrf-ui";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { fillNotificationEvent } from "../../notification-utils";
import { useUnreadNotificationsPageMutation } from "../../systemNotificationQuery";
import { useSystemNotifications } from "./SystemNotificationProvider";
import useIntersectionObserver from "../../../utils/useIntersectionObserver";

const NotificationButton = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation("translation");
    moment.locale(i18n.language);

    const [notifications, setNotifications] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownMenuRef = React.createRef();

    const pageParams = useRef({ page: 1, size: 10, offset: 0 });
    const notificationsMutation = useUnreadNotificationsPageMutation();
    const isNewNotificationsFetching = useRef(false);

    const [nextButtonRef, nextButtonEntry] = useIntersectionObserver({ threshold: 0 }, false);

    useEffect(() => {
        if (!notifications?.content.length && !notifications?.totalPages) fetchNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // если пользователь пометил как прочитанные несколько уведомлений и отображается меньше 10,
        // и при этом есть еще незагруженные уведомления, то загружаем их
        if (notifications?.content.length < 10 && notifications?.number + 1 < notifications?.totalPages && !isNewNotificationsFetching.current) {
            fetchNewNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications?.content]);

    useEffect(() => {
        // простейшая реализация бесконечной прокрутки, когда кнопка "Загрухить еще" попадает в поле зрения
        // то загружается следующая страница уведомлений
        if (nextButtonEntry.isIntersecting && notifications?.number + 1 < notifications?.totalPages && !isNewNotificationsFetching.current) {
            fetchNewNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextButtonEntry.isIntersecting, notifications]);

    // outside click detection
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownMenuRef]);

    function handleClickOutside(e) {
        if (!dropdownMenuRef.current.contains(e.target)) {
            setDropdownOpen(false);
        }
    }

    const onNewNotificationsFetch = useCallback(
        (data) => {
            if (data?.content.length > 0) {
                data.content.forEach((x) => {
                    x.newest = true;
                    fillNotificationEvent(x.event, t);
                });

                let targetNotifications = {
                    ...notifications,
                    content: [...data.content, ...notifications.content],
                };
                targetNotifications.totalElements += data.totalElements;
                targetNotifications.totalPages = Math.ceil(targetNotifications.totalElements / notifications.size);

                // если еше не все страницы загружены
                if (notifications.totalPages - 1 > notifications.number) {
                    if (data.totalElements >= notifications.size) {
                        // если новых уведолмений пришло больше чем вмещает страница, то увеличиваем текущую страницу
                        // а то что не поместилось на одну страницу отбрасываем (страница полностью не загружена)
                        targetNotifications.number += Math.floor(data.totalElements / notifications.size);
                        if (data.totalElements !== notifications.size) {
                            targetNotifications.content.splice(-(data.totalElements % notifications.size));
                        }
                    } else {
                        // отбрасываем неполную страницу (так как она еще полностью не загружена)
                        targetNotifications.content.splice(-data.totalElements);
                    }
                } else {
                    targetNotifications.number = targetNotifications.totalPages - 1;
                }
                setNotifications(targetNotifications);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notifications]
    );

    // notification events
    const onNotificationMarkAsRead = useCallback(
        (notification) => {
            if (notifications) {
                let targetNotifications = {
                    ...notifications,
                    content: [...notifications?.content],
                };
                targetNotifications.totalElements--;
                const index = targetNotifications.content.findIndex((x) => x.id === notification.id);
                if (index >= 0) {
                    targetNotifications.content.splice(index, 1);
                }
                setNotifications(targetNotifications);
            }
        },
        [notifications]
    );

    const onNotificationMarkAsReadSuccessfully = () => {
        pageParams.current.offset--;
    };

    const onAllNotificationsMarked = useCallback(() => {
        setNotifications({
            ...notifications,
            totalElements: 0,
            totalPages: 0,
            number: 0,
            content: [],
        });
    }, [notifications]);

    const { afterDate, setAfterDate, markAsRead, markAllAsRead } = useSystemNotifications({
        onNewNotificationFetched: onNewNotificationsFetch,
        onNotificationMarked: onNotificationMarkAsRead,
        onNotificationMarkedSuccessfully: onNotificationMarkAsReadSuccessfully,
        onAllNotificationsMarked: onAllNotificationsMarked,
    });

    // fetching
    const fetchNotifications = () => {
        notificationsMutation.mutate(
            { pageParams: { ...pageParams.current, beforeDate: afterDate } },
            {
                onSuccess: (data) => {
                    if (data?.content) {
                        if (data.content.length) {
                            if (data.number === 0) {
                                setAfterDate(Date.parse(data.content[0].event.appearTime));
                            }
                            data.content.forEach((x) => fillNotificationEvent(x.event, t));
                            if (!notifications) {
                                setNotifications(data);
                            } else {
                                let targetNotifications = {
                                    ...notifications,
                                    content: [...notifications.content, ...data.content],
                                };
                                targetNotifications.number = data.number;
                                targetNotifications.totalPages = data.totalPages;
                                setNotifications(targetNotifications);
                            }
                        } else {
                            setAfterDate(Date.now());
                        }
                    }
                    isNewNotificationsFetching.current = false;
                },
            }
        );
    };

    // click events
    const fetchNewNotifications = () => {
        isNewNotificationsFetching.current = true;
        pageParams.current.page++;
        fetchNotifications();
    };

    const onNotificationClick = (item) => {
        setDropdownOpen(false);
        markAsRead(item);
    };

    const onAllNotificationsMarkAsRead = () => {
        setDropdownOpen(false);
        if (notifications?.content?.length) {
            markAllAsRead();
        }
    };

    const onOpenNotificationSettings = () => {
        setDropdownOpen(false);
        navigate("/user-settings/");
    };

    const markAsReadHandler = (notification) => {
        if (notification) {
            let tagetNotifications = {
                ...notifications,
                content: [...notifications?.content],
            };
            const index = tagetNotifications.content.findIndex((x) => x.id === notification.id);
            if (index >= 0) {
                tagetNotifications.content.splice(index, 1);
            }
            setNotifications(tagetNotifications);

            markAsRead(notification);
        }
    };

    return (
        <li className={dropdownOpen ? "open" : ""}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="notification_dropdown" onClick={() => setDropdownOpen(!dropdownOpen)} className="dropdown-toggle">
                <span className={`fa fa-bell`} />
                &nbsp;{notifications?.totalElements ?? 0}
            </a>
            <ul
                className="notify-menu user-menu pull-right dropdown-menu dropdown-menu-right dropdown-yellow dropdown-caret dropdown-close padding-0"
                ref={dropdownMenuRef}
            >
                <div className="notify-menu-header">
                    <span>{t("Уведомления")}</span>
                    <span className="notify-menu-button">
                        <Button
                            onClick={onOpenNotificationSettings}
                            id={"notify_menu_settings"}
                            onlyIcon
                            icon="cog"
                            iconSize={130}
                            tooltip={t("Настройки")}
                        />
                    </span>
                    <span className="notify-menu-button">
                        <Button
                            disabled={!notifications?.content.length}
                            onClick={onAllNotificationsMarkAsRead}
                            id={"notify_menu_mark_all"}
                            onlyIcon
                            icon="check"
                            iconSize={130}
                            tooltip={t("Пометить все как прочитанные")}
                        />
                    </span>
                </div>
                <div className="notify-list">
                    {notifications?.content.map((item, index) => (
                        <div key={item.id} className="notify-item">
                            <Link
                                to={`/${t(item.event.eventType.systemMessageTemplate.onClickLink, item.event)}`}
                                onClick={() => onNotificationClick(item)}
                                id={`notifications_list__item_${index}`}
                            >
                                <p>{t(item.event.eventType.systemMessageTemplate.body, item.event)}</p>
                                <div>
                                    <span className="notify-time">{moment(item.event.appearTime).fromNow()}</span>
                                </div>
                            </Link>
                            {!item.isRead && (
                                <div className="float-right">
                                    <Button
                                        onClick={() => markAsReadHandler(item)}
                                        id={`notifications_list__item_mark_${index}`}
                                        onlyIcon
                                        icon="close"
                                        iconSize={90}
                                        tooltip={t("Пометить как прочитанное")}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <div ref={nextButtonRef}>
                        {notifications?.number + 1 < notifications?.totalPages && !isNewNotificationsFetching.current && (
                            <div className="notify-button" id="notifications_list__load_more" onClick={fetchNewNotifications}>
                                {t("Загрузить еще")}
                            </div>
                        )}
                    </div>
                    {!notifications?.content?.length && !(notifications?.number + 1 < notifications?.totalPages) && (
                        <p className="text-align-center">{t("Список новых уведомлений пуст")}</p>
                    )}
                </div>
                <hr className="margin-0" />
                <Link to={"/notifications/"} onClick={() => setDropdownOpen(false)} className="notify-button" id="notifications_list__view_all">
                    {t("Смотреть все")}
                </Link>
            </ul>
        </li>
    );
};
export default NotificationButton;
