import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../../common/utils/constants";

export const { businessParticipants } = createActions({
    BUSINESS_PARTICIPANTS: {
        ...loadActions,
        PAGE: loadActions,
        SAVE: asyncActions,
        DELETE: asyncActions,
        ITEM: loadActions,
        ENTITY_AUTOCOMPLETE: loadActions,
        ENTITY_AUTOCOMPLETE_RESET: null,
        AUTOCOMPLETE_RESET: null,
    },
});
