import React, { Suspense, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "flag-icons/css/flag-icons.css";

import AppUpdateChecker from "./info/AppUpdateChecker";
import BusinessParticipantRoutes from "./dictionary/BusinessParticipantRoutes";
import DepartmentRoutes from "./department/DepartmentRoutes";
import ErrorBoundary from "./errors/ErrorBoundary";
import FetchingPageIndicator from "./components/fetching/FetchingPageIndicator";
import Footer from "./components/menu/Footer";
import InfoAlert from "./common/info/components/InfoAlert";
import InternalErrorPage from "./errors/InternalErrorPage";
import LanguageDropdown from "./components/menu/navbar/LanguageDropdown";
import Navbar from "./components/menu/navbar/Navbar";
import NotificationButton from "./system-notification/containers/components/NotificationButton";
import NotificationContainer from "./common/notification/components/NotificationContainer";
import OfficialVeterinarianRoutes from "./dictionary/OfficialVeterinarianRoutes";
import PageNotFound from "./errors/PageNotFound";
import PermissionDenied from "./errors/PermissionDenied";
import PrivateContent from "./components/private-route/PrivateContent";
import ProcessingRoutes from "./processing/ProcessingRoutes";
import PubCheckAuthenticityPub from "./pub/containers/PubCheckAuthenticityPub";
import PubViewShortUrl from "./pub/containers/PubViewShortUrl";
import RequireAuth from "./components/private-route/RequireAuth";
import Sidebar from "./components/menu/sidebar/Sidebar";
import SystemNotificationsRoutes from "./system-notification/SystemNotificationsRoutes";
import UserSettingsRoutes from "./user-settings/UserSettingsRoutes";
import avatarImage from "./assets/img/user.jpg";
import logoImage from "./assets/img/dragon.svg";
// import logoImage from "./assets/img/irena-6.png";
import navListItems from "./utils/nav-list-items";
import { hasSomeAuthority } from "./utils/authority-utils";
import { useUserContext } from "./session/UserContextProvider";
import {
    application_view_all,
    application_view_org,
    food_cert_view_all,
    food_cert_view_org,
    import_cert_view,
    rabies_protocol_view_all,
    rabies_protocol_view_org,
    record_view_all,
    record_view_org,
    user_list_view,
    user_rights_assignment,
    wbr_application_view_all,
    wbr_application_view_org,
    wbr_conclusion_record_view,
} from "./common/constants/permissions";
import { PROFILE, getCurrentProfile } from "./utils/constants/external-services-base-url";

const ApplicationRoutes = React.lazy(() => import("./application/ApplicationRoutes"));
const ECertRoutes = React.lazy(() => import("./ecert/ECertRoutes"));
const AdminRoutes = React.lazy(() => import("./admin/AdminRoutes"));
const PubRoutes = React.lazy(() => import("./pub/PubRoutes"));
const RecordRoutes = React.lazy(() => import("./record/RecordRoutes"));
const DocumentTypeRoutes = React.lazy(() => import("./document-type/DocumentTypeRoutes"));
const DictionaryRoutes = React.lazy(() => import("./dictionary/DictionaryRoutes"));
const WbrConclusionRoutes = React.lazy(() => import("./wbr/WbrConclusionRoutes"));
const WbrApplicationRoutes = React.lazy(() => import("./wbr/application/WbrApplicationRoutes"));
const ProtocolRoutes = React.lazy(() => import("./protocol/rabies-protocol/ProtocolRoutes"));

const languages = [
    { code: "en", name: "EN - English" },
    { code: "ru", name: "RU - Русский" },
];

const App = () => {
    const { t, i18n } = useTranslation();
    const [isShowMenu, setIsShowMenu] = useState(false);
    const { user } = useUserContext();

    useEffect(() => {
        const ele = document.getElementById("ipl-progress-indicator");
        if (ele) {
            setTimeout(() => {
                ele.classList.add("available");
                setTimeout(() => {
                    ele.outerHTML = "";
                }, 2000);
            }, 1000);
        }
    }, []);

    const isActive = (navListItem) => {
        return "hover";
    };

    const menuToggleHandler = () => {
        setIsShowMenu(!isShowMenu);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const isAuthorized = !!user?.id;
    const shortcuts = {
        btnSuccess: {
            id: "sidebar_shortcuts__vet_eagle",
            href: "http://www.fsvps.ru/",
            title: t("Официальный сайт Россельхознадзора"),
            icon: "vet vet-eagle",
        },
        btnInfo: {
            id: "sidebar_shortcuts__vet_vetis",
            href: "http://vetrf.ru/",
            title: t("Ветис"),
            icon: "vet vet-vetis",
        },
        btnWarning: {
            id: "sidebar_shortcuts__wiki",
            href: "http://help.vetrf.ru/wiki/",
            title: t("Справка"),
            icon: "fa fa-users",
        },
    };
    const navList = {
        navListItems: navListItems(t),
        isActive: isActive,
        userAuthorities: user?.authorities,
        hasSomeAuthority: hasSomeAuthority,
    };

    const title = getCurrentProfile() === PROFILE.DEMO ? "ECert.DEMO" : "ECert";
    const logo = {
        image: {
            style: {
                height: "45px",
                marginTop: "-7px",
            },
            source: logoImage,
        },
        text: title,
    };
    const navbarMenu = (
        <li>
            <PrivateContent someAuthority={[user_list_view, user_rights_assignment]}>
                <Link id="user_info__dropdown_btn__admin" to={"/admin/"}>
                    <i className="ace-icon fa fa-wrench" />
                    {t("Админка")}
                </Link>
            </PrivateContent>
            <Link id="user_info__dropdown_btn__user_setting" to={"/user-settings/"}>
                <i className="ace-icon fa fa-cog" />
                {t("Настройки")}
            </Link>
        </li>
    );

    const navbarButtons = (
        <React.Fragment>
            <LanguageDropdown changeLanguage={changeLanguage} chosenLanguage={i18n.language} languages={languages} />
            {isAuthorized && <NotificationButton />}
        </React.Fragment>
    );

    return (
        <div>
            <Navbar
                user={user}
                logo={logo}
                avatarSrc={avatarImage}
                navbarMenu={navbarMenu}
                navbarButtons={navbarButtons}
                menuToggleHandler={menuToggleHandler}
            />
            <div className="main-container ace-save-state">
                <Sidebar navList={navList} shortcuts={shortcuts} showMenu={isShowMenu} />
                <div className="main-content">
                    <InfoAlert />
                    <ErrorBoundary fallback={InternalErrorPage}>
                        <Suspense fallback={<FetchingPageIndicator />}>
                            <Routes>
                                <Route exact path="/" element={<PubCheckAuthenticityPub />} />
                                <Route path="/info/forms/*" element={<DocumentTypeRoutes />} />
                                <Route path="/pub/*" element={<PubRoutes />} />
                                <Route path="/verify/*" element={<PubRoutes />} />
                                <Route path="/c/:shortUrl" element={<PubViewShortUrl />} />
                                <Route
                                    path="/application/*"
                                    element={
                                        <RequireAuth someAuthority={[application_view_all, application_view_org]}>
                                            <ApplicationRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/cert/*"
                                    element={
                                        <RequireAuth someAuthority={[food_cert_view_all, food_cert_view_org]}>
                                            <ECertRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/record/*"
                                    element={
                                        <RequireAuth someAuthority={[record_view_all, record_view_org]}>
                                            <RecordRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/wbr-conclusion/record/*"
                                    element={
                                        <RequireAuth authority={wbr_conclusion_record_view}>
                                            <WbrConclusionRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/wbr-conclusion/application/*"
                                    element={
                                        <RequireAuth someAuthority={[wbr_application_view_all, wbr_application_view_org]}>
                                            <WbrApplicationRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/protocol/*"
                                    element={
                                        <RequireAuth someAuthority={[rabies_protocol_view_all, rabies_protocol_view_org]}>
                                            <ProtocolRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/notifications/*"
                                    element={
                                        <RequireAuth>
                                            <SystemNotificationsRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="/admin/*"
                                    element={
                                        <RequireAuth someAuthority={[user_list_view, user_rights_assignment]}>
                                            <AdminRoutes />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="/import/cert/*"
                                    element={
                                        <RequireAuth authority={import_cert_view}>
                                            <ProcessingRoutes />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="/user-settings/*"
                                    element={
                                        <RequireAuth>
                                            <UserSettingsRoutes />
                                        </RequireAuth>
                                    }
                                />

                                <Route path="/catalogs/*" element={<DictionaryRoutes />} />
                                <Route path="/departments/*" element={<DepartmentRoutes />} />
                                <Route path="/participants/*" element={<BusinessParticipantRoutes />} />
                                <Route path="/veterinarians/*" element={<OfficialVeterinarianRoutes />} />
                                <Route path="/saml" element={<PermissionDenied />} />
                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </Suspense>
                    </ErrorBoundary>
                </div>
            </div>
            <Footer>
                <p>
                    {logo.text} {process.env.REACT_APP_VERSION} &copy; 2007 &ndash; 2024 &nbsp;
                    {t("Федеральная служба ветеринарного и фитосанитарного надзора (Россельхознадзор)")}
                </p>
                <p>
                    {t("По вопросам технической поддержки обращайтесь по адресу")}
                    &nbsp;<a href="mailto:ecert@fsvps.ru">ecert@fsvps.ru</a>
                </p>
            </Footer>
            <NotificationContainer />
            <AppUpdateChecker />
        </div>
    );
};

export default App;
