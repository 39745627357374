import { useMutation, useQuery } from "react-query";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

import { queryClient } from "./../index";
import ajax from "./ajax";

export function usePageQuery(baseUrl, pageParams, searchParams, options = {}) {
    const request = ajax(addSeparatorIfNeed(baseUrl));
    return useQuery({
        ...options,
        keepPreviousData: true,
        enabled: !!pageParams,
        queryKey: [baseUrl, pageParams, searchParams],
        queryFn: () =>
            request({
                type: "POST",
                url: setQueryParams("page", pageParams),
                data: searchParams,
            }),
    });
}
export function useListQuery(baseUrl, searchParams, options = {}) {
    const request = ajax(baseUrl);
    return useQuery({
        ...options,
        keepPreviousData: true,
        queryKey: [baseUrl, searchParams],
        queryFn: () => request({ type: "GET", url: setQueryParams("", searchParams) }),
    });
}

/**
 * @param {string} baseUrl - request url
 * @param {object} query - query settings object
 * @param {string} [query.method="GET"] - request method
 * @param {object} [query.params] - query params
 * @param {object} [query.body] - request body
 * @param {object} [query.headers] - request headers
 * @param {object} [options] - react-query options
 */
export function useCustomQuery(baseUrl, query, options = {}) {
    const request = ajax(baseUrl);
    return useQuery({
        ...options,
        keepPreviousData: true,
        queryKey: [baseUrl, query.body, query.params],
        queryFn: () =>
            request({
                type: query.method ?? "GET",
                url: setQueryParams("", query.params),
                data: query.body,
                headers: query.headers,
            }),
    });
}

export function useOneItemQuery(baseUrl, id, options = {}) {
    const request = ajax(addSeparatorIfNeed(baseUrl));
    return useQuery({
        ...options,
        queryKey: [baseUrl, id],
        queryFn: () => request({ type: "GET", url: id }),
        enabled: !!id,
    });
}

export function useCreateMutation(baseUrl, options = {}) {
    const request = ajax(baseUrl);
    const createRequest = (data) => {
        return request({ type: "POST", data });
    };

    return useMutation({
        ...options,
        mutationFn: createRequest,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([baseUrl]);
            if (data) {
                queryClient.setQueryData([baseUrl, data.id], data);
            }
        },
    });
}

/**
 * @callback requestCallback
 * @param {object} data - mutate variables
 */

/**
 * @param {string} baseUrl - request url
 * @param {requestCallback} requestCallback - callback to set request settings (ajax.js) from mutate variables
 * @param {object} [options] - react-query options
 *
 * @example
 * const createRequest = data => ({
 *     type: "POST",
 *     url: `${data.id}/markAsRead`,
 * });
 *
 * return useCustomMutation(baseUrl, createRequest, {});
 */
export function useCustomMutation(baseUrl, requestCallback, options = {}) {
    const request = ajax(baseUrl);
    const createRequest = (data) => {
        return request(requestCallback(data));
    };

    return useMutation({
        ...options,
        mutationFn: createRequest,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([baseUrl]);
            queryClient.invalidateQueries([baseUrl, data.id]);
            if (data) {
                queryClient.setQueryData([baseUrl, data.id], data);
            }
        },
    });
}

export function useUpdateMutation(baseUrl, options = {}) {
    const request = ajax(baseUrl);
    const createRequest = (data) => {
        return request({ type: "PUT", url: data.id ?? null, data });
    };

    return useMutation({
        ...options,
        mutationFn: createRequest,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([baseUrl]);
            queryClient.invalidateQueries([baseUrl, data.id]);
            if (data) {
                queryClient.setQueryData([baseUrl, data.id], data);
            }
        },
    });
}

export function useDeleteMutation(baseUrl, options = {}) {
    const request = ajax(baseUrl);
    const deleteRequest = (data) => {
        return request({ type: "DELETE", url: data.id });
    };

    return useMutation({
        ...options,
        mutationFn: deleteRequest,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([baseUrl]);
            queryClient.invalidateQueries([baseUrl, data.id]);
            if (data) {
                queryClient.setQueryData([baseUrl, data.id], data);
            }
        },
    });
}

export function useActionMutation(baseUrl, id, action, options) {
    const request = ajax(baseUrl);
    const deleteRequest = (data) => {
        return request({ type: "POST", url: id + action, data });
    };

    return useMutation({
        ...options,
        mutationFn: deleteRequest,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([baseUrl]);
            queryClient.invalidateQueries([baseUrl, data.id]);
            if (data) {
                queryClient.setQueryData([baseUrl, data.id], data);
            }
        },
    });
}

export function setQueryParams(baseUrl, params) {
    const addParam = (url, name, value) => {
        if (value === undefined || value === null || (Array.isArray(value) && !value.length)) {
            return url;
        }
        url += url.includes("?") ? "&" : "?";
        if (Array.isArray(value)) {
            value.forEach((value, index) => {
                url += `${index === 0 ? "" : "&"}${name}=${encodeURIComponent(value.toString().trim())}`;
            });
        } else {
            url += `${name}=${encodeURIComponent(value.toString().trim())}`;
        }
        return url;
    };
    let url = baseUrl;
    if (url[url.length - 1] === "/") url.slice(0, -1);
    for (const key in params) {
        url = addParam(url, key, params[key]);
    }
    return url;
}

export function createFormData(appends) {
    const formData = new FormData();
    const appendData = (key, value) => {
        if (value instanceof File || value instanceof Blob) {
            formData.append(key, value, value.name ?? "unknown");
        } else {
            formData.append(key, new Blob([JSON.stringify(value)], { type: "application/json" }));
        }
    };

    for (const key in appends) {
        if (Object.hasOwnProperty.call(appends, key)) {
            const objectToAppend = appends[key];
            if (isArray(objectToAppend) && (objectToAppend[0] instanceof File || objectToAppend[0] instanceof Blob)) {
                objectToAppend.forEach((object) => appendData(key, object));
            } else {
                appendData(key, objectToAppend);
            }
        }
    }
    return formData;
}

export function addSeparatorIfNeed(baseUrl) {
    if (baseUrl[baseUrl.length - 1] !== "/") {
        baseUrl += "/";
    }
    return baseUrl;
}

/**
 * @param {number} retryCount - количетсво повторений
 * @param {object} [options] - параметры фильтрации повторений
 * @param {(string|string[])} [options.notRetryCode] - код (или список) при котором повтора не будет (например, код о том, что сущность не найдена)
 * @returns {Function}
 */
export function filterRetry(retryCount = 5, options = { notRetryCode: undefined }) {
    return (failureCount, error) => {
        if (!isEmpty(options.notRetryCode) && error.errorList) {
            const notRetryCodes = Array.isArray(options.notRetryCode) ? options.notRetryCode : [options.notRetryCode];
            if (isHasErrorCode(error, notRetryCodes)) {
                return false;
            }
        }
        return failureCount < retryCount;
    };
}

export function filterError(callback, options = { ignoreCodes: [] }) {
    return (error) => {
        if (!isHasErrorCode(error, options.ignoreCodes)) {
            callback(error);
        }
    };
}

function isHasErrorCode(error, codes) {
    if (error.errorList) {
        if (error.errorList.some((error) => codes.includes(error.code))) return true;
    }
    return false;
}
