import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, deleteIn, InputFormGroup, RowEditTable, SelectFormGroup, setIn } from "vetrf-ui";
import { isEmptyValidationError } from "../../../../utils/isEmptyAfterOmitEmpty";
import { ERROR, UPDATE, VALID } from "../../../../components/sidePanel/constants/FormStatus";
import { indexOf, isNull } from "lodash";
import TransportEquipmentSpoiler from "../../../../components/transport/TransportEquipmentSpoiler";
import { useTranslation } from "react-i18next";
import WbrConclusionMultipleTransportField from "./WbrConclusionMultipleTransportField";

const WbrConclusionTransportForm = ({
    conclusion,
    onChangeHandler,
    submitHandler,
    statusHandler,
    previousButtonHandler,
    transportTypes,
    transportEquipmentTypes,
}) => {
    const { t } = useTranslation("translation");
    const invalidFields = useRef({});
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keydownHandler = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            nextOnClickHandler();
        }
    };

    const onChange = (value, path) => {
        statusHandler(UPDATE);
        onChangeHandler && onChangeHandler(value, path);
    };

    const addNewTransportRow = () => {
        const transportMovements = conclusion?.consignment?.transportMovements || [{ orderNumber: 0 }];
        const newTransportMovements = transportMovements.slice();
        newTransportMovements.push({ orderNumber: transportMovements.length });
        onChange(newTransportMovements, "conclusion.consignment.transportMovements");
    };

    const nextOnClickHandler = () => {
        setShowError(true);
        setTimeout(() => {
            const status = isEmptyValidationError(invalidFields) ? VALID : ERROR;
            submitHandler(status);
        }, 300);
    };

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const removeTransportEquipment = (item) => {
        const items = conclusion.consignment.transportEquipments;
        const index = indexOf(items, item);
        const transportEquipments = deleteIn(items, `[${index}]`);
        onChange(transportEquipments, `conclusion.consignment.transportEquipments`);
    };

    const rowFormField = (item, onChangeField, showError, errorHandler, formEditRowId) => {
        return (
            <Fragment>
                <SelectFormGroup
                    labelText={t("Тип оборудования")}
                    require
                    clearable
                    options={transportEquipmentTypes}
                    valueKey="id"
                    onChange={onChangeField}
                    field={"type"}
                    value={item && item.type}
                    showError={showError}
                    errorHandler={errorHandler}
                    placeholder={t("Тип оборудования...")}
                    prefixId={formEditRowId}
                />
                <InputFormGroup
                    labelText={t("Номер")}
                    onChange={onChangeField}
                    field={"number"}
                    value={item && item.number}
                    showError={showError}
                    errorHandler={errorHandler}
                    maxLength={255}
                    prefixId={formEditRowId}
                />
                <InputFormGroup
                    labelText={t("Номер пломбы")}
                    onChange={onChangeField}
                    field={"affixedSeal"}
                    value={item && item.affixedSeal}
                    showError={showError}
                    maxLength={255}
                    errorHandler={errorHandler}
                    prefixId={formEditRowId}
                />
            </Fragment>
        );
    };

    const handleChangeRow = (item, index) => {
        if (isNull(index)) {
            const transportEquipments = conclusion.consignment.transportEquipments;
            const items = transportEquipments ? transportEquipments : [];
            onChange(items.concat(item), `conclusion.consignment.transportEquipments`);
        } else {
            const itemPath = `conclusion.consignment.transportEquipments[${index}]`;
            onChange(item, itemPath);
        }
    };

    const columns = [
        {
            key: "type",
            title: t("Тип"),
            dataFormatter: (item) => (item.type ? item.type.name : "-"),
        },
        {
            key: "number",
            title: t("Номер"),
            dataFormatter: (item) => item.number || "-",
        },
        {
            key: "affixedSeal",
            title: t("Номер пломбы"),
            dataFormatter: (item) => item.affixedSeal || "-",
        },
        {
            key: "actions",
            title: "",
            width: "90px",
            buttonFormatter: {
                deleteBtn: {
                    action: removeTransportEquipment,
                    confirmHeaderText: t("Удаление сведений об оборудовании"),
                    confirmBodyText: t("Вы действительно хотите удалить сведения об используемом оборудовании?"),
                },
            },
        },
    ];

    return (
        <Fragment>
            <div className="form-horizontal css-form">
                <WbrConclusionMultipleTransportField
                    conclusion={conclusion}
                    transportTypes={transportTypes}
                    onChange={onChange}
                    showError={showError}
                    errorHandler={errorHandler}
                />
            </div>
            <TransportEquipmentSpoiler open fixed>
                <div className={"wraptablebig"}>
                    <RowEditTable
                        id="conclusion__transport_equipments"
                        columns={columns}
                        dataList={conclusion.consignment.transportEquipments}
                        rowFormField={rowFormField}
                        handleChangeRow={(item, index) => handleChangeRow(item, index)}
                        buttonAddRow={true}
                        formStyles={{
                            height: "350px",
                        }}
                    />
                </div>
            </TransportEquipmentSpoiler>

            <div className="clearfix form-actions no-margin-bottom col-xs-12">
                <Button id="back_btn" text={t("Назад")} size="sm" icon="arrow-left" onClick={previousButtonHandler} />
                &nbsp;&nbsp;
                <div className="pull-right actions">
                    <Button
                        id="transport__add_btn"
                        text={t("Добавить новое транспортное средство")}
                        size="sm"
                        color="success"
                        icon="plus"
                        onClick={addNewTransportRow}
                    />
                    <Button id="next_btn" text={t("Применить")} size="sm" color="info" icon="check" onClick={nextOnClickHandler} />
                </div>
            </div>
        </Fragment>
    );
};

export default WbrConclusionTransportForm;
