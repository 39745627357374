import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ajax from "../../../utils/ajax";
import { completeLoading, startLoading } from "../../../common/store/loading/service";
import { getECertPageParams, getECertSearchParams, getECertBadgeSearchParams, getECertSearchIsValidForm } from "../selectors";
import { notifyError } from "../../../common/store/notification/service";
import { deleteIn, setIn } from "vetrf-ui";
import { isEmptyValidationError } from "../../../utils/isEmptyAfterOmitEmpty";

let request = ajax("/rest-api/ecert/");

export const requestECertList = createAsyncThunk("ecertList/page", async (arg, { dispatch, getState }) => {
    dispatch(startLoading());

    const pageParams = getECertPageParams(getState());
    const searchParams = getECertSearchParams(getState());
    const badgeSearchParams = getECertBadgeSearchParams(getState());
    const isValidForm = getECertSearchIsValidForm(getState());
    let url = "";
    if (pageParams) {
        url = `?page=${pageParams.page + 1}&size=${pageParams.size}`;
        if (pageParams.sort && pageParams.sort.property && pageParams.sort.direction) {
            url += `&sort=${pageParams.sort.property},${pageParams.sort.direction}`;
        }
    }
    return request({
        type: "POST",
        url: url,
        data: isValidForm ? searchParams : badgeSearchParams,
    })
        .catch((error) => {
            dispatch(notifyError(error, "Произошла ошибка при загрузке списка сертификатов."));
        })
        .finally(() => {
            dispatch(completeLoading());
        });
});

const initialState = {
    result: {},
    pageParams: {
        number: 0,
        page: 0,
        size: 10,
    },
    search: {
        searchParams: {},
        isParamsChanged: false,
        invalidFields: {},
        isValidForm: true,
        badgeSearchParams: {},
        showFilter: false,
    },
};

const ecertListSlice = createSlice({
    name: "ecertList",
    initialState,
    reducers: {
        toggleFilter: (state, action) => {
            state.search.showFilter = !state.search.showFilter;
            state.search.searchParams = state.search.badgeSearchParams;
            state.search.isValidForm = true;
            state.search.invalidFields = {};
        },
        initializeSearchParams: (state, action) => {
            state.search.searchParams = action.payload;
            state.search.isParamsChanged = false;
        },
        updateSearchParams: (state, action) => {
            state.search.searchParams = action.payload;
            state.search.isParamsChanged = true;
        },
        changePage: (state, action) => {
            state.pageParams.page = action.payload - 1; // в интерфейсе страницы с 1
        },
        changeSize: (state, action) => {
            state.pageParams.size = action.payload;
            state.pageParams.page = 0;
        },
        updatePageParams: (state, action) => {
            state.pageParams = action.payload;
        },
        updateInvalidFields: (state, action) => {
            if (action.payload.hasError) {
                state.search.invalidFields = setIn(state.search.invalidFields, action.payload.field, action.payload.hasError);
            } else {
                state.search.invalidFields = deleteIn(state.search.invalidFields, action.payload.field);
            }
            state.search.isValidForm = isEmptyValidationError(state.search.invalidFields);
        },
    },
    extraReducers: {
        [requestECertList.pending]: (state) => {
            if (state.search.isValidForm) {
                state.search.badgeSearchParams = state.search.searchParams;
            }
        },
        [requestECertList.fulfilled]: (state, action) => {
            state.result = action.payload;
        },
    },
});

export const { initializeSearchParams, updateSearchParams, updatePageParams, updateInvalidFields, toggleFilter, changePage, changeSize } = ecertListSlice.actions;

export default ecertListSlice.reducer;
