import { NumberField } from "vetrf-ui";

import withOnChange from "./hoc/withOnChange";

/**
 * @param {callback} onChange - обратный вызов при изменении значения
 */
const ENumberField = ({ ...props }) => {
    return <NumberField {...props} />;
};

export default withOnChange(ENumberField);
