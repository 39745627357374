import isArray from "lodash/isArray";
import { BooleanSelectField, DateRangeField, InputField, SelectField } from "vetrf-ui";
import { t } from "i18next";
import React, { Fragment } from "react";

import { getQueryContent } from "./CatalogFormBuilder";
import FilterBadge from "../../filterBadge/FilterBadge";

export const getFieldForm = (fieldKey, form, isActive) => {
    const name = fieldKey;
    const label = form.title ?? t("Поиск");
    const labelClassName = "col-sm-3 control-label no-padding-right" + (isActive ? " active-filter-label" : "");
    switch (form.type) {
        case "checkbox":
            return (
                <BooleanSelectField
                    name={name}
                    clearable
                    options={[
                        { label: t("Да"), value: true },
                        { label: t("Нет"), value: false },
                    ]}
                    labelText={label}
                    placeholder={form.placeholder ?? t("Выберите...")}
                    help={form.help}
                    labelClassName={labelClassName}
                />
            );
        case "select": {
            const options = form.options ?? getQueryContent(form.useOptionsListQuery) ?? [];
            return (
                <SelectField
                    name={name}
                    clearable
                    isResultValueKey={form.isObjectSelect === false}
                    multiple={form.multiple}
                    labelKey={form.labelKey}
                    valueKey={form.valueKey}
                    options={isArray(options) ? options : []}
                    labelText={label}
                    placeholder={form?.placeholder ?? `${t("Выберите")} ${label.toLowerCase()}...`}
                    help={form.help}
                    labelClassName={labelClassName}
                />
            );
        }
        case "dateRange":
            return (
                <DateRangeField
                    name={{ beginDate: `${name}.startDateTime`, endDate: `${name}.endDateTime` }}
                    labelText={label}
                    placeholder={form?.placeholder ?? "дд.мм.гггг"}
                    help={form.help}
                    labelClassName={labelClassName}
                />
            );
        default:
            return (
                <InputField
                    name={name}
                    className="width-max"
                    labelText={label}
                    placeholder={form.placeholder ?? `${label}...`}
                    help={form.help}
                    labelClassName={labelClassName}
                />
            );
    }
};

const badgeKeyPrefix = "filter-badge";
export const getBadge = (fieldKey, schema, values, onRemove) => {
    const id = `${badgeKeyPrefix}__${fieldKey}`;
    const form = schema.fields[fieldKey];
    let value;

    switch (form.type) {
        case "select":
            if (form.multiple === true) {
                return (
                    <Fragment key={fieldKey}>
                        {isArray(values[fieldKey]) &&
                            values[fieldKey].map((v, index) => {
                                const valueToSearch = form.isObjectSelect !== false ? v[form.valueKey] : v;
                                const value = form.options?.find((x) => x[form.valueKey] === valueToSearch)?.[form.labelKey];
                                return (
                                    <FilterBadge
                                        key={fieldKey + index}
                                        id={`${id}_${index}`}
                                        text={value}
                                        handleClick={() => onRemove(`${fieldKey}[${index}]`)}
                                    />
                                );
                            })}
                    </Fragment>
                );
            } else {
                const valueToSearch = form.isObjectSelect !== false ? values[fieldKey]?.[form.valueKey] : values[fieldKey];
                value = form.options?.find((x) => x[form.valueKey] === valueToSearch)?.[form.labelKey];
            }
            break;
        case "checkbox":
            if (values[fieldKey] != null) {
                value = values[fieldKey] === true ? t("Да") : t("Нет");
            }
            break;
        case "dateRange":
            if (values[fieldKey] != null) {
                value = `${values[fieldKey].startDateTime ?? "..."} - ${values[fieldKey].endDateTime ?? "..."}`;
            }
            break;
        default:
            value = values[fieldKey];
            break;
    }

    if (!value) return <Fragment key={fieldKey}></Fragment>;
    return <FilterBadge key={fieldKey} id={id} text={`${form.title}: ${value}`} handleClick={() => onRemove(fieldKey)} />;
};
