import { handleActions } from "redux-actions";
import { generateKey } from "../../../utils/panelItemGenerate";
import { ecert, ecertList } from "./actions";

const initialState = {
    eCert: {},
    list: {},
    filterObj: {},
    searchFormValue: {},
    error: null,
    loading: false,
    saving: false,
};

const fillEcert = (eCert) => {
    eCert.consignment.transportEquipments.forEach(generateKey);
    return eCert;
};

export default handleActions(
    {
        [ecertList.load.request]: (state, action) => ({
            ...state,
            loading: true,
        }),
        [ecertList.load.success]: (state, action) => ({
            ...state,
            list: action.payload,
        }),
        [ecertList.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
        [ecertList.filter.set]: (state, action) => ({
            ...state,
            searchFormValue: action.payload,
            filterObj: action.payload,
        }),
        [ecertList.filter.clear]: (state) => ({
            ...state,
            searchFormValue: {},
            filterObj: {},
        }),
        [ecert.load.request]: (state) => ({
            ...state,
            loading: true,
        }),
        [ecert.load.success]: (state, action) => ({
            ...initialState,
            eCert: fillEcert(action.payload),
            templates: state.templates,
        }),
        [ecert.load.error]: (state, action) => ({
            ...initialState,
            error: action.payload,
        }),
        [ecert.saving.request]: (state) => ({
            ...state,
            saving: true,
        }),
        [ecert.saving.success]: (state, action) => ({
            ...state,
            eCert: action.payload,
            error: null,
            saving: false,
        }),
        [ecert.saving.error]: (state, action) => ({
            ...state,
            error: action.payload,
            saving: false,
        }),
    },
    initialState
);
