export const wbrApplicationStatuses = {
    NEW: "NEW",
    DATA_FOR_CONTRACT_RECEIVED: "DATA_FOR_CONTRACT_RECEIVED",
    CONTRACT_PREPARING: "CONTRACT_PREPARING",
    CONTRACT_RECEIVED: "CONTRACT_RECEIVED",
    PREPAYMENT_INFO_RECEIVED: "PREPAYMENT_INFO_RECEIVED",
    IN_PROCESS: "IN_PROCESS",
    REPEAT_NEW: "REPEAT_NEW",
    RESEARCH_FINISHED: "RESEARCH_FINISHED",
    PAYMENT_INFO_RECEIVED: "PAYMENT_INFO_RECEIVED",
    ACT_RECEIVED: "ACT_RECEIVED",
    WAITING_DATA_FOR_CONTRACT: "WAITING_DATA_FOR_CONTRACT",
    WAITING_SIGN_CONTRACT: "WAITING_SIGN_CONTRACT",
    WAITING_PREPAYMENT: "WAITING_PREPAYMENT",
    WAITING_PAYMENT: "WAITING_PAYMENT",
    WAITING_CLOSING_ACT: "WAITING_CLOSING_ACT",
    SENT_FOR_REVISION: "SENT_FOR_REVISION",
    REJECTED: "REJECTED",
    COMPLETED: "COMPLETED",
    AUTOMATICALLY_CLOSED: "AUTOMATICALLY_CLOSED",
};

export const getWbrApplicationStatuses = (t) => {
    return Object.keys(wbrApplicationStatuses).map((key) => ({
        id: wbrApplicationStatuses[key],
        view: getWbrApplicationStatusView(wbrApplicationStatuses[key], t),
    }));
};

export const getWbrApplicationStatusView = (status, t) => {
    switch (status) {
        case wbrApplicationStatuses.NEW:
            return t("Новая");
        case wbrApplicationStatuses.DATA_FOR_CONTRACT_RECEIVED:
            return t("Получены сведения для договора");
        case wbrApplicationStatuses.CONTRACT_PREPARING:
            return t("Подготовка договора");
        case wbrApplicationStatuses.CONTRACT_RECEIVED:
            return t("Договор получен");
        case wbrApplicationStatuses.PREPAYMENT_INFO_RECEIVED:
            return t("Получены сведения о предоплате");
        case wbrApplicationStatuses.IN_PROCESS:
            return t("На рассмотрении");
        case wbrApplicationStatuses.REPEAT_NEW:
            return t("После доработки");
        case wbrApplicationStatuses.RESEARCH_FINISHED:
            return t("Исследование завершено");
        case wbrApplicationStatuses.PAYMENT_INFO_RECEIVED:
            return t("Получены сведения об оплате");
        case wbrApplicationStatuses.ACT_RECEIVED:
            return t("Получен акт выполненных работ");
        case wbrApplicationStatuses.WAITING_DATA_FOR_CONTRACT:
            return t("Ожидание сведений для договора");
        case wbrApplicationStatuses.WAITING_SIGN_CONTRACT:
            return t("Ожидание подписания договора");
        case wbrApplicationStatuses.WAITING_PREPAYMENT:
            return t("Ожидание предоплаты");
        case wbrApplicationStatuses.WAITING_PAYMENT:
            return t("Ожидание оплаты");
        case wbrApplicationStatuses.WAITING_CLOSING_ACT:
            return t("Ожидание акта");
        case wbrApplicationStatuses.SENT_FOR_REVISION:
            return t("На доработке");
        case wbrApplicationStatuses.REJECTED:
            return t("Отклонена");
        case wbrApplicationStatuses.COMPLETED:
            return t("Обработана");
        case wbrApplicationStatuses.AUTOMATICALLY_CLOSED:
            return t("Закрыта автоматически");
        default:
            return t("Неизвстно");
    }
};

export const isWbrApplicationRejected = (application) => {
    if (!application) return false;
    return application.status === wbrApplicationStatuses.REJECTED;
};

export const isWbrApplicationFinishing = (application) => {
    if (!application) return false;
    return isWbrAppInStatus(application, [
        wbrApplicationStatuses.RESEARCH_FINISHED,
        wbrApplicationStatuses.WAITING_PAYMENT,
        wbrApplicationStatuses.PAYMENT_INFO_RECEIVED,
        wbrApplicationStatuses.WAITING_CLOSING_ACT,
        wbrApplicationStatuses.ACT_RECEIVED,
        wbrApplicationStatuses.COMPLETED,
    ]);
};

export const isWbrApplicationClosed = (application) => {
    if (!application) return false;
    return isWbrAppInStatus(application, [
        wbrApplicationStatuses.REJECTED,
        wbrApplicationStatuses.COMPLETED,
        wbrApplicationStatuses.AUTOMATICALLY_CLOSED,
    ]);
};

export const isWbrAppInStatus = (application, statuses = []) => {
    if (!application) return false;
    return statuses.includes(application.status);
};
