import { useEffect } from "react";
import logger from "../base/LogManager";

const ErrorEventLogProducer = () => {
    useEffect(() => {
        window.onerror = (message, url, lineNumber, columnNumber, error) => onErrorEventHandler(error, message);
        window.error = (message, url, lineNumber, columnNumber, error) => onErrorEventHandler(error, message);
        window.addEventListener("error", (event) => onErrorEventHandler(event?.error ?? event?.reason));
        window.addEventListener("unhandledrejection", (event) => onErrorEventHandler(event?.error ?? event?.reason));
        return () => {
            window.removeEventListener("error", (event) => onErrorEventHandler(event?.error ?? event?.reason));
            window.removeEventListener("unhandledrejection", (event) => onErrorEventHandler(event?.error ?? event?.reason));
        };
    }, []);

    function onErrorEventHandler(error, message) {
        const errorMessage = (error && (error.name || error.message) ? `${error?.name || "Error"}: ${error?.message}` : "Unknown error") || message;
        const stack =
            error?.stack &&
            (error.stack.includes(error.name) || error.stack.includes(error.message)
                ? error.stack.split(/\r?\n/).slice(1, 8).join("\n")
                : error.stack);
        logger.error(`${errorMessage}${stack ? `\n${stack}` : ""}`);
    }

    return null;
};
export default ErrorEventLogProducer;
