import { organizationList } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка организаций";

export const requestOrganizationList = () =>
    createLoadAsyncAction(
        organizationList,
        request,
        {
            type: "POST",
            url: "organizationList",
        },
        errorMessage
    );

export const requestVDOrganizationList = () =>
    createLoadAsyncAction(
        organizationList,
        request,
        {
            type: "POST",
            url: `organizationList?type=VD`,
        },
        errorMessage
    );

export const requestLabOrganizationList = () =>
    createLoadAsyncAction(
        organizationList,
        request,
        {
            type: "POST",
            url: `organizationList?type=LB,RI,RC,AH,PL`,
        },
        errorMessage
    );
