import { diseases } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка видов животных";

export const requestDiseases = () =>
    createLoadAsyncAction(
        diseases,
        request,
        {
            type: "GET",
            url: "diseases/all",
        },
        errorMessage
    );
