import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function useLocationEffect(callback) {
    const location = useLocation();
    const lastPathnameRef = useRef();

    useEffect(() => {
        const pathname = window.location.href;
        if (lastPathnameRef.current !== pathname) {
            callback?.(filterPathname(pathname));
            lastPathnameRef.current = pathname;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
}

export function filterPathname(pathname) {
    // скрываем уиды из урлов
    return pathname.replace(/\w{4}-\w{4}-\w{4}-\w{4}-\w{12}/g, "****");
}

export default useLocationEffect;
