import { SelectFormGroup } from "vetrf-ui";
import React, { Fragment } from "react";

import { PRECISION_TYPE } from "./FloatingDateFormGroup";
import FormatDateFormGroup from "./FormatDateFormGroup";

const SeparatedDateTimeFormGroup = ({ timeLabelText, valuePattern = "dd.MM.yyyy HH:mm", onChange, ...props }) => {   
    const onChangeHandler = (value, field) => {
        if (!field.endsWith("time") && !value) {
            onChange(null, props.field);
            return;
        }

        let precision = props.value?.precision;
        let date = props.value?.value.substring(0, 10);
        let time = props.value?.value.substring(11) || "00:00";

        if (!field.endsWith("time")) {
            date = value?.substring(0, 10);
            if (!precision) {
                precision = PRECISION_TYPE.DAY;
            }
        } else {
            if (value?.value) {
                time = value?.value;
                precision = PRECISION_TYPE.TIME;
            } else {
                time = "00:00";
                precision = PRECISION_TYPE.DAY;
            }
        }

        onChange({ value: `${date} ${time}`, precision }, props.field);
    };

    return (
        <Fragment>
            <FormatDateFormGroup
                {...props}
                field={props.field}
                value={props.value?.value}
                valuePattern={valuePattern}
                onChange={onChangeHandler}
            />
            <SelectFormGroup
                {...props}
                field={`${props.field}.time`}
                labelText={timeLabelText}
                value={props.value?.value && props.value?.precision === PRECISION_TYPE.TIME && { value: props.value?.value.substring(11) }}
                require={false}
                disabled={!props.value || !props.value.value}
                help={undefined}
                clearable
                options={generateTimeArray()}
                valueKey="value"
                labelKey="value"
                onChange={onChangeHandler}
            />
        </Fragment>
    );
};

function generateTimeArray() {
    let times = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j++) {
            let hour = i < 10 ? "0" + i.toString() : i.toString();
            let minute = j < 10 ? "0" + j.toString() : j.toString();
            let time = hour + ":" + minute;
            times.push({ value: time });
        }
    }
    return times;
}

export default SeparatedDateTimeFormGroup;
