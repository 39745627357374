const ItemsList = ({ items, empty }) => {
    if (!items || items.length === 0) {
        return empty;
    } else if (items.length === 1) {
        return items[0];
    } else {
        return (
            <ul className="items-list">
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    }
};

export default ItemsList;
