import { createActions } from "redux-actions";
import { loadActions } from "../../../common/utils/constants";

export const { countries } = createActions({
    COUNTRIES: loadActions,
});
export const { supportedCountries } = createActions({
    SUPPORTED_COUNTRIES: loadActions,
});
export const { euCountries } = createActions({
    EU_COUNTRIES: loadActions,
});
