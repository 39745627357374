import { dateTimeFormats } from "./actions";
import ajax from "../../../utils/ajax";
import { createLoadAsyncAction } from "../../../common/utils/service-utils";

const request = ajax("/rest-api/dictionary/");
const errorMessage = "Произошла ошибка при загрузке списка видов животных";

export const requestDateTimeFormats = () =>
    createLoadAsyncAction(
        dateTimeFormats,
        request,
        {
            type: "GET",
            url: "dateTimeFormats",
        },
        errorMessage
    );
