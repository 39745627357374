import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AuthorizationDropdown from "./authorization-dropdown/AuthorizationDropdown";
import FetchingIndicator from "../../fetching/FetchingIndicator";
import Logo from "./Logo";
import MenuToggler from "./MenuToggler";
import NavbarButtons from "./NavbarButtons";

class Navbar extends Component {
    render() {
        const { logo, user, navbarMenu, avatarSrc, navbarButtons, menuToggleHandler, showLoading } = this.props;
        return (
            <Fragment>
                {showLoading && (
                    <div className="ipl-progress-indicator-loader" id="ipl-progress-indicator-loader">
                        <div className="ipl-progress-indicator-head">
                            <div className="first-indicator"></div>
                            <div className="second-indicator"></div>
                        </div>
                    </div>
                )}
                <FetchingIndicator />
                <div className="navbar navbar-default" id="navbar">
                    <div className="navbar-container" id="navbar-container">
                        <MenuToggler menuToggleHandler={menuToggleHandler} />
                        <Logo image={logo.image} text={logo.text} />
                        <NavbarButtons>
                            {navbarButtons}
                            <AuthorizationDropdown user={user} menu={navbarMenu} avatarSrc={avatarSrc} />
                        </NavbarButtons>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Navbar.propTypes = {
    logo: PropTypes.shape({
        image: PropTypes.shape({
            className: PropTypes.string,
            source: PropTypes.string,
            style: PropTypes.object,
        }),
        text: PropTypes.string,
    }),
    user: PropTypes.shape({
        fio: PropTypes.string,
        login: PropTypes.string,
        institution: PropTypes.shape({
            shortName: PropTypes.string,
        }),
    }),
    avatarSrc: PropTypes.string,
    navbarMenu: PropTypes.node,
    navbarButtons: PropTypes.node,
};

function mapStateToProps(state) {
    return {
        showLoading: state.common.loadingStore.show,
    };
}

export default connect(mapStateToProps, {})(Navbar);
