import { notifyError } from "../store/notification/service";
import { defaultGetRequestParams } from "./constants";
import { completeLoading, startLoading } from "../store/loading/service";

export const createLoadAsyncAction =
    (actions, request, requestParams = defaultGetRequestParams, errorMessage) =>
    (dispatch) => {
        dispatch(actions.load.request());
        dispatch(startLoading());
        request(requestParams)
            .then((response) => {
                dispatch(actions.load.success(response));
                dispatch(completeLoading());
            })
            .catch((error) => {
                if (errorMessage) {
                    dispatch(notifyError(error, errorMessage));
                }
                dispatch(completeLoading());
                dispatch(actions.load.error(error));
            });
    };

export const createPageableRequestUrl = (pageable = {}, url = "") => {
    if (!pageable.page) {
        return url;
    }

    url += `?page=${pageable.page}`;
    if (pageable.count) {
        url += `&size=${pageable.count}`;
    } else if (pageable.size) {
        url += `&size=${pageable.size}`;
    }
    if (pageable.filter) {
        for (const key in pageable.filter) {
            if (pageable.filter[key]) {
                url += `&filter%5B${key}%5D=${pageable.filter[key]}`;
            }
        }
    }
    if (pageable.sorting) {
        url += `&sorting=${pageable.sorting}`;
    }

    return url;
};

export const createPageableRequestParams = (pageable, url) => ({
    type: "GET",
    url: createPageableRequestUrl(pageable, url),
});
