import React from "react";

import { ProfileTable, SubHeader } from "vetrf-ui";
import { useTranslation } from "react-i18next";
import { getUnknownCursiveText } from "../../../../utils/constants/constants";

const AdditionalInfoView = ({ department }) => {
    const { t } = useTranslation();
    const unknown = getUnknownCursiveText(t);
    const commonRows = [
        {
            header: {
                key: "shortName",
                title: t("Короткое наименование"),
            },
            dataFormatter: (item) => item?.shortName || unknown,
        },
        {
            header: {
                key: "certAddressView",
                title: t("Адрес для печати в сертификате"),
            },
            dataFormatter: (item) => item?.certAddressView || unknown,
        },
    ];

    return (
        <div className={"row"}>
            <SubHeader header={t("Дополнительные сведения")} />
            <div className="wraptablebig">
                <ProfileTable
                    id="preview__common"
                    itemList={department ? [{ item: department }] : [{}]}
                    tableClassName="table color-table-border font-weight-normal"
                    rows={commonRows}
                />
            </div>
        </div>
    );
};

export default AdditionalInfoView;
