export const COUNTRY_LIST_LOAD = "dictionary/countries/load";
export const COUNTRY_LIST_RECEIVE = "dictionary/countries/receive";
export const COUNTRY_LIST_ERROR = "dictionary/countries/load/error";

export const SUPPORTED_COUNTRY_LIST_LOAD = "dictionary/countries/supported/load";
export const SUPPORTED_COUNTRY_LIST_RECEIVE = "dictionary/countries/supported/receive";
export const SUPPORTED_COUNTRY_LIST_ERROR = "dictionary/countries/supported/load/error";

export const DOCUMENT_TYPE_LIST_LOAD = "dictionary/eCertDocumentTypes/load";
export const DOCUMENT_TYPE_LIST_RECEIVE = "dictionary/eCertDocumentTypes/receive";
export const DOCUMENT_TYPE_LIST_ERROR = "dictionary/eCertDocumentTypes/load/error";

export const TRANSPORT_TYPE_LIST_LOAD = "dictionary/transportTypes/load";
export const TRANSPORT_TYPE_LIST_RECEIVE = "dictionary/transportTypes/receive";
export const TRANSPORT_TYPE_LIST_ERROR = "dictionary/transportTypes/load/error";

export const TRANSPORT_EQUIPMENT_TYPE_LIST_LOAD = "dictionary/transportEquipmentTypes/load";
export const TRANSPORT_EQUIPMENT_TYPE_LIST_RECEIVE = "dictionary/transportEquipmentTypes/receive";
export const TRANSPORT_EQUIPMENT_TYPE_LIST_ERROR = "dictionary/transportEquipmentTypes/load/error";

export const PROCESS_TYPE_LIST_LOAD = "dictionary/processTypes/load";
export const PROCESS_TYPE_LIST_RECEIVE = "dictionary/processTypes/receive";
export const PROCESS_TYPE_LIST_ERROR = "dictionary/processTypes/load/error";

export const CLAUSE_PATTERN_LIST_LOAD = "dictionary/clausePatterns/load";
export const CLAUSE_PATTERN_LIST_RECEIVE = "dictionary/clausePatterns/receive";
export const CLAUSE_PATTERN_LIST_ERROR = "dictionary/clausePatterns/load/error";

export const RUSSIAN_REGION_LIST_LOAD = "dictionary/russianRegions/load";
export const RUSSIAN_REGION_LIST_RECEIVE = "dictionary/russianRegions/receive";
export const RUSSIAN_REGION_LIST_ERROR = "dictionary/russianRegions/load/error";

export const DATE_TIME_FORMAT_LIST_LOAD = "dictionary/dateTimeFormats/load";
export const DATE_TIME_FORMAT_LIST_RECEIVE = "dictionary/dateTimeFormats/receive";
export const DATE_TIME_FORMAT_LIST_ERROR = "dictionary/dateTimeFormats/load/error";

export const UNIT_MEASURE_LIST_LOAD = "dictionary/unitMeasures/load";
export const UNIT_MEASURE_LIST_RECEIVE = "dictionary/unitMeasures/receive";
export const UNIT_MEASURE_LIST_ERROR = "dictionary/unitMeasures/load/error";

export const USER_LIST_BY_CURRENT_USER_LOAD = "dictionary/userListByCurrentUser/load";
export const USER_LIST_BY_CURRENT_USER_RECEIVE = "dictionary/userListByCurrentUser/receive";
export const USER_LIST_BY_CURRENT_USER_ERROR = "dictionary/userListByCurrentUser/load/error";

export const ORGANIZATION_LIST_LOAD = "admin/user/organizationList/load";
export const ORGANIZATION_LIST_RECEIVE = "admin/user/organizationList/receive";
export const ORGANIZATION_LIST_ERROR = "admin/user/organizationList/load/error";

export const ANIMAL_SPECIES_LIST_LOAD = "dictionary/animal-species/load";
export const ANIMAL_SPECIES_LIST_RECEIVE = "dictionary/animal-species/receive";
export const ANIMAL_SPECIES_LIST_ERROR = "dictionary/animal-species/load/error";

export const DISEASES_LIST_LOAD = "dictionary/disease/load";
export const DISEASES_LIST_RECEIVE = "dictionary/disease/receive";
export const DISEASES_LIST_ERROR = "dictionary/disease/load/error";

export const PACKAGE_TYPE_LIST_LOAD = "dictionary/packageTypes/load";
export const PACKAGE_TYPE_LIST_RECEIVE = "dictionary/packageTypes/receive";
export const PACKAGE_TYPE_LIST_ERROR = "dictionary/packageTypes/load/error";
