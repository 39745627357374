import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Logo = ({ image, text }) => {
    return (
        <div className="navbar-header pull-left">
            <Link id="logo_btn" to={"/"} className="navbar-brand">
                <small>
                    <img className={image.className} style={image.style} src={image.source} alt="Logo" />
                    &nbsp; {process.env.NODE_ENV === "demo" ? text + "DEMO" : text}
                </small>
            </Link>
        </div>
    );
};

Logo.propTypes = {
    image: PropTypes.shape({
        className: PropTypes.string,
        source: PropTypes.string,
        style: PropTypes.object,
    }),
    text: PropTypes.string,
};

export default Logo;
