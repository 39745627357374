import { Button } from "vetrf-ui";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ModalBox from "./ModalBox";
import ShortFormProvider from "../form/ShortFormProvider";

const ModalForm = ({ defaultValues, size, closeAfterConfirm, onSubmit, footer, children, ...props }) => {
    const { t } = useTranslation();
    const methods = useForm({ defaultValues });
    const { handleSubmit } = methods;

    const onSubmitHandler = (onCloseCallback) => {
        return () => {
            handleSubmit((data) => {
                if (closeAfterConfirm) {
                    onCloseCallback();
                }
                onSubmit?.(data);
            })();
        };
    };

    const defaultFooter = (handleSubmit, handleClose) => (
        <Fragment>
            <Button id="modal__submit_btn" text={t("Отправить")} color="success" onClick={handleSubmit} />
            <Button id="modal__cancel_btn" text={t("Отмена")} color="default" onClick={handleClose} />
        </Fragment>
    );

    return (
        <ModalBox
            closeAfterConfirm={closeAfterConfirm}
            size={size ?? "lg"}
            footer={(handleClose) => (footer ?? defaultFooter)(onSubmitHandler(handleClose), handleClose)}
            {...props}
        >
            <ShortFormProvider methods={methods}>{children}</ShortFormProvider>
        </ModalBox>
    );
};

export default ModalForm;
