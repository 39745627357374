import { createActions } from "redux-actions";
import { asyncActions, loadActions } from "../../../common/utils/constants";

export const { ecertList } = createActions({
    ECERT_LIST: {
        ...loadActions,
        FILTER: {
            SET: null,
            CLEAR: null,
        },
    },
});
export const { ecert } = createActions({
    ECERT: {
        ...loadActions,
        SAVING: asyncActions,
    },
});
