import { Alert, Button, deleteIn, Form, InputFormGroup, MultipleInputFormGroup, Page, setIn, CheckboxFormGroup } from "vetrf-ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from "react";

import { getOfficeHoursView } from "../../../utils/filterDepartments";
import { isEmptyValidationError } from "../../../utils/isEmptyAfterOmitEmpty";
import { RUSSIA } from "../../../utils/constants/constants";
import { useGetDepartment, useSaveDepartmentMutation } from "../../api/departmentQuery";
import * as departmentService from "../../../dictionary/store/authorityDepartments/service";
import * as dictionaryService from "../../../dictionary/store/service";

const getTitle = (isEdit) => (isEdit ? "Редактирование " : "Добавление ") + "места оформления";

const DepartmentFormPage = ({ russianRegions, savingError, itemSaving, dictionaryService }) => {
    const { t } = useTranslation("translation");
    const { id } = useParams();
    const { data: item } = useGetDepartment(id);
    const saveMutation = useSaveDepartmentMutation();
    let navigate = useNavigate();
    const isEdit = !isEmpty(id);
    const [itemForSave, setItemForSave] = useState({
        address: {
            country: RUSSIA,
        },
        officeHours: [],
    });
    const itemForSaveRef = useRef();
    itemForSaveRef.current = itemForSave;
    const invalidFields = useRef({});
    const [showError, setShowError] = useState(false);
    const [saving, setSaving] = useState(false);

    const breadcrumbs = [
        {
            id: "breadcrumb__home_page",
            home: true,
            link: "/",
            text: t("Главная"),
        },
        {
            id: "department_list_page",
            link: "/departments/",
            text: t("Места оформления"),
        },
        {
            active: true,
            text: getTitle(isEdit),
        },
    ];

    useEffect(() => {
        if (!russianRegions || russianRegions.length === 0) {
            dictionaryService.requestRussianRegions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(item)) setItemForSave(item);
    }, [item]);

    useEffect(() => {
        if (saving && !itemSaving && savingError === null) navigate("..");
        setSaving(itemSaving);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemSaving]);

    const errorHandler = (hasError, field) => {
        invalidFields.current = hasError ? setIn(invalidFields.current, field, true) : deleteIn(invalidFields.current, field);
    };

    const onSave = () => {
        clearForm();
        if (!showError) setShowError(true);

        setTimeout(() => {
            if (isEmptyValidationError(invalidFields)) {
                saveMutation.mutate(itemForSave, {
                    onSuccess: () => {
                        navigate("..");
                    },
                });
            }
        }, 300);
    };

    const clearForm = () => {
        const removeEmptyValues = (array) => (array?.length ? array.filter((x) => !isEmpty(x.trim())) : []);

        let itemForSaveTarget = itemForSave;
        itemForSaveTarget.contactEmailList = removeEmptyValues(itemForSaveTarget.contactEmailList);
        itemForSaveTarget.contactPhoneNumberList = removeEmptyValues(itemForSaveTarget.contactPhoneNumberList);
        itemForSaveTarget.responsibleVeterinarianList = itemForSaveTarget.responsibleVeterinarianList?.length
            ? itemForSaveTarget.responsibleVeterinarianList.filter((x) => !isEmpty(x.fio.trim()))
            : [];
        itemForSaveTarget.officeHours = itemForSaveTarget.officeHours.filter((x) => !isEmpty(x.customDateInterval.trim()));
        setItemForSave(itemForSaveTarget);
        itemForSaveRef.current = itemForSaveTarget;
    };

    const onChangeHandler = (value, field) => {
        const state = itemForSaveRef.current;
        setItemForSave(setIn(state, field, value));
    };

    const onChangeOfficeHoursHandler = (day) => {
        const handler = (value, field) => {
            let officeHours = [...itemForSave.officeHours];
            const index = officeHours.findIndex((item) => item.dayOfWeek === day);
            if (!value.length) {
                if (index >= 0) officeHours.splice(index, 1);
            } else {
                if (index >= 0) {
                    officeHours[index].view = value;
                    officeHours[index].customDateInterval = value;
                } else {
                    officeHours = [...officeHours, { dayOfWeek: day, view: value, customDateInterval: value }];
                }
            }
            onChangeHandler(officeHours, field);
        };
        return handler;
    };

    const isDisabledSave = isEmpty(itemForSave) || itemSaving;

    return (
        <Page header={getTitle(isEdit)} breadcrumbs={breadcrumbs} title={getTitle(isEdit)}>
            <div className="col-lg-offset-2 col-lg-8 col-md-12">
                {savingError && (
                    <Alert messageType="danger">
                        <p>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;&nbsp;
                            <strong>Произошла ошибка при сохранении.</strong>
                        </p>
                        <p>{`${savingError.statusText} : ${savingError.message}`}</p>
                    </Alert>
                )}
                <Form name="modifyDepartmentForm">
                    <h4 className="header smaller lighter blue">{t("Сведения о площадке")}</h4>
                    <InputFormGroup
                        labelText={t("Наименование")}
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.name}
                        field="name"
                        onChange={onChangeHandler}
                        require={"Укажите название в ИС Цербер и дождитесь синхронизации"}
                        disabled
                    />
                    <InputFormGroup
                        labelText={t("Наименование на английском")}
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.englishName}
                        field="englishName"
                        onChange={onChangeHandler}
                        require={"Укажите наименование на английском в ИС Цербер и дождитесь синхронизации"}
                        disabled
                    />
                    <InputFormGroup
                        labelText="Адрес"
                        require={"Укажите адрес в ИС Цербер и дождитесь синхронизации"}
                        disabled
                        onChange={onChangeHandler}
                        field={`address.addressView`}
                        value={itemForSave && itemForSave.address && itemForSave.address.addressView}
                        maxLength={255}
                        showError={showError}
                        errorHandler={errorHandler}
                    />
                    <InputFormGroup
                        labelText="Адрес на английском"
                        require={"Укажите адрес на английском в ИС Цербер и дождитесь синхронизации"}
                        disabled
                        onChange={onChangeHandler}
                        field={`address.enAddressView`}
                        value={itemForSave && itemForSave.address && itemForSave.address.enAddressView}
                        maxLength={255}
                        showError={showError}
                        errorHandler={errorHandler}
                    />

                    <h4 className="header smaller lighter blue">Дополнительные сведения</h4>
                    <InputFormGroup
                        labelText="Короткое наименование"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.shortName}
                        field="shortName"
                        onChange={onChangeHandler}
                        require={true}
                    />
                    <InputFormGroup
                        labelText="Адрес для печати в сертификате"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.certAddressView}
                        field="certAddressView"
                        onChange={onChangeHandler}
                        require
                        validateLatin
                        help="Укажите адрес на английском языке, так как он будет печататься на сертификате"
                    />
                    <CheckboxFormGroup
                        labelText={t("Осуществляет оформление сертификатов на питомцев")}
                        showError={showError}
                        field="forPetsIssuer"
                        value={itemForSave.forPetsIssuer}
                        onChange={onChangeHandler}
                    />
                    <h4 className="header smaller lighter blue">Контактная информация</h4>
                    <MultipleInputFormGroup
                        labelText="Адреса электронной почты"
                        maxInputs={15}
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave?.contactEmailList}
                        field="contactEmailList"
                        onChange={onChangeHandler}
                        require={itemForSave.forPetsIssuer}
                    />
                    <MultipleInputFormGroup
                        labelText="Контактный телефон"
                        maxInputs={15}
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave?.contactPhoneNumberList}
                        field="contactPhoneNumberList"
                        onChange={onChangeHandler}
                        require={itemForSave.forPetsIssuer}
                    />
                    <MultipleInputFormGroup
                        labelText="Сотрудники"
                        maxInputs={15}
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.responsibleVeterinarianList?.map((i) => i.fio)}
                        field="responsibleVeterinarianList"
                        onChange={(value, field) => {
                            onChangeHandler(
                                value.map((i) => {
                                    return { fio: i };
                                }),
                                field
                            );
                        }}
                        require={itemForSave.forPetsIssuer}
                        help="Список сотрудников, который отображается на форме подачи заявки на запись"
                    />
                    <h4 className="header smaller lighter blue">Расписание работы</h4>
                    <InputFormGroup
                        labelText="Понедельник"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("MONDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("MONDAY")}
                    />
                    <InputFormGroup
                        labelText="Вторник"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("TUESDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("TUESDAY")}
                    />
                    <InputFormGroup
                        labelText="Среда"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("WEDNESDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("WEDNESDAY")}
                    />
                    <InputFormGroup
                        labelText="Четверг"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("THURSDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("THURSDAY")}
                    />
                    <InputFormGroup
                        labelText="Пятница"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("FRIDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("FRIDAY")}
                    />
                    <InputFormGroup
                        labelText="Суббота"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("SATURDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("SATURDAY")}
                    />
                    <InputFormGroup
                        labelText="Воскресенье"
                        showError={showError}
                        errorHandler={errorHandler}
                        value={itemForSave.officeHours && getOfficeHoursView("SUNDAY", itemForSave.officeHours, true)}
                        field="officeHours"
                        onChange={onChangeOfficeHoursHandler("SUNDAY")}
                    />
                </Form>
                <div className="clearfix form-actions no-margin-bottom col-xs-12">
                    <Button id="cancel_btn" size="sm" text="Отмена" onClick={() => navigate("..")} />
                    <div className="pull-right">
                        <Button id="save_btn" size="sm" color="success" onClick={onSave} text="Сохранить" disabled={isDisabledSave} />
                    </div>
                </div>
            </div>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    itemSaving: state.catalogStore.authorityDepartmentsStore.saving,
    savingError: state.catalogStore.authorityDepartmentsStore.error,
    russianRegions: state.catalogStore.russianRegionsStore.russianRegions,
});

const mapDispatchToProps = (dispatch) => ({
    departmentService: bindActionCreators(departmentService, dispatch),
    dictionaryService: bindActionCreators(dictionaryService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentFormPage);
