import React from "react";

import AccessDenied from "../../errors/AccessDenied";
import { useUserContext } from "../../session/UserContextProvider";
import { hasAuthority, hasEveryAuthority, hasSomeAuthority } from "../../utils/authority-utils";

const RequireAuth = ({ children, isAuthorized = true, authority, someAuthority, everyAuthority }) => {
    const { user, authorities } = useUserContext();

    return isAuthorized === !!user.id &&
        (!isAuthorized ||
            (authority && hasAuthority(authority, authorities)) ||
            (someAuthority && hasSomeAuthority(someAuthority, authorities)) ||
            (everyAuthority && hasEveryAuthority(everyAuthority)) ||
            (!authority && !someAuthority && !everyAuthority)) ? (
        children
    ) : (
        <AccessDenied />
    );
};

export default RequireAuth;
